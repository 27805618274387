import * as React from "react";
import {
  Button,
  CircularProgress,
  FontIcon,
  List,
  TextField,
} from "react-md";
import {connect} from "react-redux";
import {
  Link,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";

import {namedComponent} from "../../../namedComponent";

import {compileFilter} from "../filter";
import {parseQuery} from "../format";
import {ScheduleItem} from "../model";
import {
  ScheduleModelAction,
  ScheduleModelState,
} from "../model/reducer";

import { Center } from "../../layout/Center";
import {InlineBadge} from "../../layout/InlineBadge";
import {QuickFilter} from "../QuickFilter";
import {
  BEM,
  ROW_HEIGHT,
} from "./constants";
import {
  EntryTile,
} from "./EntryTile";

interface ScheduleListItem {
  item: ScheduleItem;
  isSelected: boolean;
  dispatch(action: ScheduleModelAction): void;
}

const ScheduleListItem: React.FC<ScheduleListItem> = React.memo(
  namedComponent("ScheduleListItem", (props) => {
    const [touchTapInProgress, setTouchTapInProgress] = React.useState(false);
    const [dragStart, setDragStart] =
      React.useState<[number, number]>();

    const item = props.item;

    return (
      <React.Fragment>
      <div className={`${BEM}_drawer_list_item`}>
        <div
          className={`${BEM}_drawer_list_tile`}
          style={{height: ROW_HEIGHT}}
          onMouseDown={
            (e) => setDragStart([e.clientX, e.clientY])
          }
          onMouseMove={(e) => {
            // Check if we've moved more that 5 pixels from the drag start
            // location.
            if (!dragStart) {
              return;
            }
            const dx = e.clientX - dragStart[0];
            const dy = e.clientY - dragStart[0];
            if (dx * dx + dy * dy > 25) {
              props.dispatch({
                type: "START_NEW_ENTRY",
                itemId: item.id,
              });
              setDragStart(undefined);
            }
          }}
          onMouseLeave={() => {
            if (dragStart) {
              props.dispatch({
                type: "START_NEW_ENTRY",
                itemId: item.id,
              });
              setDragStart(undefined);
            }
          }}
          onMouseUp={() => {
            if (dragStart) {
              setDragStart(undefined);
            }
          }}
          onClick={(e) => {
            props.dispatch({
              type: "START_NEW_ENTRY",
              itemId: item.id,
            });
            props.dispatch({
              type: "MOVE_ENTRY_PREVIEW_OFF_GRID",
              x: e.clientX,
              y: e.clientY,
            });
          }}
          onTouchStart={() => {
            setTouchTapInProgress(true);
          }}
          onTouchMove={() => {
            setTouchTapInProgress(false);
          }}
          onTouchEnd={(e) => {
            if (e.cancelable && touchTapInProgress) {
              setTouchTapInProgress(false);
              props.dispatch({
                type: "START_NEW_ENTRY",
                itemId: item.id,
              });
              props.dispatch({
                type: "SHOW_MESSAGE",
                message: `Tap where you would like to place ${item.displayName}.`,
              });
              e.preventDefault();
            }
          }}
        >
          <EntryTile
            displayName={item.displayName}
            emphasis={item.emphasis}
            rating={item.source.rating}
            status={item.status}
            color={item.color}
            state={props.isSelected ? "MOVING" : "LIST_ITEM"}
          />
        </div>
        <InlineBadge
          className={`${BEM}_drawer_entry_badge`}
          title={
            props.item.entries.length === 0
              ? "Not scheduled"
              : props.item.entries.length === 1
                ? "Scheduled 1 time"
                : `Scheduled ${props.item.entries.length} times`
          }
        >
          {props.item.entries.length}
        </InlineBadge>
        <Button
          icon
          component={Link}
          to={`items/${item.id}`}
        >
          info
        </Button>
      </div>
      </React.Fragment>
    );
  }),
);

function extractTerms(item: ScheduleItem) {
  const terms = [
    item.displayName,
    item.source.rating,
    item.status,
  ];
  if (item.source.orgName) {
    terms.push(item.source.orgName);
  }
  if (item.source.description) {
    terms.push(item.source.description);
  }
  if (item.source.requestedCount) {
    terms.push(item.source.requestedCount.toString());
  }
  if (item.source.ownerParticipant) {
    terms.push(item.source.ownerParticipant.displayName);
  }
  for (const part of item.participants) {
    terms.push(part.scheduleParticipant.displayName);
  }
  for (const field of item.source.customFields) {
    if (field.displayValue) {
      terms.push(field.displayValue);
    }
  }
  return terms;
}

interface ItemDrawerListProps {
  items: ReadonlyMap<string, ScheduleItem>;
  dispatch(action: ScheduleModelAction): void;
}

interface ItemDrawerListStateProps {
  selectedItemId: string|null;
}

export const ItemDrawerList =
  connect(
    (state: ScheduleModelState, ownProps: ItemDrawerListProps) => {
      return {
        selectedItemId: state.movingEntry ? state.movingEntry.itemId : null,
      };
    },
  )(
    React.memo(
      namedComponent(
        "ItemDrawerList",
        withRouter(
         (
           props: ItemDrawerListProps
            & ItemDrawerListStateProps
            & RouteComponentProps,
         ) => {
           const filter = parseQuery(props.location.search).q || "";
           const setFilter = (
             (newFilter: string) =>
             props.history.replace({
               search: `?q=${encodeURIComponent(newFilter)}`,
             })
           );
           const [loading, setLoading] = React.useState(true);

           const items = Array.from(props.items.values());
           items.sort((a, b) => a.displayName.localeCompare(b.displayName));

           const filterFunc = compileFilter(extractTerms, filter);
           const filteredItems = items.filter(filterFunc);
           const origFilteredCount = filteredItems.length;
           const filteredItemIds = new Set(
             filteredItems
             .slice(0, loading ? 50 : undefined)
             .map((item) => item.id),
           );

           if (loading) {
             setTimeout(() => setLoading(false), 0);
           }

           return (
             <div className={`${BEM}_drawer_list`}>
               <section
                 className={`md-toolbar-relative dialogs__content ${BEM}_drawer`}
               >
                 <div className={`${BEM}_drawer_list_filter_row`}>
                   <TextField
                     id="item-search"
                     leftIcon={<FontIcon>search</FontIcon>}
                     value={filter}
                     onChange={(value) => {
                       if (typeof value === "string") {
                         setFilter(value);
                       }
                     }}
                     inlineIndicator={
                       <Button
                         icon
                         inherit
                         className="inline-button"
                         onClick={() => setFilter("")}
                       >
                         close
                       </Button>
                     }
                   />
                   <QuickFilter setFilter={setFilter} />
                 </div>
                 <div
                   className={`${BEM}_drawer_list_filter_row`}
                 >
                   <Button
                     raised
                     primary
                     component={Link}
                     to="items/new"
                   >
                     New Item
                   </Button>
                 </div>
               </section>
               <section className="md-list--drawer">
                 <List
                   // Pad to prevent prevent scroll bar from changing during
                   // loading.
                   style={{minHeight: `${ROW_HEIGHT * origFilteredCount}px`}}
                 >
                   {
                     items.map(
                       (item) => (
                         (!loading || (loading && filteredItemIds.has(item.id))) ? (
                           // Visibility is set outside the component because we
                           // don't want the component to rerun on visibility
                           // changes.
                           <div key={item.id}
                             style={{
                               display:
                                 !filteredItemIds.has(item.id) ? "none" : undefined,
                             }}>
                             <ScheduleListItem
                               item={item}
                               isSelected={props.selectedItemId === item.id}
                               dispatch={props.dispatch}
                             />
                           </div>
                         ) : null
                       ),
                     )
                   }
                   {
                     items.length === 0
                       ? <Center><em>No items found</em></Center>
                       : filteredItemIds.size === 0
                       ? <Center><em>No matching items found</em></Center>
                       : null
                   }
                   {
                     // If they somehow get this far down before it loads, show the
                     // loading circle.
                     loading ? (
                       <div style={{position: "sticky", top: 0}}>
                         <CircularProgress id="schedule-item-list-progress" />
                       </div>
                     ) : null
                   }
                 </List>
               </section>
             </div>
           );
         },
        ),
      ),
    ),
  );

