import * as React from "react";

export const useDragHandlers = (
  {onDrag, handleTouch}: {
    handleTouch: boolean;
    onDrag(): void;
  },
) => {
  const [mouseStart, setMouseStart] =
    React.useState<{ x: number, y: number } | null>(null);

  const handlers = React.useMemo(
    () => ({
      onMouseDown(e: React.MouseEvent) {
        if (
          e.button === 0
        ) {
          setMouseStart({
            x: e.clientX,
            y: e.clientY,
          });
          e.preventDefault();
        }
      },
      onMouseMove(e: React.MouseEvent) {
        if (
          mouseStart
          && (
            Math.abs(e.clientX - mouseStart.x) > 20
            || Math.abs(e.clientY - mouseStart.y) > 20
          )
        ) {
          onDrag();
          setMouseStart(null);
        }
      },
      onMouseLeave() {
        if (mouseStart) {
          onDrag();
          setMouseStart(null);
        }
      },
      onMouseUp() {
        if (mouseStart) {
          setMouseStart(null);
        }
      },
      ...(
        handleTouch ? {
          onTouchStart(e: React.TouchEvent) {
            setMouseStart({
              x: e.touches[0].clientX,
              y: e.touches[0].clientY,
            });
            e.preventDefault();
          },
          onTouchMove(e: React.TouchEvent) {
            if (
              mouseStart
              && (
                Math.abs(e.touches[0].clientX - mouseStart.x) > 20
                || Math.abs(e.touches[0].clientY - mouseStart.y) > 20
              )
            ) {
              onDrag();
              setMouseStart(null);
            }
            e.preventDefault();
          },
          onTouchEnd(e: React.TouchEvent) {
            if (mouseStart) {
              setMouseStart(null);
            }
            e.preventDefault();
          },
        } : {}
      ),
    }),
    [handleTouch, mouseStart, setMouseStart, onDrag],
  );

  return handlers;
};
