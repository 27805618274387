import { useFormikContext } from "formik";
import * as React from "react";
import { Checkbox } from "react-md";

import { BooleanFieldFormData } from "./FieldFormData";

export const BooleanFieldOptions: React.FC = () => {
  const form = useFormikContext<BooleanFieldFormData>();
  return (
    <>
      <Checkbox
        id="default"
        name="default"
        label="Check this field by default"
        onChange={(_, e) => form.handleChange(e)}
        checked={form.values.default || false}
      />
    </>
  );
};
