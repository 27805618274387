import {
  History,
  Location,
} from "history";
import * as React from "react";
import {
  ApolloConsumer,
} from "react-apollo";
import {CircularProgress} from "react-md";
import {Route} from "react-router-dom";

import {parseQuery} from "../format";
import {
  ScheduleModel,
  ScheduleModelChildrenProps,
} from "../model";

import { AppFrame, MediaArg, MediaType } from "../../AppFrame";
import {
  CONFLICT_PATH,
  ITEM_DETAIL_PATH,
  ITEM_EDIT_PATH,
  LIST_ITEM_PATH,
  NEW_ITEM_PATH,
} from "./constants";
import {ScheduleGrid} from "./ScheduleGrid";
import {ScheduleGridAppFrame} from "./ScheduleGridAppFrame";
import {RouteInfo} from "./util";

import {
  ScheduleGridAction,
  editItemSaga,
  selectEntrySaga,
  selectItemSaga,
  showItemListSaga,
} from "./sagas";

import "../index.scss";
import "./ScheduleGridScreen.scss";

export interface ScreenHistoryState {
  scrollToEntry?: boolean;
}

export interface ScreenProps {
  eventSlug: string;

  history: History<ScreenHistoryState>;

  drawerVisible: MediaArg;
  changeDrawerVisibility(media: MediaType, visible: boolean): void;
  logout(): void;
}

export class ScheduleGridScreen extends React.Component<ScreenProps> {
  // This is cached to allow identity comparisons.
  private cachedRouteInfo: RouteInfo|null = null;

  private unregisterHistory: () => void;
  private gridRef: React.RefObject<ScheduleGrid>;

  constructor(props: ScreenProps) {
    super(props);

    this.gridRef = React.createRef();

    this.createEditItemSaga = this.createEditItemSaga.bind(this);
    this.createSelectEntrySaga = this.createSelectEntrySaga.bind(this);
    this.createSelectItemSaga = this.createSelectItemSaga.bind(this);
    this.showItemListSaga = this.showItemListSaga.bind(this);
    this.handleHistoryChange =
      this.handleHistoryChange.bind(this);

    this.unregisterHistory =
      this.props.history.listen(this.handleHistoryChange);
  }

  componentWillUnmount() {
    this.unregisterHistory();
  }

  render() {
    const props = this.props;

    return (
      // Matching is used for drawer visibility.
      <Route
        path={[
          NEW_ITEM_PATH,
          ITEM_EDIT_PATH,
          ITEM_DETAIL_PATH,
          LIST_ITEM_PATH,
          CONFLICT_PATH,
        ]}
        exact
      >
        {
          (route) => {
            const parsedFragment = parseQuery(location.hash);
            const entryId = parsedFragment.entryId;

            let newRouteInfo: RouteInfo|null = null;
            if (route.match) {
              switch (route.match && route.match.path) {
                case ITEM_DETAIL_PATH:
                  newRouteInfo = {
                    path: ITEM_DETAIL_PATH,
                    itemId: route.match.params.itemId,
                  };
                  break;
                case ITEM_EDIT_PATH:
                  newRouteInfo = {
                    path: ITEM_EDIT_PATH,
                    itemId: route.match.params.itemId,
                  };
                  break;
                case CONFLICT_PATH:
                  newRouteInfo = { path: CONFLICT_PATH };
                  break;
                case NEW_ITEM_PATH:
                  newRouteInfo = { path: NEW_ITEM_PATH };
                  break;
                case LIST_ITEM_PATH:
                  newRouteInfo = { path: LIST_ITEM_PATH };
                  break;
              }
            }

            // Make sure that routeInfo maintains identity if unchanged.
            if (
              JSON.stringify(this.cachedRouteInfo)
              !== JSON.stringify(newRouteInfo)
            ) {
              this.cachedRouteInfo = newRouteInfo;
            }

            return (
              <ApolloConsumer>
                {(client) =>
                  <ScheduleModel
                    eventSlug={props.eventSlug}
                    whileLoading={
                      <AppFrame
                        drawerVisible={props.drawerVisible}
                        changeDrawerVisibility={props.changeDrawerVisibility}
                        eventSlug={props.eventSlug}
                        logout={props.logout}
                      >
                        {
                          () =>
                            <CircularProgress id="schedule-grid-loading" />
                        }
                      </AppFrame>
                    }
                    client={client}
                    history={props.history}
                    extraSagas={[
                      this.createEditItemSaga,
                      this.createSelectEntrySaga,
                      this.createSelectItemSaga,
                      this.showItemListSaga,
                    ]}
                    selectedEntryId={entryId || null}
                    children={
                      (
                        childProps
                          : ScheduleModelChildrenProps<ScheduleGridAction>,
                      ) =>
                      <ScheduleGridAppFrame
                        leftDrawerVisible={props.drawerVisible}
                        rightDrawerVisible={!!route.match}
                        changeNavDrawerVisibility={props.changeDrawerVisibility}
                        state={childProps.state}
                        selectedEntry={
                          childProps.state.model
                          && entryId
                            ? (childProps.state.model.entries.get(entryId) || null)
                            : null
                        }
                        eventSlug={props.eventSlug}
                        dispatch={childProps.dispatch}
                        routeInfo={this.cachedRouteInfo}
                        gridRef={this.gridRef}
                        logout={this.props.logout}
                      />
                    }
                  />
                }
              </ApolloConsumer>
            );
          }
        }
      </Route>
    );
  }

  createSelectEntrySaga() {
    return selectEntrySaga(this.props.history);
  }

  createSelectItemSaga() {
    return selectItemSaga(this.props.history);
  }

  createEditItemSaga() {
    return editItemSaga(this.props.history);
  }

  showItemListSaga() {
    return showItemListSaga(this.props.history);
  }

  handleHistoryChange(location: Location<ScreenHistoryState>) {
    const parsedFragment = parseQuery(location.hash);
    const entryId = parsedFragment.entryId;
    const isScrollToEntrySetByAction = (
      !!location.state
      && typeof location.state.scrollToEntry === "boolean"
    );
    const scrollToEntryFromAction = (
      isScrollToEntrySetByAction
      && location.state.scrollToEntry as boolean
    );
    const scrollToEntryFromNav = !isScrollToEntrySetByAction && !!entryId;
    if (
      this.gridRef.current
        && entryId
        && (scrollToEntryFromAction
        || scrollToEntryFromNav)
    ) {
      this.gridRef.current.scrollToEntry(entryId);
    }
  }
}

