import * as React from "react";
import {
  Button,
  List,
  ListItem,
} from "react-md";
import {Link} from "react-router-dom";

import {
  ScheduleParticipant,
} from "./model";

import {InviteList} from "./InviteList";
import {
  ParticipantField,
  SetNewParticipant,
} from "./ParticipantField";

import {
  fullScheduleItem_event_schedule_item,
} from "./__generated__/fullScheduleItem";

import "./index.scss";

interface ItemParticipantListProps {
  eventSlug: string;
  item: fullScheduleItem_event_schedule_item;
  allParticipants: ReadonlyArray<ScheduleParticipant>;
  createParticipantForItem: SetNewParticipant;
  addItemParticipant(itemId: string, identityId: string): void;
  removeItemParticipant(itemParticipantId: string): void;
}

export const ItemParticipantList: React.FC<ItemParticipantListProps> = (props) => {
  const sortedParticipants = [...props.item.participants];
  sortedParticipants.sort(
    (a, b) => (
      a.scheduleParticipant.displayName
      .localeCompare(b.scheduleParticipant.displayName)
    ),
  );

  return (
    <React.Fragment>
      {
        sortedParticipants.length > 0
          ? (
            <List>
              {
                sortedParticipants.map(
                  (part) => (
                    <ListItem
                      key={part.id}
                      component={Link}
                      to={
                        `/event/${props.eventSlug}/schedule/participants/${part.scheduleParticipant.identityId}`
                      }
                      primaryText={
                        part.scheduleParticipant.displayName
                      }
                      renderChildrenOutside
                    >
                      <Button
                        icon
                        tooltipLabel="Remove participant"
                        onClick={() => props.removeItemParticipant(part.id)}
                      >
                        close
                      </Button>
                    </ListItem>
                  ),
                )
              }
            </List>
          ) : <em>None</em>
      }
      <ParticipantField
        id="schedule-item-add-participant"
        label="Add participant"
        participant={null}
        participants={props.allParticipants}
        keepSetParticipant={false}
        setParticipant={(value) => {
          if (value) {
            if (value.type === "NEW") {
              props.createParticipantForItem(value);
            } else {
              props.addItemParticipant(props.item.id, value.id);
            }
          }
        }}
      />
      <InviteList
        itemId={props.item.id}
        itemName={props.item.displayName}
        defaultInviterName=""
        invites={props.item.participantInvites}
      />
    </React.Fragment>
  );
};

