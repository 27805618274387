import {DocumentNode} from "graphql";
import * as React from "react";
import {Mutation, MutationFunction} from "react-apollo";

import {ScheduleModelAction} from "../model/reducer";

import {EditItemForm} from "../EditItemForm";

import {BEM} from "./constants";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const createPersonMutation: DocumentNode = require("../createPerson.graphql");
import {
  createPerson,
  createPersonVariables,
} from "../__generated__/createPerson";

interface ItemDrawerEditProps {
  itemId: string;
  eventSlug: string;
  dispatch(action: ScheduleModelAction): void;
}

export const ItemDrawerEdit: React.FC<ItemDrawerEditProps> = (props) => {
  return (
    <section
      className={`md-toolbar-relative dialogs__content md-list--drawer ${BEM}_drawer`}
    >
      <Mutation mutation={createPersonMutation}>
        {
          (createPersonFn: MutationFunction<createPerson, createPersonVariables>) =>
          <EditItemForm eventSlug={props.eventSlug}
            submit={(formValues) => {
              const {owner, ...params} = formValues;

              if (owner && owner.type === "NEW") {
                const {type, ...personParams} = owner;
                createPersonFn({
                  variables: personParams,
                })
                .then((personResult) => {
                  if (!personResult || !personResult.data) {
                    props.dispatch({
                      type: "SHOW_MESSAGE",
                      message:
                        "Creating the person failed. Item could not be saved.",
                    });
                    return;
                  }

                  props.dispatch({
                    type: "UPDATE_SCHEDULE_ITEM",
                    itemId: props.itemId,
                    owner: personResult.data.createPerson.id,
                    ...params,
                  });
                });
              } else {
                props.dispatch({
                  type: "UPDATE_SCHEDULE_ITEM",
                  itemId: props.itemId,
                  owner: owner ? owner.id : null,
                  ...params,
                });
              }
            }}
            itemId={props.itemId}
          />
        }
      </Mutation>
    </section>
  );
};

