import { useFormikContext } from "formik";
import * as React from "react";
import { Checkbox, TextField } from "react-md";

import { TextFieldFormData } from "./FieldFormData";

export const TextFieldOptions: React.FC = () => {
  const form = useFormikContext<TextFieldFormData>();
  return (
    <div className="md-cell md-cell--12 md-text-container">
      <TextField
        id="default"
        name="default"
        label="Default text"
        rows={form.values.multiline ? 3 : undefined}
        helpText="The default option used for this field if it is not set."
        onChange={(value) => {
          if (!value) {
            form.setFieldValue("default", null);
          } else if (typeof value === "string") {
            form.setFieldValue("default", value);
          }
        }}
        value={form.values.default || ""}
      />
      <Checkbox
        id="multiline"
        name="multiline"
        label="Allow multiple lines"
        onChange={(_, e) => form.handleChange(e)}
        checked={form.values.multiline}
      />
      <TextField
        id="maximumLength"
        name="maximumLength"
        label="Maximum length"
        pattern="[0-9]*"
        helpText="The maximum number of characters allowed for this field."
        onChange={(value) => {
          if (value === "") {
            form.setFieldValue("maximumLength", null);
          } else {
            const parsedValue =
              typeof value === "number"
                ? value
                : value.match(/^[0-9]+$/)
                ? +value
                : null;
            if (
              typeof parsedValue === "number"
              // This prevents overly large numbers that get
              // displayed in exponential form. Also prevents
              // values that are not allowed by the API.
              && parsedValue < 2147483647
            ) {
              form.setFieldValue("maximumLength", parsedValue);
            }
          }
        }}
        value={form.values.maximumLength ?? ""}
        error={!!form.errors.maximumLength}
        errorText={form.errors.maximumLength}
      />
    </div>
  );
};
