import * as React from "react";
import { ListItem } from "react-md";
import { Link } from "react-router-dom";

import {
  getLocation_event_schedule_location as Location,
} from "./__generated__/getLocation";

interface LocationLinkListProps
{
  locations: ReadonlyArray<Location>;
  eventSlug: string;
}

export const LocationLinkList: React.FC<LocationLinkListProps> = (props) => {
  return (
    <ul className="md-list">
      {
        props.locations.map((loc) => {
          const encodedId = encodeURIComponent(loc.id);
          const url = `/event/${props.eventSlug}/schedule/locations/${encodedId}`;
          return (
            <Link to={url} key={loc.id}>
              <ListItem primaryText={loc.name} />
            </Link>
          );
        })
      }
    </ul>
  );
};
