import * as React from "react";

import {
  Conflict,
  ScheduleEntry,
  ScheduleLocation,
} from "../model";
import {ScheduleModelAction} from "../model/reducer";

import {
  BEM,
  PIXELS_PER_MINUTE,
  ROW_HEIGHT,
} from "./constants";
import {GridEntryTile} from "./GridEntryTile";
import {ResourceExclusionZone} from "./ResourceExclusionZone";

export interface LocationRowProps {
  y: number;
  selectedEntryState: {
    entry: ScheduleEntry,
    state: "SELECTED" | "MOVING" | "DELETING",
  }|null;
  location: ScheduleLocation;
  entries: ReadonlyMap<string, ScheduleEntry>;
  eventSlug: string;
  dispatch(action: ScheduleModelAction): void;
}

export class LocationRow extends React.Component<LocationRowProps, {}> {
  render() {
    const props = this.props;

    const conflicts = new Map<number, Conflict>();
    for (const entry of props.location.entries) {
      for (const conflict of entry.conflicts) {
        if (conflict.resource.id === props.location.id) {
          conflicts.set(
            conflict.startMinutes,
            conflict,
          );
        }
      }
    }
    const height = props.location.rowHeight * ROW_HEIGHT;

    return (
      <div className={`${BEM}_location`}
        style={{
          height,
          transform: `translateY(${props.y}px)`,
        }}
      >
        {
          Array.from(
            conflicts.values(),
            (conflict) => (
              <ResourceExclusionZone
                key={conflict.startMinutes}
                span={conflict}
              />
            ),
          )
        }
        {Array.from(props.location.entries, (entry) => {
          // If this entry is selected or ghosted, show that. If another entry
          // is selected and it conflicts with this entry, show this entry as
          // conflicted.
          const state = (
            props.selectedEntryState
              ? (
                props.selectedEntryState.entry.id === entry.id
                  ? props.selectedEntryState.state
                  : (
                    (
                      // Check if this entry conflicts with the selected entry.
                      entry.conflicts.some(
                        (conflict) => {
                          // If there's a location conflict, you know it on sight.
                          if (conflict.resource.type === "LOCATION") {
                            return false;
                          }
                          const selectedEntryId =
                            props.selectedEntryState!.entry.id;
                          return conflict.entryIds.some(
                            (id) => id === selectedEntryId,
                          );
                        },
                      ) || (
                        props.selectedEntryState.state === "MOVING"
                        && entry.item.resources.some(
                          (resource) =>
                            props.selectedEntryState!.entry.item.resources
                              .some(
                                (otherResource) => (
                                  otherResource.type === resource.type
                                  && otherResource.id === resource.id
                                ),
                              ),
                        )
                      )
                    ) ? "CONFLICT"
                      : undefined
                  )
              ) : undefined
          );

          return (
            <GridEntryTile
              key={entry.id}
              item={entry.item}
              entryId={entry.id}
              x={entry.startMinutes * PIXELS_PER_MINUTE}
              y={entry.subRow * ROW_HEIGHT}
              width={entry.durationMinutes * PIXELS_PER_MINUTE}
              state={state}
              eventSlug={props.eventSlug}
              dispatch={props.dispatch}
            />
          );
        })}
      </div>
    );
  }

  shouldComponentUpdate(newProps: Readonly<LocationRowProps>) {
    const oldProps = this.props;
    return !(
      oldProps.location.version === newProps.location.version
      && oldProps.location.maxEntryVersion === newProps.location.maxEntryVersion
      && oldProps.location.entries.length === newProps.location.entries.length
      && (
        (
          oldProps.selectedEntryState === null
          && newProps.selectedEntryState === null
        ) || (
          oldProps.selectedEntryState !== null
          && newProps.selectedEntryState !== null
          && (
            oldProps.selectedEntryState.entry.id
            === newProps.selectedEntryState.entry.id
          ) && (
            oldProps.selectedEntryState.entry.item.version
            === newProps.selectedEntryState.entry.item.version
          ) && (
            oldProps.selectedEntryState.state
            === newProps.selectedEntryState.state
          )
        )
      )
      && oldProps.location.topRow === newProps.location.topRow
      && oldProps.location.rowHeight === newProps.location.rowHeight
      && oldProps.y === newProps.y
      && oldProps.eventSlug === oldProps.eventSlug
    );
  }
}

