import {Duration} from "js-joda";
import * as React from "react";
import {
  Button,
  Cell,
  SelectField,
  TextField,
} from "react-md";
import { TextFieldComponent } from "react-md/lib/TextFields/TextField";

import {CustomFields} from "./CustomFields";
import {
  formatDuration,
  parseDuration,
  statusList,
} from "./format";
import {ScheduleParticipant} from "./model";
import {
  ParticipantField,
  ParticipantFieldValue,
} from "./ParticipantField";

import {
  FieldInstanceInput,
} from "./../../__generated__/globalTypes";
import {
  getItemForm_event_schedule_itemForm_fields,
} from "./__generated__/getItemForm";

export const filterFields = (
  fields: getItemForm_event_schedule_itemForm_fields[],
  fieldValues: FieldInstanceInput[] | null | undefined,
) => {
  if (!fieldValues) { return []; }
  const result: FieldInstanceInput[] = [];

  for (const fieldValue of fieldValues) {
    const field =
      fields.find((f) => f.name === fieldValue.name);
    if (field && field.adminWritable) {
      result.push(fieldValue);
    }
  }

  return result;
};

export interface ItemFormValues {
  event: string;
  version: number;
  displayName: string;
  status: string | null;
  orgName: string | null;
  description: string | null;
  owner: ParticipantFieldValue | null;
  requestedCount: number | null;
  requestedDuration: Duration | null;
  rating: string | null;
  customFields: FieldInstanceInput[] | null;
}

interface ItemFormProps {
  formValues: ItemFormValues;
  participants: ReadonlyArray<ScheduleParticipant>;
  customFields: getItemForm_event_schedule_itemForm_fields[];
  submitLabel: string;
  setFormValues(newValues: ItemFormValues): void;
  onSubmit(formValuesPatch: Partial<ItemFormValues>): void;
}

export const ItemForm: React.FC<ItemFormProps> = (props) => {
  const [durationStr, setDurationStr] =
    React.useState<string>(
      props.formValues.requestedDuration
        ? formatDuration(props.formValues.requestedDuration)
        : "",
    );
  const [durationError, setDurationError] = React.useState<boolean>(false);
  const titleRef = React.useRef<TextFieldComponent|null>(null);

  return (
    <form className="md-grid"
      onSubmit={(e) => {
        e.preventDefault();
        const duration = parseDuration(durationStr);
        if (durationStr) {
          if (!duration) {
            setDurationError(true);
            props.setFormValues({...props.formValues, requestedDuration: null });
            return;
          }
          setDurationStr(formatDuration(duration));
        }
        setDurationError(false);
        props.setFormValues({...props.formValues, requestedDuration: duration });
        props.onSubmit({
          ...props.formValues,
          requestedDuration: duration,
        });
      }}
  >
    <TextField
      id="schedule-item__title"
      label="Title (public)"
      required
      ref={(field) => {
        if (field && !titleRef.current) {
          // The typings on TextField are incorrect.
          // See https://github.com/mlaursen/react-md/issues/869
          titleRef.current = field as unknown as TextFieldComponent;
          titleRef.current.focus();
        }
      }}
      value={props.formValues.displayName || ""}
      onChange={(displayName) => {
        props.setFormValues({...props.formValues, displayName: "" + displayName});
      }}
    />

    <SelectField
      id="schedule-item__status"
      label="Status"
      value={props.formValues.status || "NEW"}
      fullWidth
      position={SelectField.Positions.BELOW}
      onChange={(status) => {
        props.setFormValues({...props.formValues, status: ("" + status) || "NEW" });
      }}
      required
      stripActiveItem={false}
      menuItems={statusList}
      />

    <p className="md-caption">
      Changing an item from "New", "Pending", or "Waitlisted" to "Approved" or
      "Public" will send an email to the owner of this item.
    </p>

    <TextField
      id="schedule-item__description"
      label="Description (public)"
      rows={3}
      value={props.formValues.description || ""}
      onChange={(description) => {
        if (typeof description === "string") {
          props.setFormValues({...props.formValues, description: "" + description});
        }
      }}
      />

    <TextField
      id="schedule-item__orgName"
      label="Presented by (public)"
      value={props.formValues.orgName || ""}
      onChange={(orgName) => {
        props.setFormValues({...props.formValues, orgName: "" + orgName});
      }}
      />

    <ParticipantField
      id="schedule-item__owner"
      label="Owner"
      participants={props.participants}
      participant={props.formValues.owner}
      keepSetParticipant={true}
      setParticipant={(params) =>
        props.setFormValues({
          ...props.formValues,
          owner: params,
        })
      }
    />

    {
      props.formValues.owner
      && props.formValues.owner.type === "NEW"
        ? <p>Owner profile will be created after form submission.</p>
        : null
    }

    <SelectField
      id="schedule-item__rating"
      label="Rating (public)"
      value={props.formValues.rating || "G"}
      fullWidth
      position={SelectField.Positions.BELOW}
      onChange={(rating) => {
        props.setFormValues({...props.formValues, rating: ("" + rating) || "G"});
      }}
      required
      stripActiveItem={false}
      menuItems={[
        {
          value: "G",
          label: "General audiences",
        },
        {
          value: "16+",
          label: "16+",
        },
        {
          value: "18+",
          label: "18+",
        },
      ]}
    />

    <TextField
      id="schedule-item__requestedCount"
      label="Requested Count"
      value={props.formValues.requestedCount || undefined}
      type="number"
      onChange={(count) => {
        const numVal = Number(count);
        if (typeof numVal === "number" && !isNaN(numVal)) {
          props.setFormValues({...props.formValues, requestedCount: numVal});
        } else {
          props.setFormValues({...props.formValues, requestedCount: null});
        }
      }}
      />

    <TextField
      id="schedule-item__requestedDuration"
      label="Requested Duration"
      value={durationStr}
      onChange={(value) => {
        setDurationStr(`${value}`);
      }}
      placeholder="HH:MM"
      error={durationError}
      onBlur={() => {
        if (!durationStr) {
          setDurationError(false);
          props.setFormValues({...props.formValues, requestedDuration: null });
          return;
        }

        const duration = parseDuration(durationStr);

        if (duration) {
          setDurationError(false);
          setDurationStr(formatDuration(duration));
          props.setFormValues({...props.formValues, requestedDuration: duration });
        } else {
          setDurationError(true);
          props.setFormValues({...props.formValues, requestedDuration: null });
        }
      }}
      />

    <CustomFields
      customFields={props.customFields}
      fieldValues={props.formValues.customFields || []}
      setFieldValues={
        (newFieldValues) => props.setFormValues({
          ...props.formValues,
          customFields: newFieldValues,
        })
      }
    />

    <Cell size={12}>
      <Button
        raised
        primary
        type="submit"
      >
        {props.submitLabel}
      </Button>
    </Cell>
  </form>
  );
};

