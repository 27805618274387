import {Duration} from "js-joda";
import * as React from "react";
import {ApolloConsumer} from "react-apollo";
import {RouteChildrenProps} from "react-router";
import {
  Link,
  Route,
} from "react-router-dom";
import {Saga} from "redux-saga";

import {
  Button,
  Checkbox,
  CircularProgress,
  DataTable,
  DialogContainer,
  FontIcon,
  List,
  ListItem,
  ListItemControl,
  Snackbar,
  TableBody,
  TableColumn,
  TableColumnProps,
  TableHeader,
  TableRow,
  TextField,
} from "react-md";

import {namedComponent} from "../../namedComponent";

import { AppFrame, MediaArg, MediaType } from "../AppFrame";
import {compileFilter} from "./filter";
import {
  formatDuration,
  nl2br,
  parseQuery,
  statusList,
} from "./format";
import {Schedule} from "./model";
import {
  ScheduleModel,
  ScheduleModelChildrenProps,
} from "./model/ScheduleModel";
import {QuickFilter} from "./QuickFilter";
import {selectItemSaga} from "./ScheduleItemScreen/sagas";
import {SideSheet} from "./ScheduleItemScreen/SideSheet";

import {
  scheduleGetEverything_event_schedule_itemForm_fields as CustomField,
  scheduleGetEverything_event_schedule_items as ScheduleItem,
} from "./__generated__/scheduleGetEverything";

import "./index.scss";
import "./ScheduleItemScreen.scss";

const BEM = "rw-schedule-items";

const ITEM_ROOT_PATH = "/event/:eventSlug/schedule/items/";
const ITEM_NEW_PATH = "/event/:eventSlug/schedule/items/new";
const ITEM_DETAIL_PATH = "/event/:eventSlug/schedule/items/:itemId";
const ITEM_EDIT_PATH = "/event/:eventSlug/schedule/items/:itemId/edit";

const convertDuration = (() => {
  const durationCache: Map<string, Duration> = new Map();

  return (durationStr: string) => {
    const cached = durationCache.get(durationStr);
    if (cached) {
      return cached;
    }
    const duration = Duration.parse(durationStr);
    durationCache.set(durationStr, duration);
    return duration;
  };
})();

interface SortState {
  key: string;
  ascending: boolean;
}

interface HeaderCellProps extends TableColumnProps {
  sortKey: string;
  label: string;
  sort: SortState;
  toggleSort(key: string): void;
}

const HeaderCell: React.FC<HeaderCellProps> = (props) => {
  const {sortKey, sort, label, toggleSort, ...other} = props;

  return (
    <TableColumn
      sorted={
        sort.key === sortKey
          ? sort.ascending : undefined
      }
      className={`${BEM}_table_header`}
      onClick={(e) => {
        if (
          e.ctrlKey
            || e.altKey
            || e.metaKey
            || e.shiftKey
        ) {
          return;
        }
        toggleSort(sortKey);
      }}
      {...other}
    >
      {label}
    </TableColumn>
  );
};

interface RowCellsProps {
  customFields: ReadonlyArray<CustomField>;
  columnsEnabled: ColumnsEnabled;
  item: ScheduleItem;
}

const RowCells: React.FC<RowCellsProps> = React.memo(
  namedComponent(
    "RowCells",
    ({customFields, item, columnsEnabled}) => {
      const renderText = (text: string|null) => {
        return (
          <div title={text || undefined}
            className={`${BEM}_table_column`}>
            {nl2br(text, "None")}
          </div>
        );
      };

      const statusEntry = statusList.find((s) => item.status === s.value);

      const status = statusEntry ? statusEntry.label : item.status;
      const requestedCount = (
        item.requestedCount ? item.requestedCount.toString() : null
      );
      const scheduledCount = item.entries.length.toString();
      const owner = (
        item.ownerParticipant ? item.ownerParticipant.displayName : null
      );
      const requestedDuration = (
        item.requestedDuration
          ? formatDuration(convertDuration(item.requestedDuration))
          : null
      );
      const customFieldValues =
        customFields.map(
          (field) => {
            const fieldValue = item.customFields.find(
              (f) => f.name === field.name,
            );

            return {
              name: field.name,
              value: fieldValue && fieldValue.displayValue,
            };
          },
      );

      return (
        <React.Fragment>
          {
            columnsEnabled.title
              ? (
                <td
                  className="md-table-column md-text-left md-table-column--plain md-table-column--adjusted md-text"
                  key="title">
                  <div title={item.displayName}
                    className={`${BEM}_table_column`}>
                    {item.displayName}
                  </div>
                </td>
              ) : null
          }
          {
            columnsEnabled.status
              ? (
                <td
                  className="md-table-column md-text-left md-table-column--plain md-table-column--adjusted md-text"
                  key="status">
                  {renderText(status)}
                </td>
              ) : null
          }
          {
            columnsEnabled.orgName
              ? (
                <td
                  className="md-table-column md-text-left md-table-column--plain md-table-column--adjusted md-text"
                  key="orgName">
                  {renderText(item.orgName)}
                </td>
              ) : null
          }
          {
            columnsEnabled.owner
              ? (
                <td
                  className="md-table-column md-text-left md-table-column--plain md-table-column--adjusted md-text"
                  key="owner">
                  {renderText(owner)}
                </td>
              ) : null
          }
          {
            columnsEnabled.rating
              ? (
                <td
                  className="md-table-column md-text-left md-table-column--plain md-table-column--adjusted md-text"
                  key="rating">
                  {renderText(item.rating)}
                </td>
              ) : null
          }
          {
            columnsEnabled.requestedCount
              ? (
                <td
                  className="md-table-column md-text-left md-table-column--plain md-table-column--adjusted md-text"
                  key="requestedCount">
                  {renderText(requestedCount)}
                </td>
              ) : null
          }
          {
            columnsEnabled.scheduledCount
              ? (
                <td
                  className="md-table-column md-text-left md-table-column--plain md-table-column--adjusted md-text"
                  key="scheduledCount">
                  {renderText(scheduledCount)}
                </td>
              ) : null
          }
          {
            columnsEnabled.requestedDuration
              ? (
                <td
                  className="md-table-column md-text-left md-table-column--plain md-table-column--adjusted md-text"
                  key="requestedDuration">
                  {renderText(requestedDuration)}
                </td>
              ) : null
          }
          {
            columnsEnabled.description
              ? (
                <td
                  className="md-table-column md-text-left md-table-column--plain md-table-column--adjusted md-text"
                  key="description">
                  {renderText(item.description)}
                </td>
              ) : null
          }
          {
            customFieldValues
            .filter((value) => columnsEnabled.customFields.get(value.name))
            .map(
              (value) => {
                return (
                  <td
                    className="md-table-column md-text-left md-table-column--plain md-table-column--adjusted md-text"
                    key={value.name}>
                    {renderText(value.value || null)}
                  </td>
                );
              },
            )
          }
        </React.Fragment>
      );
    },
  ),
);

interface RowProps {
  customFields: ReadonlyArray<CustomField>;
  columnsEnabled: ColumnsEnabled;
  item: ScheduleItem;
  visible: boolean;
  hideBottomBorder: boolean;
  eventSlug: string;
  isSelected: boolean;
  getFilter(): string;
  showItem(itemId: string): void;
}

const Row: React.FC<RowProps> = React.memo(
  namedComponent(
    "Row",
    (props: RowProps) => {
      const {
        visible,
        item,
        eventSlug,
        getFilter,
        showItem,
        hideBottomBorder,
        isSelected,
        ...otherProps
      } = props;

      const href = `/event/${eventSlug}/schedule/items/${item.id}`;

      return (
        <a
          className={
            `${BEM}_table_row`
            + (hideBottomBorder ? ` ${BEM}_table_row--no-border` : "")
            + (visible ? "" : ` ${BEM}_table_row--hidden`)
            + (isSelected ? ` ${BEM}_table_row--selected` : "")
          }
          href={href}
          onClick={
            (e) => {
              if (
                e.ctrlKey
                || e.altKey
                || e.metaKey
                || e.shiftKey
              ) {
                return;
              }

              showItem(item.id);
              e.preventDefault();
            }
          }>
          <RowCells item={item} {...otherProps} />
        </a>
      );
    },
  ),
);

interface ToggleColumnsProps {
  fields: CustomField[];
  columnsEnabled: ColumnsEnabled;
  setColumnsEnabled(newColumnsEnabled: ColumnsEnabled): void;
}

const ToggleColumns: React.FC<ToggleColumnsProps> = (props) => {
  const builtinItem =
    (
      key: Exclude<keyof ColumnsEnabled, "customFields">,
      label: string,
    ) => {
      const checked = props.columnsEnabled[key];
      const onChange = (
        (newChecked: boolean) =>
        props.setColumnsEnabled({
          ...props.columnsEnabled,
          [key]: newChecked,
        })
      );
      return (
        <ListItemControl
          key={key}
          tileClassName={`${BEM}_column_dialog_item`}
          primaryAction={
            <Checkbox
              id={`toggle-column-${key}`}
              name="toggle-column"
              label={label}
              value={key}
              checked={checked}
              onChange={onChange}
            />
          }
        />
      );
    };
  const customItem =
    (
      key: string,
      label: string,
    ) => {
      if (!props.columnsEnabled.customFields.has(key)) {
        return null;
      }

      const checked = props.columnsEnabled.customFields.get(key);
      const onChange = (
        (newChecked: boolean) =>
        props.setColumnsEnabled({
          ...props.columnsEnabled,
          customFields: new Map(
            [
              ...props.columnsEnabled.customFields,
              [key, newChecked],
            ],
          ),
        })
      );
      return (
        <ListItemControl
          key={key}
          tileClassName={`${BEM}_column_dialog_item`}
          primaryAction={
            <Checkbox
              id={`toggle-column-custom-${key}`}
              name="toggle-column"
              label={label}
              value={key}
              checked={checked}
              onChange={onChange}
            />
          }
        />
      );
    };

  return (
    <React.Fragment>
      {builtinItem("status", "Status")}
      {builtinItem("orgName", "Presented by")}
      {builtinItem("owner", "Owner")}
      {builtinItem("rating", "Rating")}
      {builtinItem("requestedCount", "Requested Count")}
      {builtinItem("scheduledCount", "Scheduled Count")}
      {builtinItem("requestedDuration", "Requested Duration")}
      {builtinItem("description", "Description")}
      {
        props.fields.map(
          (field) => customItem(
            field.name,
            field.label,
          ),
        )
      }
    </React.Fragment>
  );
};

function extractTerms(item: ScheduleItem) {
  const terms = [
    item.displayName,
    item.rating,
    item.status,
  ];
  if (item.orgName) {
    terms.push(item.orgName);
  }
  if (item.description) {
    terms.push(item.description);
  }
  if (item.requestedCount) {
    terms.push(item.requestedCount.toString());
  }
  if (item.ownerParticipant) {
    terms.push(item.ownerParticipant.displayName);
  }
  for (const part of item.participants) {
    if (part.identity) {
      terms.push(part.identity.displayName);
    }
  }
  for (const field of item.customFields) {
    if (field.displayValue) {
      terms.push(field.displayValue);
    }
  }
  return terms;
}

interface ItemTableBodyProps {
  filter: string;
  customFields: ReadonlyArray<CustomField>;
  columnsEnabled: ColumnsEnabled;
  items: ReadonlyArray<ScheduleItem>;
  eventSlug: string;
  selectedItem: string|null;
  showItem(itemId: string): void;
}

const ItemTableBody: React.FC<ItemTableBodyProps> = React.memo(
  namedComponent(
    "ItemTableBody",
    (props) => {
      const filterFunc = compileFilter(extractTerms, props.filter);
      const getFilter = () => props.filter;

      const rowVisible = props.items.map(filterFunc);
      const lastVisible = rowVisible.lastIndexOf(true);

      return (
        <TableBody>
          {
            props.items.map(
              (item, index) => (
                <Row key={item.id}
                  visible={rowVisible[index]}
                  customFields={props.customFields}
                  columnsEnabled={props.columnsEnabled}
                  item={item}
                  hideBottomBorder={index >= lastVisible}
                  eventSlug={props.eventSlug}
                  getFilter={getFilter}
                  isSelected={props.selectedItem === item.id}
                  showItem={props.showItem}
                />
              ),
            )
          }
        </TableBody>
      );
    },
  ),
);

export interface Props {
  eventSlug: string;
  model: Schedule|null;
  filter: string;
  selectedItem: string|null;
  setFilter(value: string): void;
  showItem(itemId: string): void;
}

interface ColumnsEnabled {
  title: boolean;
  status: boolean;
  orgName: boolean;
  owner: boolean;
  rating: boolean;
  requestedCount: boolean;
  scheduledCount: boolean;
  requestedDuration: boolean;
  description: boolean;
  customFields: Map<string, boolean>;
}

export interface State {
  sort: SortState;
  columnsEnabled: ColumnsEnabled|null;
  loading: boolean;
  columnMenuVisible: boolean;
  filterText: string;
}

export class ScheduleItemList
extends React.Component<Props, State>
{
  loadingTimeout: number|null = null;
  lastFilter: string;
  lastSort: SortState;
  lastColumnsEnabled: ColumnsEnabled|null;
  lastScheduleVersion: number = 0;
  sortedItems: ScheduleItem[];

  constructor(props: Props) {
    super(props);

    this.state = {
      sort: {
        key: "title",
        ascending: true,
      },
      columnsEnabled: null,
      loading: true,
      columnMenuVisible: false,
      filterText: props.filter,
    };
    this.lastFilter = props.filter;
    this.lastSort = this.state.sort;
    this.lastColumnsEnabled = this.state.columnsEnabled;
    this.sortedItems = [];

    this.toggleSort = this.toggleSort.bind(this);
    this.compareItems = this.compareItems.bind(this);
    this.showColumnMenu = this.showColumnMenu.bind(this);
    this.hideColumnMenu = this.hideColumnMenu.bind(this);
  }

  componentDidMount() {
    this.setupColumns(this.props);
  }

  componentWillReceiveProps(newProps: Props) {
    this.setupColumns(newProps);
    // Force a rerender when the schedule changes.
    if (
      newProps.model
      && newProps.model.version !== this.lastScheduleVersion
    ) {
      this.lastScheduleVersion = newProps.model.version;
      this.setState({ loading: true });
    }
    if (
      newProps.filter !== this.props.filter
      && this.state.filterText !== newProps.filter
    ) {
      this.setState({
        loading: true,
        filterText: newProps.filter,
      });
    }
  }

  render(): React.ReactNode {
    if (
      !this.props.model
        || !this.props.model.source
        || !this.props.model.source.event
        || !this.props.model.source.event.schedule
        || !this.state.columnsEnabled
        || !this.lastColumnsEnabled
    ) {
      return <CircularProgress id="schedule-item-list-loading" />;
    }

    const schedule = this.props.model.source.event.schedule;
    const columnsEnabled = (
      this.state.loading ? this.lastColumnsEnabled : this.state.columnsEnabled
    );

    const filter = this.state.loading ? this.lastFilter : this.props.filter;
    const sortedItems = this.sortedItems;

    if (this.state.loading) {
      if (this.loadingTimeout) {
        clearTimeout(this.loadingTimeout);
        this.loadingTimeout = null;
      }
      this.loadingTimeout = setTimeout(
        () => {
          this.props.setFilter(this.state.filterText);
          this.loadingTimeout = null;
          this.lastFilter = this.props.filter;
          this.lastSort = this.state.sort;
          this.lastColumnsEnabled = this.state.columnsEnabled;
          this.sortedItems = Array.from(schedule.items.values());
          this.sortedItems.sort(this.compareItems);

          // This has to come last because it triggers synchronous rerender.
          this.setState({ loading: false });
        },
        200,
      );
    }

    return (
      <div className={`${BEM}_main`}>
        <div className={`${BEM}_filter-container`}>
          <TextField
            id="item-search"
            className={`${BEM}_filter-container_fill-child`}
            leftIcon={<FontIcon>search</FontIcon>}
            value={this.state.filterText}
            onChange={(value) => {
              if (typeof value === "string") {
                this.setState({
                  loading: true,
                  filterText: value,
                });
              }
            }}
            inlineIndicator={
              <Button
                icon
                inherit
                className="inline-button"
                onClick={() =>
                  this.setState({
                    loading: true,
                    filterText: "",
                  })
                }
              >
                close
              </Button>
            }
          />
          <QuickFilter
            setFilter={this.props.setFilter}
          />
          <Button
            primary
            raised
            onClick={this.showColumnMenu}
          >
            Toggle columns
          </Button>
          <DialogContainer
            id="toggle-columns-dialog"
            visible={this.state.columnMenuVisible}
            title="Toggle Columns"
            paddedContent={false}
            onHide={this.hideColumnMenu}>
            <List>
              <ToggleColumns
                fields={schedule.itemForm.fields}
                setColumnsEnabled={
                  (newColumnsEnabled) => {
                    this.setState({
                      ...this.state,
                      loading: true,
                      columnsEnabled: newColumnsEnabled,
                    });
                  }
                }
                columnsEnabled={this.state.columnsEnabled!}
              />
            </List>
          </DialogContainer>
        </div>
        <DataTable
          plain
          className={`${BEM}_table_container`}
          baseId="schedule-item-table-"
        >
          <TableHeader>
            {this.renderHeaderRow()}
          </TableHeader>
          <ItemTableBody
            items={sortedItems}
            columnsEnabled={columnsEnabled}
            filter={filter}
            customFields={this.props.model.source.event.schedule.itemForm.fields}
            eventSlug={this.props.eventSlug}
            selectedItem={this.props.selectedItem}
            showItem={this.props.showItem}
          />
        </DataTable>
      </div>
    );
  }

  private renderHeaderRow() {
    if (
      !this.props.model
        || !this.props.model.source
        || !this.props.model.source.event
        || !this.props.model.source.event.schedule
        || !this.state.columnsEnabled
        || !this.lastColumnsEnabled
    ) {
      return <CircularProgress id="schedule-item-list-loading" />;
    }

    const schedule = this.props.model.source.event.schedule;
    const columnsEnabled = (
      this.state.loading ? this.lastColumnsEnabled : this.state.columnsEnabled
    );

    const customFieldHeaders = (
      schedule.itemForm.fields
      .filter((field) => columnsEnabled.customFields.get(field.name))
      .map(
        (field) => (
          <HeaderCell key={field.name}
            sortKey={field.name}
            label={field.label}
            sort={this.state.sort}
            toggleSort={this.toggleSort} />
        ),
      )
    );

    return (
      <TableRow>
        <HeaderCell key="title"
          sortKey="title"
          label="Title"
          sort={this.state.sort}
          toggleSort={this.toggleSort} />
        {
          columnsEnabled.status
            ? (
              <HeaderCell key="status"
                sortKey="status"
                label="Status"
                sort={this.state.sort}
                toggleSort={this.toggleSort} />
              ) : null
        }
        {
          columnsEnabled.orgName
            ? (
              <HeaderCell key="orgName"
                sortKey="orgName"
                label="Presented by"
                sort={this.state.sort}
                toggleSort={this.toggleSort}  />
              ) : null
        }
        {
          columnsEnabled.owner
            ? (
              <HeaderCell key="owner"
                sortKey="owner"
                label="Owner"
                sort={this.state.sort}
                toggleSort={this.toggleSort}  />
              ) : null
        }
        {
          columnsEnabled.rating
            ? (
              <HeaderCell key="rating"
                sortKey="rating"
                label="Rating"
                sort={this.state.sort}
                toggleSort={this.toggleSort}  />
              ) : null
        }
        {
          columnsEnabled.requestedCount
            ? (
              <HeaderCell key="requestedCount"
                sortKey="requestedCount"
                label="Requested Count"
                sort={this.state.sort}
                toggleSort={this.toggleSort}  />
              ) : null
        }
        {
          columnsEnabled.scheduledCount
            ? (
              <HeaderCell key="scheduledCount"
                sortKey="scheduledCount"
                label="Scheduled Count"
                sort={this.state.sort}
                toggleSort={this.toggleSort}  />
              ) : null
        }
        {
          columnsEnabled.requestedDuration
            ? (
              <HeaderCell key="requestedDuration"
                sortKey="requestedDuration"
                label="Requested Duration"
                sort={this.state.sort}
                toggleSort={this.toggleSort}  />
              ) : null
        }
        {
          columnsEnabled.description
            ? (
              <HeaderCell key="description"
                sortKey="description"
                label="Description"
                sort={this.state.sort}
                toggleSort={this.toggleSort}  />
              ) : null
        }
        {customFieldHeaders}
      </TableRow>
    );
  }

  private setupColumns(newProps: Props) {
    if (
      !this.state.columnsEnabled
        && newProps.model
        && newProps.model.source
        && newProps.model.source.event
        && newProps.model.source.event.schedule
    ) {
      const columnsEnabled = {
        title: true,
        status: true,
        orgName: false,
        owner: false,
        requestedCount: false,
        scheduledCount: true,
        requestedDuration: false,
        description: true,
        rating: true,
        customFields: new Map(
          newProps
          .model
          .source
          .event
          .schedule
          .itemForm
          .fields
          .map((field) => [field.name, false]),
        ),
      };

      this.lastColumnsEnabled = columnsEnabled;
      this.sortedItems = newProps.model.source.event.schedule.items;
      this.sortedItems.sort(this.compareItems);
      this.setState({
        ...this.state,
        columnsEnabled,
      });
    }
  }

  toggleSort(key: string) {
    this.setState({
      ...this.state,
      loading: true,
      sort: {
        key,
        ascending:
          this.state.sort.key === key ? !this.state.sort.ascending : true,
      },
    });
  }

  showColumnMenu() {
    this.setState({ columnMenuVisible: true });
  }

  hideColumnMenu() {
    this.setState({ columnMenuVisible: false });
  }

  private compareItems(item1: ScheduleItem, item2: ScheduleItem) {
    const key1 = this.extractSortKey(item1);
    const key2 = this.extractSortKey(item2);
    const ascending =
      this.state.loading ? this.lastSort.ascending : this.state.sort.ascending;
    const orderMultiplier = ascending ? 1 : -1;

    if (key1 === null && key2 === null) {
      return 0;
    }

    if (typeof key1 !== typeof key2) {
      const typeNum1 = (
        key1 === null ? 0
          : typeof key1 === "number" ? 1
          : typeof key1 === "string" ? 2
          : 3
      );
      const typeNum2 = (
        key2 === null ? 0
          : typeof key2 === "number" ? 1
          : typeof key2 === "string" ? 2
          : 3
      );
      return (typeNum1 - typeNum2) * orderMultiplier;
    }

    if (
      typeof key1 === "number"
      && typeof key2 === "number"
    ) {
      return (key1 - key2) * orderMultiplier;
    }

    if (
      typeof key1 === "string"
      && typeof key2 === "string"
    ) {
      return key1.localeCompare(key2) * orderMultiplier;
    }

    throw new Error("This should be impossible.");
  }

  private extractSortKey(item: ScheduleItem) {
    const key =
      this.state.loading ? this.lastSort.key : this.state.sort.key;
    switch (key) {
      case "title": return item.displayName;
      case "description": return item.description;
      case "status": return item.status;
      case "orgName": return item.orgName;
      case "rating": return item.rating;
      case "requestedCount":
        return (
          item.requestedCount != null ? item.requestedCount : null
        );
      case "requestedDuration":
        return (
          item.requestedDuration != null
            ? convertDuration(item.requestedDuration).toMinutes()
            : null
        );
      case "scheduledCount": return item.entries.length;
      case "owner": return item.ownerParticipant
        ? item.ownerParticipant.displayName
        : null;
      default:
        // This is going to need logic for numeric values later. But custom
        // numeric fields don't exist yet.
        const fieldInst =
          item.customFields
            .find((value) => value.name === this.state.sort.key);
        const fieldVal = fieldInst != null ? fieldInst.displayValue : null;
        return fieldVal;
    }
  }
}

interface RouteParams {
  itemId?: string;
}

interface ScheduleItemContentProps {
  modelProps: ScheduleModelChildrenProps;
  routeProps: RouteChildrenProps<RouteParams>;
  screenProps: ScreenProps;
}

const ScheduleItemContent: React.FC<ScheduleItemContentProps> =
  ({
    routeProps,
    modelProps,
    screenProps,
  }) => {
    // Create a stable reference for history.
    const historyRef = React.useRef(routeProps.history);
    historyRef.current = routeProps.history;

    const goToItemRef = React.useRef(
      (newItemId: string) => {
        historyRef.current.push({
          ...historyRef.current.location,
          pathname:
            `/event/${screenProps.eventSlug}/schedule/items/${newItemId}`,
        });
      },
    );

    const progress = (
      <CircularProgress id="schedule-item-progress" />
    );
    const query = parseQuery(routeProps.location.search);

    const messagesList = React.useMemo(
      () => modelProps.state.messages.map((message) => ({ text: message })),
      [modelProps.state.messages],
    );

    if (
      !modelProps.state.model
        || !modelProps.state.model.source.event.schedule
    ) {
      return progress;
    }

    const schedule =
      modelProps.state.model.source.event.schedule;
    const view = (
      routeProps.match
        ? (
          routeProps.match.path === ITEM_DETAIL_PATH ? "DETAIL"
            : routeProps.match.path === ITEM_EDIT_PATH ? "EDIT"
            : routeProps.match.path === ITEM_NEW_PATH ? "NEW"
            : null
        ) : null
    );
    const itemId =
      (
        routeProps.match
        && routeProps.match.params
        && typeof routeProps.match.params.itemId === "string"
    ) ? routeProps.match.params.itemId : null;

    return (
      <AppFrame
        drawerVisible={screenProps.drawerVisible}
        eventSlug={screenProps.eventSlug}
        changeDrawerVisibility={screenProps.changeDrawerVisibility}
        logout={screenProps.logout}
        toolbarActions={
          <Button
            flat
            component={Link}
            tooltipLabel="Create item"
            iconChildren="add"
            className="md-btn--toolbar"
            to={`/event/${screenProps.eventSlug}/schedule/items/new`}
          >
            Create item
          </Button>
        }
        actionsMenu={
          <ListItem
            leftIcon={<FontIcon>add</FontIcon>}
            component={Link}
            primaryText="Create item"
            to={`/event/${screenProps.eventSlug}/schedule/items/new`}
          />
        }
      >
        {
          () =>
            <div className={`${BEM}_container`}>
              <ScheduleItemList model={modelProps.state.model}
                                eventSlug={screenProps.eventSlug}
                                filter={query.q || ""}
                                setFilter={
                                  (value) => routeProps.history.replace({
                                    search: `?q=${encodeURIComponent(value)}`,
                                  })
                                }
                                selectedItem={itemId}
                                showItem={goToItemRef.current}
              />
              {
                modelProps.state.model
                  ? (
                    <SideSheet
                      eventSlug={screenProps.eventSlug}
                      items={modelProps.state.model.items}
                      itemId={itemId}
                      view={view}
                      customFields={schedule.itemForm.fields}
                      changeView={
                        (newView) =>
                          routeProps.history.push(
                            {
                              ...routeProps.history.location,
                              pathname:
                                (
                                  newView === "DETAIL"
                                ) ? `/event/${screenProps.eventSlug}/schedule/items/${itemId}`
                                  : (
                                    newView === "EDIT"
                                  ) ? `/event/${screenProps.eventSlug}/schedule/items/${itemId}/edit`
                                  : `/event/${screenProps.eventSlug}/schedule/items/`,
                            },
                          )
                      }
                      goBack={() => routeProps.history.goBack()}
                    />
                  ) : null
              }
              <Snackbar
                key="item-snackbar"
                toasts={messagesList}
                onDismiss={() => {
                  modelProps.dispatch({
                    type: "DISMISS_MESSAGE",
                  });
                }} />
            </div>
        }
      </AppFrame>
    );
  };

export interface ScreenProps
{
  eventSlug: string;

  drawerVisible: MediaArg;
  changeDrawerVisibility(media: MediaType, visible: boolean): void;
  logout(): void;
}

export const ScheduleItemScreen: React.SFC<ScreenProps> = (props) => {
  const sagaRef = React.useRef<Saga<[History]>>();

  return (
    <Route
      path={[
        ITEM_ROOT_PATH,
        ITEM_NEW_PATH,
        ITEM_DETAIL_PATH,
        ITEM_EDIT_PATH,
      ]}
      exact
    >
      {
        (routeProps) => {
          if (!sagaRef.current) {
            sagaRef.current = () => selectItemSaga(routeProps.history);
          }
          const saga = sagaRef.current;

          const progress = (
            <AppFrame
              drawerVisible={props.drawerVisible}
              changeDrawerVisibility={props.changeDrawerVisibility}
              eventSlug={props.eventSlug}
              logout={props.logout}
            >
              {
                () =>
                  <CircularProgress id="schedule-grid-loading" />
              }
            </AppFrame>
          );

          return (
            <ApolloConsumer>
              {(client) =>
                <ScheduleModel
                  eventSlug={props.eventSlug}
                  whileLoading={progress}
                  client={client}
                  history={routeProps.history}
                  selectedEntryId={null}
                  extraSagas={[saga]}
                  children={
                    (modelProps) => (
                      <ScheduleItemContent
                        modelProps={modelProps}
                        routeProps={routeProps}
                        screenProps={props} />
                    )
                  }
                />
              }
            </ApolloConsumer>
          );
        }
      }
    </Route>
  );
};

