import { Form, Formik } from "formik";
import {DocumentNode} from "graphql";
import * as React from "react";
import { Mutation } from "react-apollo";
import {
  Button,
  DialogContainer,
  List,
  ListItem,
  TextField,
} from "react-md";
import {connect} from "react-redux";
import {Dispatch} from "redux";

import {namedComponent} from "../../namedComponent";
import {ScheduleModelAction} from "./model/reducer";

import {
  fullScheduleItem_event_schedule_item_participantInvites as ParticipantInvite,
} from "./__generated__/fullScheduleItem";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const displayScheduleItemFragment: DocumentNode = require("./displayScheduleItemFragment.graphql");
import {
  displayScheduleItemFragment,
} from "./__generated__/displayScheduleItemFragment";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const sendInviteMutation: DocumentNode = require("./sendInvite.graphql");
import {
  sendInvite,
  sendInviteVariables,
} from "./__generated__/sendInvite";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const cancelInviteMutation: DocumentNode = require("./cancelInvite.graphql");
import {
  cancelInvite,
  cancelInviteVariables,
} from "./__generated__/cancelInvite";

interface InviteDialogProps
{
  visible: boolean;
  itemName: string;
  defaultInviterName: string;
  hide(): void;
  submit(fields: InviteFormParams): Promise<void>;
}

interface InviteFormParams
{
  displayName: string;
  email: string;
  inviterName: string;
  inviteeName: string;
}

const InviteDialog: React.FC<InviteDialogProps> = (props) => {
  return (
    <Formik
      initialValues={{
        displayName: props.itemName,
        email: "",
        inviterName: props.defaultInviterName,
        inviteeName: "",
      }}
      onSubmit={(values, submitProps) => {
        props.submit(values)
          .then(() => {
            submitProps.setSubmitting(false);
            submitProps.resetForm();
          })
          .catch((e: any) => submitProps.setStatus(e.toString()));
      }}
    >
      {
        (formProps) => (
          <DialogContainer
            id="item-invite-dialog"
            visible={props.visible}
            title="Send invite"
            onHide={props.hide}
            portal
            actions={[
              {
                children: "Cancel",
                secondary: true,
                onClick: () => {
                  formProps.resetForm();
                  props.hide();
                },
              },
              {
                children: "Send",
                primary: true,
                id: "participant-dialog-send",
                type: "submit",
              },
            ]}
            component={Form}
          >
            {formProps.status ? <p>{formProps.status.toString()}</p> : null}
            <TextField
              required
              id="displayName"
              name="displayName"
              label="What they're invited to"
              value={formProps.values.displayName}
              onBlur={formProps.handleBlur}
              onChange={(_, e) => formProps.handleChange(e)}
            />
            <TextField
              required
              id="inviterName"
              name="inviterName"
              label="Inviter name"
              value={formProps.values.inviterName}
              onBlur={formProps.handleBlur}
              onChange={(_, e) => formProps.handleChange(e)}
            />
            <TextField
              required
              id="inviteeName"
              name="inviteeName"
              label="Invitee name"
              value={formProps.values.inviteeName}
              onBlur={formProps.handleBlur}
              onChange={(_, e) => formProps.handleChange(e)}
            />
            <TextField
              required
              id="email"
              name="email"
              label="Invitee email"
              value={formProps.values.email}
              onBlur={formProps.handleBlur}
              onChange={(_, e) => formProps.handleChange(e)}
            />
          </DialogContainer>
        )
      }
    </Formik>
  );
};

interface SendInviteButtonProps
{
  itemId: string;
  itemName: string;
  defaultInviterName: string;
  notify(message: string): void;
}

const SendInviteButton: React.FC<SendInviteButtonProps> = (props) => {
  const [dialogVisible, setDialogVisible] = React.useState<boolean>(false);
  const showDialog = React.useCallback(() => setDialogVisible(true), []);
  const hideDialog = React.useCallback(() => setDialogVisible(false), []);

  return (
    <Mutation<sendInvite, sendInviteVariables>
      mutation={sendInviteMutation}
    >
      {
        (mutate, result) => (
          <React.Fragment>
            <Button raised
              onClick={showDialog}
            >
              Send participant invite
            </Button>
            <InviteDialog
              visible={dialogVisible}
              itemName={props.itemName}
              defaultInviterName={props.defaultInviterName}
              hide={hideDialog}
              submit={
                (params) => (
                  mutate({
                    variables: {
                      itemId: props.itemId,
                      ...params,
                    },
                  }).then(
                    (resultAfter) => {
                      if (!result || !result.client) {
                        return;
                      }
                      props.notify(`Invite sent to ${params.inviteeName}.`);
                      hideDialog();

                      // On success, add this invite to the cached item
                      const oldItem =
                        result.client.cache
                          .readFragment<displayScheduleItemFragment, {}>({
                            id: `ScheduleItem:${props.itemId}`,
                            fragment: displayScheduleItemFragment,
                            fragmentName: "displayScheduleItemFragment",
                          });
                      if (!oldItem || !resultAfter || !resultAfter.data) {
                        return;
                      }
                      const newItem: displayScheduleItemFragment = {
                        ...oldItem,
                        participantInvites: [
                          resultAfter.data.inviteToScheduleItem,
                          ...oldItem.participantInvites,
                        ],
                      };
                      result.client.cache.writeFragment({
                        id: `ScheduleItem:${props.itemId}`,
                        fragment: displayScheduleItemFragment,
                        fragmentName: "displayScheduleItemFragment",
                        data: newItem,
                      });
                    },
                  ).catch(
                    (e) => {
                      props.notify(`Error sending invite: ${e.toString()}`);
                    },
                  )
                )
              }
            />
          </React.Fragment>
        )
      }
    </Mutation>
  );
};

interface InviteCancelButton
{
  itemId: string;
  inviteId: string;
}

const InviteCancelButton: React.FC<InviteCancelButton> = (props) => (
  <Mutation<cancelInvite, cancelInviteVariables>
    mutation={cancelInviteMutation}
    variables={{inviteId: props.inviteId}}
  >
    {
      (mutate, result) => (
        <Button
          icon
          tooltipLabel="Cancel invitation"
          onClick={(_) => {
            mutate()
              .then(() => {
                if (!result || !result.client) {
                  return;
                }
                // On success, remove this invite from the cached item
                const oldItem =
                  result.client.cache
                    .readFragment<displayScheduleItemFragment>({
                      id: `ScheduleItem:${props.itemId}`,
                      fragment: displayScheduleItemFragment,
                      fragmentName: "displayScheduleItemFragment",
                    });
                if (!oldItem) {
                  return;
                }
                const newItem: displayScheduleItemFragment = {
                  ...oldItem,
                  participantInvites:
                    oldItem.participantInvites.filter(
                      (invite) => invite.id !== props.inviteId,
                    ),
                };
                result.client.cache.writeFragment({
                  id: `ScheduleItem:${props.itemId}`,
                  fragment: displayScheduleItemFragment,
                  fragmentName: "displayScheduleItemFragment",
                  data: newItem,
                });
              });
          }}
          disabled={result.loading || !!result.data}
        >
          {result.loading || result.data ? "hourglass_empty" : "close"}
        </Button>
      )
    }
  </Mutation>
);

interface InviteListProps
{
  itemId: string;
  itemName: string;
  defaultInviterName: string;
  invites: ReadonlyArray<ParticipantInvite>;
}

interface InviteListDispatch
{
  notify(message: string): void;
}

export const InviteList =
  connect(
    () => ({}),
    (dispatch: Dispatch<ScheduleModelAction>) => ({
      notify: (message: string) => {
        dispatch({
          type: "SHOW_MESSAGE",
          message,
        });
      },
    }),
  )(
    namedComponent(
      "InviteList",
      (props: InviteListProps & InviteListDispatch) => {
        return (
          <React.Fragment>
            {
              props.invites.length > 0
                ? (
                  <React.Fragment>
                    <h4>Participant Invites</h4>
                    <List>
                    {
                      props.invites.map(
                        (invite) => (
                          <ListItem
                            key={invite.id}
                            className="md-list-item--flex"
                            primaryText={`${invite.inviteeName} (${invite.email})`}
                            // This is work around for the lack of SimpleListItem:
                            // https://github.com/mlaursen/react-md/issues/819
                            // which should be fixed by react-md 2.0
                            tileStyle={{
                              background: "transparent",
                              cursor: "unset",
                            }}
                            inkDisabled
                            role={null}
                            tabIndex={-1}
                            activeClassName=""
                            activeBoxClassName=""
                            renderChildrenOutside
                          >
                            <InviteCancelButton
                              itemId={props.itemId}
                              inviteId={invite.id}
                            />
                          </ListItem>
                        ),
                      )
                    }
                    </List>
                  </React.Fragment>
                ) : null
            }
            <div className="button-group">
              <SendInviteButton
                itemName={props.itemName}
                itemId={props.itemId}
                defaultInviterName={props.defaultInviterName}
                notify={props.notify}
              />
            </div>
          </React.Fragment>
        );
      },
    ),
  );

