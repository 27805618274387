import { useLocalStorage } from "@rehooks/local-storage";
import {ApolloError} from "apollo-client";
import {DocumentNode} from "graphql";
import * as React from "react";
import {ChildProps, Query, QueryResult} from "react-apollo";
import {Route, Switch} from "react-router";

import {CircularProgress} from "react-md";

declare module "react-md/lib/NavigationDrawers/NavigationDrawer" {
  interface NavigationDrawerProps {
    id?: string | number;
  }
}

import {Auth} from "../net/auth";

import {MyAccount} from "./account/MyAccount";
import {MediaArg, MediaType} from "./AppFrame";
import {AuthRedirect} from "./AuthRedirect";
import {EventListScreen} from "./EventListScreen";
import {AcceptInvite} from "./invite/AcceptInvite";
import {Center} from "./layout/Center";
import {LocationListScreen} from "./schedule/LocationListScreen/LocationListScreen";
import {ScheduleConfigurationScreen} from "./schedule/ScheduleConfigurationScreen";
import {ScheduleGridScreen} from "./schedule/ScheduleGridScreen";
import {ScheduleItemScreen} from "./schedule/ScheduleItemScreen";
import {ScheduleParticipantScreen} from "./schedule/ScheduleParticipantScreen";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const selfForRootQuery: DocumentNode = require("./account.graphql");
import {selfForRoot} from "./__generated__/selfForRoot";

interface RootSettings
{
  showDesktopNavDrawer: boolean;
}

export interface RootSettingsProps
{
  settings: RootSettings;
  setSettings(newSettings: RootSettings): void;
}

export interface RootComponentProps
{
  auth: Auth;
}

export interface State
{
  loginError?: string;
  drawerVisible: MediaArg;
  loggingOut: boolean;
}

interface InnerRootComponentProps
{
  loginPending: boolean;
}

type Props =
  ChildProps<RootComponentProps, selfForRoot>
    & RootSettingsProps
    & InnerRootComponentProps;

function is401(error: ApolloError): boolean {
  if (!error.networkError) return false;
  const netErr = error.networkError as Error & {statusCode?: number};
  return netErr.statusCode === 401;
}

class RootComponent
extends React.Component<Props, State>
{
  state: State = {
    drawerVisible: {
      desktop: true,
      tablet: false,
      mobile: false,
    },
    loggingOut: false,
  };

  constructor(props: Props) {
    super(props);

    this.state.drawerVisible.desktop = props.settings.showDesktopNavDrawer;
    this.handleLogout = this.handleLogout.bind(this);
    this.changeDrawerVisibility = this.changeDrawerVisibility.bind(this);
  }

  componentDidUpdate(): void {
    // Reload if there's no session and we're logging out.
    if (this.props.data && !this.props.data.session && this.state.loggingOut) {
      window.location.reload();
    }
  }

  render() {
    const loginPending = this.props.loginPending;
    const data = this.props.data;
    if (data && data.error && !is401(data.error) && !loginPending) {
      // we received an error from the server
      return (
        <Center>
          <h2>There was a problem!</h2>
        </Center>
      );
    } else if (!data || (data.loading && !data.session && !loginPending)) {
      // the server hasn't responded yet
      return (
        <Center>
          <CircularProgress id="app-progress"/>
        </Center>
      );
    } else if (data && !data.session && this.state.loggingOut) {
      // If there's no session and we're logging out, reload to force a
      // redirect.
      return <Center><h2>Logging out...</h2></Center>;
    } else {
      // we are logged in
      return (
        <>
          <Switch>
            <Route path="/event/:event/schedule/items"
              render={(props) => (
                <ScheduleItemScreen
                  eventSlug={props.match.params.event}
                  drawerVisible={this.state.drawerVisible}
                  changeDrawerVisibility={this.changeDrawerVisibility}
                  logout={this.handleLogout}
                />
              )}
            />
            <Route path="/event/:event/schedule/grid"
              render={(props) => (
                <ScheduleGridScreen
                  eventSlug={props.match.params.event}
                  history={props.history}
                  drawerVisible={this.state.drawerVisible}
                  changeDrawerVisibility={this.changeDrawerVisibility}
                  logout={this.handleLogout}
                />
              )}
            />
            <Route path="/event/:event/schedule/participants"
              render={(props) => (
                <ScheduleParticipantScreen
                  eventSlug={props.match.params.event}
                  drawerVisible={this.state.drawerVisible}
                  changeDrawerVisibility={this.changeDrawerVisibility}
                  logout={this.handleLogout}
                />
              )}
            />
            <Route path="/event/:event" exact
              render={(props) => (
                <EventListScreen
                  eventSlug={props.match.params.event}
                  drawerVisible={this.state.drawerVisible}
                  changeDrawerVisibility={this.changeDrawerVisibility}
                  logout={this.handleLogout}
                />
              )}
            />
            <Route path="/event/:event/schedule/locations"
              render={(props) => (
                <LocationListScreen
                  eventSlug={props.match.params.event}
                  drawerVisible={this.state.drawerVisible}
                  changeDrawerVisibility={this.changeDrawerVisibility}
                  logout={this.handleLogout}
                />
              )}
            />
            <Route
              path="/event/:event/schedule/config"
              render={(props) => (
                <ScheduleConfigurationScreen
                  eventSlug={props.match.params.event}
                  drawerVisible={this.state.drawerVisible}
                  changeDrawerVisibility={this.changeDrawerVisibility}
                  logout={this.handleLogout}
                />
              )}
            />
            <Route path="/account" exact
              render={(props) => (
                <MyAccount
                  drawerVisible={this.state.drawerVisible}
                  changeDrawerVisibility={this.changeDrawerVisibility}
                  logout={this.handleLogout}
                />
              )}
            />
            <Route path="/invite/:inviteId/accept" exact
              render={(props) => (
                <AcceptInvite
                  inviteId={props.match.params.inviteId}
                  logout={this.handleLogout}
                />
              )}
            />
            <Route path="/" exact
              render={(props) => (
                <EventListScreen
                  eventSlug={null}
                  drawerVisible={this.state.drawerVisible}
                  changeDrawerVisibility={this.changeDrawerVisibility}
                  logout={this.handleLogout}
                />
              )}
            />
          </Switch>
        </>
      );
    }
  }

  private handleLogout() {
    this.setState({
      ...this.state,
      loggingOut: true,
    });
    this.props.auth.logout();
  }

  private changeDrawerVisibility(media: MediaType, visible: boolean) {
    if (media === "desktop") {
      this.props.setSettings({
        ...this.props.settings,
        showDesktopNavDrawer: visible,
      });
    }
    this.setState((prevState) => ({
      ...prevState,
      drawerVisible: {
        ...prevState.drawerVisible,
        [media]: visible,
      },
    }));
  }
}

const RootQuery =
  (props: RootComponentProps & RootSettingsProps) => (
    <Query
      query={selfForRootQuery}
      onError={() =>
        setTimeout(
          () => window.location.reload(),
          5000 * Math.random(),
        )
      }
    >
      {
        (queryProps: QueryResult<selfForRoot>) => {
          if (queryProps.loading) {
            return (
              <Center>
                <CircularProgress id="app-progress" />
              </Center>
            );
          }
          return (
            <RootComponent
              data={queryProps}
              loginPending={window.location.pathname === "/auth"}
              {...props}
            />
          );
        }
      }
    </Query>
);

export const RootScreen =
  (props: RootComponentProps) => {
    const [rootSettings, setRootSettings] =
      useLocalStorage<RootSettings>(
        "RegWorksLocalRootSettings",
        {
          showDesktopNavDrawer: true,
        },
      );
    const innerProps = {
      ...props,
      settings: {
        showDesktopNavDrawer: true,
        ...rootSettings,
      },
      setSettings: setRootSettings,
    };
    return (
      <Switch>
        <Route path="/invite/:inviteId/accept"
          render={(routeProps) => (
            <AuthRedirect
              idpSource={{
                type: "invite",
                inviteId: routeProps.match.params.inviteId,
              }}
              auth={props.auth}
              render={() => RootQuery(innerProps)}
            />
          )}
        />
        <Route path="/event/:event"
          render={(routeProps) => (
            <AuthRedirect
              idpSource={{
                type: "event",
                eventSlug: routeProps.match.params.event,
              }}
              auth={props.auth}
              render={() => RootQuery(innerProps)}
            />
          )}
        />
        <Route path="/"
          render={(routeProps) => (
            <AuthRedirect
              idpSource={null}
              auth={props.auth}
              render={() => RootQuery(innerProps)}
            />
          )}
        />
      </Switch>
    );
  };

