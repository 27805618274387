import * as React from "react";

import {
  Conflict,
} from "../model";
import {
  ScheduleModelAction,
} from "../model/reducer";

import {
  BEM,
  ROW_HEIGHT,
} from "./constants";
import {
  EntryTile,
} from "./EntryTile";
import {getResourceName} from "./util";

interface PreviewEntryTileProps {
  x: number;
  y: number;
  width: number;
  displayName: string;
  color: string;
  isSelected: boolean;
  isResizing: boolean;
  isDeleting: boolean;
  canDrop: boolean;
  conflicts: Conflict[];
  itemRating: string;
  itemStatus: string;
  itemEmphasis: boolean;
  dispatch(action: ScheduleModelAction): void;
}

export const PreviewEntryTile: React.FC<PreviewEntryTileProps> = (props) => {
  const color = props.color;
  return (
    <div style={{
      position: "absolute",
      height: ROW_HEIGHT,
      width: `${props.width}px`,
      transform: `translate(${props.x}px, ${props.y}px)`,
      zIndex: 15,
    }}>
      <div className={`${BEM}_preview_conflict_list`}
        style={{
          transform: (
            props.conflicts.length > 0
              ? `translateY(-${ROW_HEIGHT}px)`
              : undefined
          ),
          opacity: props.conflicts.length > 0 ? 1 : 0,
        }}>
        Conflicts:
        {
          props.conflicts.length > 0
          ? props.conflicts.map(
            (conflict) => (
              <div key={`${conflict.entryIds[0]}:${conflict.resource.id}`}>
                {getResourceName(conflict.resource)}
              </div>
            ),
          ) : null
        }
      </div>
      <div
        className={
          `${BEM}_entry`
          + (
            props.canDrop
              ? ""
              : ` ${BEM}_entry--no-drop`
          )
        }
        onClick={(e) => {
          props.dispatch({
            type: "SAVE_ENTRY",
            transactionId: Math.random(),
          });
          // Don't trigger the parent handler to deselect.
          e.stopPropagation();
        }}
        style={{
          width: `${props.width}px`,
          touchAction: "none",
        }}>
        <EntryTile
          displayName={props.displayName}
          color={color}
          state={
            props.isDeleting ? "DELETING"
              : props.isResizing ? "RESIZING"
              : undefined
          }
          rating={props.itemRating}
          status={props.itemStatus}
          emphasis={props.itemEmphasis}
        />
      </div>
    </div>
  );
};

