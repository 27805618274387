import {DocumentNode} from "graphql";
import {
  ChronoUnit,
  Duration,
  ZonedDateTime,
  convert,
} from "js-joda";
import * as React from "react";
import {
  Query,
  QueryResult,
} from "react-apollo";
import { Doughnut } from "react-chartjs-2";
import {
  Link,
} from "react-router-dom";

import {
  Button,
  Card,
  CardActions,
  CardText,
  CardTitle,
  CircularProgress,
  MenuButton,
} from "react-md";

import { AppFrame, MediaArg, MediaType } from "./AppFrame";

import "./EventListScreen.scss";

const BEM = "event-list-screen";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const eventListQuery: DocumentNode = require("./eventList.graphql");
import {
  eventList,
  eventList_events as Event,
  eventList_events_schedule_items as ScheduleItem,
  eventList_events_schedule_items_entries as ScheduleEntry,
} from "./__generated__/eventList";

interface ItemChartProps
{
  items: ScheduleItem[];
}

const ItemChart: React.FC<ItemChartProps> = (props) => {
  const newCount = (
    props
      .items
      .filter((item) => item.status === "NEW")
      .length
  );
  const doneCount = (
    props
      .items
      .filter(
        (item) =>
        item.status === "PUBLIC" && item.entries.length > 0,
      )
      .length
  );
  const declinedCount = (
    props
      .items
      .filter((item) => item.status === "DECLINED")
      .length
  );
  const waitlistedCount = (
    props
      .items
      .filter((item) => item.status === "WAITLISTED")
      .length
  );
  const totalCount = props.items.length;
  const pendingCount = (
    totalCount
    - newCount
    - doneCount
    - declinedCount
    - waitlistedCount
  );

  return (
    <React.Fragment>
      <h3>Item statuses</h3>
      <Doughnut
        width={250}
        options={{
          legend: {
            position: "right",
          },
        }}
        data={{
          datasets: [{
            data: [
              newCount,
              pendingCount,
              doneCount,
              waitlistedCount,
            ],
            backgroundColor: [
              "#999999",
              "#377eb8",
              "#3daf4a",
              "#ff7f00",
            ],
          }],
          labels: [
            "New",
            "Pending",
            "Scheduled",
            "Waitlisted",
          ],
        }}
      />
    </React.Fragment>
  );
};

// Override the definition of MenuButton

interface EventCardProps
{
  event: Event;
}

const EventCard: React.FC<EventCardProps> = ({event}) => {
  const [now, setNow] = React.useState(ZonedDateTime.now());

  // Refresh the count down occasionally. This may be overkill.
  React.useEffect(
    () => {
      const loop = setInterval(
        () => setNow(ZonedDateTime.now()),
        60 * 60 * 1000,
      );

      return () => clearInterval(loop);
    },
    [setNow],
  );

  const itemListUrl = `/event/${event.slug}/schedule/items/`;
  const gridUrl = `/event/${event.slug}/schedule/grid/`;
  const participantsUrl = `/event/${event.slug}/schedule/participants/`;
  const locationsUrl = `/event/${event.slug}/schedule/locations/`;
  const configUrl = `/event/${event.slug}/schedule/config/`;

  const dateFormat = new Intl.DateTimeFormat("default", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const timeFormat = new Intl.DateTimeFormat("default", {
    hour: "numeric",
    minute: "numeric",
  });

  const startDate = ZonedDateTime.parse(event.startsAt);
  const startDateString =
    dateFormat.format(convert(startDate.toLocalDate()).toDate());
  const endDate = ZonedDateTime.parse(event.endsAt);
  const endDateString =
    dateFormat.format(convert(endDate.toLocalDate()).toDate());
  // This uses an em dash as is appropriate for ranges.
  const date =
    startDateString === endDateString
      ? startDateString
      : `${startDateString} \u2014 ${endDateString}`;
  const daysUntilStart = now.toLocalDate()
    .until(startDate.toLocalDate(), ChronoUnit.DAYS);
  const daysUntilEnd = now.toLocalDate()
    .until(endDate.toLocalDate(), ChronoUnit.DAYS);
  // |0 is the standard way to truncate toward 0. At least until Math.trunc is
  // usable in more browsers.
  // tslint:disable-next-line:no-bitwise
  const weeksUntilStart = (daysUntilStart / 7) | 0;
  // tslint:disable-next-line:no-bitwise
  const remainderDaysUntilStart = daysUntilStart % 7;

  let body = <p>Schedule not enabled</p>;
  if (event.schedule) {
    const contentTotalMinutes = (
      event.schedule
        .items
        .reduce(
          (entries, item) => (
            item.status === "PUBLIC"
             ? [...entries, ...item.entries]
             : entries
          ),
          [] as ScheduleEntry[],
        )
        .reduce(
          (total, entry) => (
            total + Duration.parse(entry.duration).toMinutes()
          ),
          0,
        )
    );
    const contentHours =
      Math.floor(contentTotalMinutes / 60);
    const contentMinutes = contentTotalMinutes % 60;
    const contentHoursStrings = (
      contentHours === 0 ? []
        : contentHours === 1 ? ["1 hour"]
        : [`${contentHours} hours`]
    );
    const contentMinutesStrings = (
      contentMinutes === 0 ? []
        : contentMinutes === 1 ? ["1 minute"]
        : [`${contentMinutes} minutes`]
    );
    const contentString = (
      [...contentHoursStrings, ...contentMinutesStrings]
        .join(" and ")
    );

    body = (
      <React.Fragment>
        <ItemChart items={event.schedule.items} />
        <p>
          {
            contentString
              ? `${contentString} of content scheduled`
              : "No content scheduled"
          }
          <br />
          {
            weeksUntilStart > 1 && remainderDaysUntilStart > 1
              ? `Starts in ${weeksUntilStart} weeks and ${remainderDaysUntilStart} days`
              : weeksUntilStart > 1 && remainderDaysUntilStart === 1
              ? `Starts in ${weeksUntilStart} weeks and 1 day`
              : weeksUntilStart > 1 && remainderDaysUntilStart === 0
              ? `Starts in ${weeksUntilStart} weeks`
              : weeksUntilStart === 1 && remainderDaysUntilStart > 1
              ? `Starts in 1 week and ${remainderDaysUntilStart} days`
              : weeksUntilStart === 1 && remainderDaysUntilStart === 1
              ? "Starts in 1 week and 1 day"
              : weeksUntilStart === 1 && remainderDaysUntilStart === 0
              ? "Starts in 1 week"
              : daysUntilStart > 1
              ? `Starts in ${daysUntilStart} days`
              : daysUntilStart === 1
              ? "Starts tomorrow"
              : daysUntilStart === 0 && startDate.isAfter(now)
              ? `Starts at ${timeFormat.format(convert(startDate).toDate())}`
              : daysUntilEnd > 1
              ? `Ends in ${daysUntilEnd} days`
              : daysUntilEnd === 1
              ? "Ends tomorrow"
              : daysUntilEnd === 0 && endDate.isAfter(now)
              ? `Ends at ${timeFormat.format(convert(endDate).toDate())}`
              : endDate.isEqual(now)
              ? "Ending now"
              : daysUntilEnd === 0 && endDate.isBefore(now)
              ? `Ended at ${timeFormat.format(convert(endDate).toDate())}`
              : daysUntilEnd === -1
              ? "Ended yesterday"
              : `Ended ${endDateString}`
          }
        </p>
      </React.Fragment>
    );
  }

  return (
    <Card className={`${BEM}_event-card`}>
      <CardTitle
        title={event.displayName}
        subtitle={date}
      />
      <CardText className={`${BEM}_event-body`}>
        {body}
      </CardText>
      {
        event.schedule
          ? (
            <CardActions>
              <Button
                component={Link}
                to={itemListUrl}
                flat
              >
                Item List
              </Button>
              <Button
                component={Link}
                to={gridUrl}
                flat
              >
                Schedule Grid
              </Button>
              <div className={`${BEM}-overflow-container`}>
                <MenuButton
                  id={`${event.id}-overflow`}
                  icon
                  simplifiedMenu={false}
                  anchor={{
                    x: "inner right",
                    y: "center",
                  }}
                  menuItems={[
                    {
                      primaryText: "Participant List",
                      component: Link,
                      to: participantsUrl,
                    },
                    {
                      primaryText: "Locations",
                      component: Link,
                      to: locationsUrl,
                    },
                    {
                      primaryText: "Schedule Configuration",
                      component: Link,
                      to: configUrl,
                    },
                    // If there is a public schedule and this event is the
                    // current event of an event series, then show the link to
                    // the public schedule.
                    ...(
                      (
                        event.eventSeries?.slug
                        && event.eventSeries?.currentEvent?.id === event.id
                        && event.publicSchedule
                      ) ? [{
                        primaryText: "Public Schedule",
                        component: "a",
                        href: `http://${event.eventSeries.slug}.reg.works/`,
                      }] : []
                    ),
                  ]}
                >
                  more_vert
                </MenuButton>
              </div>
            </CardActions>
          ) : null
      }
    </Card>
  );
};

export interface ScreenProps
{
  eventSlug: string|null;
  drawerVisible: MediaArg;
  changeDrawerVisibility(media: MediaType, visible: boolean): void;
  logout(): void;
}

export const EventListScreen: React.SFC<ScreenProps> = (props) => {
  const progress = (
    <CircularProgress id="event-list-progress" />
  );

  const contents = (
    <Query
      key="list"
      query={eventListQuery}
    >
      {
        (
          queryResult: QueryResult<eventList>,
        ) => {
          if (
            queryResult.loading
              || !queryResult.data
          ) {
            return progress;
          }
          const events = (
            queryResult
              .data
              .events
              .slice()
          );
          events.sort(
            (a, b) => a.displayName.localeCompare(b.displayName),
          );

          return (
            <React.Fragment>
              <h1>My Events</h1>
              <div
                className={`${BEM}_list-container`}
              >
                {
                  events.map((event) => (
                    <EventCard
                      key={event.id}
                      event={event}
                    />
                  ))
                }
              </div>
            </React.Fragment>
          );
        }
      }
    </Query>
  );

  return (
    <AppFrame
      eventSlug={props.eventSlug}
      drawerVisible={props.drawerVisible}
      changeDrawerVisibility={props.changeDrawerVisibility}
      logout={props.logout}
    >
      {
        () =>
          <div style={{
            overflowY: "auto",
            flex: "1 1 auto",
            padding: 20,
          }}>
            {contents}
          </div>
      }
    </AppFrame>
  );
};

