import {LocalDate} from "js-joda";
import * as React from "react";
import {
  Autocomplete,
  Button,
  DialogContainer,
  FontIcon,
  ListItem,
  TextField,
} from "react-md";

import {namedComponent} from "../../namedComponent";
import {
  ScheduleParticipant,
} from "./model";

export interface SetNewParticipantParams {
  readonly legalName: string;
  readonly displayGivenName: string;
  readonly displayFamilyName: string|null;
  readonly handle: string|null;
  readonly birthdate: LocalDate|null;
  readonly email: string;
}

interface NewParticipant extends SetNewParticipantParams {
  readonly type: "NEW";
}

interface ExistingParticipant {
  readonly type: "EXISTING";
  readonly id: string;
}

export type ParticipantFieldValue = NewParticipant | ExistingParticipant;

export type SetNewParticipant = (params: SetNewParticipantParams) => void;

interface ParticipantDialogProps {
  visible: boolean;
  setNewParticipant: SetNewParticipant;
  show(): void;
  hide(): void;
}

interface ParticipantDialog {
  setLegalName(name: string): void;
}

const ParticipantDialog =
  React.forwardRef(
    namedComponent(
      "ParticipantDialog",
      (
        props: ParticipantDialogProps,
        ref: React.Ref<ParticipantDialog>,
      ) => {
        const emailRegEx = /.@./;
        const [legalName, setLegalName] = React.useState<string>("");
        const [legalNameError, setLegalNameError] = React.useState(false);
        const [givenName, setGivenName] = React.useState<string>("");
        const [givenNameError, setGivenNameError] = React.useState(false);
        const [familyName, setFamilyName] = React.useState<string>("");
        const [handle, setHandle] = React.useState<string>("");
        const [birthdate, setBirthdate] = React.useState<string>("");
        const [birthdateError, setBirthdateError] = React.useState(false);
        const [email, setEmail] = React.useState<string>("");
        const [emailError, setEmailError] = React.useState(false);

        const clearForm = () => {
          setLegalName("");
          setLegalNameError(false);
          setGivenName("");
          setGivenNameError(false);
          setFamilyName("");
          setHandle("");
          setBirthdate("");
          setBirthdateError(false);
          setEmail("");
          setEmailError(false);
        };

        const splitName = (name: string) => {
          const maybeSplitName = name.split(/ +/);
          const maybeGivenName = (
            maybeSplitName.length > 1
              ? (
                maybeSplitName.slice(0, maybeSplitName.length - 1)
                  .join(" ")
              ) : maybeSplitName.length === 1 ? maybeSplitName[0]
              : ""
          );
          const maybeFamilyName = (
            maybeSplitName.length > 1
              ? maybeSplitName[maybeSplitName.length - 1]
              : ""
          );
          return [maybeGivenName, maybeFamilyName] as [string, string];
        };

        const submit = (e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          e.stopPropagation();

          let error = false;
          let birthdateDate = null;
          try {
            if (birthdate) {
              birthdateDate = LocalDate.parse(birthdate);
            }
          } catch (e) {
            setBirthdateError(true);
            error = true;
          }

          if (!legalName) {
            setLegalNameError(true);
            error = true;
          }
          if (!givenName) {
            setGivenNameError(true);
            error = true;
          }
          if (!email || !emailRegEx.exec(email)) {
            setEmailError(true);
            error = true;
          }

          if (error) {
            return;
          }

          props.setNewParticipant({
            legalName,
            displayGivenName: givenName,
            displayFamilyName: familyName || null,
            handle: handle || null,
            birthdate: birthdateDate,
            email,
          });
          props.hide();
          clearForm();
        };

        const hide = () => {
          props.hide();
          clearForm();
        };

        React.useImperativeHandle(ref, () => ({
          setLegalName(name: string) {
            const newSplitName = splitName(name);

            setGivenName(newSplitName[0]);
            setFamilyName(newSplitName[1]);
            setLegalName(name);
            setHandle("");
            setBirthdate("");
            setBirthdateError(false);
            setEmail("");
            props.show();
          },
        }));

        const contents = (
          <form
            onSubmit={submit}>
            <TextField
              id="participant-legal-name"
              label="Legal Name"
              placeholder="Charlie Smith"
              value={legalName}
              required
              error={legalNameError}
              onBlur={() => {
                if (!legalName) {
                  setLegalNameError(true);
                }
              }}
              onChange={(newValue) => {
                if (typeof newValue === "string") {
                  const oldSplitName = splitName(legalName);
                  const newSplitName = splitName(newValue);

                  if (
                    givenName === oldSplitName[0]
                    && familyName === oldSplitName[1]
                  ) {
                    setGivenName(newSplitName[0]);
                    if (newSplitName[0]) {
                      setGivenNameError(false);
                    }
                    setFamilyName(newSplitName[1]);
                  }

                  setLegalName(newValue);

                  if (newValue) {
                    setLegalNameError(false);
                  }
                }
              }} />
            <TextField
              id="participant-given-name"
              label="Given Name"
              placeholder="Chuck"
              value={givenName}
              required
              error={givenNameError}
              onBlur={() => {
                if (!givenName) {
                  setGivenNameError(true);
                }
              }}
              onChange={(newValue) => {
                if (typeof newValue === "string") {
                  setGivenName(newValue);
                  if (newValue) {
                    setGivenNameError(false);
                  }
                }
              }} />
            <TextField
              id="participant-family-name"
              label="Family Name"
              placeholder="Smith"
              value={familyName}
              onChange={(newValue) => {
                if (typeof newValue === "string") {
                  setFamilyName(newValue);
                }
              }} />
            <TextField id="participant-handle"
              label="Preferred Name"
              placeholder="Captain"
              value={handle}
              onChange={(newValue) => {
                if (typeof newValue === "string") {
                  setHandle(newValue);
                }
              }} />
            <TextField
              id="participant-birthdate"
              label="Birth Date"
              placeholder="YYYY-MM-DD"
              helpText="Format: YYYY-MM-DD (note the dashes)"
              value={birthdate}
              error={birthdateError}
              onBlur={() => {
                try {
                  if (birthdate) {
                    LocalDate.parse(birthdate);
                  }
                  setBirthdateError(false);
                } catch (e) {
                  setBirthdateError(true);
                }
              }}
              onChange={(newValue) => {
                if (typeof newValue === "string") {
                  setBirthdate(newValue);
                  setBirthdateError(false);
                }
              }} />
            <TextField
              id="participant-email"
              label="Email"
              placeholder="csmith@example.com"
              value={email}
              required
              error={emailError}
              onBlur={() => {
                if (!email || !emailRegEx.exec(email)) {
                  setEmailError(true);
                }
              }}
              onChange={(newValue) => {
                if (typeof newValue === "string") {
                  setEmail(newValue);
                  if (newValue) {
                    setEmailError(false);
                  }
                }
              }} />
            <input
              type="submit"
              hidden
              value="Save"
              style={{visibility: "hidden", position: "absolute"}}
            />
          </form>
        );

        return (
          <DialogContainer
            id={"participant-form-dialog"}
            key={"participant-form-dialog"}
            visible={props.visible}
            onHide={hide}
            portal
            initialFocus={"#participant-legal-name"}
            actions={[
              {
                children: "Cancel",
                secondary: true,
                onClick: hide,
              },
              {
                children: "Create",
                primary: true,
                id: "participant-dialog-ok",
                onClick: submit,
              },
            ]}
            title="New participant">
            {contents}
          </DialogContainer>
        );
      },
    ),
  );

const displayName = (part: SetNewParticipantParams) => (
  part.handle || part.legalName
);

interface ParticipantFieldProps {
  id: string;
  label: string;
  participants: ReadonlyArray<ScheduleParticipant>;
  participant: ParticipantFieldValue|null;
  keepSetParticipant: boolean;
  setParticipant(value: ParticipantFieldValue|null): void;
}

export const ParticipantField: React.FC<ParticipantFieldProps> = (props) => {
  const participantFormValues = props.participant;
  const currentParticipant = (() => {
    let result = null;
    if (participantFormValues) {
      if (participantFormValues.type === "EXISTING") {
        const maybeParticipant = props.participants.find(
          (part) => part.id === participantFormValues.id,
        );
        if (maybeParticipant) {
          result = {
            ...maybeParticipant,
            type: "EXISTING" as "EXISTING",
          };
        }
      } else {
        result = {
          type: "NEW" as "NEW",
          displayName: displayName(participantFormValues),
        };
      }
    }
    return result;
  })();

  const [name, setName] = React.useState<string>(
    currentParticipant
      ? currentParticipant.displayName
      : "",
  );
  const dialog = React.useRef<ParticipantDialog>(null);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);

  // The typings on fuzzyFilter are wrong.
  const participantMatches = (
    Autocomplete.fuzzyFilter(
      props.participants.slice() as Array<{ displayName: React.ReactText }>,
      name,
      "displayName",
    ) as unknown as ScheduleParticipant[]
  );
  const addParticipantItem =
    <ListItem
      key="new"
      leftIcon={<FontIcon>add</FontIcon>}
      primaryText="New participant"
    />;
  const noResults =
    <div className="md-caption md-text-center">No results</div>;
  const participantOptions = [
    addParticipantItem,
    ...participantMatches,
    ...(participantMatches.length === 0 ? [noResults] : []),
  ];

  const setId =
    (id: string|null) => {
      if (id) {
        props.setParticipant({ type: "EXISTING", id });
      } else {
        props.setParticipant(null);
      }
    };

  return (
    <React.Fragment>
      <Autocomplete
        id={props.id}
        label={props.label}
        dataLabel="displayName"
        dataValue="id"
        data={
          participantOptions as Array<{ displayName: React.ReactText } | React.ReactElement>
        }
        filter={null}
        value={name}
        showUnfilteredData={true}
        onChange={(value) => {
          const newName = "" + value;
          setName(newName);
          if (
            currentParticipant
            && currentParticipant.displayName.toLowerCase()
            !== newName.toLowerCase()
          ) {
            props.setParticipant(null);
          }
          const possibleMatch =
            props.participants.find(
              (part) =>
                part.displayName.toLowerCase() === newName.toLowerCase(),
            );
          if (possibleMatch) {
            setId(possibleMatch.id);
          }
        }}
        onAutocomplete={(_, index) => {
          if (index === 0) {
            setShowDialog(true);
            setId(null);
            setName("");
          } else if (index > 0 && index <= participantMatches.length) {
            // Skip the first item, which is add participant, to index into
            // the actual search results.
            const participantMatch = participantMatches[index - 1];
            setId(participantMatch.id);
            if (props.keepSetParticipant) {
              setName(participantMatch.displayName);
            } else {
              setName("");
            }
          }
        }}
        onBlur={
          () => {
            if (!currentParticipant) {
              setName("");
            } else {
              setName(currentParticipant.displayName);
            }
          }
        }
        fullWidth={true}
        inlineIndicator={
          props.participant
            ? (
              <Button
                icon
                inherit
                className="inline-button"
                onMouseDown={(e) => e.stopPropagation()}
                title="Clear"
                onClick={(e) => {
                  props.setParticipant(null);
                  setName("");
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                close
              </Button>
            ) : undefined
        }
      />
      <ParticipantDialog
        ref={dialog}
        visible={showDialog}
        show={() => setShowDialog(true)}
        hide={() => setShowDialog(false)}
        setNewParticipant={(params) => {
          props.setParticipant({
            ...params,
            type: "NEW",
          });
          if (props.keepSetParticipant) {
            setName(displayName(params));
          } else {
            setName("");
          }
        }} />
    </React.Fragment>
  );
};

