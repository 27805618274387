import {DocumentNode} from "graphql";
import * as React from "react";
import {
  Mutation,
  MutationFunction,
  Query,
  QueryResult,
} from "react-apollo";
import {RouteChildrenProps} from "react-router";
import {
  Link,
  Route,
} from "react-router-dom";

import {
  Button,
  Checkbox,
  CircularProgress,
  DialogContainer,
  List,
  ListItem,
  Snackbar,
} from "react-md";

import { AppFrame, MediaArg, MediaType } from "../AppFrame";
import { CustomFields, Field } from "./CustomFields";
import {extractCustomFieldValue, nl2br} from "./format";

import {
  FieldInstanceInput,
  FieldValueInput,
} from "./../../__generated__/globalTypes";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const getParticipantsQuery: DocumentNode = require("./ScheduleParticipantScreen/getParticipants.graphql");
import {
  getParticipants,
  getParticipantsVariables,
  getParticipants_people as Person,
} from "./ScheduleParticipantScreen/__generated__/getParticipants";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const getParticipantQuery: DocumentNode = require("./ScheduleParticipantScreen/getParticipant.graphql");
import {
  getParticipant,
  getParticipantVariables,
  getParticipant_event_schedule_participant as Participant,
  getParticipant_event_schedule_participantForm as ParticipantForm,
} from "./ScheduleParticipantScreen/__generated__/getParticipant";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const updateParticipantMutation: DocumentNode = require("./ScheduleParticipantScreen/updateParticipant.graphql");
import {
  updateParticipant,
  updateParticipantVariables,
} from "./ScheduleParticipantScreen/__generated__/updateParticipant";


const PARTICIPANT_ROOT_PATH = "/event/:eventSlug/schedule/participants/";
const PARTICIPANT_DETAIL_PATH = "/event/:eventSlug/schedule/participants/:id";

interface ParticipantDetailProps
{
  eventSlug: string;
  eventId: string;
  version: number;
  participant: Participant;
  form: ParticipantForm;
}

const ParticipantDetail: React.FC<ParticipantDetailProps> = (props) => {
  // Make sure that all the default values are set.
  const initialFieldValues: FieldInstanceInput[] = [];

  for (const field of props.form.fields) {
    let value: FieldValueInput|null = null;
    switch (field.__typename) {
      case "TextField":
        case "SelectOneField":
          const stringValue = extractCustomFieldValue(
            props.participant.customFields,
            field.name,
            "stringValue",
          );
          if (stringValue !== undefined) {
            value = { stringValue };
          }
          break;
      case "BooleanField":
        const booleanValue = extractCustomFieldValue(
          props.participant.customFields,
          field.name,
          "booleanValue",
        );
        if (booleanValue !== undefined) {
          value = { booleanValue };
        } else if (field.booleanDefault !== null) {
          value = { booleanValue: field.booleanDefault };
        } else if (field.required && field.adminWritable) {
          // Default to unchecked if it's required and writable.
          value = { booleanValue: false };
        }
        break;
    }

    initialFieldValues.push({
      name: field.name,
      value,
    });
  }

  const [saveInProgress, setSaveInProgress] = React.useState(false);
  const [fieldValues, setFieldValues] = React.useState<FieldInstanceInput[]>([
    {
      name: "@overrideDisplayName",
      value: {
        stringValue: props.participant.overrideDisplayName || "",
      },
    },
    {
      name: "@isPublic",
      value: {
        booleanValue: props.participant.public,
      },
    },
    ...initialFieldValues,
  ]);
  const [toasts, setToasts] = React.useState<Array<{ text: string }>>([]);

  const participantFields: Field[] = [
    {
      __typename: "TextField",
      name: "@overrideDisplayName",
      label: "Override display name",
      order: -1000,
      required: false,
      adminWritable: true,
      help: "Normally, the displayed name for a participant comes from their" +
        " user profile. This overrides that name when displayed on the" +
        " schedule for this event.",
      maximumLength: null,
      textDefault: null,
      multiline: false,
      public: true,
      applicationWritable: true,
    },
    {
      __typename: "BooleanField",
      name: "@isPublic",
      label: "Show on public schedule",
      order: -999,
      required: true,
      adminWritable: true,
      booleanDefault: false,
      help: null,
      public: true,
      applicationWritable: true,
    },
    ...props.form.fields,
  ];

  const none = <em>None</em>;
  const primaryEmail = (
    props.participant.identity
    && props.participant.identity.__typename === "Person"
    && props.participant.identity.email
  ) || null;
  const otherContacts = (
    props.participant.identity
    && props.participant.identity.contacts
    && props.participant.identity.contacts.length > 0
      ? (
        <dd>
        <ul>
        {
          props.participant.identity.contacts
            .filter(
              (contact) => (
                // Don't show primary email twice.
                contact.__typename !== "EmailAddress"
                  || primaryEmail !== contact.address
              ),
            )
            .map((contact) => {
              const label = (
                contact.label
                  ? ` (${contact.label})`
                  : ""
              );
              switch (contact.__typename) {
                case "EmailAddress":
                  return (
                    <li key={contact.id}>
                      Email{label}: {contact.address}
                    </li>
                );
                case "PhysicalAddress":
                  return (
                    <li key={contact.id}>
                      Physical address{label}:
                      <div>
                        {nl2br(contact.address, "None")}
                        {contact.country}
                      </div>
                    </li>
                );
                case "PhoneNumber":
                  return <li key={contact.id}>Phone number{label}: {contact.number}</li>;
              }
            })
        }
        </ul>
        </dd>
      ) : (
        <dd><em>None</em></dd>
      )
  );

  const identityInfo = (
    props.participant.identity
      ? (
        props.participant.identity.__typename === "Person"
          ? (
            <dl>
              <dt>Default Display Name</dt>
              <dd>{props.participant.identity.displayName}</dd>
              <dt>Legal Name</dt>
              <dd>{props.participant.identity.legalName}</dd>
              <dt>Given Name</dt>
              <dd>{props.participant.identity.displayGivenName}</dd>
              <dt>Family Name</dt>
              <dd>{props.participant.identity.displayFamilyName || none}</dd>
              <dt>Preferred Name</dt>
              <dd>{props.participant.identity.handle || none}</dd>
              <dt>Birth Date</dt>
              <dd>{props.participant.identity.birthdate || none}</dd>
              <dt>Default Email</dt>
              <dd>{props.participant.identity.email || none}</dd>
              <dt>Other Contacts</dt>
              {otherContacts}
            </dl>
          ) : (
            <dl>
              <dt>Default Display Name</dt>
              <dd>{props.participant.identity.displayName}</dd>
            </dl>
          )
      ) : (
        <em>Not Permitted</em>
      )
  );

  const showToast = (message: string) => {
    setToasts([{text: message}, ...toasts]);
  };

  return (
    <React.Fragment>
      <Button
        flat
        component={Link}
        to={`/event/${props.eventSlug}/schedule/participants/`}>
        Back
      </Button>
      <h1>{props.participant.displayName}</h1>
      <h2>Event-Specific Info</h2>
      <Mutation
        mutation={updateParticipantMutation}
      >
        {(mutate: MutationFunction<updateParticipant, updateParticipantVariables>) => {
          const onSubmit = (e: React.FormEvent) => {
            e.preventDefault();

            let overrideDisplayName = null;
            let isPublic = false;
            const customFields = [];

            for (const field of fieldValues) {
              if (field.name === "@overrideDisplayName") {
                if (field.value && field.value.stringValue) {
                  overrideDisplayName = field.value.stringValue;
                }
              } else if (field.name === "@isPublic") {
                if (field.value && field.value.booleanValue) {
                  isPublic = true;
                }
              } else {
                customFields.push(field);
              }
            }

            setSaveInProgress(true);
            mutate({
              variables: {
                eventId: props.eventId,
                identityId: props.participant.identityId,
                version: Math.max(props.version, props.participant.version),
                displayName: overrideDisplayName,
                isPublic,
                customFields,
              },
            }).then(() => {
              setSaveInProgress(false);
              showToast(`"${props.participant.displayName}" saved.`);
            }).catch((error) => {
              setSaveInProgress(false);
              showToast(error.toString());
            });
          };

          return (
            <form
              className="md-body-1"
              onSubmit={onSubmit}
            >
              <CustomFields
                customFields={participantFields}
                fieldValues={fieldValues}
                setFieldValues={setFieldValues}
              />
              <Button raised
                primary
                type="submit"
                disabled={saveInProgress}
              >
                Save Participant
              </Button>
            </form>
          );
        }}
      </Mutation>
      <h2>Participating Items</h2>
      <List className="md-body-1">
        {
          props.participant.items.map(
            (item) => {
              const encodedId = encodeURIComponent(item.id);
              const url = `/event/${props.eventSlug}/schedule/items/${encodedId}`;
              const label = (
                item.displayName
                  + ` (${item.status.toLowerCase()},`
                  + ` ${item.entries.length} entries)`
              );
              return (
                <Link to={url} key={item.id}>
                  <ListItem primaryText={label} />
                </Link>
              );
            },
          )
        }
      </List>
      <h2>Systemwide Info</h2>
      {identityInfo}
      <Snackbar toasts={toasts} onDismiss={() => setToasts(toasts.slice(1))} />
    </React.Fragment>
  );
};

interface AddMissingParticipantsButtonProps
{
  eventId: string;
  eventSlug: string;
  participants: Person[];
  scheduleRef: string;
  updateParticipantsCache(
    update: (previousQueryResult: getParticipants) => getParticipants,
  ): void;
}

const AddMissingParticipantsButton
: React.FC<AddMissingParticipantsButtonProps> = (props) => {
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [selectedIdentities, setSelectedIdentities] = React.useState<string[]>([]);
  const [importDialogVisible, setImportDialogVisible] =
    React.useState<boolean>(false);

  const showDialog = () => setImportDialogVisible(true);
  const hideDialog = () => setImportDialogVisible(false);

  return (
    <Mutation
      mutation={updateParticipantMutation}
      refetchQueries={() => ["getParticipants"]}
    >
      {(
        mutate: MutationFunction<updateParticipant, updateParticipantVariables>,
      ) => {
        const onClick = (e: React.MouseEvent) => {
          const identitiesLeft = [...selectedIdentities];
          setIsSaving(true);

          const handleNext = () => {
            const nextId = identitiesLeft.pop();
            if (nextId) {
              mutate({
                variables: {
                  eventId: props.eventId,
                  identityId: nextId,
                  version: 0,
                },
              })
              .then(
                (result) => {
                  setSelectedIdentities(identitiesLeft);
                  if (!result || !result.data) {
                    return;
                  }
                  const newPart = result.data.setScheduleParticipantFields;
                  props.updateParticipantsCache(
                    (oldParticipants) => {
                      if (!oldParticipants.event.schedule) {
                        return oldParticipants;
                      }
                      const newParticipants: typeof oldParticipants = {
                        ...oldParticipants,
                        event: {
                          ...oldParticipants.event,
                          schedule: {
                            ...oldParticipants.event.schedule,
                            version: newPart.version,
                            participants: [
                              ...oldParticipants.event.schedule.participants,
                              newPart,
                            ],
                          },
                        },
                      };
                      return newParticipants;
                    },
                  );
                },
              )
              .then(handleNext);
            } else {
              setIsSaving(false);
              setImportDialogVisible(false);
            }
          };
          handleNext();
        };

        return (
          <React.Fragment>
            <Button raised onClick={showDialog}>
              Import {props.participants.length} known
              {props.participants.length === 1 ? " person " : " people "}
              missing from event
            </Button>
            <DialogContainer
              id="participant-dialog"
              visible={importDialogVisible}
              title="Import participants"
              onHide={hideDialog}
            >
              {
                props.participants
                  .map(
                    (person) => {
                      const checked =
                        !!selectedIdentities.find((id) => id === person.id);
                      const checkboxId = `person-${person.id}`;
                      return (
                        <Checkbox
                          id={checkboxId}
                          name={checkboxId}
                          checked={checked}
                          label={person.displayName}
                          key={person.id}
                          disabled={isSaving}
                          onChange={
                            (value) => {
                              if (!value) {
                                setSelectedIdentities(
                                  selectedIdentities
                                    .filter((id) => id !== person.id),
                                );
                              } else {
                                setSelectedIdentities([
                                  ...selectedIdentities,
                                  person.id,
                                ]);
                              }
                            }
                          }
                        />
                      );
                    },
                  )
              }
              <Button
                flat
                onClick={onClick}
                disabled={isSaving || selectedIdentities.length === 0}>
                {
                  isSaving
                    ? "Importing participants..."
                    : "Import participants"
                }
              </Button>
            </DialogContainer>
          </React.Fragment>
        );
      }}
    </Mutation>
  );
};

interface RouteParams
{
  id?: string;
}

export interface ScreenProps
{
  eventSlug: string;

  drawerVisible: MediaArg;
  changeDrawerVisibility(media: MediaType, visible: boolean): void;
  logout(): void;
}

export const ScheduleParticipantScreen: React.SFC<ScreenProps> = (props) => {
  return (
    <Route
      path={[
        PARTICIPANT_ROOT_PATH,
        PARTICIPANT_DETAIL_PATH,
      ]}
      exact
    >
      {
        (routeProps: RouteChildrenProps<RouteParams>) => {
          const progress = (
            <CircularProgress id="schedule-item-progress" />
          );

          let contents: React.ReactNode = null;

          if (
            routeProps.match
            && routeProps.match.path === PARTICIPANT_ROOT_PATH
          ) {
            contents = (
              <Query<getParticipants, getParticipantsVariables>
                key="list"
                query={getParticipantsQuery}
                variables={{eventSlug: props.eventSlug}}
              >
                {
                  (
                    queryResult: QueryResult<getParticipants>,
                  ) => {
                    if (
                      queryResult.loading
                      || !queryResult.data
                      || !queryResult.data.event
                      || !queryResult.data.event.schedule
                      || !queryResult.data.event.schedule.participants
                    ) {
                      return progress;
                    }
                    const parts = (
                      queryResult
                        .data
                        .event
                        .schedule
                        .participants
                    );
                    parts.sort(
                      (a, b) => a.displayName.localeCompare(b.displayName),
                    );

                    let missingParticipants = queryResult.data.people;
                    for (const part of parts) {
                      missingParticipants = missingParticipants.filter(
                        (person) => person.id !== part.identityId,
                      );
                    }

                    return (
                      <React.Fragment>
                        {
                          missingParticipants.length > 0
                          ? <AddMissingParticipantsButton
                            participants={missingParticipants}
                            eventId={queryResult.data.event.id}
                            scheduleRef={queryResult.data.event.schedule.ref}
                            eventSlug={props.eventSlug}
                            updateParticipantsCache={queryResult.updateQuery}
                          /> : null
                        }
                        <List>
                          {
                            parts.map((part) => {
                              const encodedId = encodeURIComponent(part.identityId);
                              const url = `/event/${props.eventSlug}/schedule/participants/${encodedId}`;
                              return (
                                <Link to={url} key={part.identityId}>
                                  <ListItem primaryText={part.displayName} />
                                </Link>
                              );
                            })
                          }
                        </List>
                      </React.Fragment>
                    );
                  }
                }
              </Query>
            );
          }

          if (
            routeProps.match
            && routeProps.match.path === PARTICIPANT_DETAIL_PATH
            && routeProps.match.params.id
          ) {
            const id = routeProps.match.params.id;

            contents = (
              <Query<getParticipant, getParticipantVariables>
                key="detail"
                query={getParticipantQuery}
                variables={{
                  eventSlug: props.eventSlug,
                  identityId: id,
                }}
              >
                {
                  (queryResult) => {
                    if (
                      queryResult.loading
                      || !queryResult.data
                      || !queryResult.data.event
                      || !queryResult.data.event.schedule
                      || !queryResult.data.event.schedule.participant
                    ) {
                      return progress;
                    }

                    return (
                      <ParticipantDetail
                        eventSlug={props.eventSlug}
                        eventId={queryResult.data.event.id}
                        version={queryResult.data.event.schedule.version}
                        participant={queryResult.data.event.schedule.participant}
                        form={queryResult.data.event.schedule.participantForm}
                      />
                    );
                  }
                }
              </Query>
            );
          }


          return (
            <AppFrame
              eventSlug={props.eventSlug}
              drawerVisible={props.drawerVisible}
              changeDrawerVisibility={props.changeDrawerVisibility}
              logout={props.logout}
            >
              {
                () =>
                  <div style={{
                    overflowY: "auto",
                    flex: "1 0 auto",
                    padding: 15,
                  }} key={routeProps.location.pathname}>
                    {contents}
                  </div>
              }
            </AppFrame>
          );
        }
      }
    </Route>
  );
};

