import * as React from "react";

import "./InlineBadge.scss";

export interface InlineBadgeProps
  extends React.HTMLProps<HTMLDivElement>
{}

export const InlineBadge: React.FC<InlineBadgeProps> =
  ({children, className, ...innerProps}) => (
    <div
      className={`rw-inline-badge ${className || ""}`}
      {...innerProps}
    >
      {children}
    </div>
  );

