import * as React from "react";

import {
  Conflict,
} from "../model";

import {
  BEM,
  PIXELS_PER_MINUTE,
} from "./constants";

interface ResourceExclusionZoneProps {
  span: Conflict;
}

export const ResourceExclusionZone: React.FC<ResourceExclusionZoneProps> = (props) => {
  const left =  props.span.startMinutes * PIXELS_PER_MINUTE;
  const width = props.span.durationMinutes * PIXELS_PER_MINUTE;

  return (
    <div className={`${BEM}_resource_exclusion`}
      style={{
      left,
      width,
      }} />
  );
};

