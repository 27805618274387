import { useMutation } from "@apollo/react-hooks";
import { useFormik } from "formik";
import { DocumentNode } from "graphql";
import * as React from "react";
import {
  Button,
  SelectField,
} from "react-md";

import { CompoundViolationError } from "../../../net/CompoundViolationError";
import {
  FieldIdInput,
} from "../../../__generated__/globalTypes";
import {
  customFields_event_schedule_itemForm_fields as CustomField,
} from "./__generated__/customFields";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const updateConfigurationMutation: DocumentNode = require("./updateConfiguration.graphql");
import {
  updateConfiguration,
  updateConfigurationVariables,
} from "./__generated__/updateConfiguration";

const NULL_OPTION = -1;
const BEM = "rw-schedule-config";

interface GlobalSettingsFormValues {
  coloredFieldName: string|null;
  emphasizedFieldId: FieldIdInput|null;
}

interface GlobalSettingsProps {
  initialValue: GlobalSettingsFormValues;
  eventId: string;
  version: number;
  itemFields: ReadonlyArray<CustomField>;
  participantFields: ReadonlyArray<CustomField>;
  setError(error: string|null): void;
}

export const GlobalSettings: React.FC<GlobalSettingsProps> = (props) => {
  const [mutation] =
    useMutation<updateConfiguration, updateConfigurationVariables>(
      updateConfigurationMutation,
    );
  const formik = useFormik<GlobalSettingsFormValues>({
    initialValues: props.initialValue,
    onSubmit: async (values, formikHelpers) => {
      try {
        const result = await mutation({
          variables: {
            coloredFieldName: values.coloredFieldName,
            emphasizedFieldId: values.emphasizedFieldId,
            eventId: props.eventId,
            version: props.version,
          },
        });
        if (result.errors) {
          props.setError(
            "There was an error: "
            + result.errors
              .map((error) => error.toString())
              .join(", "),
          );
        } else if (!result.data) {
          props.setError(
            "There was an error: No response received.",
          );
        } else {
          props.setError(null);
          formikHelpers.resetForm({ values });
        }
      } catch (error) {
        const regWorksError = CompoundViolationError.fromError(error);
        if (regWorksError) {
          props.setError(regWorksError.toString());
        } else {
          props.setError(error.toString());
        }
      }
    },
  });

  const { emphasizedFieldId } = formik.values;

  const coloredFieldOptions =
    props.itemFields
      .filter((field) => (
        field.__typename === "SelectOneField"
      ))
      .map((field) => ({
        label: field.label,
        value: field.name,
      }));
  const emphasizedFieldOptions =
    props.itemFields.concat(...props.participantFields)
      .filter((field) => (
        field.__typename === "BooleanField"
      ))
      .map((field) => ({
        label: (
          field.formId === "ScheduleItem"
            ? "Item field: "
            : "Participant field: "
        ) + field.label,
        value: `${field.formId}.${field.name}`,
      }));
  const emphasizedFieldIdSelectValue =
    emphasizedFieldId
      ? `${emphasizedFieldId.formId}.${emphasizedFieldId.name}`
      : NULL_OPTION;

  return (
    <form onSubmit={formik.handleSubmit}>
      <SelectField
        id="coloredFieldName"
        label="Colored field"
        fullWidth={true}
        menuItems={[
          ...coloredFieldOptions,
          {
            label: <em>None</em>,
            value: NULL_OPTION,
          },
        ]}
        onChange={(newValue) => {
          if (typeof newValue === "string") {
            formik.setFieldValue("coloredFieldName", newValue);
          } else if (newValue === NULL_OPTION) {
            formik.setFieldValue("coloredFieldName", null);
          }
        }}
        helpText={
          "This is the field used to color the entry. Only select one fields" +
          " on items can be used for this."
        }
        value={formik.values.coloredFieldName || NULL_OPTION}
      />
      <SelectField
        id="emphasizedField"
        label="Emphasized field"
        fullWidth={true}
        menuItems={[
          ...emphasizedFieldOptions,
          {
            label: <em>None</em>,
            value: NULL_OPTION,
          },
        ]}
        onChange={(newValue) => {
          if (typeof newValue === "string") {
            const [formId, name] = newValue.split(".");
            formik.setFieldValue(
              "emphasizedFieldId",
              { formId, name },
            );
          } else if (newValue === NULL_OPTION) {
            formik.setFieldValue("emphasizedFieldId", null);
          }
        }}
        helpText={
          "This is the field used to italicize the title of the entry. Only" +
          " boolean fields can be used for this."
        }
        value={emphasizedFieldIdSelectValue}
      />
      <Button
        className={`${BEM}_button ${BEM}_right`}
        type="submit"
        raised
        primary
        disabled={formik.isSubmitting || !formik.dirty}
      >
        {
          formik.isSubmitting
            ? "Saving..."
            : formik.dirty
            ? "Save"
            : "Saved"
        }
      </Button>
    </form>
  );
};
