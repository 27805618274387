import { useMutation } from "@apollo/react-hooks";
import { ApolloError } from "apollo-client";
import { DocumentNode } from "graphql";

import { CompoundViolationError } from "../../../net/CompoundViolationError";
import { FieldFormData } from "./FieldFormData";
import { handleMutationError } from "./util";
import {
  customFields_event_schedule_itemForm_fields as CustomField,
} from "./__generated__/customFields";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const updateBooleanFieldMutation: DocumentNode = require("./updateBooleanField.graphql");
import {
  updateBooleanField,
  updateBooleanFieldVariables,
} from "./__generated__/updateBooleanField";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const updateSelectOneFieldMutation: DocumentNode = require("./updateSelectOneField.graphql");
import {
  updateSelectOneField,
  updateSelectOneFieldVariables,
} from "./__generated__/updateSelectOneField";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const updateTextFieldMutation: DocumentNode = require("./updateTextField.graphql");
import {
  updateTextField,
  updateTextFieldVariables,
} from "./__generated__/updateTextField";

export const convertFieldToFormData = (field: CustomField) => (
  field.__typename === "TextField"
    ? {
      type: "TextField" as const,
      name: field.name,
      label: field.label,
      order: field.order,
      help: field.help || "",
      required: field.required,
      public: field.public,
      adminWritable: field.adminWritable,
      maximumLength: field.maximumLength,
      default: field.textDefault,
      multiline: field.multiline,
      applicationWritable: field.applicationWritable,
    } : field.__typename === "BooleanField"
    ? {
      type: "BooleanField" as const,
      name: field.name,
      label: field.label,
      order: field.order,
      help: field.help || "",
      required: field.required,
      public: field.public,
      adminWritable: field.adminWritable,
      default: field.booleanDefault,
      applicationWritable: field.applicationWritable,
    } : {
      type: "SelectOneField" as const,
      name: field.name,
      label: field.label,
      order: field.order,
      help: field.help || "",
      required: field.required,
      public: field.public,
      adminWritable: field.adminWritable,
      default: field.selectOneDefault,
      options: field.options,
      applicationWritable: field.applicationWritable,
    }
);

interface Parameters {
  ref: string;
  version: number;
}

export const useUpdateField = () => {
  const [updateBooleanMutate] =
    useMutation<updateBooleanField, updateBooleanFieldVariables>(
      updateBooleanFieldMutation,
    );
  const [updateSelectOneMutate] =
    useMutation<updateSelectOneField, updateSelectOneFieldVariables>(
      updateSelectOneFieldMutation,
    );
  const [updateTextMutate] =
    useMutation<updateTextField, updateTextFieldVariables>(
      updateTextFieldMutation,
    );

  return (fieldMetadata: Parameters) => async (data: FieldFormData) => {
    let result;
    try {
      if (data.type === "TextField") {
        result = await updateTextMutate({
          variables: {
            ...data,
            ...fieldMetadata,
          },
        });
        result = handleMutationError(result, "updateScheduleTextField");
      } else if (data.type === "BooleanField") {
        result = await updateBooleanMutate({
          variables: {
            ...data,
            ...fieldMetadata,
          },
        });
        result = handleMutationError(result, "updateScheduleBooleanField");
      } else {
        result = await updateSelectOneMutate({
          variables: {
            ...data,
            // Remove __typename.
            options: data.options.map((option) => ({
              color: option.color,
              label: option.label,
              name: option.name,
            })),
            ...fieldMetadata,
          },
        });
        result = handleMutationError(result, "updateScheduleSelectOneField");
      }

      return result;
    } catch (error) {
      if (error instanceof ApolloError) {
        const regWorksError = CompoundViolationError.fromError(error);
        if (regWorksError) {
          throw regWorksError;
        } else {
          throw error;
        }
      } else {
        throw error;
      }
    }
  };
};
