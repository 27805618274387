import {ApolloClient} from "apollo-client";
import {DocumentNode} from "graphql";
import * as React from "react";
import { Query } from "react-apollo";
import {
  Button,
  Card,
  CardText,
  CardTitle,
  CircularProgress,
} from "react-md";
import { useHistory } from "react-router-dom";

import {Center} from "../layout/Center";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const getInviteQuery: DocumentNode = require("./getInvite.graphql");
import {
  getInvite,
  getInviteVariables,
} from "./__generated__/getInvite";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const acceptInviteMutation: DocumentNode = require("./acceptInvite.graphql");
import {
  acceptInvite,
  acceptInviteVariables,
} from "./__generated__/acceptInvite";

import "./index.scss";

type AcceptStatus = "unaccepted" | "accepted" | "loading" | "already-exists-error" | "other-error";

interface UnacceptedProps
{
  inviteName: string;
  inviteId: string;
  loginName: string;
  client: ApolloClient<any>;
  updateStatus(status: AcceptStatus): void;
  logout(): void;
}

const Unaccepted = (props: UnacceptedProps) => {
  const history = useHistory();
  const onContinueClick = () => {
    props.updateStatus("loading");
    props.client.mutate<acceptInvite, acceptInviteVariables>(
      {
        mutation: acceptInviteMutation,
        variables: { inviteId: props.inviteId },
      },
    )
      .then(
        (acceptResult) => {
          if (acceptResult.errors) {
            const errors = acceptResult.errors as unknown;
            if (
              errors instanceof Array
              && errors.length > 1
              && typeof errors[0].extensions === "object"
              && errors[0].extensions.errors instanceof Array
              && errors[0].extensions.errors.length > 0
              && errors[0].extensions.errors[0].field === "team member"
              && errors[0].extensions.errors[0].humanError === "already exists"
            ) {
              props.updateStatus("already-exists-error");
            } else {
              props.updateStatus("other-error");
            }
          } else {
            props.updateStatus("accepted");
            history.push("/");
          }
        },
      )
      .catch((e) => {
        if (
          typeof e === "object"
          && e.graphQLErrors
          && e.graphQLErrors instanceof Array
          && e.graphQLErrors.length > 0
          && e.graphQLErrors[0].extensions
          && e.graphQLErrors[0].extensions.errors instanceof Array
          && e.graphQLErrors[0].extensions.errors[0].field === "team member"
          && e.graphQLErrors[0].extensions.errors[0].humanError === "already exists"
        ) {
          props.updateStatus("already-exists-error");
        } else {
          props.updateStatus("other-error");
        }
      });
  };

  return (
    <React.Fragment>
      <p className="md-text md-body-1">
        You've been invited to {props.inviteName}.
      </p>
      <div className="rw-invite-actions">
        <Button
          flat
          secondary
          onClick={props.logout}
        >
          Log out
        </Button>
        <Button
          flat
          primary
          onClick={onContinueClick}
        >
          Continue as {props.loginName}
        </Button>
      </div>
    </React.Fragment>
  );
};

interface AcceptInviteProps
{
  inviteId: string;
  logout(): void;
}

export const AcceptInvite: React.FC<AcceptInviteProps> = (props) => {
  const [status, setStatus] = React.useState<AcceptStatus>("unaccepted");

  return (
    <Query<getInvite, getInviteVariables>
      query={getInviteQuery}
      variables={{ inviteId: props.inviteId }}
    >
      {
        (result) => {
          if (result.error) {
            return <>Something went wrong!</>;
          }

          if (result.loading || !result.data) {
            return <CircularProgress id="invite-progress" />;
          }

          if (!result.data.invite) {
            return <>Invite not found</>;
          }

          if (!result.data.session) {
            return <>
              You are not logged in. You need to be logged in to accept
              invites.
            </>;
          }

          let loginName = null;
          if (result.data.session.user) {
            const idP = (
              result
                .data
                .session
                .authentications[0]
                .credential
                .identityProvider
                .description
            );
            if (result.data.session.user.username) {
              loginName = `"${result.data.session.user.username}"`;
            } else if (result.data.session.user.person) {
              const displayName = result.data.session.user.person.displayName;
              loginName = `"${displayName}" via ${idP}`;
            } else {
              // This case shouldn't happen, but we should still handle it.
              const userId = result.data.session.user.id;
              loginName = `user ID ${userId} via ${idP}`;
            }
          } else {
            return <>
              You don't have a user account, which probably means you're a
              robot. Robots aren't allowed to accept invites
            </>;
          }

          const invite = result.data.invite;
          const inviteName =
            invite.__typename === "ScheduleItemParticipantInvite"
              ? `"${invite.displayName}" at "${invite.event.displayName}"`
              : `"${invite.displayName}"`;

          return (
            <Center>
              <Card>
                <CardTitle title="Welcome to Reg.Works!" />
                <CardText>
                  {
                    status === "unaccepted"
                      ? (
                        <Unaccepted
                          inviteId={props.inviteId}
                          client={result.client}
                          inviteName={inviteName}
                          updateStatus={setStatus}
                          loginName={loginName}
                          logout={props.logout}
                        />
                      )
                      : status === "loading"
                      ? <CircularProgress id="invite-progress" />
                      : status === "accepted"
                      ? (
                        <>
                          <p>
                            You're in! Loading Reg.Works...
                          </p>
                        </>
                      )
                      : status === "already-exists-error"
                      ? (
                        <>
                          <p>
                            It looks like you may have already accepted this
                            invite.
                          </p>
                          {
                            invite.__typename === "OrganizationInvite"
                              ? (
                                <Button
                                  component="a"
                                  href="/"
                                  raised
                                  label="Open Reg.Works"
                                />
                              ) : null
                          }
                        </>
                      )
                      : <p>There was an error!</p>
                  }
                </CardText>
              </Card>
            </Center>
          );
        }
      }
    </Query>
  );
};

