
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"scheduleFieldsWithItems"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Schedule"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"scheduleFieldsWithoutItems"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"items"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"updatedSinceVersion"},"value":{"kind":"Variable","name":{"kind":"Name","value":"version"}}}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"displayScheduleItemFragment"},"directives":[]}]}}]}}],"loc":{"start":0,"end":260}};
    doc.loc.source = {"body":"#import \"../displayScheduleItemFragment.graphql\"\n#import \"./scheduleFieldsWithoutItems.graphql\"\n\nfragment scheduleFieldsWithItems on Schedule {\n  ... scheduleFieldsWithoutItems\n  items(updatedSinceVersion: $version) {\n    ... displayScheduleItemFragment\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../displayScheduleItemFragment.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./scheduleFieldsWithoutItems.graphql").definitions));


      module.exports = doc;
    
