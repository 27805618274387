interface OAuthTokenSuccess {
  access_token: string;
  token_type: string;
  expires_in?: string;
  refresh_token?: string;
  scope?: string;
}

interface OAuthTokenError {
  error:
    | "invalid_request"
    | "invalid_client"
    | "invalid_grant"
    | "unauthorized_client"
    | "unsupported_grant_type"
    | "invalid_scope";

  error_description?: string;
  error_uri?: string;
}

type OAuthTokenResult = OAuthTokenSuccess | OAuthTokenError;

const TOKEN_URL = (
  "production" === process.env.NODE_ENV
    ? "https://api.regworks.io/auth/token"
    : `//${document.location.hostname}:8081/auth/token`
);
const AUTH_URL = (
  "production" === process.env.NODE_ENV
    ? "https://auth.regworks.io/authorize"
    : `//${document.location.hostname}:8091/authorize`
);
const CALLBACK_URI = (
  `${document.location.protocol}//${document.location.host}/auth`
);
export const STATE_NOT_FOUND_ERROR = "state-not-found";

async function createUserToken(
  username: string,
  password: string,
): Promise<OAuthTokenResult> {
  const response = await fetch(TOKEN_URL, {
    method: "POST",
    body: (
      // tslint:disable-next-line:prefer-template
      "grant_type=password"
      + "&username=" + encodeURIComponent(username)
      + "&password=" + encodeURIComponent(password)
      + "&redirect_uri=" + encodeURIComponent(CALLBACK_URI)
    ),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    mode: "cors",
    credentials: "omit",
    referrerPolicy: "no-referrer",
    redirect: "error",
  });

  if (!response.ok) {
    try {
      const message = await response.json();
      if ("object" === typeof message && null != message.error) {
        return message;
      }
    } catch (error) {
      // intended no-op; just fall through to the throw
    }

    throw new Error(`token request failed with ${response.status}`);
  } else {
    return await response.json();
  }
}

async function fetchTokenWithCode(
  code: string,
): Promise<OAuthTokenResult> {
  const response = await fetch(TOKEN_URL, {
    method: "POST",
    body: (
      // tslint:disable-next-line:prefer-template
      "grant_type=authorization_code"
      + "&code=" + encodeURIComponent(code)
      + "&client_id=app"
      + "&redirect_uri=" + encodeURIComponent(CALLBACK_URI)
    ),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    mode: "cors",
    credentials: "omit",
    referrerPolicy: "no-referrer",
    redirect: "error",
  });

  if (!response.ok) {
    try {
      const message = await response.json();
      if ("object" === typeof message && null != message.error) {
        return message;
      }
    } catch (error) {
      // intended no-op; just fall through to the throw
    }

    throw new Error(`token request failed with ${response.status}`);
  } else {
    return await response.json();
  }
}


function isOAuthError(
  result: OAuthTokenResult,
): result is OAuthTokenError {
  return (result as OAuthTokenError).error != null;
}


const TOKEN_KEY = "token";
const STATE_KEY = "state";
const REDIRECT_KEY = "redirect";

export class Auth {
  static isBadCredentialsError(error: Error): boolean {
    return (true === (error as any).isBadCredentials);
  }

  private onTokenChanged: () => Promise<void>;
  private token: string | null;

  constructor(onTokenChanged: () => Promise<void>) {
    this.onTokenChanged = onTokenChanged;
    this.token = localStorage.getItem(TOKEN_KEY);
  }


  getToken(): string | null {
    return this.token;
  }

  /**
   * @param redirectTarget string the eventual redirect target. Use href.
   * @param idp? string the ID of the IdP to use. Uses the default one if not
   * specified
   * @param inviteId? string the ID of the invite, if there is one
   * @return string
   */
  async createAuthorizationRedirect(
    redirectTarget: string,
    idp?: string,
    inviteId?: string,
  ) {
    // This function is async because it writes to local storage and deferring
    // the action taken with the result, which is expected to be a redirect,
    // has been empirically shown to reduce incidence of a race condition that
    // causes the write to not be committed. Async is preferred to setTimeout
    // because it provides more flexibility for this interface later.

    // 128 bits of random hex
    const state =
      Array.from(crypto.getRandomValues(new Uint8Array(16)))
        .map((byte) => ("0" + byte.toString(16)).substr(-2))
        .join("");
    sessionStorage.setItem(STATE_KEY, state);
    // Prevent infinite redirect loops.
    const currentAddr = (
      redirectTarget.indexOf("/auth") !== -1
        ? "/"
        : redirectTarget
    );
    const idpParam = idp ? `&idp=${idp}` : "";
    const inviteParam = inviteId ? `&invite=${inviteId}` : "";
    sessionStorage.setItem(REDIRECT_KEY, currentAddr);
    return `${AUTH_URL}?response_type=code&client_id=app&state=${state}${idpParam}${inviteParam}`
      + `&redirect_uri=${encodeURIComponent(CALLBACK_URI)}`;
  }

  async fetchToken(code: string): Promise<string> {
    // Make sure the state matches.
    const redirect = sessionStorage.getItem(REDIRECT_KEY);
    const state = sessionStorage.getItem(STATE_KEY);
    const storedStateMatch = /state=([^&#=]*)/.exec(window.location.search);
    const storedState = storedStateMatch ? storedStateMatch[1] : null;
    sessionStorage.removeItem(REDIRECT_KEY);
    sessionStorage.removeItem(STATE_KEY);
    if (state !== storedState) {
      throw STATE_NOT_FOUND_ERROR;
    }

    const result = await fetchTokenWithCode(code);
    if (isOAuthError(result)) {
      if ("invalid_grant" === result.error) {
        const error = new Error(
          result.error_description || "Invalid username or password.",
        );
        (error as any).isBadCredentials = true;
        throw error;
      } else {
        throw new Error(
          `token request failed: ${result.error}: ${result.error_description}`,
        );
      }
    } else {
      // the login request succeeded, at least nominally
       if ("bearer" !== result.token_type) {
        throw new Error(
          `received unsupported token type '${result.token_type}'`,
        );
      } else {
        // login is complete; update the token and resolve
        await this.setToken(result.access_token);
        if (redirect) {
          return redirect;
        } else {
          return "/";
        }
      }
    }
  }

  async setToken(token: string | null): Promise<void> {
    this.token = token;
    if (token) {
      localStorage.setItem(TOKEN_KEY, token);
    } else {
      localStorage.removeItem(TOKEN_KEY);
    }
    await this.onTokenChanged();
  }


  async login(username: string, password: string): Promise<void> {
    const result = await createUserToken(username, password);
    if (isOAuthError(result)) {
      if ("invalid_grant" === result.error) {
        const error = new Error(
          result.error_description || "Invalid username or password.",
        );
        (error as any).isBadCredentials = true;
        throw error;
      } else {
        throw new Error(
          `token request failed: ${result.error}: ${result.error_description}`,
        );
      }
    } else {
      // the login request succeeded, at least nominally
      if ("bearer" !== result.token_type) {
        throw new Error(
          `received unsupported token type '${result.token_type}'`,
        );
      } else {
        // login is complete; update the token and resolve
        await this.setToken(result.access_token);
      }
    }
  }

  async logout(): Promise<void> {
    await this.setToken(null);
  }
}
