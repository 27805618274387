import { DocumentNode } from "graphql";
import * as React from "react";
import { useApolloClient } from "react-apollo";
import { Button, FontIcon, List } from "react-md";

import { computeOrderDiff } from "../util";
import { Field } from "./Field";
import { convertFieldToFormData, useUpdateField } from "./useUpdateField";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const customFieldsQuery: DocumentNode = require("./customFields.graphql");
import {
  customFields,
  customFieldsVariables,
  customFields_event_schedule_itemForm_fields as CustomField,
} from "./__generated__/customFields";

interface FieldListProps {
  fields: ReadonlyArray<CustomField>;
  eventSlug: string;
  version: number;
  setError(error: string|null): void;
}

export const FieldList: React.FC<FieldListProps> = (props) => {
  const apolloClient = useApolloClient();
  const updateField = useUpdateField();
  const [order, setOrder] = React.useState<ReadonlyArray<number>|null>(null);
  const [saving, setSaving] = React.useState(false);

  const moveField = (srcRef: string, destRef: string) => {
    const oldOrder = order ? [...order] : Array.from(props.fields.keys());
    const srcOrderIndex =
      oldOrder.findIndex((idx) => props.fields[idx].ref === srcRef);
    const destOrderIndex =
      oldOrder.findIndex((idx) => props.fields[idx].ref === destRef);
    if (srcOrderIndex === -1 || destOrderIndex === -1) {
      return;
    }

    const newOrder = [...oldOrder];
    newOrder.splice(
      destOrderIndex,
      0,
      newOrder.splice(
        srcOrderIndex,
        1,
      )[0],
    );

    setOrder(newOrder);
  };
  const saveOrder = () => {
    if (!order) {
      return;
    }

    const orderDiff = computeOrderDiff(
      (field) => field.ref,
      (field) => field.order,
      props.fields,
      order.map((idx) => props.fields[idx]),
    );

    (
      async () => {
        setSaving(true);
        try {
          let newVersion = 0;
          for (const update of orderDiff) {
            const mutationResult = await updateField({
              ref: update.entity.ref,
              version: props.version,
            })({
              ...convertFieldToFormData(update.entity),
              order: update.newOrder,
            });
            if (mutationResult) {
              newVersion = mutationResult.version;
            }
          }

          const fieldsQuery = apolloClient
            .cache
            .readQuery<customFields, customFieldsVariables>({
              query: customFieldsQuery,
              variables: {
                eventSlug: props.eventSlug,
              },
            });

          if (!fieldsQuery) {
            return;
          }
          // Update the cached locations.
          fieldsQuery.event.schedule!.version = newVersion;
          const form =
            props.fields[0].formId === "ScheduleItem"
              ? fieldsQuery.event.schedule!.itemForm
              : fieldsQuery.event.schedule!.participantForm;
          form.fields.sort((a, b) => {
            if (a.order !== b.order) {
              return a.order - b.order;
            }
            if (a.name !== b.name) {
              return a.name.localeCompare(b.name);
            }
            return 0;
          });
          apolloClient
            .writeQuery<customFields, customFieldsVariables>({
              query: customFieldsQuery,
              variables: {
                eventSlug: props.eventSlug,
              },
              data: fieldsQuery,
            });
        } catch (e) {
          setSaving(false);
          props.setError(e.toString());
          return;
        }
        setOrder(null);
        setSaving(false);
        props.setError(null);
      }
    )();
  };

  return (
    <>
      <List>
        {
          (
            order
              ? order.map((index) => props.fields[index])
              : props.fields
          )
            .map(
              (field) => (
                <Field
                  key={field.name}
                  field={field}
                  eventSlug={props.eventSlug}
                  setError={props.setError}
                  version={props.version}
                  moveField={moveField}
                />
              ),
            )
        }
      </List>
      {
        order
          ? (
            <div className="button-group rw-right-container">
              <Button
                iconChildren={<FontIcon>cancel</FontIcon>}
                onClick={() => setOrder(null)}
                primaryText="Cancel"
                flat
                secondary
              >
                Cancel re-ordering
              </Button>
              <Button
                iconChildren={<FontIcon>save</FontIcon>}
                onClick={saveOrder}
                primaryText="Save new order"
                raised
                disabled={!order || saving}
              >
                Save field order
              </Button>
            </div>
          ) : null
      }
    </>
  );
};
