import {ApolloClient} from "apollo-client";
import {
  ChronoUnit,
  Duration,
  ZonedDateTime,
} from "js-joda";
import { Store, applyMiddleware, createStore } from "redux";
import {
  Saga,
  Task,
  default as createSagaMiddleware,
} from "redux-saga";

import {FieldInstanceInput} from "../../../__generated__/globalTypes";
import {
  scheduleFetch,
  scheduleFetch_event_schedule,
  scheduleFetch_event_schedule_itemForm_fields as BaseField,
  scheduleFetch_event_schedule_itemForm_fields_SelectOneField as SelectOneField,
} from "./__generated__/scheduleFetch";

import {
  ColorInfo,
  Conflict,
  Resource,
  ResourceUsageChange,
  Schedule,
  ScheduleEntry,
  ScheduleItem,
  ScheduleLocation,
  ScheduleParticipant,
} from "./model";
import {
  addItemParticipantSaga,
  createItemSaga,
  deleteEntrySaga,
  publishScheduleSaga,
  removeItemParticipantSaga,
  saveEntrySaga,
  updateItemSaga,
} from "./sagas";

const DEFAULT_ENTRY_DURATION = 60;
export const START_TIME_INCREMENT = 15;

const defaultColor = "#999999";

export type MovingEntryMode =
  "FULL" | "START" | "END";

export interface GridPosition {
  type: "grid";
  minutesAfterStart: number;
  locationRow: number;
}

export interface OffGridPosition {
  type: "off-grid";
  x: number;
  y: number;
}

export interface EntryPreviewInfo {
  position: GridPosition | OffGridPosition;
  durationMinutes: number;
  conflicts: Conflict[];
}

export interface MovingEntryInfo {
  itemId: string;
  entryId: string|null;
  duration: Duration;
  durationMinutes: number;
  displayName: string;
  version: number;
  color: string;
  mode: MovingEntryMode;
  possibleConflicts: Conflict[];
}

export interface TransactionState {
  type: "SAVE" | "DELETE";
  id: number;
  entryId: string | null;
}

export interface ScheduleModelState {
  model: Schedule|null;
  messages: string[];

  movingEntry: MovingEntryInfo|null;
  /**
   * This is currently to make sure that entry forms collapse after the save
   * completes and to optimistically delete tiles.
   */
  transactionState: TransactionState|null;
  entryPreviewPos: EntryPreviewInfo|null;
}

export interface DeleteEntryAction {
  type: "DELETE_ENTRY";
  entryId: string;
  transactionId: number;
}

export interface CreateScheduleItemAction
{
  type: "CREATE_SCHEDULE_ITEM";
  event: string;
  version: number;
  displayName: string;
  status: string | null;
  orgName: string | null;
  description: string | null;
  owner: string | null;
  requestedCount: number | null;
  requestedDuration: Duration | null;
  rating: string | null;
  customFields: FieldInstanceInput[] | null;
}

export interface UpdateScheduleItemAction
{
  type: "UPDATE_SCHEDULE_ITEM";
  itemId: string;
  version: number;
  displayName?: string;
  status?: string | null;
  orgName?: string | null;
  description?: string | null;
  owner?: string | null;
  requestedCount?: number | null;
  requestedDuration?: Duration | null;
  rating?: string | null;
  customFields?: FieldInstanceInput[] | null;
}

export interface AddItemParticipantAction
{
  type: "ADD_ITEM_PARTICIPANT";
  identityId: string;
  itemId: string;
}

export interface RemoveItemParticipantAction
{
  type: "REMOVE_ITEM_PARTICIPANT";
  itemParticipantId: string;
}

export interface SelectItemAction {
  type: "SELECT_ITEM";
  itemId: string|null;
}

export interface EditItemAction {
  type: "EDIT_ITEM";
  itemId: string;
}

export interface SelectEntryAction {
  type: "SELECT_ENTRY";
  entryId: string|null;
  scrollToEntry: boolean;
}

export interface SaveEntryAction {
  type: "SAVE_ENTRY";
  transactionId: number;
}

export interface PublishScheduleAction {
  type: "PUBLISH_SCHEDULE";
}

export interface MoveEntryPreviewOffGridAction {
  type: "MOVE_ENTRY_PREVIEW_OFF_GRID";
  x: number;
  y: number;
  durationMinutes?: number;
}

export type ScheduleModelAction = (
  {
    type: "START_MOVING_ENTRY",
    entryId: string,
    mode: MovingEntryMode,
  } | {
    type: "TRANSACTION_STARTED",
    transactionState: TransactionState,
  } | {
    type: "TRANSACTION_COMPLETED",
  } | {
    type: "UPDATE_MODEL",
    eventSlug: string,
    data: scheduleFetch,
  } | {
    type: "MOVE_ENTRY_PREVIEW",
    minutesAfterStart: number,
    locationRow: number,
    durationMinutes?: number,
  } | SaveEntryAction
    | {
    type: "SHOW_MESSAGE",
    message: string,
  } | {
    type: "DISMISS_MESSAGE",
  } | DeleteEntryAction
  | {
    type: "START_NEW_ENTRY",
    itemId: string,
  } | {
    type: "STOP_MOVING_ENTRY",
  } | CreateScheduleItemAction
    | SelectItemAction
    | SelectEntryAction
    | EditItemAction
    | UpdateScheduleItemAction
    | AddItemParticipantAction
    | RemoveItemParticipantAction
    | PublishScheduleAction
    | MoveEntryPreviewOffGridAction
);

export type ScheduleStore = Store<ScheduleModelState, ScheduleModelAction>;

export function reducer(
  state: ScheduleModelState|undefined,
  action: ScheduleModelAction,
): ScheduleModelState {
  if (!state) {
    state = {
      movingEntry: null,
      model: null,
      entryPreviewPos: null,
      transactionState: null,
      messages: [],
    };
  }
  switch (action.type) {
    case "START_MOVING_ENTRY":
      if (!state.model) {
        return state;
      }
      if (state.movingEntry && action.entryId === state.movingEntry.entryId) {
        return {
          ...state,
          movingEntry: null,
        };
      } else {
        const foundEntry = Array.from(state.model.entries.values()).find(
          (entry) => entry.id === action.entryId,
        );
        if (foundEntry) {
          const limitOverrides = getItemResourceLimits(foundEntry.item);
          const possibleConflicts =
            findResourceConflicts(
              state.model.resourceChanges.filter(
                (change) => change.entry.id !== foundEntry.id,
              ),
              limitOverrides,
            );

          return {
            ...state,
            movingEntry: {
              itemId: foundEntry.item.id,
              entryId: foundEntry.id,
              displayName: foundEntry.item.displayName,
              duration: foundEntry.duration,
              durationMinutes: foundEntry.durationMinutes,
              version: foundEntry.version,
              color: foundEntry.item.color,
              mode: action.mode,
              possibleConflicts,
            },
            entryPreviewPos: {
              position: {
                type: "grid",
                minutesAfterStart: foundEntry.startMinutes,
                locationRow: foundEntry.location.topRow + foundEntry.subRow,
              },
              durationMinutes: foundEntry.durationMinutes,
              conflicts: (
                findOverlappingSpans(
                  foundEntry.startMinutes,
                  foundEntry.durationMinutes,
                  possibleConflicts,
                )
              ),
            },
          };
        } else {
          return state;
        }
      }

    case "STOP_MOVING_ENTRY":
      return {
        ...state,
        movingEntry: null,
        entryPreviewPos: null,
      };

    case "START_NEW_ENTRY":
      if (!state.model) {
        return state;
      }
      if (state.movingEntry && action.itemId === state.movingEntry.itemId) {
        return {
          ...state,
          movingEntry: null,
        };
      } else {
        const foundItem = Array.from(state.model.items.values()).find(
          (item) => item.id === action.itemId,
        );
        if (foundItem) {
          const duration = (
            foundItem.requestedDuration
              || Duration.ofMinutes(DEFAULT_ENTRY_DURATION)
          );
          const durationMinutes = duration.toMinutes();
          return {
            ...state,
            movingEntry: {
              itemId: foundItem.id,
              entryId: null,
              displayName: foundItem.displayName,
              duration,
              durationMinutes,
              version: foundItem.version,
              color: foundItem.color,
              mode: "FULL",
              possibleConflicts: (
                findResourceConflicts(
                  state.model.resourceChanges,
                  getItemResourceLimits(foundItem),
                )
              ),
            },
          };
        } else {
          return state;
        }
      }


    case "UPDATE_MODEL":
      const model = transformModel(state.model, action.data, action.eventSlug);
      const newItem = (
        state.movingEntry
          ? (model.items.get(state.movingEntry.itemId) || null)
          : null
      );
      const newEntry = (
        state.movingEntry && state.movingEntry.entryId
          ? (model.entries.get(state.movingEntry.entryId) || null)
          : null
      );
      // Tell the user that the selected entry was updated.
      const messages = (
        (
          newEntry
          && state.movingEntry
          && newEntry.version !== state.movingEntry.version
          && !state.transactionState
        ) ? [
          ...state.messages,
          `"${state.movingEntry.displayName}" was updated by another ${""
          }user.`,
        ] : state.messages
      );

      return {
        ...state,
        model,
        movingEntry: (
          (
            state.movingEntry
            && newItem
          ) ? {
            itemId: newItem.id,
            entryId: state.movingEntry.entryId,
            displayName: newItem.displayName,
            duration:
              newEntry ? newEntry.duration : state.movingEntry.duration,
            durationMinutes:
              newEntry
                ? newEntry.durationMinutes
                : state.movingEntry.durationMinutes,
            version: newItem.version,
            mode: state.movingEntry.mode,
            possibleConflicts:
              findResourceConflicts(
                model.resourceChanges,
                getItemResourceLimits(newItem),
              ),
            color: newItem.color,
          } : state.movingEntry
        ),
        messages,
      };

    case "MOVE_ENTRY_PREVIEW":
      // This has no effect when there's no entry to preview, or if it's
      // waiting for a request to finish.
      if (!(state.movingEntry && !state.transactionState)) {
        return state;
      }

      const durationMinutesPreview = (
        action.durationMinutes
        || (
          state.entryPreviewPos
            ? state.entryPreviewPos.durationMinutes
            : state.movingEntry.durationMinutes
        )
      );

      return {
        ...state,
        entryPreviewPos: {
          position: {
            type: "grid",
            minutesAfterStart: action.minutesAfterStart,
            locationRow: action.locationRow,
          },
          durationMinutes: durationMinutesPreview,
          conflicts: (
            findOverlappingSpans(
              action.minutesAfterStart,
              durationMinutesPreview,
              state.movingEntry.possibleConflicts,
            )
          ),
        },
      };

    case "MOVE_ENTRY_PREVIEW_OFF_GRID":
      // This has no effect when there's no entry to preview, if it's
      // waiting for a request to finish, or if this is a resize type of move.
      if (
        !(state.movingEntry && !state.transactionState)
        || state.movingEntry.mode !== "FULL"
      ) {
        return state;
      }

      const durationMinutesOffGrid = (
        action.durationMinutes
        || (
          state.entryPreviewPos
            ? state.entryPreviewPos.durationMinutes
            : state.movingEntry.durationMinutes
        )
      );

      return {
        ...state,
        entryPreviewPos: {
          position: {
            type: "off-grid",
            x: action.x,
            y: action.y,
          },
          durationMinutes: durationMinutesOffGrid,
          conflicts: [],
        },
      };


    case "TRANSACTION_STARTED":
      return {
        ...state,
        transactionState: action.transactionState,
      };

    case "TRANSACTION_COMPLETED":
      return {
        ...state,
        movingEntry: null,
        transactionState: null,
        entryPreviewPos: null,
      };

    case "SHOW_MESSAGE":
      return {
        ...state,
        messages: [...state.messages, action.message],
      };

    case "DISMISS_MESSAGE":
      return {
        ...state,
        messages: state.messages.slice(1),
      };

    default:
      return state;
  }
}

const filterSelectOneField = (field: BaseField): field is SelectOneField => (
  field.__typename === "SelectOneField"
);
/**
 * Get the colored field and its palette.
 */
function getItemColors(schedule: scheduleFetch_event_schedule) {
  if (!schedule.configuration.coloredItemField) {
    return null;
  }
  const coloredFieldName = schedule.configuration.coloredItemField.name;
  const coloredField = (
    coloredFieldName
      ? (
        schedule.itemForm.fields.filter(filterSelectOneField)
        .find((field) => field.name === coloredFieldName)
      ) : null
  );
  if (!coloredField) {
    return null;
  }
  const coloredFieldMap = new Map<string, string>();
  for (const option of coloredField.options) {
    if (option.color) {
      coloredFieldMap.set(option.name, option.color);

    } else {
      coloredFieldMap.set(option.name, defaultColor);
    }
  }
  return {
    field: coloredField,
    colorMap: coloredFieldMap,
  };
}

function getItemResources(item: ScheduleItem): Resource[] {
  const result: Resource[] = [];
  for (const participant of item.participants) {
    result.push({
      type: "PARTICIPANT",
      id: participant.scheduleParticipant.id,
      participant: participant.scheduleParticipant,
      limit: 1,
    });
  }
  return result;
}

function getItemResourceLimits(item: ScheduleItem): Map<string, number|null> {
  return new Map(
    getItemResources(item)
      .map(
        (resource) =>
        [
          resource.id,
          resource.limit === null ? null : resource.limit - 1,
        ],
      ),
  );
}

function findOverlappingSpans(
  startMinutes: number,
  durationMinutes: number,
  conflicts: ReadonlyArray<Conflict>,
): Conflict[] {
  return conflicts.filter(
    (conflict) => (
      startMinutes < conflict.startMinutes + conflict.durationMinutes
      && conflict.startMinutes < startMinutes + durationMinutes
    ),
  );
}

/**
 * If resourceLimits is not set, then use the default limits.
 * If it is set, all the resources that are unspecified have no limits.
 */
function findResourceConflicts(
  resourceChanges: ReadonlyArray<ResourceUsageChange>,
  resourceLimits?: Map<string, number|null>,
): Conflict[] {
  // Detect conflicts in resource use.
  const conflicts: Conflict[] = [];
  const resourceUsage: Map<string, {
    entries: ScheduleEntry[],
    usage: number,
  }> = new Map();
  const conflictsInProgress: Map<string, {
    resource: Resource,
    entries: ScheduleEntry[],
    startMinutes: number,
    start: ZonedDateTime,
  }> = new Map();

  for (const change of resourceChanges) {
    const originalUsage = resourceUsage.get(change.resource.id) || {
      entries: [],
      usage: 0,
    };
    const newUsage = originalUsage.usage + change.change;
    const limitOverride = (
      resourceLimits
        ? resourceLimits.get(change.resource.id)
        : undefined
    );
    const limit = (
      limitOverride === undefined
        ? (
          resourceLimits
            ? null
            : change.resource.limit
        ) : limitOverride
    );
    const newEntries = (
      change.change < 0
        ? originalUsage.entries.filter((entry) => change.entry.id !== entry.id)
        : [...originalUsage.entries, change.entry]
    );
    resourceUsage.set(change.resource.id, {
      entries: newEntries,
      usage: newUsage,
    });

    // If there is no limit then we don't even note when the resource changes.
    if (limit === null) {
      continue;
    }

    if (originalUsage.usage <= limit && limit < newUsage) {
      // Find out if this is the continuation of a conflict that seemed to end
      // at the same moment.
      let continuingConflict = null;
      if (conflicts.length > 0) {
        // Iterate backwards from the last completed conflict over all
        // conflicts that completed at the same time as this one started.
        let prevConflictIndex = conflicts.length - 1;
        while (
          0 <= prevConflictIndex
          && (
            conflicts[prevConflictIndex].startMinutes
            + conflicts[prevConflictIndex].durationMinutes
            === change.timeMinutes
          )
        ) {
          if (conflicts[prevConflictIndex].resource.id === change.resource.id) {
            continuingConflict = conflicts[prevConflictIndex];
            conflicts.splice(prevConflictIndex, 1);
            break;
          }
          --prevConflictIndex;
        }
      }

      if (!continuingConflict) {
        // If a conflict starts here, note that.
        conflictsInProgress.set(
          change.resource.id,
          {
            resource: change.resource,
            entries: [...originalUsage.entries, change.entry],
            startMinutes: change.timeMinutes,
            start: change.time,
          },
        );
      } else {
        // If we're continuing a conflict, then continue the conflict in
        // progress.
        conflictsInProgress.set(
          change.resource.id,
          {
            resource: change.resource,
            entries: [...continuingConflict.entries, change.entry],
            startMinutes: continuingConflict.startMinutes,
            start: continuingConflict.start,
          },
        );
      }
    } else if (limit < originalUsage.usage && limit < newUsage) {
      // If this is part of an on-going conflict, add the current entry to the
      // conflict if it's not already there.
      const currentConflict = conflictsInProgress.get(change.resource.id);
      if (!currentConflict) {
        throw new Error(
          `Conflict in progress not found on ${change.resource.id} at ${change.timeMinutes}`,
        );
      }

      if (
        !currentConflict.entries.find(
          (entry) => change.entry.id === entry.id,
        )
      ) {
        currentConflict.entries.push(change.entry);
      }
    } else if (newUsage <= limit && limit < originalUsage.usage) {
      const currentConflict = conflictsInProgress.get(change.resource.id);
      if (!currentConflict) {
        throw new Error(
          `Conflict in progress not found on ${change.resource.id} at ${change.timeMinutes}`,
        );
      }
      conflictsInProgress.delete(change.resource.id);
      // If a conflict is ending, then add it to the conflict list.
      const conflictEnd = change.timeMinutes;
      const conflictDurationMinutes = conflictEnd - currentConflict.startMinutes;
      const conflictDuration = Duration.ofMinutes(conflictDurationMinutes);
      const conflict: Conflict = {
        resource: currentConflict.resource,
        startMinutes: currentConflict.startMinutes,
        start: currentConflict.start,
        duration: conflictDuration,
        durationMinutes: conflictDurationMinutes,
        entryIds: currentConflict.entries.map((entry) => entry.id),
        entries: currentConflict.entries,
      };
      conflicts.push(conflict);
    }
  }
  return conflicts;
}

/**
 * This is a function to compare entries such that they sort stably.
 */
function compareEntries(a: ScheduleEntry, b: ScheduleEntry) {
  return (a.startMinutes - b.startMinutes)
    || (a.version - b.version);
}

export function transformModel(
  state: Schedule | null,
  input: scheduleFetch,
  eventSlug: string,
): Schedule {
  if (state != null && state.source === input) {
    return state;
  }

  const startsAt = (
    state ? state.startsAt
    : ZonedDateTime.parse(input.event.startsAt)
  );
  const endsAt = (
    state ? state.endsAt
    : ZonedDateTime.parse(input.event.endsAt)
  );

  let version = 0;
  const items = new Map<string, ScheduleItem>();
  const entries = new Map<string, ScheduleEntry>();
  const locations = new Map<string, ScheduleLocation>();
  const participants = new Map<string, ScheduleParticipant>();
  let colorInfo: ColorInfo|null = null;

  if (input.event.schedule) {
    version = input.event.schedule.version;

    for (const participant of input.event.schedule.participants) {
      participants.set(
        participant.identityId,
        {
          id: participant.identityId,
          displayName: participant.displayName,
        },
      );
    }

    for (const location of input.event.schedule.locations) {
      locations.set(location.id, {
        id: location.id,
        topRow: 0,
        name: location.name,
        maxEntryVersion: 0,
        version: location.version,
        entries: [],
        rowHeight: 0,
        maxConcurrentEntries: location.maxConcurrentEntries,
      });
    }

    colorInfo = getItemColors(input.event.schedule);
    const emphasisField = input.event.schedule.configuration.emphasizedField;

    for (const item of input.event.schedule.items) {
      const color = (
        colorInfo
          ? (
            item.customFields
            .slice(0)
            .reduce(
              (acc: string|undefined, fieldInst, _, arr) => {
                if (
                  fieldInst.value
                  && fieldInst.value.__typename === "StringFieldValue"
                  && fieldInst.value.stringValue
                  && fieldInst.name === colorInfo!.field.name
                ) {
                  // break
                  arr.splice(1);
                  return colorInfo!.colorMap.get(fieldInst.value.stringValue);
                }
                return;
              },
              undefined,
            )
          )
          : null
      ) || defaultColor;
      const emphasis = (
        emphasisField
        ? (
          emphasisField.formId === "ScheduleItem"
            ? !!item.customFields
                .find(
                  (fieldInst) =>
                    !!fieldInst.value
                    && fieldInst.value.__typename === "BooleanFieldValue"
                    && !!fieldInst.value.booleanValue
                    && fieldInst.name === emphasisField.name,
                )
            : !!item.participants.find(
              (part) =>
              !!part.scheduleParticipant.customFields
                .find(
                  (fieldInst) =>
                    !!fieldInst.value
                    && fieldInst.value.__typename === "BooleanFieldValue"
                    && !!fieldInst.value.booleanValue
                    && fieldInst.name === emphasisField.name,
                ),
            )
        )
        : false
      );
      const modelItem = {
        id: item.id,
        version: item.version,
        displayName: item.displayName,
        status: item.status,
        color: `#${color}`,
        emphasis,
        requestedDuration: (
          item.requestedDuration
          ? Duration.parse(item.requestedDuration)
          : undefined
        ),
        entries: [],
        participants: (
          item.participants
            .filter((part) => participants.has(part.scheduleParticipant.identityId))
            .map(
              (part) => ({
                id: part.id,
                scheduleParticipant:
                  participants.get(part.scheduleParticipant.identityId)!,
              }),
            )
        ),
        resources: [],
        source: item,
      };
      (modelItem.resources as Resource[]) = getItemResources(modelItem);

      items.set(item.id, modelItem);

      for (const entry of item.entries) {
        const modelLoc = locations.get(entry.location.id);
        if (!modelLoc) throw new Error("entry refers to missing location");

        const oldEntry = state && state.entries.get(entry.id);

        let start: ZonedDateTime;
        let startMinutes: number;
        let duration: Duration;
        let durationMinutes: number;
        if (oldEntry && oldEntry.version === entry.version) {
          start = oldEntry.start;
          startMinutes = oldEntry.startMinutes;
          duration = oldEntry.duration;
          durationMinutes = oldEntry.durationMinutes;
        } else {
          start = ZonedDateTime.parse(entry.start);
          startMinutes = startsAt.until(start, ChronoUnit.MINUTES);
          duration = Duration.parse(entry.duration);
          durationMinutes = duration.toMinutes();
        }

        const modelEntry = {
          id: entry.id,
          version: entry.version,
          item: modelItem,
          location: modelLoc,
          start,
          startMinutes,
          duration,
          durationMinutes,
          subRow: 0,
          conflicts: [],
        };

        entries.set(entry.id, modelEntry);

        (modelLoc.entries as ScheduleEntry[]).push(modelEntry);
        (modelLoc.maxEntryVersion as number) =
          Math.max(
            Math.max(modelLoc.maxEntryVersion, entry.version),
            modelItem.version,
          );

        (modelItem.entries as ScheduleEntry[]).push(modelEntry);
      }
      (modelItem.entries as ScheduleEntry[]).sort(compareEntries);
    }
  }

  let currentRow = 0;
  for (const location of locations.values()) {
    // This sorts entries first by start time, then by version.
    // The version acts as a tie breaker, ensuring that the sort is stable for
    // entries that start at the same time. This is important because the
    // algorithm for determining the entry's subrow depends on the order of the
    // entries.
    (location.entries as ScheduleEntry[])
      .sort(compareEntries);

    // Calculate how many subrows the location takes up.
    let maxRow = 0;
    let activeEntries: ScheduleEntry[] = [];
    const getUnoccupiedSubrow = () => {
      const occupiedRows = new Set<number>(
        activeEntries.map((entry) => entry.subRow),
      );
      let result = 0;
      while (occupiedRows.has(result)) {
        ++result;
      }
      maxRow = Math.max(maxRow, result);
      return result;
    };

    for (const entry of location.entries as ScheduleEntry[]) {
      const start = entry.startMinutes;
      // Remove all entries that have elapsed.
      activeEntries = activeEntries.filter(
        (activeEntry) =>
          start < activeEntry.startMinutes + activeEntry.durationMinutes,
      );

      // Set the subrow of this entry to the first unoccupied subrow.
      (entry.subRow as number) = getUnoccupiedSubrow();

      // Add to the active entries list for later consideration.
      activeEntries.push(entry);
    }

    (location.rowHeight as number) =
      location.maxConcurrentEntries === null
        ? maxRow + 1
        : Math.max(location.maxConcurrentEntries, maxRow + 1);
    (location.topRow as number) = currentRow;
    currentRow += location.rowHeight;
  }

  // Enumerate resource usage change events.
  const resourceChanges: ResourceUsageChange[] = [];
  for (const entry of entries.values()) {
    resourceChanges.push({
      resource: {
        type: "LOCATION",
        id: entry.location.id,
        location: entry.location,
        limit: entry.location.maxConcurrentEntries,
      },
      entry,
      timeMinutes: entry.startMinutes,
      time: entry.start,
      change: 1,
    });
    resourceChanges.push({
      resource: {
        type: "LOCATION",
        id: entry.location.id,
        location: entry.location,
        limit: entry.location.maxConcurrentEntries,
      },
      entry,
      timeMinutes: entry.startMinutes + entry.durationMinutes,
      time: entry.start.plusTemporalAmount(entry.duration),
      change: -1,
    });

    for (const resource of entry.item.resources) {
      resourceChanges.push({
        resource,
        entry,
        timeMinutes: entry.startMinutes,
        time: entry.start,
        change: 1,
      });
      resourceChanges.push({
        resource,
        entry,
        timeMinutes: entry.startMinutes + entry.durationMinutes,
        time: entry.start.plusTemporalAmount(entry.duration),
        change: -1,
      });
    }
  }
  resourceChanges.sort(
    (a, b) => {
      if (a.timeMinutes !== b.timeMinutes) {
        return a.timeMinutes - b.timeMinutes;
      }
      // We need this to make sure that decrements are applied before
      // increments, so that a simultaneous decrement and increment don't
      // count as an overlap.
      if (a.change !== b.change) {
        return a.change - b.change;
      }
      return 0;
    },
  );

  // Detect conflicts in resource use.
  const conflicts = findResourceConflicts(resourceChanges);
  for (const conflict of conflicts) {
    for (const entryId of conflict.entryIds) {
      const entry = entries.get(entryId);
      if (entry) {
        (entry.conflicts as Conflict[]).push(conflict);
      }
    }
  }

  return {
    source: input,

    eventSlug,
    version,
    startsAt,
    endsAt,
    durationMinutes: startsAt.until(endsAt, ChronoUnit.MINUTES),

    items,
    entries,
    locations,
    participants,

    conflicts,
    resourceChanges,
    colorInfo,
  };
}

export function createScheduleStore(
  client: ApolloClient<{}>,
  extraSagas: Saga[],
) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(reducer, applyMiddleware(sagaMiddleware));

  sagaMiddleware.run(saveEntrySaga, client);
  sagaMiddleware.run(deleteEntrySaga, client);
  sagaMiddleware.run(createItemSaga, client);
  sagaMiddleware.run(updateItemSaga, client);
  sagaMiddleware.run(addItemParticipantSaga, client);
  sagaMiddleware.run(removeItemParticipantSaga, client);
  sagaMiddleware.run(publishScheduleSaga, client);

  const sagas: Map<Saga, Task> = new Map();
  for (const saga of extraSagas) {
    sagas.set(saga, sagaMiddleware.run(saga));
  }

  return {
    store,
    sagas,
    sagaMiddleware,
  };
}
