import * as React from "react";
import { FontIcon } from "react-md";

import { ScheduleEntry } from "../model/model";
import { ScheduleModelAction } from "../model/reducer";
import { PIXELS_PER_MINUTE, ROW_HEIGHT } from "./constants";
import { useDragHandlers } from "./useDragHandlers";

import "./SelectedEntryButton.scss";

const BEM = "rw-schedule-grid-selected-entry-button";

interface SelectedEntryButtonProps {
  selectedEntry: ScheduleEntry|null;
  containerHeight: number|null;
  dispatch(action: ScheduleModelAction): void;
}

export const SelectedEntryButton: React.FC<SelectedEntryButtonProps> = (props) => {
  const startMove = () => {
    props.dispatch({
      type: "START_MOVING_ENTRY",
      entryId: selectedEntry.id,
      mode: "FULL",
    });
  };
  const [animationStarted, setAnimationStarted] =
    React.useState(!!props.selectedEntry);
  const [lastEntryId, setLastEntryId] =
    React.useState(props.selectedEntry?.id);
  const dragHandlers =
    useDragHandlers({ onDrag: startMove, handleTouch: true });
  React.useEffect(
    () => {
      // Start the animation after a tick.
      if (
        props.selectedEntry
        && props.selectedEntry.id !== lastEntryId
      ) {
        const id = props.selectedEntry.id;
        setAnimationStarted(false);
        setLastEntryId(undefined);
        window.setTimeout(
          () => {
            setAnimationStarted(true);
            setLastEntryId(id);
          },
          16,
        );
      }
      // Reset the animation when unselected.
      if (!props.selectedEntry) {
        setAnimationStarted(false);
        setLastEntryId(undefined);
      }
    },
    [animationStarted, props.selectedEntry, lastEntryId],
  );

  if (
    // Prevent rendering when there is no selection
    !props.selectedEntry
    // or before we start animating, in between switch the location of this
    // to another entry. We need this check to stop the button from flashing.
    || (
      props.selectedEntry
      && lastEntryId
      && animationStarted
      && props.selectedEntry.id !== lastEntryId
    )
  ) {
    return null;
  }

  const selectedEntry = props.selectedEntry;

  // Place this to the left of the selected entry, unless it's all the way to
  // the left. In that case, put it on the right.
  const top = (
    selectedEntry.location.topRow
    + selectedEntry.subRow
  ) * ROW_HEIGHT;
  const placeButtonOnRight = selectedEntry.startMinutes <= 0;
  const left = (
    placeButtonOnRight
      ? selectedEntry.durationMinutes * PIXELS_PER_MINUTE
      : selectedEntry.startMinutes * PIXELS_PER_MINUTE
  );

  return (
    <div
      className={
        `${BEM}_container`
        + (
          placeButtonOnRight ? ` ${BEM}_container--right` : ""
        )
      }
      style={{
        top,
        left,
        height: ROW_HEIGHT,
      }}
    >
      <div
        className={
          `${BEM}_button`
          + (
            placeButtonOnRight ? ` ${BEM}_button--right` : ""
          )
          + (
            animationStarted ? ` ${BEM}_button--animating` : ""
          )
        }
        style={{
          width: ROW_HEIGHT,
          height: ROW_HEIGHT,
        }}
        onClick={startMove}
        {...dragHandlers}
      >
        <FontIcon className={`${BEM}_icon`}>
          open_with
        </FontIcon>
      </div>
    </div>
  );
};
