import * as React from "react";
import {
  FontIcon,
  Snackbar,
} from "react-md";

import {namedComponent} from "../../../namedComponent";

import {
  EntryPreviewInfo,
  Schedule,
  ScheduleEntry,
} from "../model";
import {
  MovingEntryInfo,
  START_TIME_INCREMENT,
  ScheduleModelAction,
  TransactionState,
} from "../model/reducer";

import { MediaType } from "../../AppFrame";
import {
  BEM,
  PIXELS_PER_MINUTE,
  ROW_HEIGHT,
} from "./constants";
import {GutterLeft} from "./GutterLeft";
import {GutterTop} from "./GutterTop";
import {ItemDrawer} from "./ItemDrawer";
import {
  LocationRow,
  LocationRowProps,
} from "./LocationRow";
import {PreviewEntryTile} from "./PreviewEntryTile";
import {ResourceExclusionZone} from "./ResourceExclusionZone";
import {ScheduleGridDispatch} from "./sagas";
import { SelectedEntryButton } from "./SelectedEntryButton";
import {
  RouteInfo,
} from "./util";

import {AbsoluteViewport} from "../../layout/AbsoluteViewport";

const MAX_HORIZ_SCROLL_SPEED = 1250;
const MAX_VERT_SCROLL_SPEED = 750;
// The scroll gutter width scales from the maximum to the minimum as the
// dimension of the viewport perpendicular to the gutter goes from the maximum
// to minimum size.
const MAX_SCROLL_GUTTER_VIEWPORT_SIZE = 1000;
const MIN_SCROLL_GUTTER_VIEWPORT_SIZE = 500;
const MAX_SCROLL_GUTTER_SIZE = 100;
const MIN_SCROLL_GUTTER_SIZE = 50;
const SCROLL_START_DELAY = 250;

interface GutterBottomProps {
  messages: string[];
  dispatch(action: ScheduleModelAction): void;
}

const GutterBottom: React.FC<GutterBottomProps> = React.memo(
  namedComponent("GutterBottom", (props) => (
    <Snackbar
      id="grid-alert"
      toasts={props.messages.map((message) => ({text: message}))}
      onDismiss={() => props.dispatch({type: "DISMISS_MESSAGE"})}
    />
  )),
);

export interface ScheduleGridProps {
  model: Schedule;
  selectedEntry: ScheduleEntry|null;
  movingEntry: MovingEntryInfo|null;
  entryPreviewPos: EntryPreviewInfo|null;
  messages: string[];
  eventSlug: string;
  drawerVisible: boolean;
  routeInfo: RouteInfo|null;
  dispatch: ScheduleGridDispatch;
  transactionState: TransactionState|null;
  media: MediaType;
}

const gutterLeftSize = 100;
const gutterTopSize = 30;

export class ScheduleGrid extends React.Component<ScheduleGridProps> {
  private viewportRef: React.RefObject<AbsoluteViewport>;
  private gridRef: React.RefObject<HTMLDivElement>;
  // The last client mouse positions. Used for when we need the position of the
  // mouse in the context of the scroll handler.
  private lastMousePos: { x: number, y: number }|null = null;

  private scrollStartTime: number|undefined;
  private xScrollVelocity = 0;
  private yScrollVelocity = 0;
  private lastAutoscrollTime: number;
  private autoscrollHandle?: number;

  constructor(props: ScheduleGridProps) {
    super(props);

    this.viewportRef = React.createRef();
    this.gridRef = React.createRef();
    this.handleMovingEntry = this.handleMovingEntry.bind(this);
    this.handleMouseMovingEntry = this.handleMouseMovingEntry.bind(this);
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchMovingEntry = this.handleTouchMovingEntry.bind(this);
    this.handleTrashCanMouseUp = this.handleTrashCanMouseUp.bind(this);
    this.handleGridDragEnd = this.handleGridDragEnd.bind(this);
    this.handleGridOnClick = this.handleGridOnClick.bind(this);
    this.handleGridDoubleClick = this.handleGridDoubleClick.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.hideItemDrawer = this.hideItemDrawer.bind(this);
    this.handleAutoScroll = this.handleAutoScroll.bind(this);
    this.onTrashFabMouseMove = this.onTrashFabMouseMove.bind(this);

    this.lastAutoscrollTime = Date.now();
  }

  componentDidMount() {
    if (this.props.selectedEntry) {
      this.scrollToEntry(this.props.selectedEntry.id);
    }
    this.autoscrollHandle =
      requestAnimationFrame(this.handleAutoScroll);
  }

  componentWillReceiveProps(newProps: ScheduleGridProps) {
    // If we stop moving the entry, make sure that autoscroll stops. This
    // shouldn't be needed, as the appropriate handlers should stop scrolling.
    // However, it is possible to stop an entry from being placed if the entry
    // being moved is moved by someone else. And that code doesn't even know
    // that scrolling exists.
    if (
      this.props.entryPreviewPos
      && newProps.entryPreviewPos === null
      && this.viewportRef.current
    ) {
      this.xScrollVelocity = 0;
      this.yScrollVelocity = 0;
      this.lastMousePos = null;
      this.scrollStartTime = undefined;
    }
  }

  componentWillUnmount() {
    if (this.autoscrollHandle) {
      cancelAnimationFrame(this.autoscrollHandle);
    }
  }

  render() {
    const model = this.props.model;

    const gutterLeft = (
      <GutterLeft locations={Array.from(model.locations.values())}
        version={model.version} />
    );

    const gutterBottom = (
      <GutterBottom messages={this.props.messages}
        dispatch={this.props.dispatch} />
    );

    const hoverPos = (
      this.props.movingEntry
      && this.props.entryPreviewPos
      && this.viewportRef.current
        ? (
          this.props.entryPreviewPos.position.type === "grid"
            ? {
              isOnGrid: true,
              x: this.props.entryPreviewPos.position.minutesAfterStart
                * PIXELS_PER_MINUTE
              ,
              y: this.props.entryPreviewPos.position.locationRow * ROW_HEIGHT,
            } : ((rect) => (rect && {
              isOnGrid: false,
              x: this.props.entryPreviewPos.position.x - rect.left,
              y: this.props.entryPreviewPos.position.y - rect.top,
            }))(this.viewportRef.current.getBoundingClientRect())
        ) : null
    );

    const movingItem = (
      this.props.movingEntry
      && model.items.get(this.props.movingEntry.itemId)
    );

    const movingEntry = (
      this.props.movingEntry
      && this.props.movingEntry.entryId
      && model.entries.get(this.props.movingEntry.entryId)
    );

    const locationHeight = (
      Array.from(model.locations.values())
      .reduce((rows, loc) => rows + loc.rowHeight, 0)
    ) * ROW_HEIGHT;

    const previewEntryTile = (
      hoverPos
      && this.props.movingEntry
      && movingItem
        ? (
          <PreviewEntryTile
            x={hoverPos.x}
            y={hoverPos.y}
            width={
              this.props.entryPreviewPos
                ? (
                  this.props.entryPreviewPos.durationMinutes
                  * PIXELS_PER_MINUTE
                ) : (
                  this.props.movingEntry.durationMinutes
                  * PIXELS_PER_MINUTE
                )
            }
            isSelected={false}
            isResizing={this.props.movingEntry.mode !== "FULL"}
            displayName={this.props.movingEntry.displayName}
            color={this.props.movingEntry.color}
            dispatch={this.props.dispatch}
            conflicts={this.props.entryPreviewPos!.conflicts}
            canDrop={
              !!this.props.entryPreviewPos
              && this.props.entryPreviewPos.position.type === "grid"
            }
            itemEmphasis={movingItem.emphasis}
            itemRating={movingItem.source.rating}
            itemStatus={movingItem.status}
            isDeleting={
              this.props.transactionState !== null
              && this.props.transactionState.entryId === this.props.movingEntry.entryId
              && this.props.transactionState.type === "DELETE"
            }
          />
        ) : null
    );
    const isFabVisible = (
      this.props.movingEntry
      && this.props.movingEntry.mode === "FULL"
    );

    return (
      <div
        className={`${BEM}_viewport_container`}
        onMouseMove={this.handleMouseMovingEntry}
        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMovingEntry}
        onMouseUp={this.handleGridDragEnd}
        onTouchEnd={this.handleGridDragEnd}
      >
        <AbsoluteViewport
          ref={this.viewportRef}
          sizeRight={model.durationMinutes * PIXELS_PER_MINUTE}
          sizeBottom={locationHeight}
          gutterLeft={gutterLeft}
          gutterLeftSize={gutterLeftSize}
          gutterTop={
            <GutterTop startsAt={model.startsAt}
                       endsAt={model.endsAt}
                       timeZone={model.source.event.timeZone}
            />
          }
          gutterTopSize={gutterTopSize}
          gutterBottom={gutterBottom}
          gutterBottomSize={0}
          overlay={
            isFabVisible
              ? (
                <React.Fragment>
                  <div
                    className={`${BEM}_shadow_fab`}
                    title="Delete this entry"
                    onMouseUp={this.handleTrashCanMouseUp}
                    onMouseMove={this.onTrashFabMouseMove}
                  />
                  <div className={`${BEM}_fab`}>
                    <FontIcon><span /></FontIcon>
                  </div>
                </React.Fragment>
              ) : null
          }
        >
          <div className={`${BEM}_contentbg`}
               ref={this.gridRef}
          />
          {
            this.props.movingEntry
              ? (
                this.props.movingEntry.possibleConflicts.map(
                  (span) =>
                    <ResourceExclusionZone
                      key={`${span.entryIds[0]}:${span.resource.id}`}
                      span={span} />,
                )
              ) : null
          }
          <div
            className={
              // Use a resize cursor if we're resizing.
              this.props.movingEntry && this.props.movingEntry.mode !== "FULL"
                ? `${BEM}_resize_mode`
                : undefined
            }
            onClick={this.handleGridOnClick}
            onDoubleClick={this.handleGridDoubleClick}
          >
            {
              Array.from(model.locations.values(), (location) => {
                // If the entry is selected and being deleted, then it
                // should be go into the deleting state.
                const isDeleting =
                  this.props.transactionState
                  && this.props.selectedEntry
                  && this.props.selectedEntry.id === this.props.transactionState.entryId
                  && this.props.transactionState.type === "DELETE";
                // Make sure we don't update if the selection is not on this
                // location.
                const selectedEntryState
                  : LocationRowProps["selectedEntryState"] = (
                  // If the entry is moving, the original should be ghosted.
                  movingEntry
                    ? {
                      entry: movingEntry,
                      state: isDeleting ? "DELETING" : "MOVING",
                    }
                    : this.props.selectedEntry
                    ? {
                      entry: this.props.selectedEntry,
                      state: isDeleting ? "DELETING" : "SELECTED",
                    }
                    : null
                );

                return (
                  <LocationRow
                    key={location.id}
                    y={location.topRow * ROW_HEIGHT}
                    location={location}
                    selectedEntryState={selectedEntryState}
                    entries={model.entries}
                    eventSlug={this.props.eventSlug}
                    dispatch={this.props.dispatch}
                  />
                );
              })
            }
          </div>
          {
            hoverPos && hoverPos.isOnGrid ? previewEntryTile : null
          }
          {
            <SelectedEntryButton
              containerHeight={locationHeight}
              selectedEntry={this.props.selectedEntry}
              dispatch={this.props.dispatch}
            />
          }
        </AbsoluteViewport>
        {
          this.props.model ?
            <ItemDrawer
              eventSlug={this.props.eventSlug}
              dispatch={this.props.dispatch}
              visible={this.props.drawerVisible}
              hide={this.hideItemDrawer}
              model={this.props.model}
              routeInfo={this.props.routeInfo}
              selectedEntry={this.props.selectedEntry}
              isModal={this.props.media === "mobile"}
            /> : null
        }
        {
          hoverPos && !hoverPos.isOnGrid ? previewEntryTile : null
        }
      </div>
    );
  }

  scrollToEntry(entryId: string) {
    const entry = this.props.model.entries.get(entryId);
    // Scroll to the div if the state changes from unselected to selected or
    // ghosted.
    if (entry) {
      if (this.viewportRef.current) {
        const row = entry.location.topRow + entry.subRow;
        const top = row * ROW_HEIGHT;
        const left = entry.startMinutes * PIXELS_PER_MINUTE;
        const width = entry.durationMinutes * PIXELS_PER_MINUTE;
        this.viewportRef.current.scrollIntoView({
          top,
          left,
          bottom: top + ROW_HEIGHT,
          right: left + width,
          exclusionBufferPercentage: 10,
        });
      }
    }
  }

  handleMouseMovingEntry(e: React.MouseEvent<Element>) {
    this.handleMovingEntry(e.clientX, e.clientY);
    e.preventDefault();
    e.stopPropagation();
  }

  handleTouchStart(e: React.TouchEvent<Element>) {
    if (this.props.movingEntry) {
      this.handleMovingEntry(e.touches[0].clientX, e.touches[0].clientY);
      this.handleGridDragEnd(e);
      if (e.cancelable) {
        e.preventDefault();
      }
    }
  }

  handleTouchMovingEntry(e: React.TouchEvent<Element>) {
    this.handleMovingEntry(e.touches[0].clientX, e.touches[0].clientY);
  }

  handleMovingEntry(clientX: number, clientY: number) {
    // Save the mouse position to handle autoscroll.
    this.lastMousePos = {
      x: clientX,
      y: clientY,
    };

    if (
      this.props.movingEntry
      && this.gridRef.current
      && this.viewportRef.current
    ) {
      if (this.props.movingEntry.mode === "FULL") {
        // Determine if this action came from the grid or off grid. It's on the
        // grid if it's both in the viewport and grid.
        const gridRect = this.gridRef.current.getBoundingClientRect();
        const viewportRect = this.viewportRef.current.getBoundingClientRect()!;
        const inViewport = (
          viewportRect.left <= clientX && clientX <= viewportRect.right
          && viewportRect.top <= clientY && clientY <= viewportRect.bottom
        );
        const isOnGrid = (
          gridRect.left <= clientX && clientX <= gridRect.right
          && gridRect.top <= clientY && clientY <= gridRect.bottom
        );
        const isOnVisibleGrid = inViewport && isOnGrid;

        if (inViewport) {
          this.setAutoScrollFromMousePos(
            clientX - gridRect.left,
            clientY - gridRect.top,
          );
        } else if (this.isDelayingAutoscroll) {
          // If we're waiting for the scroll start delay and outside the
          // viewport, zero the velocity.
          this.xScrollVelocity = 0;
          this.yScrollVelocity = 0;
        } else if (this.xScrollVelocity !== 0 || this.yScrollVelocity !== 0) {
          // If we're outside the viewport, already moving, and we aren't
          // waiting for the scroll start delay to finish, then keep moving at
          // maximum speed.

          // Since we're outside the viewport, we need to clamp x and y.
          const x = Math.max(
            Math.min(clientX, viewportRect.right),
            viewportRect.left,
          );
          const y = Math.max(
            Math.min(clientY, viewportRect.bottom),
            viewportRect.top,
          );
          this.setAutoScrollFromMousePos(x - gridRect.left, y - gridRect.top);
        }

        if (isOnVisibleGrid) {
          this.handleMoveOnGrid(clientX, clientY);
        } else {
          this.handleMoveOffGrid(clientX, clientY);
        }
      } else {
        const gridRect = this.gridRef.current.getBoundingClientRect();
        this.handleResizeOnGrid(clientX, clientY);
        this.setAutoScrollFromMousePos(
          clientX - gridRect.left,
          clientY - gridRect.top,
        );
      }
    }
  }

  handleMoveOnGrid(clientX: number, clientY: number) {
    if (!this.gridRef.current || !this.viewportRef.current) {
      return;
    }

    const gridRect = this.gridRef.current.getBoundingClientRect();
    const x = clientX - Math.floor(gridRect.left);
    const y = clientY - Math.floor(gridRect.top);
    const newMinutes = (
      Math.floor(
        x / PIXELS_PER_MINUTE / START_TIME_INCREMENT,
      ) * START_TIME_INCREMENT
    );
    const newRow = Math.floor(y / ROW_HEIGHT);
    if (
      !this.props.entryPreviewPos
      || (
        this.props.entryPreviewPos.position.type === "grid"
        && (
          this.props.entryPreviewPos.position.minutesAfterStart
          !== newMinutes
          || this.props.entryPreviewPos.position.locationRow !== newRow
        )
      )
      || (
        this.props.entryPreviewPos.position.type === "off-grid"
      )
    ) {
      this.props.dispatch({
        type: "MOVE_ENTRY_PREVIEW",
        locationRow: newRow,
        minutesAfterStart: newMinutes,
      });
    }
  }

  handleMoveOffGrid(clientX: number, clientY: number) {
    const x = clientX;
    const y = clientY;
    if (
      !this.props.entryPreviewPos
        || (
          this.props.entryPreviewPos.position.type === "off-grid"
          && (
            this.props.entryPreviewPos.position.x !== x
            || this.props.entryPreviewPos.position.y !== y
          )
        )
        || (
          this.props.entryPreviewPos.position.type === "grid"
        )
    ) {
      this.props.dispatch({
        type: "MOVE_ENTRY_PREVIEW_OFF_GRID",
        x,
        y,
      });
    }
  }

  handleResizeOnGrid(clientX: number, clientY: number) {
    if (
      !this.gridRef.current
      || !this.viewportRef.current
      || !this.props.movingEntry
      || !this.props.entryPreviewPos
      || this.props.entryPreviewPos.position.type === "off-grid"
    ) {
      return;
    }

    const gridRect = this.gridRef.current.getBoundingClientRect();
    if (this.props.movingEntry.mode === "START") {
      const x = clientX - Math.floor(gridRect.left);
      const end = (
        this.props.entryPreviewPos.position.minutesAfterStart
        + this.props.entryPreviewPos.durationMinutes
      );
      const newStart = (
        Math.floor(
          x / PIXELS_PER_MINUTE / START_TIME_INCREMENT,
        ) * START_TIME_INCREMENT
      );
      const newDuration = end - newStart;

      if (
        this.props.entryPreviewPos.position.minutesAfterStart !== newStart
        && newStart < end
      ) {
        this.props.dispatch({
          type: "MOVE_ENTRY_PREVIEW",
          minutesAfterStart: newStart,
          locationRow: this.props.entryPreviewPos.position.locationRow,
          durationMinutes: newDuration,
        });
      }
    } else if (this.props.movingEntry.mode === "END") {
      const x = clientX - Math.floor(gridRect.left);
      const newEnd = (
        Math.ceil(
          x / PIXELS_PER_MINUTE / START_TIME_INCREMENT,
        ) * START_TIME_INCREMENT
      );
      const newDuration = (
        newEnd - this.props.entryPreviewPos.position.minutesAfterStart
      );

      if (
        this.props.entryPreviewPos.durationMinutes !== newDuration
        && newDuration > 0
      ) {
        this.props.dispatch({
          type: "MOVE_ENTRY_PREVIEW",
          minutesAfterStart:
            this.props.entryPreviewPos.position.minutesAfterStart,
          locationRow: this.props.entryPreviewPos.position.locationRow,
          durationMinutes: newDuration,
        });
      }
    }
  }

  handleTrashCanMouseUp(e: React.MouseEvent<HTMLElement>) {
    if (this.props.movingEntry) {
      if (this.props.movingEntry.entryId) {
        this.props.dispatch({
          type: "DELETE_ENTRY",
          entryId: this.props.movingEntry.entryId,
          transactionId: Math.random(),
        });
      } else {
        this.props.dispatch({
          type: "STOP_MOVING_ENTRY",
        });
      }
      if (this.viewportRef.current) {
        this.xScrollVelocity = 0;
        this.yScrollVelocity = 0;
        this.scrollStartTime = undefined;
      }
      e.preventDefault();
      e.stopPropagation();
    }
  }

  handleGridDragEnd(e: React.SyntheticEvent) {
    if (this.props.movingEntry) {
      this.props.dispatch({
        type: "SAVE_ENTRY",
        transactionId: Math.random(),
      });
      if (this.viewportRef.current) {
        this.xScrollVelocity = 0;
        this.yScrollVelocity = 0;
        this.scrollStartTime = undefined;
      }
      if (e.cancelable) {
        e.preventDefault();
      }
    }
  }

  handleGridOnClick(e: React.MouseEvent<HTMLElement>) {
    if (this.props.selectedEntry) {
      this.props.dispatch({
        type: "SELECT_ENTRY",
        entryId: null,
        scrollToEntry: false,
      });
      e.preventDefault();
    }
  }

  handleGridDoubleClick(e: React.MouseEvent<HTMLElement>) {
    if (!this.props.movingEntry) {
      this.props.dispatch({
        type: "SHOW_ITEM_LIST",
      });
      e.preventDefault();
    }
  }

  setAutoScrollFromMousePos(gridX: number, gridY: number) {
    if (!this.viewportRef.current) {
      return;
    }

    const scrollRect = this.viewportRef.current.getScroll();
    if (scrollRect) {
      // If a dimension is greater than the maximum gutter scaling breakpoint,
      // use the max gutter size. If less than the minimum, use the minimum
      // gutter size. If the dimension is between the two, interpolate linearly
      // between the max and min gutter sizes.
      const gutterWidth = (
        MAX_SCROLL_GUTTER_VIEWPORT_SIZE < scrollRect.width
          ? MAX_SCROLL_GUTTER_SIZE
          : scrollRect.width < MIN_SCROLL_GUTTER_VIEWPORT_SIZE
          ? MIN_SCROLL_GUTTER_SIZE
          : (
            (scrollRect.width - MIN_SCROLL_GUTTER_VIEWPORT_SIZE)
            / (MAX_SCROLL_GUTTER_VIEWPORT_SIZE - MIN_SCROLL_GUTTER_VIEWPORT_SIZE)
            * (MAX_SCROLL_GUTTER_SIZE - MIN_SCROLL_GUTTER_SIZE)
            + MIN_SCROLL_GUTTER_SIZE
          )
      );
      const gutterHeight = (
        MAX_SCROLL_GUTTER_VIEWPORT_SIZE < scrollRect.height
          ? MAX_SCROLL_GUTTER_SIZE
          : scrollRect.height < MIN_SCROLL_GUTTER_VIEWPORT_SIZE
          ? MIN_SCROLL_GUTTER_SIZE
          : (
            (scrollRect.height - MIN_SCROLL_GUTTER_VIEWPORT_SIZE)
            / (MAX_SCROLL_GUTTER_VIEWPORT_SIZE - MIN_SCROLL_GUTTER_VIEWPORT_SIZE)
            * (MAX_SCROLL_GUTTER_SIZE - MIN_SCROLL_GUTTER_SIZE)
            + MIN_SCROLL_GUTTER_SIZE
          )
      );

      const leftThreshold = scrollRect.left + gutterWidth;
      const rightThreshold = scrollRect.right - gutterWidth;
      const topThreshold = scrollRect.top + gutterHeight;
      const bottomThreshold = scrollRect.bottom - gutterHeight;

      let velocityX = 0;
      let velocityY = 0;
      if (gridX < leftThreshold) {
        velocityX =
          (gridX - leftThreshold) / (scrollRect.left - leftThreshold)
          * -MAX_HORIZ_SCROLL_SPEED;
      }
      if (rightThreshold < gridX) {
        velocityX =
          (rightThreshold - gridX) / (rightThreshold - scrollRect.right)
          * MAX_HORIZ_SCROLL_SPEED;
      }
      if (gridY < topThreshold) {
        velocityY =
          (gridY - topThreshold) / (scrollRect.top - topThreshold)
          * -MAX_VERT_SCROLL_SPEED;
      }
      if (bottomThreshold < gridY) {
        velocityY =
          (bottomThreshold - gridY) / (bottomThreshold - scrollRect.bottom)
          * MAX_VERT_SCROLL_SPEED;
      }
      if (
        (this.xScrollVelocity === 0 && velocityX !== 0)
        || (this.yScrollVelocity === 0 && velocityY !== 0)
      ) {
        this.scrollStartTime = Date.now();
      }
      this.xScrollVelocity = velocityX;
      this.yScrollVelocity = velocityY;
    }
  }

  handleScroll() {
    if (
      !this.props.movingEntry
      || !this.gridRef.current
      || !this.viewportRef.current
      || !this.lastMousePos
    ) {
      return;
    }

    const {x, y} = this.lastMousePos;

    if (this.props.movingEntry.mode === "FULL") {
      // Determine if this action came from the grid or off grid. It's on the
      // grid if it's both in the viewport and grid.
      const gridRect = this.gridRef.current.getBoundingClientRect();
      const viewportRect = this.viewportRef.current.getBoundingClientRect()!;
      const isOnGrid = (
        gridRect.left <= x && x <= gridRect.right
        && gridRect.top <= y && y <= gridRect.bottom
        && viewportRect.left <= x && x <= viewportRect.right
        && viewportRect.top <= y && y <= viewportRect.bottom
      );

      if (isOnGrid) {
        this.handleMoveOnGrid(x, y);
      }
    } else {
      this.handleResizeOnGrid(x, y);
    }
  }

  hideItemDrawer() {
    this.props.dispatch({
      type: "SELECT_ITEM",
      itemId: null,
    });
  }

  handleAutoScroll() {
    const lastFrame = this.lastAutoscrollTime;
    this.lastAutoscrollTime = Date.now();
    this.autoscrollHandle = requestAnimationFrame(this.handleAutoScroll);

    if (!this.viewportRef.current) {
      return;
    }

    // No need to scroll if there's no autoscroll speed set.
    if (
      this.xScrollVelocity === 0
      && this.yScrollVelocity === 0
    ) {
      return;
    }

    // Delay actually scrolling for a bit after starting, in case they want to
    // drag out of the window.
    if (this.isDelayingAutoscroll) {
      return;
    }

    // Clamp dt so we don't go back in time or skip too far when the frame rate
    // drops to less than 2 FPS.
    const dt = Math.min(
      Math.max(0, this.lastAutoscrollTime - lastFrame),
      500,
    );
    const dy = this.yScrollVelocity * dt / 1000;
    const dx = this.xScrollVelocity * dt / 1000;
    this.viewportRef.current.scrollBy(dx, dy);
    this.handleScroll();
  }

  onTrashFabMouseMove(e: React.MouseEvent<HTMLElement>) {
    // If scrolling hasn't started yet, hovering the trash FAB should not
    // scroll.
    if (this.isDelayingAutoscroll) {
      this.xScrollVelocity = 0;
      this.yScrollVelocity = 0;
      this.scrollStartTime = undefined;
      this.handleMoveOffGrid(e.clientX, e.clientY);
      e.preventDefault();
      e.stopPropagation();
    }
  }

  get isDelayingAutoscroll() {
    return (
      !this.scrollStartTime
      || Date.now() < this.scrollStartTime + SCROLL_START_DELAY
    );
  }
}
