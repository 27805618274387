import { ApolloClient } from "apollo-client";
import {DocumentNode} from "graphql";
import * as React from "react";

import { computeOrderDiff } from "../util";
import { locationSort } from "./util";
import {
  getLocation_event_schedule_location as Location,
} from "./__generated__/getLocation";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const getLocationsQuery: DocumentNode = require("./getLocations.graphql");
import {
  getLocations,
  getLocationsVariables,
} from "./__generated__/getLocations";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const updateLocationMutation: DocumentNode = require("./updateLocation.graphql");
import {
  updateLocation,
  updateLocationVariables,
} from "./__generated__/updateLocation";

export interface State
{
  client: ApolloClient<unknown>;
  eventSlug: string;
  draggable: boolean;
  saving: boolean;
  reorderedLocations: ReadonlyArray<Location>;
}

const handlers = {
  saveLocationOrder(
    state: State,
    data: {
      dispatch: Dispatch,
    },
  ): State {
    const { client, eventSlug, reorderedLocations } = state;
    const { dispatch } = data;
    const locationsQueryResult = client
      .cache
      .readQuery<getLocations, getLocationsVariables>({
        query: getLocationsQuery,
        variables: { eventSlug },
      });

    if (!locationsQueryResult || !locationsQueryResult.event.schedule) {
      alert("Schedule not loaded.");
      return state;
    }
    const locations = locationsQueryResult.event.schedule.locations;
    const version = locationsQueryResult.event.schedule.version;

    const locationDiff =
      computeOrderDiff<Location>(
        (loc: Location) => loc.id,
        (loc: Location) => loc.order,
        locations,
        reorderedLocations,
      );

    (
      async () => {
        dispatch({ type: "setSaving", saving: true });
        try {
          let newVersion = 0;
          for (const update of locationDiff) {
            const mutationResult = await client
              .mutate<updateLocation, updateLocationVariables>({
                mutation: updateLocationMutation,
                variables: {
                  id: update.entity.id,
                  order: update.newOrder,
                  version,
                },
              });
            if (mutationResult.data) {
              newVersion =
                mutationResult.data.updateEventLocation.version;
            }
          }

          const locationsQueryResult2 = client
            .cache
            .readQuery<getLocations, getLocationsVariables>({
              query: getLocationsQuery,
              variables: { eventSlug },
            });

          // Update the cached locations.
          locationsQueryResult2!.event.schedule!.version = newVersion;
          locationsQueryResult2!.event.schedule!.locations.sort(locationSort);
          client
            .writeQuery<getLocations, getLocationsVariables>({
              query: getLocationsQuery,
              variables: {
                eventSlug,
              },
              data: locationsQueryResult2!,
            });
        } catch (e) {
          dispatch({ type: "setSaving", saving: false });
          alert(e);
          return;
        }
        dispatch({ type: "setSaving", saving: false });
        dispatch({ type: "stopDrag" });
      }
    )();
    return state;
  },
  setSaving(state: State, data: { saving: boolean }) {
    return {
      ...state,
      saving: data.saving,
    };
  },
  startDrag(state: State, data: {}) {
    const locationsQueryResult = state
      .client
      .cache
      .readQuery<getLocations, getLocationsVariables>({
        query: getLocationsQuery,
        variables: { eventSlug: state.eventSlug },
      });

    if (
      !locationsQueryResult
      || !locationsQueryResult.event.schedule
    ) {
      alert("Schedule not loaded.");
      return state;
    }
    const locations = locationsQueryResult.event.schedule.locations;

    return {
      ...state,
      draggable: true,
      reorderedLocations: locations,
    };
  },
  stopDrag(state: State, data: {}) {
    return {
      ...state,
      draggable: false,
    };
  },
  setLocationOrder(
    state: State,
    data: { locationOrder: ReadonlyArray<Location> },
  ) {
    return {
      ...state,
      reorderedLocations: data.locationOrder.slice(),
    };
  },
};

type Actions = {
  [T in keyof typeof handlers]: Parameters<typeof handlers[T]>[1] & { type: T }
};

// Action type generated from Handler method names and data parameter
type Action = Actions[keyof Actions];

export interface Dispatch
{
  // This is required to cut the recursive type loop.
  // tslint:disable-next-line:callable-types
  (action: Action): void;
}

function reducer(state: State, action: Action): State {
  if (handlers.hasOwnProperty(action.type)) {
    const { type, ...params } = action;
    return handlers[type](state, params as any);
  }
  // Internal redux action
  return state;
}

interface LocationModelProps
{
  eventSlug: string;
  client: ApolloClient<unknown>;
  children(
    state: State,
    dispatch: Dispatch,
  ): React.ReactElement;
}

export const LocationModel: React.FC<LocationModelProps> = (props) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      saving: false,
      draggable: false,
      eventSlug: props.eventSlug,
      client: props.client,
      reorderedLocations: [],
    },
  );

  return props.children(state, dispatch);
};
