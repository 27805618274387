import { ApolloError } from "apollo-client";
import { GraphQLError } from "graphql";

export interface Violation {
  readonly field: string;
  readonly humanError: string;
  readonly value: string;
}

export class CompoundViolationError extends Error {
  static fromGraphQLErrors(errors: ReadonlyArray<GraphQLError>): CompoundViolationError|null {
    const violations: Violation[] = [];
    for (const graphQLError of errors) {
      if (
        graphQLError.extensions
        && graphQLError.extensions.errors
        && graphQLError.extensions.errors instanceof Array
      ) {
        for (const regWorksError of graphQLError.extensions.errors) {
          if (
            typeof regWorksError.field === "string"
            && typeof regWorksError.humanError === "string"
            && typeof regWorksError.value === "string"
          ) {
            violations.push(regWorksError);
          }
        }
      }
    }
    if (0 < violations.length) {
      return new CompoundViolationError(violations);
    } else {
      return null;
    }
  }
  static fromError(error: Error): CompoundViolationError|null {
    if (error instanceof ApolloError) {
      return CompoundViolationError.fromGraphQLErrors(error.graphQLErrors);
    } else {
      return null;
    }
  }
  private readonly privViolations: ReadonlyArray<Violation>;

  get name(): string {
    return "Reg.Works Error";
  }
  get violations(): ReadonlyArray<Violation> {
    return this.privViolations;
  }

  constructor(violations: Violation[]) {
    const message = violations
      .map(
        (violation) => `${violation.field} ${violation.humanError}`,
      )
      .join(", ");
    // Error breaks the prototype chain here.
    // See breaking changes for TypeScript 2.2.
    super(message);
    // Fix the prototype.
    const actualProto = new.target.prototype;

    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(this, actualProto);
    } else {
      (this as any).__proto__ = actualProto;
    }

    this.privViolations = Object.freeze(violations.slice());
  }
}
