import * as React from "react";

import "./Center.scss";

export const Center: React.SFC<{}> = (props) => {
  return (
    <div className="rw-center">
      {props.children}
    </div>
  );
};
