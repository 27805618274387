import {DocumentNode} from "graphql";
import {LocalDate} from "js-joda";
import * as React from "react";
import {
  Query,
  QueryResult,
} from "react-apollo";
import {
  Button,
  CardActions,
  CircularProgress,
  Snackbar,
  TextField,
} from "react-md";

import { AppFrame, MediaArg, MediaType } from "../AppFrame";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const getAccountQuery: DocumentNode = require("./getAccount.graphql");
import {
  getAccount,
  getAccount_session_user_person_contacts_EmailAddress as EmailAddress,
} from "./__generated__/getAccount";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const updatePersonMutation: DocumentNode = require("./updatePerson.graphql");
import {
  updatePerson,
  updatePersonVariables,
} from "./__generated__/updatePerson";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const updateEmailMutation: DocumentNode = require("./updateEmail.graphql");
import {
  updateEmail,
  updateEmailVariables,
} from "./__generated__/updateEmail";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const updatePasswordMutation: DocumentNode = require("./updatePassword.graphql");
import {
  updatePassword,
  updatePasswordVariables,
} from "./__generated__/updatePassword";

import "./index.scss";

interface PersonParams {
  readonly id: string;
  readonly legalName: string;
  readonly displayGivenName: string;
  readonly displayFamilyName: string|null;
  readonly handle: string|null;
  readonly birthdate: LocalDate|null;
  readonly emailId: string|null;
  readonly emailAddress: string|null;
}

export type SubmitPerson = (params: PersonParams) => Promise<unknown>;

interface PersonFormProps {
  submit: SubmitPerson;
  default: PersonParams;
}

const PersonForm: React.FC<PersonFormProps> =
  (props: PersonFormProps) => {
    const [legalName, setLegalName] =
      React.useState<string>(props.default.legalName);
    const [givenName, setGivenName] =
      React.useState<string>(props.default.displayGivenName);
    const [familyName, setFamilyName] =
      React.useState<string>(props.default.displayFamilyName || "");
    const [handle, setHandle] =
      React.useState<string>(props.default.handle || "");
    const [birthdate, setBirthdate] =
      React.useState<string>(
        props.default.birthdate
          ? props.default.birthdate.toString()
          : "",
      );
    const [birthdateError, setBirthdateError] = React.useState(false);
    const [email, setEmail] =
      React.useState<string>(props.default.emailAddress || "");
    const [submitting, setSubmitting] = React.useState(false);

    const splitName = (name: string) => {
      const maybeSplitName = name.split(/ +/);
      const maybeGivenName = (
        maybeSplitName.length > 1
          ? (
            maybeSplitName.slice(0, maybeSplitName.length - 1)
              .join(" ")
          ) : maybeSplitName.length === 1 ? maybeSplitName[0]
          : ""
      );
      const maybeFamilyName = (
        maybeSplitName.length > 1
          ? maybeSplitName[maybeSplitName.length - 1]
          : ""
      );
      return [maybeGivenName, maybeFamilyName] as [string, string];
    };

    const submit: React.FormEventHandler<HTMLFormElement> = (e) => {
      let birthdateDate = null;
      try {
        if (birthdate) {
          birthdateDate = LocalDate.parse(birthdate);
        }
      } catch (e) {
        setBirthdateError(true);
        return;
      }

      if (
        !legalName
        || !givenName
        || !email
      ) {
        return;
      }

      setSubmitting(true);
      props.submit({
        id: props.default.id,
        emailId: props.default.emailId,
        legalName,
        displayGivenName: givenName,
        displayFamilyName: familyName || null,
        handle: handle || null,
        birthdate: birthdateDate,
        emailAddress: email || null,
      })
      .finally(() => setSubmitting(false));

      e.preventDefault();
    };

    const contents = (
      <form onSubmit={submit}>
        <TextField id="person-legal-name"
          label="Legal Name"
          placeholder="Charlie Smith"
          value={legalName}
          required
          onChange={(newValue) => {
            if (typeof newValue === "string") {
              const oldSplitName = splitName(legalName);
              const newSplitName = splitName(newValue);

              if (
                givenName === oldSplitName[0]
                && familyName === oldSplitName[1]
              ) {
                setGivenName(newSplitName[0]);
                setFamilyName(newSplitName[1]);
              }

              setLegalName(newValue);
            }
          }} />
        <TextField id="person-given-name"
          label="Given Name"
          placeholder="Chuck"
          value={givenName}
          required
          onChange={(newValue) => {
            if (typeof newValue === "string") {
              setGivenName(newValue);
            }
          }} />
        <TextField id="person-family-name"
          label="Family Name"
          placeholder="Smith"
          value={familyName}
          onChange={(newValue) => {
            if (typeof newValue === "string") {
              setFamilyName(newValue);
            }
          }} />
        <TextField id="person-handle"
          label="Preferred Name"
          placeholder="Captain"
          value={handle}
          onChange={(newValue) => {
            if (typeof newValue === "string") {
              setHandle(newValue);
            }
          }} />
        <TextField
          id="person-birthdate"
          label="Birth Date"
          helpText="Format: YYYY-MM-DD (note the dashes)"
          value={birthdate}
          error={birthdateError}
          onBlur={() => {
            try {
              if (birthdate) {
                LocalDate.parse(birthdate);
              }
              setBirthdateError(false);
            } catch (e) {
              setBirthdateError(true);
            }
          }}
          onChange={(newValue) => {
            if (typeof newValue === "string") {
              setBirthdate(newValue);
              setBirthdateError(false);
            }
          }} />
        <TextField id="person-email"
          label="Email"
          placeholder="csmith@example.com"
          value={email}
          required
          onChange={(newValue) => {
            if (typeof newValue === "string") {
              setEmail(newValue);
            }
          }} />
        <CardActions>
          <Button
            raised
            primary
            type="submit"
            disabled={submitting}
            className="md-cell--right">
            Save
          </Button>
        </CardActions>
      </form>
    );

    return (
      <React.Fragment>
        <h1>Personal Information</h1>
        {contents}
      </React.Fragment>
    );
  };

interface EditPasswordProps {
  notify(message: string): void;
  submit(lastPassword: string, newPassword: string): Promise<unknown>;
}

const EditPassword: React.FC<EditPasswordProps> = (props) => {
  const [lastPassword, setLastPassword] = React.useState("");
  const [newPassword1, setNewPassword1] = React.useState("");
  const [newPassword2, setNewPassword2] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);

  const submit: React.FormEventHandler<HTMLFormElement> = React.useCallback(
    (e) => {
      e.preventDefault();

      const missing = [];
      if (!lastPassword) {
        missing.push("the previous password");
      }
      if (!newPassword1 && !newPassword2) {
        missing.push("a new password");
      } else if (
        (newPassword1 && !newPassword2)
          || (!newPassword1 && newPassword2)
      ) {
        missing.push("the new password twice");
      } else if (newPassword1 !== newPassword2) {
        missing.push("the same new password twice");
      }

      if (missing.length > 0) {
        const error = "Please enter: " + missing.reverse().join(", ");
        props.notify(error);
        return;
      }

      setSubmitting(true);
      props.submit(lastPassword, newPassword1)
        .then(() => {
          props.notify("New password saved.");
          setLastPassword("");
          setNewPassword1("");
          setNewPassword2("");
        })
        .catch(() => {
          props.notify(
            "Saving your new password failed. Did you enter your previous"
              + " password correctly?",
          );
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [
      props.submit,
      props.notify,
      lastPassword,
      newPassword1,
      newPassword2,
    ],
  );

  return (
    <React.Fragment>
      <h1>Change Password</h1>
      <form onSubmit={submit}>
        <TextField id="prev-password"
          type="password"
          label="Previous Password"
          value={lastPassword}
          required
          disabled={submitting}
          onChange={(newValue) => {
            if (typeof newValue === "string") {
              setLastPassword(newValue);
            }
          }} />
        <TextField id="new-password-1"
          type="password"
          label="New Password"
          value={newPassword1}
          required
          disabled={submitting}
          onChange={(newValue) => {
            if (typeof newValue === "string") {
              setNewPassword1(newValue);
            }
          }} />
        <TextField id="new-password-2"
          type="password"
          label="Confirm New Password"
          value={newPassword2}
          required
          disabled={submitting}
          onChange={(newValue) => {
            if (typeof newValue === "string") {
              setNewPassword2(newValue);
            }
          }} />
        <CardActions>
          <Button
            raised
            primary
            type="submit"
            disabled={submitting}
            className="md-cell--right">
            Save
          </Button>
        </CardActions>
      </form>
    </React.Fragment>
  );
};

interface EditPersonProps extends QueryResult<getAccount> {
  notify(message: string): void;
}

const EditPerson: React.FC<EditPersonProps> = (props) => {
  const submitPerson = React.useCallback(
    (params: PersonParams) => {
      const { emailId, emailAddress, ...newPerson } = params;
      return props.client.mutate<updatePerson, updatePersonVariables>({
        mutation: updatePersonMutation,
        variables: newPerson,
      })
      .then((result) => {
        if (!emailId || !emailAddress) {
          return Promise.resolve(undefined);
        }

        return props.client.mutate<updateEmail, updateEmailVariables>({
          mutation: updateEmailMutation,
          variables: {
            id: emailId,
            address: emailAddress,
          },
        });
      })
      .then(() => {
        props.notify("Profile saved.");
      })
      .catch((e) => {
        props.notify("Error saving profile.");
      });
    },
    [props.notify],
  );

  const userId = (
    (
      props.data
      && props.data.session
      && props.data.session.user
    ) ? props.data.session.user.id : null
  );
  const submitPassword = React.useCallback(
    (oldPassword: string, newPassword: string) => {
      if (!userId) {
        return Promise.reject();
      }

      return props.client.mutate<updatePassword, updatePasswordVariables>({
        mutation: updatePasswordMutation,
        variables: {
          id: userId,
          oldPassword,
          newPassword,
        },
      });
    },
    [props.notify, userId],
  );


  if (
    !props.data
      || !props.data.session
      || !props.data.session.user
      || !props.data.session.user.person
  ) {
    return <CircularProgress id="person-progress" />;
  }

  const person = props.data.session.user.person;
  const email = person.contacts.find(
   (contact): contact is EmailAddress =>
     contact.__typename === "EmailAddress"
     && !!contact.purposes.find((purpose) => purpose === "SECURITY"),
  );

  // Only allow password editing if you have a username and password.
  const passwordForm = (
    props.data.session.user.username
      ? (
        <EditPassword submit={submitPassword} notify={props.notify} />
      ) : null
  );

  return (
    <div className="my-account-screen">
      <div className="my-account-container">
        <PersonForm
          submit={submitPerson}
          default={{
            id: person.id,
            legalName: person.legalName,
            displayGivenName: person.displayGivenName,
            displayFamilyName: person.displayFamilyName,
            handle: person.handle,
            birthdate: person.birthdate,
            emailId: email ? email.id : null,
            emailAddress: email ? email.address : null,
          }}
        />
        {passwordForm}
      </div>
    </div>
  );
};

interface MyAccountProps {
  drawerVisible: MediaArg;
  changeDrawerVisibility(media: MediaType, visible: boolean): void;
  logout(): void;
}

export const MyAccount: React.FC<MyAccountProps> = (props) => {
  const [toasts, setToasts] = React.useState<Array<{ text: string }>>([]);

  const dismissToast = React.useCallback(
    () => setToasts(toasts.slice(1)),
    [toasts],
  );

  const addToast = React.useCallback(
    (message: string) => setToasts([...toasts, { text: message }]),
    [toasts],
  );

  return (
    <AppFrame
      eventSlug={null}
      drawerVisible={props.drawerVisible}
      changeDrawerVisibility={props.changeDrawerVisibility}
      logout={props.logout}
    >
      {
        () =>
          <>
            <Query query={getAccountQuery}>
              {
                (queryProps: QueryResult<getAccount>) =>
                  <EditPerson notify={addToast} {...queryProps} />
              }
            </Query>
            <Snackbar toasts={toasts} onDismiss={dismissToast} />
          </>
      }
    </AppFrame>
  );
};

