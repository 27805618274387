import {DocumentNode} from "graphql";
import * as React from "react";
import {
  Mutation,
  MutationFunction,
} from "react-apollo";
import {Button} from "react-md";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const updateScheduleItemMutation: DocumentNode = require("./model/updateScheduleItem.graphql");
import {
  updateScheduleItem,
  updateScheduleItemVariables,
} from "./model/__generated__/updateScheduleItem";

type Status = (
  "NEW"
  | "PENDING"
  | "WAITLISTED"
  | "APPROVED"
  | "DRAFT"
  | "REVIEW"
  | "DECLINED"
  | "PUBLIC"
);
const stateTransitions: {
  [F in Status]: { [T in Status]?: string }
} = {
  NEW: {
    PENDING: "Mark as read",
    WAITLISTED: "Waitlist",
    APPROVED: "Approve*",
    DECLINED: "Decline",
    PUBLIC: "Queue to publish*",
  },
  PENDING: {
    WAITLISTED: "Waitlist",
    APPROVED: "Approve*",
    DECLINED: "Decline",
    PUBLIC: "Queue to publish*",
  },
  WAITLISTED: {
    APPROVED: "Approve*",
    DECLINED: "Decline",
    PUBLIC: "Queue to publish*",
  },
  APPROVED: {
    WAITLISTED: "Waitlist",
    REVIEW: "Queue for review",
    DECLINED: "Decline",
    PUBLIC: "Queue to publish",
  },
  DRAFT: {
    REVIEW: "Queue for review",
    DECLINED: "Decline",
    PUBLIC: "Queue to publish",
  },
  REVIEW: {
    DECLINED: "Decline",
    PUBLIC: "Queue to publish",
  },
  DECLINED: {},
  PUBLIC: {
    DECLINED: "Decline",
  },
};

function isValidStatus(status: string): status is Status {
  return typeof (stateTransitions as any)[status] !== "undefined";
}

interface ItemTransitionsProps {
  itemId: string;
  currentStatus: string;
  version: number;
}

export const ItemTransitions: React.FC<ItemTransitionsProps> = (props) => (
  <Mutation mutation={updateScheduleItemMutation}>
    {
      (updateItem: MutationFunction<updateScheduleItem, updateScheduleItemVariables>) => {
        if (!isValidStatus(props.currentStatus)) {
          return null;
        }
        const updateStatus = (newStatus: Status) => () => {
          updateItem({ variables: {
            itemId: props.itemId,
            status: newStatus,
            version: props.version,
          }});
        };

        const buttons = [];
        const currentTransitions = stateTransitions[props.currentStatus];
        for (const toStatus of Object.keys(currentTransitions) as Status[]) {
          const label: string =
            stateTransitions[props.currentStatus][toStatus]!;
          buttons.push(
            <Button
              key={toStatus}
              raised
              onClick={updateStatus(toStatus)}
            >
              {label}
            </Button>,
          );
        }

        const help = (
          props.currentStatus === "NEW"
          || props.currentStatus === "PENDING"
          || props.currentStatus === "WAITLISTED"
        ) ? (
          <p key="help">
            * sends an email
          </p>
        ) : null;

        return (
          <div>
            {buttons}
            {help}
          </div>
        );
      }
    }
  </Mutation>
);
