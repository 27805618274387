import * as React from "react";
import * as ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";

import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import {ApolloClient} from "apollo-client";
import {ApolloLink, split} from "apollo-link";
import {setContext} from "apollo-link-context";
import {createHttpLink} from "apollo-link-http";
import {WebSocketLink} from "apollo-link-ws";
import {getMainDefinition} from "apollo-utilities";
import {ApolloProvider} from "react-apollo";

// we load polyfills in this file, so side effects are desired
// tslint:disable:no-import-side-effect
import "@babel/polyfill";
import "unfetch/polyfill/index.js";


import {Auth} from "./net/auth";
import {RootScreen} from "./view";
import "./view/index.scss";

let apollo: ApolloClient<any>|null = null;
const auth = new Auth(async () => {
  if (apollo) {
    await apollo.resetStore();
  }
});

const wsLink = new WebSocketLink({
  uri: "production" === process.env.NODE_ENV
    ? "wss://api.regworks.io/graphql"
    : `ws://${document.location.hostname}:8081/graphql`,
  options: {
    reconnect: true,
    connectionParams: () => ({
      authToken: auth.getToken(),
    }),
  },
});
const httpLink =
  ApolloLink.from([
    setContext((_, {headers}) => {
      const authToken = auth.getToken();
      if (authToken) {
        return {
          headers: {
            ...headers,
            Authorization: `Bearer ${authToken}`,
          },
        };
      } else {
        return null;
      }
    }),
    createHttpLink({
      uri: (
        "production" === process.env.NODE_ENV
          ? "https://api.regworks.io/graphql"
          : `//${document.location.hostname}:8081/graphql`
      ),
    }),
  ]);

const link = split(
  ({query}) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink,
);

// tslint:disable-next-line:no-require-imports
const schema: any = require("../graphql.schema.json");
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: schema,
});

const cache = new InMemoryCache({
  fragmentMatcher,
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      default:
        if (object.id) {
          return `${object.__typename}:${object.id}`;
        } else if ((object as any).ref) {
          return `${object.__typename}:${(object as any).ref}`;
        } else {
          return undefined;
        }
    }
  },
});

apollo = new ApolloClient({
  cache,
  link,
});

ReactDOM.render(
  <ApolloProvider client={apollo}>
    <BrowserRouter>
      <RootScreen auth={auth} />
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("app"),
);
