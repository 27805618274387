import * as React from "react";
import { useDrag, useDrop } from "react-dnd";
import { FontIcon } from "react-md";

import {
  getLocation_event_schedule_location as Location,
} from "./__generated__/getLocation";

const LOCATION_ITEM_TYPE = "location";

interface LocationItemProps
{
  location: Location;
  eventSlug: string;
  moveLocation(id: string, to: number): void;
  findLocation(id: string): number|null;
}

const DraggableLocationItem: React.FC<LocationItemProps> = (props) => {
  const originalIndex = props.findLocation(props.location.id);
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: LOCATION_ITEM_TYPE,
      id: props.location.id,
      originalIndex,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const { id: droppedId, originalIndex: innerIndex } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        props.moveLocation(droppedId, innerIndex);
      }
    },
  });

  const [, drop] = useDrop({
    accept: LOCATION_ITEM_TYPE,
    canDrop: () => false,
    hover(item: Location & { type: string }) {
      if (item.id !== props.location.id) {
        const overIndex = props.findLocation(props.location.id);
        if (overIndex === null) {
          return;
        }
        props.moveLocation(item.id, overIndex);
      }
    },
  });

  const opacity = isDragging ? 0 : 1;
  return (
    <li
      ref={
        (node) => {
          if (node) {
            drag(drop(node));
          }
          return null;
        }
      }
      style={{
        opacity,
        cursor: "move",
      }}
      className="md-list-item"
    >
      <div className="md-list-tile md-fake-btn md-text">
        <div className="md-tile-addon md-tile-addon--icon">
          <FontIcon>drag_indicator</FontIcon>
        </div>
        <div className="md-tile-content md-tile-content--left-icon">
          <div className="md-tile-text--primary">
            {props.location.name}
          </div>
        </div>
      </div>
    </li>
  );
};

interface DraggableLocationListProps
{
  locations: ReadonlyArray<Location>;
  eventSlug: string;
  listState: ReadonlyArray<Location>;
  setListState(newLocationList: ReadonlyArray<Location>): void;
}

export const DraggableLocationList: React.FC<DraggableLocationListProps> = (props) => {
  const { listState, setListState } = props;
  const moveLocation = (id: string, atIndex: number) => {
    const locIndex = findLocation(id);
    if (locIndex === null) {
      return;
    }
    const newListState = [...listState];
    newListState.splice(
      atIndex,
      0,
      newListState.splice(
        locIndex,
        1,
      )[0],
    );
    setListState(newListState);
  };
  const findLocation = (id: string) => {
    const index = listState.findIndex((l) => l.id === id);
    if (index === -1) {
      return null;
    }
    return index;
  };
  const [, drop] = useDrop({ accept: LOCATION_ITEM_TYPE });

  return (
    <ul
      ref={drop}
      className="md-list"
    >
      {
        listState.map((loc) => (
          <DraggableLocationItem
            key={loc.id}
            eventSlug={props.eventSlug}
            location={loc}
            findLocation={findLocation}
            moveLocation={moveLocation}
          />
        ))
      }
    </ul>
  );
};
