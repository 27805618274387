import {DataProxy} from "apollo-cache";
import {ApolloClient} from "apollo-client";
import { MutationOptions } from "apollo-client/core/watchQueryOptions";
import {FetchResult} from "apollo-link";
import {DocumentNode} from "graphql";
import {
  DateTimeFormatter,
  Duration,
} from "js-joda";
import {
  call,
  cancel,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const scheduleFetchQuery: DocumentNode = require("./scheduleFetch.graphql");
import {
  scheduleFetch,
  scheduleFetchVariables,
} from "./__generated__/scheduleFetch";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const createEntryMutation: DocumentNode = require("./createEntry.graphql");
import { createEntry, createEntryVariables } from "./__generated__/createEntry";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const updateEntryMutation: DocumentNode = require("./scheduleEntryUpdate.graphql");
import {
  updateEntry, updateEntryVariables,
} from "./__generated__/updateEntry";

// this is necessary until we can fix the GraphQL TS declaration generator
const deleteScheduleEntryMutation: DocumentNode =
// tslint:disable-next-line:no-require-imports
  require("./deleteScheduleEntry.graphql");
import {
  deleteScheduleEntry, deleteScheduleEntryVariables,
} from "./__generated__/deleteScheduleEntry";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const createScheduleItem: DocumentNode = require("../createScheduleItem.graphql");
import {
  createScheduleItem, createScheduleItemVariables,
} from "../__generated__/createScheduleItem";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const updateScheduleItem: DocumentNode = require("./updateScheduleItem.graphql");
import {
  updateScheduleItem, updateScheduleItemVariables,
} from "./__generated__/updateScheduleItem";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const addItemParticipantMutation: DocumentNode = require("./addItemParticipant.graphql");
import {
  addItemParticipant, addItemParticipantVariables,
} from "./__generated__/addItemParticipant";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const removeItemParticipantMutation: DocumentNode = require("./removeItemParticipant.graphql");
import {
  removeItemParticipant, removeItemParticipantVariables,
} from "./__generated__/removeItemParticipant";
//
// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const publishScheduleMutation: DocumentNode = require("./publishSchedule.graphql");
import {
  publishSchedule, publishScheduleVariables,
} from "./__generated__/publishSchedule";


import {
  AddItemParticipantAction,
  CreateScheduleItemAction,
  DeleteEntryAction,
  PublishScheduleAction,
  RemoveItemParticipantAction,
  SaveEntryAction,
  ScheduleModelState,
  UpdateScheduleItemAction,
} from "./reducer";

interface GraphQLViolation {
  field: string;
  id: string;
  humanError: string;
}

type MutationFunction<T, TVar> =
  (options: MutationOptions<T, TVar>) => Promise<FetchResult<T>>;

function extractViolations(e: any): GraphQLViolation[]|null {
  if (
    typeof e === "object"
    && e.graphQLErrors instanceof Array
    && e.graphQLErrors.length > 0
    && e.graphQLErrors[0].extensions
    && e.graphQLErrors[0].extensions.errors instanceof Array
  ) {
    return e.graphQLErrors[0].extensions.errors as GraphQLViolation[];
  } else {
    return null;
  }
}

function updateCacheFromUpdateEntry(eventSlug: string) {
  return (
    cache: DataProxy,
    result: FetchResult<updateEntry>,
  ) => {
    const cachedQuery = cache.readQuery<scheduleFetch, scheduleFetchVariables>({
      query: scheduleFetchQuery,
      variables: {eventSlug},
    });
    if (
      cachedQuery
        && cachedQuery.event
        && cachedQuery.event.schedule
        && result.data
    ) {
      const data = result.data.updateScheduleEntry;
      const event = cachedQuery.event;
      const schedule = cachedQuery.event.schedule;

      cache.writeQuery(
        {
          query: scheduleFetchQuery,
          data: {
            event: {
              ...event,
              schedule: {
                ...schedule,
                version: data.version,
                items: schedule.items.map(
                  (item) => (
                    (item.entries.every((entry) => entry.id !== data.id)
                      ? item
                      : {
                        ... item,
                        entries: (
                          item.entries.map(
                            (entry) => entry.id === data.id ? data : entry,
                          )
                        ),
                        version: data.version,
                      }
                    )
                  ),
                ),
              },
            },
          },
        },
      );
    }
  };
}

function updateCacheFromDeleteEntry(eventSlug: string) {
  return (
    cache: DataProxy,
    result: FetchResult<deleteScheduleEntry>,
  ) => {
    const cachedQuery = cache.readQuery<scheduleFetch, scheduleFetchVariables>({
      query: scheduleFetchQuery,
      variables: {eventSlug},
    });
    if (
      cachedQuery
        && cachedQuery.event
        && cachedQuery.event.schedule
        && result.data
    ) {
      const event = cachedQuery.event;
      const schedule = cachedQuery.event.schedule;

      const data = result.data.deleteScheduleEntry;
      const itemId = data.item.id;
      const entryId = data.entry.id;
      const version = data.item.version;

      cache.writeQuery(
        {
          query: scheduleFetchQuery,
          data: {
            event: {
              ...event,
              schedule: {
                ...schedule,
                version,
                items: schedule.items.map(
                  (item) => (
                    (item.id !== itemId)
                      ? item
                      : {
                        ... item,
                        entries: (
                          item.entries.filter(
                            (entry) => entry.id !== entryId,
                          )
                        ),
                        version,
                      }
                  ),
                ),
              },
            },
          },
        },
      );
    }
  };
}

function updateCacheFromCreateItem(eventSlug: string) {
  return (
    cache: DataProxy,
    result: FetchResult<createScheduleItem>,
  ) => {
    const cachedQuery = cache.readQuery<scheduleFetch, scheduleFetchVariables>({
      query: scheduleFetchQuery,
      variables: {eventSlug},
    });
    if (
      cachedQuery
        && cachedQuery.event
        && cachedQuery.event.schedule
        && result.data
    ) {
      const event = cachedQuery.event;
      const schedule = cachedQuery.event.schedule;

      const data = result.data.createScheduleItem;
      const version = data.scheduleItem.version;
      const items = cachedQuery.event.schedule.items;
      items.push(data.scheduleItem);

      cache.writeQuery(
        {
          query: scheduleFetchQuery,
          data: {
            event: {
              ...event,
              schedule: {
                ...schedule,
                version,
                items,
              },
            },
          },
        },
      );
    }
  };
}

function updateCacheFromUpdateItem(eventSlug: string) {
  return (
    cache: DataProxy,
    result: FetchResult<updateScheduleItem>,
  ) => {
    const cachedQuery = cache.readQuery<scheduleFetch, scheduleFetchVariables>({
      query: scheduleFetchQuery,
      variables: {eventSlug},
    });
    if (
      cachedQuery
        && cachedQuery.event
        && cachedQuery.event.schedule
        && result.data
    ) {
      const event = cachedQuery.event;
      const schedule = cachedQuery.event.schedule;

      const data = result.data.updateScheduleItem;
      const itemId = data.id;
      const version = data.version;

      cache.writeQuery(
        {
          query: scheduleFetchQuery,
          data: {
            event: {
              ...event,
              schedule: {
                ...schedule,
                version,
                items: schedule.items.map(
                  (item) => (
                    (item.id !== itemId) ? item : data
                  ),
                ),
              },
            },
          },
        },
      );
    }
  };
}

export function* saveEntrySaga(client: ApolloClient<{}>) {
  function* helper(action: SaveEntryAction) {
    const state: ScheduleModelState = yield select();
    if (!state.model) {
      yield cancel();
      // This should never execute.
      return;
    }
    const model = state.model!;

    if (state.movingEntry && state.entryPreviewPos) {
      const movingEntry = state.movingEntry;
      const foundEntry = (
        movingEntry.entryId
        && state.model.entries.get(movingEntry.entryId)
        || null
      );

      if (movingEntry.entryId && !foundEntry) {
        yield put({
          type: "SHOW_MESSAGE",
          message: "Error: Entry not found",
        });
        return;
      }

      // Handle if it's dropped off of the grid.
      if (state.entryPreviewPos.position.type !== "grid") {
        yield put({
          type: "SHOW_MESSAGE",
          message: "Cancelling entry placement",
        });
        yield put({
          type: "STOP_MOVING_ENTRY",
        });
        yield cancel();
        return;
      }

      const selectedLocationRow = state.entryPreviewPos.position.locationRow;
      const selectedLocation = (
        Array.from(model.locations.values())
        .find(
          (loc) =>
            loc.topRow <= selectedLocationRow
            && selectedLocationRow < loc.topRow + loc.rowHeight,
        )
      );
      const start = model.startsAt.plusMinutes(
        state.entryPreviewPos.position.minutesAfterStart,
      ).format(DateTimeFormatter.ISO_OFFSET_DATE_TIME);
      const duration =
        Duration.ofMinutes(state.entryPreviewPos.durationMinutes).toString();
      if (!selectedLocation) {
        yield put({
          type: "SHOW_MESSAGE",
          message: "Cancelling entry placement",
        });
        yield put({
          type: "STOP_MOVING_ENTRY",
        });
        yield cancel();
        // This should never execute.
        return;
      }

      // If this wouldn't update the entry, then don't update it.
      if (
        foundEntry
          && selectedLocation.id === foundEntry.location.id
          && foundEntry.startMinutes
          === state.entryPreviewPos.position.minutesAfterStart
          && foundEntry.durationMinutes
          === state.entryPreviewPos.durationMinutes
      ) {
        yield put({
          type: "START_MOVING_ENTRY",
          entryId: foundEntry.id,
        });
        return;
      }

      yield put({
        type: "TRANSACTION_STARTED",
        transactionState: {
          id: action.transactionId,
          type: "SAVE",
          entryId: movingEntry.entryId,
        },
      });
      try {
        if (foundEntry) {
          yield call<
            MutationFunction<updateEntry, updateEntryVariables>
          >(
            client.mutate,
            {
              mutation: updateEntryMutation,
              variables: {
                entryId: foundEntry.id,
                start,
                duration,
                location: selectedLocation.id,
                version: foundEntry.version,
              },
              update: updateCacheFromUpdateEntry(model.eventSlug),
            },
          );
          yield put({
            type: "SELECT_ENTRY",
            entryId: foundEntry.id,
          });
        } else {
          yield call<
            MutationFunction<createEntry, createEntryVariables>
          >(
            client.mutate,
            {
              mutation: createEntryMutation,
              variables: {
                itemId: movingEntry.itemId,
                start,
                duration,
                location: selectedLocation.id,
                version: movingEntry.version,
              },
            },
          );
        }
      } catch (e) {
        const violations = extractViolations(e);
        if (
          violations
          && violations.length > 0
          && violations[0].field === "base version"
          && violations[0].humanError
          === "indicates that entity has changed since last version"
        ) {
          yield put({
            type: "SHOW_MESSAGE",
            message: `"${movingEntry.displayName}" was updated by`
              + " someone else. Your change was canceled.",
          });
        } else {
          yield put({
            type: "SHOW_MESSAGE",
            message: `Unexpected error: ${e.message}`,
          });
        }
      } finally {
        yield put({type: "TRANSACTION_COMPLETED"});
      }
    }
  }

  yield takeEvery("SAVE_ENTRY", helper);
}

export function* deleteEntrySaga(client: ApolloClient<{}>) {
  function* helper(action: DeleteEntryAction) {
    const state: ScheduleModelState = yield select();
    if (!state.model) {
      return;
    }
    const entry = state.model.entries.get(action.entryId);

    if (!entry) {
      yield put({
        type: "SHOW_MESSAGE",
        message: "Error: Entry not found",
      });
      return;
    }

    yield put({
      type: "TRANSACTION_STARTED",
      transactionState: {
        id: action.transactionId,
        type: "DELETE",
        entryId: action.entryId,
      },
    });
    try {
      yield call<
        MutationFunction<deleteScheduleEntry, deleteScheduleEntryVariables>
      >(
        client.mutate,
        {
          mutation: deleteScheduleEntryMutation,
          variables: {
            entryId: action.entryId,
            version: state.model.version,
          },
          update: updateCacheFromDeleteEntry(state.model.eventSlug),
        },
      );
      if (
        state.movingEntry
        && state.movingEntry.entryId === entry.id
      ) {
        yield put({
          type: "TRANSACTION_COMPLETED",
        });
      }
    } catch (e) {
      const violations = extractViolations(e);
      if (
        violations
        && violations.length > 0
        && violations[0].field === "base version"
        && violations[0].humanError
        === "indicates that entity has changed since last version"
      ) {
        yield put({
          type: "SHOW_MESSAGE",
          message: `"${entry.item.displayName}" was updated by`
          + " someone else. Your change was canceled.",
        });
      } else {
        yield put({
          type: "SHOW_MESSAGE",
          message: `Unexpected error: ${e.message}`,
        });
      }
    } finally {
      yield put({ type: "TRANSACTION_COMPLETED" });
      yield put({
        type: "SELECT_ENTRY",
        entryId: null,
      });
    }
  }

  yield takeEvery("DELETE_ENTRY", helper);
}

export function* createItemSaga(client: ApolloClient<{}>) {
  function* helper(action: CreateScheduleItemAction) {
    const state: ScheduleModelState = yield select();
    if (
      !state.model
        || !state.model.source.event
    ) {
      return;
    }
    const { type, ...variables } = action;

    try {
      const result: FetchResult<createScheduleItem> = yield call<
        MutationFunction<createScheduleItem, createScheduleItemVariables>
      >(
        client.mutate,
        {
          mutation: createScheduleItem,
          variables,
          update: updateCacheFromCreateItem(state.model.eventSlug),
        },
      );
      if (!result.data) {
        yield put({
          type: "SHOW_MESSAGE",
          message: "Item creation failed. No item returned.",
        });
        return;
      }
      yield put({
        type: "SELECT_ITEM",
        itemId: result.data.createScheduleItem.scheduleItem.id,
      });
    } catch (e) {
      yield put({
        type: "SHOW_MESSAGE",
        message: `Unexpected error: ${e.message}`,
      });
    }
  }

  yield takeEvery("CREATE_SCHEDULE_ITEM", helper);
}

export function* updateItemSaga(client: ApolloClient<{}>) {
  function* helper(action: UpdateScheduleItemAction) {
    const state: ScheduleModelState = yield select();
    if (
      !state.model
        || !state.model.source.event
    ) {
      return;
    }
    const { type, ...variables } = action;

    try {
      const result: FetchResult<updateScheduleItem> = yield call<
        MutationFunction<updateScheduleItem, updateScheduleItemVariables>
      >(
        client.mutate,
        {
          mutation: updateScheduleItem,
          variables,
          update: updateCacheFromUpdateItem(state.model.eventSlug),
        },
      );
      if (!result.data) {
        yield put({
          type: "SHOW_MESSAGE",
          message: "Item update failed. No item returned.",
        });
        return;
      }
      yield put({
        type: "SELECT_ITEM",
        itemId: result.data.updateScheduleItem.id,
      });
    } catch (e) {
      yield put({
        type: "SHOW_MESSAGE",
        message: `Unexpected error: ${e.message}`,
      });
    }
  }

  yield takeEvery("UPDATE_SCHEDULE_ITEM", helper);
}

export function* addItemParticipantSaga(client: ApolloClient<{}>) {
  function* helper(action: AddItemParticipantAction) {
    const state: ScheduleModelState = yield select();
    if (
      !state.model
        || !state.model.source.event
    ) {
      return;
    }
    const model = state.model!;

    try {
      const result: FetchResult<addItemParticipant> = yield call<
        MutationFunction<addItemParticipant, addItemParticipantVariables>
      >(
        client.mutate,
        {
          mutation: addItemParticipantMutation,
          variables: {
            itemId: action.itemId,
            identityId: action.identityId,
            version: model.version,
          },
        },
      );
      if (!result.data) {
        yield put({
          type: "SHOW_MESSAGE",
          message: "Item update failed. No item returned.",
        });
        return;
      }
    } catch (e) {
      yield put({
        type: "SHOW_MESSAGE",
        message: `Unexpected error: ${e.message}`,
      });
    }
  }

  yield takeEvery("ADD_ITEM_PARTICIPANT", helper);
}

export function* removeItemParticipantSaga(client: ApolloClient<{}>) {
  function* helper(action: RemoveItemParticipantAction) {
    const state: ScheduleModelState = yield select();
    if (
      !state.model
        || !state.model.source.event
    ) {
      return;
    }
    const model = state.model!;

    try {
      const result: FetchResult<removeItemParticipant> = yield call<
        MutationFunction<removeItemParticipant, removeItemParticipantVariables>
      >(
        client.mutate,
        {
          mutation: removeItemParticipantMutation,
          variables: {
            eventId: model.source.event.id,
            itemParticipantId: action.itemParticipantId,
            version: model.version,
          },
        },
      );
      if (!result.data) {
        yield put({
          type: "SHOW_MESSAGE",
          message: "Item update failed. No item returned.",
        });
        return;
      }
    } catch (e) {
      yield put({
        type: "SHOW_MESSAGE",
        message: `Unexpected error: ${e.message}`,
      });
    }
  }

  yield takeEvery("REMOVE_ITEM_PARTICIPANT", helper);
}

export function* publishScheduleSaga(client: ApolloClient<{}>) {
  function* helper(action: PublishScheduleAction) {
    const state: ScheduleModelState = yield select();
    if (
      !state.model
        || !state.model.source.event
    ) {
      return;
    }
    const model = state.model!;

    try {
      const result: FetchResult<publishSchedule> = yield call<
        MutationFunction<publishSchedule, publishScheduleVariables>
      >(
        client.mutate,
        {
          mutation: publishScheduleMutation,
          variables: {
            event: model.source.event.id,
            version: model.version,
          },
        },
      );
      if (result.data) {
        yield put({
          type: "SHOW_MESSAGE",
          message: "Latest schedule published.",
        });
        return;
      }
    } catch (e) {
      yield put({
        type: "SHOW_MESSAGE",
        message: `Unexpected error: ${e.message}`,
      });
    }
  }

  yield takeEvery("PUBLISH_SCHEDULE", helper);
}
