import * as React from "react";
import { ColorResult, SketchPicker } from "react-color";
import { Button, DialogContainer, TextField } from "react-md";
import tinycolor from "tinycolor2";

import { Center } from "../../layout/Center";
import { ROW_HEIGHT } from "../ScheduleGridScreen/constants";
import { EntryTile } from "../ScheduleGridScreen/EntryTile";

const BEM = "rw-schedule-config";

/**
 * This palette is Brewer Set1, Set2, and Set3, concatenated.
 */
export const colors = [
  "#e41a1c",
  "#377eb8",
  "#4daf4a",
  "#984ea3",
  "#ff7f00",
  "#ffff33",
  "#a65628",
  "#f781bf",
  "#999999",
  "#66c2a5",
  "#fc8d62",
  "#8da0cb",
  "#e78ac3",
  "#a6d854",
  "#ffd92f",
  "#e5c494",
  "#b3b3b3",
  "#8dd3c7",
  "#ffffb3",
  "#bebada",
  "#fb8072",
  "#80b1d3",
  "#fdb462",
  "#b3de69",
  "#fccde5",
  "#d9d9d9",
  "#bc80bd",
  "#ccebc5",
  "#ffed6f",
];

interface ColorFieldProps {
  id: string;
  color: string | null;
  colorError: string | undefined;

  onChange(newColor: string | null): void;
}

export const ColorField: React.FC<ColorFieldProps> = (props) => {
  const [pickerVisible, setPickerVisible] = React.useState(false);

  const showPicker = () => {
    setPickerVisible(true);
  };
  const hidePicker = () => setPickerVisible(false);
  const onChangeText = (newColor: number | string) => {
    if (typeof newColor === "string" && newColor) {
      props.onChange(newColor);
    } else {
      props.onChange(null);
    }
  };
  const onChangePicker = (newColor: ColorResult) => {
    props.onChange(newColor.hex.replace("#", ""));
  };
  const clearColor = () => props.onChange(null);

  const buttonColor =
    props.color && props.color.match(/^[0-9a-fA-F]{6}$/)
      ? `#${props.color}`
      : "#999999";

  return (
    <>
      <TextField
        id={props.id}
        name={props.id}
        label="Color"
        pattern="[0-9a-fA-F]{6}"
        onChange={onChangeText}
        helpText="Click the button on the left for a color picker."
        error={props.colorError !== undefined}
        errorText={props.colorError}
        leftIcon={
          <Button
            icon
            style={{
              backgroundColor: buttonColor,
              color:
                tinycolor
                  .mostReadable(
                    buttonColor,
                    ["rgba(0, 0, 0, 0.56)", "rgba(255, 255, 255, 0.7)"],
                  )
                  .toRgbString(),
            }}
            tooltipLabel="Open color picker"
            onClick={showPicker}
          >
            color_lens
          </Button>
        }
        inlineIndicator={
          <Button
            icon
            className="inline-button"
            tooltipLabel="Clear color"
            onClick={clearColor}
          >
            clear
          </Button>
        }
        value={props.color || ""}
      />
      <DialogContainer
        visible={pickerVisible}
        id={`color-dialog-${props.id}`}
        onHide={hidePicker}
        portal={true}
        lastChild={true}
        disableScrollLocking={true}
        renderNode={document.body}
        actions={
          <Button raised primary onClick={hidePicker}>
            OK
          </Button>
        }
      >
        <div
          className={`${BEM}_preview-tile-container`}
          style={{ height: ROW_HEIGHT }}
        >
          <EntryTile
            displayName="Color preview"
            rating="G"
            status="DRAFT"
            emphasis={false}
            color={`#${props.color}` || "#99999"}
          />
        </div>
        <Center>
          <SketchPicker
            color={`#${props.color}` || "#99999"}
            onChange={onChangePicker}
            disableAlpha={true}
            presetColors={colors}
          />
        </Center>
      </DialogContainer>
    </>
  );
};
