import {
  ChronoUnit,
  ZonedDateTime,
} from "js-joda";
import * as React from "react";

import {formatDateTime} from "../format";

import {
  BEM,
  PIXELS_PER_MINUTE,
} from "./constants";

interface GutterTopProps {
  startsAt: ZonedDateTime;
  endsAt: ZonedDateTime;
  timeZone: string;
}

export const GutterTop: React.FC<GutterTopProps> = React.memo((props) => {
  function timeToPixels(time: ZonedDateTime): number {
    return props.startsAt.until(time, ChronoUnit.MINUTES) * PIXELS_PER_MINUTE;
  }
  const gutterTop: React.ReactNode[] = [];
  let hour = props.startsAt.truncatedTo(ChronoUnit.HOURS);
  while (hour.isBefore(props.endsAt)) {
    gutterTop.push(
      <div key={hour.toString()}
           className={`${BEM}_header ${BEM}_header--column`}
           style={{left: `${timeToPixels(hour)}px`}}>
        {formatDateTime(hour, props.timeZone)}
      </div>,
    );
    hour = hour.plusHours(1);
  }

  return (
    <React.Fragment>
      {...gutterTop}
    </React.Fragment>
  );
});
GutterTop.displayName = "GutterTop";

