import {History} from "history";
import {matchPath} from "react-router";
import {
  call,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";

import {
  EditItemAction,
  ScheduleModelAction,
  ScheduleModelState,
  SelectEntryAction,
  SelectItemAction,
} from "../model/reducer";
import {ITEM_DETAIL_PATH} from "./constants";

export interface ShowItemListAction {
  type: "SHOW_ITEM_LIST";
}

export type ScheduleGridAction = ShowItemListAction;
export type ScheduleGridDispatch =
  (action: ScheduleModelAction|ScheduleGridAction) => void;

export function* selectItemSaga(history: History) {
  function* helper(action: SelectItemAction) {
    const state: ScheduleModelState = yield select();
    if (
      !state.model
        || !state.model.source.event
    ) {
      return;
    }

    if (action.itemId) {
      // If an item is already selected, we don't want to actually change
      // anything.
      const selectedItemMatch =
        matchPath<{itemId: string}>(
          history.location.pathname,
          {
            path: ITEM_DETAIL_PATH,
            exact: true,
          },
        );

      if (
        selectedItemMatch
          && selectedItemMatch.params.itemId === action.itemId
      ) {
        return;
      }

      yield call(
        history.push,
        {
          pathname:
            `/event/${state.model.eventSlug}/schedule/grid/items/`
            + action.itemId,
          hash: history.location.hash,
          state: history.location.state,
        },
      );
    } else {
      yield call(
        history.push,
        {
          pathname: `/event/${state.model.eventSlug}/schedule/grid`,
          hash: history.location.hash,
          state: history.location.state,
        },
      );
    }
  }

  yield takeEvery("SELECT_ITEM", helper);
}

export function* editItemSaga(history: History) {
  function* helper(action: EditItemAction) {
    const state: ScheduleModelState = yield select();
    if (
      !state.model
        || !state.model.source.event
    ) {
      return;
    }

    if (action.itemId) {
      yield call(
        history.push,
        {
          pathname:
            `/event/${state.model.eventSlug}/schedule/grid/items/`
            + `${action.itemId}/edit`,
          hash: history.location.hash,
        },
      );
    }
  }

  yield takeEvery("EDIT_ITEM", helper);
}

export function* selectEntrySaga(history: History) {
  function* helper(action: SelectEntryAction) {
    const state: ScheduleModelState = yield select();
    if (
      !state.model
        || !state.model.source.event
    ) {
      return;
    }
    const model = state.model;

    // If an item is selected, we want to change the selected item too.
    const selectedItemMatch =
      matchPath<{itemId: string}>(
        history.location.pathname,
        {
          path: ITEM_DETAIL_PATH,
          exact: true,
        },
      );
    const entry =
      action.entryId ? model.entries.get(action.entryId) : undefined;
    const newItemId = selectedItemMatch && entry ? entry.item.id : null;

    if (action.entryId) {
      if (newItemId) {
        yield put({
          type: "SELECT_ITEM",
          itemId: newItemId,
        });
      }
      yield call(
        history.replace,
        {
          hash: `entryId=${action.entryId}`,
          search: history.location.search,
          state: {
            scrollToEntry: action.scrollToEntry,
          },
        },
      );
    } else {
      yield call(
        history.replace,
        {
          hash: "",
          search: history.location.search,
          state: {},
        },
      );
    }
  }

  yield takeEvery("SELECT_ENTRY", helper);
}

export function* showItemListSaga(history: History) {
  function* helper(action: ShowItemListAction) {
    const state: ScheduleModelState = yield select();
    if (
      !state.model
        || !state.model.source.event
    ) {
      return;
    }

    yield call(
      history.push,
      {
        pathname: `/event/${state.model.eventSlug}/schedule/grid/items`,
        hash: history.location.hash,
      },
    );
  }

  yield takeEvery("SHOW_ITEM_LIST", helper);
}

