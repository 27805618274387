import * as React from "react";
import { ApolloConsumer } from "react-apollo";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import { useHistory } from "react-router-dom";

import {
  Button,
  FontIcon,
  ListItem,
} from "react-md";

import { AppFrame, MediaArg, MediaType } from "../../AppFrame";

import { LocationDialogRoute } from "./LocationDialogRoute";
import { LocationList } from "./LocationList";
import { Dispatch, LocationModel } from "./LocationModel";

interface LocationToolbarProps
{
  eventSlug: string;
  draggable: boolean;
  saving: boolean;
  dispatch: Dispatch;
}

const LocationToolbar: React.FC<LocationToolbarProps> =
  ({ draggable, saving, dispatch, eventSlug }) => {
    const history = useHistory();
    return draggable
      ? <>
        <Button
          flat
          className="md-btn--toolbar"
          onClick={
            () => dispatch({
              type: "saveLocationOrder",
              dispatch,
            })
          }
          tooltipLabel={saving ? "Saving..." : "Save"}
          iconChildren={saving ? "hourglass_empty" : "save"}
        >
          {saving ? "Saving..." : "Save order"}
        </Button>
        <Button
          flat
          className="md-btn--toolbar"
          onClick={() => dispatch({ type: "stopDrag" })}
          tooltipLabel="Cancel reordering"
          iconChildren="close"
        >
          Cancel
        </Button>
      </> : <>
        <Button
          flat
          className="md-btn--toolbar"
          onClick={() => history.push(
            `/event/${eventSlug}/schedule/locations/new`,
          )}
          tooltipLabel="Add location"
          iconChildren="add"
        >
          Add location
        </Button>
        <Button
          flat
          className="md-btn--toolbar"
          onClick={() => dispatch({ type: "startDrag" })}
          tooltipLabel="Reorder locations"
          iconChildren="open_with"
        >
          Reorder
        </Button>
      </>;
  };

interface LocationMenuProps
{
  eventSlug: string;
  draggable: boolean;
  dispatch: Dispatch;
}

const LocationMenu: React.FC<LocationMenuProps> =
  ({ draggable, dispatch, eventSlug }) => {
    const history = useHistory();
    return draggable
      ? <>
        <ListItem
          leftIcon={<FontIcon>save</FontIcon>}
          primaryText="Save order"
          onClick={
            () => dispatch({
              type: "saveLocationOrder",
              dispatch,
            })
          }
        />
        <ListItem
          leftIcon={<FontIcon>close</FontIcon>}
          primaryText="Cancel reordering"
          onClick={() => dispatch({ type: "stopDrag" })}
        />,
      </> : <>
        <ListItem
          leftIcon={<FontIcon>add</FontIcon>}
          primaryText="New location"
          onClick={() => history.push(
            `/event/${eventSlug}/schedule/locations/new`,
          )}
        />
        <ListItem
          leftIcon={<FontIcon>open_with</FontIcon>}
          primaryText="Reorder locations"
          onClick={() => dispatch({ type: "startDrag" })}
        />
      </>;
  };

export interface ScreenProps
{
  eventSlug: string;

  drawerVisible: MediaArg;
  changeDrawerVisibility(media: MediaType, visible: boolean): void;
  logout(): void;
}

export const LocationListScreen: React.FC<ScreenProps> = (props) => {
  return (
    <ApolloConsumer>
      {
        (client) => (
          <LocationModel
            eventSlug={props.eventSlug}
            client={client}
          >
            {
              (state, dispatch) => (
                <AppFrame
                  eventSlug={props.eventSlug}
                  drawerVisible={props.drawerVisible}
                  changeDrawerVisibility={props.changeDrawerVisibility}
                  logout={props.logout}
                  toolbarActions={
                    <LocationToolbar
                      draggable={state.draggable}
                      saving={state.saving}
                      dispatch={dispatch}
                      eventSlug={props.eventSlug}
                    />
                  }
                  actionsMenu={
                    <LocationMenu
                      draggable={state.draggable}
                      dispatch={dispatch}
                      eventSlug={props.eventSlug}
                    />
                  }
                >
                  {
                    () =>
                      <DndProvider backend={Backend}>
                        <div style={{
                          overflowY: "auto",
                          flex: "1 0 auto",
                          padding: 15,
                        }}>
                          <LocationList
                            state={state}
                            dispatch={dispatch}
                          />
                          <LocationDialogRoute />
                        </div>
                      </DndProvider>
                  }
                </AppFrame>
              )
            }
          </LocationModel>
        )
      }
    </ApolloConsumer>
  );
};

