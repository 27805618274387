import * as React from "react";
import {
  Checkbox,
  SelectField,
  TextField,
} from "react-md";

import {
  extractCustomFieldValue,
} from "./format";

import {
  FieldInstanceInput,
  FieldValueInput,
} from "../../__generated__/globalTypes";
import {
  getItemForm_event_schedule_itemForm_fields_BooleanField,
  getItemForm_event_schedule_itemForm_fields_SelectOneField,
  getItemForm_event_schedule_itemForm_fields_TextField,
} from "./__generated__/getItemForm";

export type Field =
  Omit<
    getItemForm_event_schedule_itemForm_fields_BooleanField,
    "ref" | "version" | "formId"
  > | Omit<
    getItemForm_event_schedule_itemForm_fields_SelectOneField,
    "ref" | "version" | "formId"
  > | Omit<
    getItemForm_event_schedule_itemForm_fields_TextField,
    "ref" | "version" | "formId"
  >;

export interface CustomFieldsProps {
  customFields: Field[];
  fieldValues: FieldInstanceInput[];
  setFieldValues(values: FieldInstanceInput[]): void;
}

export const CustomFields: React.FC<CustomFieldsProps> = (props) => {
  function getFieldValue<T extends keyof FieldValueInput>(
    name: string,
    fieldType: T,
  ): NonNullable<FieldValueInput[T]>|undefined {
    return (
      extractCustomFieldValue(
        props.fieldValues,
        name,
        fieldType,
      )
    );
  }

  function setFieldValue(name: string, value: FieldValueInput|null) {
    const otherInsts =
      props.fieldValues.filter((inst) => inst.name !== name);
    props.setFieldValues([
      ...otherInsts,
      {
        name,
        value,
      },
    ]);
  }

  return (
    <React.Fragment>
      {
        props.customFields.map((field) => {
          // Indicate if disabled or public.
          const annotations = [
            ...(
              !field.adminWritable
                ? ["read-only"]
                : []
            ),
            ...(
              field.public
                ? ["public"]
                : []
            ),
          ];
          const suffix = annotations.length > 0
            ? ` (${annotations.join(", ")})`
            : "";
          if (field.__typename === "TextField") {
            // Make the text field and instructions.
            return (
              <TextField
                id={`schedule-item__custom-${field.name}`}
                key={field.label}
                label={`${field.label}${suffix}`}
                value={getFieldValue(field.name, "stringValue")}
                rows={field.multiline ? 2 : undefined}
                disabled={!field.adminWritable}
                className="schedule-item__custom"
                required={field.required && field.adminWritable}
                helpText={field.help}
                onChange={(newVal) => {
                  if (newVal == null || newVal === "") {
                    setFieldValue(field.name, null);
                  } else {
                    setFieldValue(field.name, { "stringValue": "" + newVal });
                  }
                }}
                />
              );
          } else if (field.__typename === "SelectOneField") {
            // Make the select field and instructions.
            return (
              <SelectField
                id={`schedule-item__custom-${field.name}`}
                key={field.label}
                label={`${field.label}${suffix}`}
                value={getFieldValue(field.name, "stringValue")}
                fullWidth
                position={SelectField.Positions.BELOW}
                menuItems={field.options.map(
                  (option) => ({ label: option.label, value: option.name }),
                )}
                disabled={!field.adminWritable}
                className="schedule-item__custom"
                required={field.required && field.adminWritable}
                helpText={field.help}
                stripActiveItem={false}
                onChange={(newVal) => {
                  if (newVal == null || newVal === "") {
                    setFieldValue(field.name, null);
                  } else {
                    setFieldValue(field.name, { "stringValue": "" + newVal });
                  }
                }}
                />
              );
          } else if (field.__typename === "BooleanField") {
            // Make the checkbox and instructions.
            const id = `schedule-item__custom-${field.name}`;
            const checkbox = (
              <Checkbox
                id={id}
                key={field.label}
                name={`custom-${field.name}`}
                label={`${field.label}${suffix}`}
                checked={getFieldValue(field.name, "booleanValue")}
                disabled={!field.adminWritable}
                className="schedule-item__custom"
                aria-describedby={field.help ? `${id}-help` : undefined}
                onChange={(newVal) => {
                  if (newVal == null) {
                    setFieldValue(field.name, null);
                  } else {
                    setFieldValue(field.name, { "booleanValue": newVal });
                  }
                }}
              />
            );
            return field.help ? (
              <div>
                {checkbox}
                <p className="md-caption" id={`${id}-help`}>
                  {field.help}
                </p>
              </div>
            ) : checkbox;
          }
          return null;
        })
      }
    </React.Fragment>
  );
};

