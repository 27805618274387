import {
  getLocation_event_schedule_location as Location,
} from "./__generated__/getLocation";

export function locationSort(a: Location, b: Location) {
  if (a.order !== b.order) {
    return a.order - b.order;
  }
  if (a.name !== b.name) {
    return a.name.localeCompare(b.name);
  }
  return 0;
}
