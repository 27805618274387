import { Duration, ZonedDateTime } from "js-joda";
import * as React from "react";
import { Button, CircularProgress } from "react-md";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { namedComponent } from "../../../namedComponent";

import { ScheduleItem, ScheduleLocation, ScheduleParticipant } from "../model";
import {
  MovingEntryInfo,
  ScheduleModelAction,
  ScheduleModelState,
} from "../model/reducer";

import { EntryList } from "../EntryList";
import { ItemDetails } from "../ItemDetails";
import {
  scheduleGetEverything_event_schedule_itemForm_fields as CustomField,
} from "../__generated__/scheduleGetEverything";
import { BEM } from "./constants";

interface ItemDrawerDetailProps {
  itemId: string;
  eventSlug: string;
}

interface ItemDrawerDetailStateProps {
  state: {
    itemModel: ScheduleItem;
    participants: ReadonlyArray<ScheduleParticipant>;
    locations: ReadonlyMap<string, ScheduleLocation>;
    eventStart: ZonedDateTime;
    eventEnd: ZonedDateTime;
    movingEntry: MovingEntryInfo|null;
    customFields: ReadonlyArray<CustomField>;
    timeZone: string;
    txnId: number|null;
  }|null;
}

interface ItemDrawerDetailDispatchProps {
  dispatch(action: ScheduleModelAction): void;
}

export const ItemDrawerDetail = (
  connect(
    (state: ScheduleModelState, ownProps: ItemDrawerDetailProps) => {
      if (
        !state.model
        || !state.model.source.event.schedule
      ) {
        return { state: null };
      }
      const item = state.model.items.get(ownProps.itemId);
      if (!item) {
        return { state: null };
      }
      return (
        {
          state: {
            itemModel: item,
            participants: Array.from(state.model.participants.values()),
            locations: state.model.locations,
            eventStart: state.model.startsAt,
            eventEnd: state.model.endsAt,
            movingEntry: state.movingEntry,
            timeZone: state.model.source.event.timeZone,
            customFields: state.model.source.event.schedule.itemForm.fields,
            txnId: state.transactionState ? state.transactionState.id : null,
          },
        }
      );
    },
    (dispatch: Dispatch<ScheduleModelAction>) => ({ dispatch }),
  )(
    namedComponent(
      "ItemDrawerDetail",
      (
        props: ItemDrawerDetailProps
          & ItemDrawerDetailStateProps
          & ItemDrawerDetailDispatchProps,
      ) => {
        let contents = <CircularProgress id="schedule-grid-progress"/>;
        if (props.state) {
          const item = props.state.itemModel.source;
          const timeZone = props.state.timeZone;
          const dispatch = props.dispatch;
          const state = props.state;

          const entryList = (
            <EntryList
              item={state.itemModel}
              timeZone={timeZone}
              eventSlug={props.eventSlug}
              locations={Array.from(state.locations.values())}
              eventStart={state.eventStart}
              eventEnd={state.eventEnd}
              transactionId={state.txnId}
              createEntry={(params) => {
                const location = state.locations.get(params.location);
                if (!location) {
                  return;
                }
                dispatch({
                  type: "START_NEW_ENTRY",
                  itemId: params.itemId,
                });
                dispatch({
                  type: "MOVE_ENTRY_PREVIEW",
                  minutesAfterStart:
                    Duration.between(
                      state.eventStart,
                      params.start,
                    ).toMinutes(),
                  durationMinutes: params.duration.toMinutes(),
                  locationRow: location.topRow,
                });
                dispatch({
                  type: "SAVE_ENTRY",
                  transactionId: params.transactionId,
                });
              }}
              updateEntry={(params) => {
                const location = state.locations.get(params.location);
                if (!location) {
                  return;
                }
                dispatch({
                  type: "START_MOVING_ENTRY",
                  entryId: params.id,
                  mode: "FULL",
                });
                dispatch({
                  type: "MOVE_ENTRY_PREVIEW",
                  minutesAfterStart:
                    Duration.between(
                      state.eventStart,
                      params.start,
                    ).toMinutes(),
                  durationMinutes: params.duration.toMinutes(),
                  locationRow: location.topRow,
                });
                dispatch({
                  type: "SAVE_ENTRY",
                  transactionId: params.transactionId,
                });
              }}
              deleteEntry={({id, transactionId}) => {
                dispatch({
                  type: "DELETE_ENTRY",
                  entryId: id,
                  transactionId,
                });
              }} />
          );

          contents = (
            <React.Fragment>
              {entryList}
              <div className="md-grid">
                <Button
                  raised
                  className="md-cell md-cell--12"
                  onClick={() =>
                    props.dispatch({
                      type: "START_NEW_ENTRY",
                      itemId: props.itemId,
                    })
                  }>
                  {
                    state.movingEntry
                      ? "Cancel scheduling"
                      : "Place new entry"
                  }
                </Button>
              </div>
              <ItemDetails
                eventSlug={props.eventSlug}
                item={item}
                allParticipants={state.participants}
                fields={props.state?.customFields}
                addItemParticipant={
                  (itemId, identityId) => props.dispatch({
                    type: "ADD_ITEM_PARTICIPANT",
                    itemId,
                    identityId,
                  })
                }
                removeItemParticipant={
                  (itemParticipantId) => props.dispatch({
                    type: "REMOVE_ITEM_PARTICIPANT",
                    itemParticipantId,
                  })
                }
                showMessage={
                  (message) => props.dispatch({
                    type: "SHOW_MESSAGE",
                    message,
                  })
                }
              />
            </React.Fragment>
          );
        }

        return (
          <section className={
            "md-toolbar-relative dialogs__content"
            + " md-list--drawer"
            + ` ${BEM}_drawer`
          }>
            <div className={`${BEM}_drawer_item_detail`}>
              {contents}
            </div>
          </section>
        );
      },
    ),
  )
);

