import {
  ArrayHelpers,
  FieldArray,
  FormikErrors,
  useFormikContext,
} from "formik";
import * as React from "react";
import { DragObjectWithType, useDrag, useDrop } from "react-dnd";
import { Button, FontIcon, SelectField, TextField } from "react-md";

import { ColorField, colors } from "./ColorField";
import {
  FieldFormData,
  SelectOneFieldFormData,
  SelectOption,
  TextFieldFormData,
} from "./FieldFormData";
import { slugify } from "./util";

const BEM = "rw-schedule-config";

// Draggable item type for react-dnd.
const OPTION_ITEM_TYPE = "option";

interface OptionDragObject extends DragObjectWithType {
  type: typeof OPTION_ITEM_TYPE;
  id: string;
}

/**
 * This is a sentinel value. The places where we need to use this only accept
 * strings or numbers. There's no way to set the name of an option to a number,
 * so that's what we use for the null case.
 */
const NULL_OPTION = -1;
const getOptionValue = (
  options: ReadonlyArray<SelectOption>,
  optionValue: string | null,
) => {
  const matchedOption = options.find(
    (option) => option.name === optionValue,
  );
  if (matchedOption) {
    return optionValue!;
  } else {
    return NULL_OPTION;
  }
};

interface OptionProps {
  index: number;
  option: SelectOption;
  optionsLength: number;
  colorError: string|undefined;
  moveOption(from: number, to: number): void;
  onLabelChange(newValue: string|number, e: Event): void;
  setColor(newColor: string|null): void;
  deleteOption(): void;
  getOptionIndex(optionName: string): number|null;
  touchOptions(): void;
}

const Option: React.FC<OptionProps> = (
  {
    index,
    option,
    setColor,
    optionsLength,
    onLabelChange,
    deleteOption,
    moveOption,
    getOptionIndex,
    colorError,
    touchOptions,
  },
) => {
  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: OPTION_ITEM_TYPE,
      id: option.name,
      index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end() {
      touchOptions();
    },
  });

  const [, drop] = useDrop({
    accept: OPTION_ITEM_TYPE,
    hover(otherOption: OptionDragObject) {
      const overIndex = getOptionIndex(otherOption.id);
      if (overIndex === null) {
        return;
      }
      if (overIndex !== index) {
        moveOption(overIndex, index);
      }
    },
  });

  return (
    <div
      className="md-cell md-cell--12"
      ref={
        (node) => {
          if (node) {
            preview(drop(node));
          }
          return null;
        }
      }
    >
      <div
        className="md-paper--1 md-grid"
        style={{
          opacity: isDragging ? 0 : 1,
        }}
      >
        <div
          className={`${BEM}_option-container`}
        >
          <div
            ref={drag}
            className={`${BEM}_option-handle`}
          >
            <FontIcon>
              drag_indicator
            </FontIcon>
          </div>
          <div
            className={`md-cell ${BEM}_option-fields`}
          >
            <TextField
              id={`options.${index}.label`}
              name={`options.${index}.label`}
              label="Displayed label"
              required
              onChange={onLabelChange}
              value={option.label}
            />
            <ColorField
              id={`options.${index}.color`}
              color={option.color}
              colorError={colorError}
              onChange={setColor}
            />
            <div className="button-group">
              {
                0 < index
                  ? (
                    <Button
                      raised
                      onClick={() => {
                        moveOption(index, index - 1);
                        touchOptions();
                      }}
                      iconChildren={<FontIcon>arrow_upward</FontIcon>}
                    >
                      Re-order up
                    </Button>
                  ) : null
              }
              {
                index < optionsLength - 1
                  ? (
                    <Button
                      raised
                      onClick={() => {
                        moveOption(index, index + 1);
                        touchOptions();
                      }}
                      iconChildren={<FontIcon>arrow_downward</FontIcon>}
                    >
                      Re-order down
                    </Button>
                  ) : null
              }
              <Button
                raised
                secondary
                onClick={deleteOption}
                iconChildren={<FontIcon>delete</FontIcon>}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface SelectOneOptions {
  version: number;
}

export const SelectOneOptions: React.FC<SelectOneOptions> = (props) => {
  const form = useFormikContext<FieldFormData>();
  // This is a map used to maintain parallel synthetic keys for options, since
  // name is mutable for new options. These keys are needed so that we have a
  // key for React to use for fields to maintain identity for each option under
  // deletion, renaming, and reordering. This key is also used to ensure
  // uniqueness for options when generating internal names for the options.
  const optionKeyMapRef = React.useRef<Map<string, number>|null>(null);

  // This really shouldn't execute if it's not a SelectOneField.
  if (form.values.type !== "SelectOneField") {
    return null;
  }
  const formValues = form.values;
  const options = formValues.options;
  // Use a getter to get lazy, type safe initialization.
  const getOptionKeyMap = () => {
    if (!optionKeyMapRef.current) {
      optionKeyMapRef.current = new Map<string, number>(
        options.map(
          (option, index) => [option.name, index],
        ),
      );
    }
    return optionKeyMapRef.current;
  };
  const getNewKey = () => {
    let max = 0;
    for (const key of getOptionKeyMap().values()) {
      max = Math.max(max, key);
    }
    return max + 1;
  };
  const getNextOptionNumber = () => {
    let result = 1;
    for (const option of options) {
      if (option.label === `Option ${result}`) {
        result += 1;
      }
    }
    return result;
  };
  const getOptionIndex = (name: string) => {
    const index = options.findIndex((opt) => opt.name === name);
    if (index === -1) {
      return null;
    }
    return index;
  };
  const onLabelChange =
    (option: SelectOption, index: number) =>
    (newValue: string|number, e: Event) => {
    // If this is a new option, slugify the name of the
    // option.
    if (
      form.initialValues.type !== "SelectOneField"
      || !form.initialValues.options.find(
      (initialOption) =>
        initialOption.name === option.name,
      )
    ) {
      // We need to maintain a map from the name to the key so that text fields
      // can be re-ordered while allowing their names to change.

      // Get the old key, or create a new key.
      const key = getOptionKeyMap().get(option.name) ?? getNewKey();
      // Compute a new name, slugified from the label, with the key.
      const slug = slugify(newValue.toString()) || "option";
      const suffix = `--${props.version}-${key}`;
      const newName = slug + suffix;
      form.setFieldValue(`options.${index}.name`, newName);
      // Maintain the key mapping.
      getOptionKeyMap().delete(option.name);
      getOptionKeyMap().set(newName, key);
    }
    form.handleChange(e);
  };
  const createNewOption = (arrayHelpers: ArrayHelpers) => () => {
    const key = getNewKey();
    const optNumber = getNextOptionNumber();
    const name = `option-${optNumber}--${props.version}-${key}`;
    arrayHelpers.push({
      name,
      label: `Option ${optNumber}`,
      color: colors[options.length % colors.length].replace("#", ""),
    });
    getOptionKeyMap().set(name, key);
  };
  const deleteOption = (arrayHelpers: ArrayHelpers, index: number) => () => {
    getOptionKeyMap().delete(formValues.options[index].name);
    arrayHelpers.remove(index);
  };

  return (
    <FieldArray
      name="options"
      validateOnChange={true}
      render={(arrayHelpers) => (
        <div>
          <div className="md-cell md-cell--12 md-text-container">
            <SelectField
              id="options.default"
              label="Default selection"
              fullWidth={true}
              menuItems={[
                ...(
                  options.map((option) => ({
                    label: option.label,
                    value: option.name,
                  }))
                ),
                {
                  label: <em>No value</em>,
                  value: NULL_OPTION,
                },
              ]}
              onChange={(option) => {
                if (!option || option === NULL_OPTION) {
                  form.setFieldValue("default", null);
                } else if (typeof option === "string") {
                  form.setFieldValue("default", option);
                }
              }}
              helpText="The default option used for this field if it is not set."
              value={
                getOptionValue(
                  options,
                  (arrayHelpers.form.values as TextFieldFormData).default,
                )
              }
            />
          </div>

          <div className="md-cell md-cell--12 md-text-container">
            <h4>Options</h4>
            <p className="md-caption">
              Dropdown fields can pick one of several options. If this
              field is the colored field for this schedule, then the colors are
              displayed as a bottom border for each schedule entry.
            </p>
          </div>
          {
            options
              .map((option, index) => (
                <Option
                  key={getOptionKeyMap().get(option.name)}
                  index={index}
                  option={option}
                  optionsLength={options.length}
                  setColor={
                    (newColor) =>
                      form.setFieldValue(`options.${index}.color`, newColor)
                  }
                  onLabelChange={onLabelChange(option, index)}
                  deleteOption={deleteOption(arrayHelpers, index)}
                  moveOption={arrayHelpers.move}
                  getOptionIndex={getOptionIndex}
                  colorError={
                    form.values.type === "SelectOneField"
                      ? (
                        form.errors as FormikErrors<SelectOneFieldFormData>
                      )
                        .options
                        ?.[index]
                        ?.color
                      : undefined
                  }
                  touchOptions={
                    () => arrayHelpers.form.setFieldTouched("options", true)
                  }
                />
              ))
          }
          <div className="button-group rw-right-container">
            <Button
              raised
              iconChildren={<FontIcon>add</FontIcon>}
              onClick={createNewOption(arrayHelpers)}
            >
              Add option
            </Button>
          </div>
        </div>
      )}
    />
  );
};
