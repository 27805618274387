
import {History} from "history";
import {matchPath} from "react-router";
import {
  call,
  select,
  takeEvery,
} from "redux-saga/effects";

import {
  ScheduleModelState,
  SelectItemAction,
} from "../model/reducer";

const ITEM_DETAIL_PATH = "/event/:eventSlug/schedule/items/:itemId";

export function* selectItemSaga(history: History) {
  function* helper(action: SelectItemAction) {
    const state: ScheduleModelState = yield select();
    if (
      !state.model
        || !state.model.source.event
    ) {
      return;
    }

    if (action.itemId) {
      // If an item is already selected, we don't want to actually change
      // anything.
      const selectedItemMatch =
        matchPath<{itemId: string}>(
          history.location.pathname,
          {
            path: ITEM_DETAIL_PATH,
            exact: true,
          },
        );

      if (
        selectedItemMatch
          && selectedItemMatch.params.itemId === action.itemId
      ) {
        return;
      }

      yield call(
        history.push,
        {
          pathname:
            `/event/${state.model.eventSlug}/schedule/items/`
            + action.itemId,
          hash: history.location.hash,
          state: history.location.state,
        },
      );
    } else {
      yield call(
        history.push,
        {
          pathname: `/event/${state.model.eventSlug}/schedule/items/`,
          hash: history.location.hash,
          state: history.location.state,
        },
      );
    }
  }

  yield takeEvery("SELECT_ITEM", helper);
}

