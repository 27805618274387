import {
  ChronoUnit,
  DateTimeFormatter,
  Duration,
  LocalDate,
  LocalDateTime,
  LocalTime,
  ZoneId,
  ZonedDateTime,
  convert as jodaConvert,
} from "js-joda";
// tslint:disable-next-line:no-import-side-effect
import "js-joda-timezone";
import * as React from "react";
import {
  Button,
  Card,
  CardActions,
  CardText,
  DatePicker,
  FontIcon,
  Grid,
  SelectField,
  TextField,
  TimePicker,
} from "react-md";
import {Link} from "react-router-dom";

import {
  formatDateTime,
  formatDuration,
  parseDuration,
} from "./format";
import {
  ScheduleItem,
  ScheduleLocation,
} from "./model";

interface EntryFormProps {
  id: string;
  eventStart: ZonedDateTime;
  eventEnd: ZonedDateTime;
  defaultLocationId: string;
  defaultStart: ZonedDateTime;
  defaultDuration: Duration;
  locations: ReadonlyArray<ScheduleLocation>;
  timeZone: string;
  isSavingForm: boolean;
  expanded: boolean;
  children: React.ReactNode;
  className?: string;
  onExpanderClick(): void;
  onClose(): void;
  saveEntry(params: {
    location: string,
    start: ZonedDateTime,
    duration: Duration,
  }): void;
}

const EntryCard: React.FC<EntryFormProps> = (props) => {
  const [location, setLocation] = React.useState(props.defaultLocationId);
  const [start, setStart] = React.useState(props.defaultStart);
  const [startStr, setStartStr] = React.useState(
    props.defaultStart.format(DateTimeFormatter.ISO_OFFSET_DATE_TIME),
  );
  const [durationStr, setDurationStr] = React.useState<string>(
    formatDuration(props.defaultDuration),
  );
  const [error, setError] = React.useState({
    location: false,
    start: false,
    duration: false,
  });

  const dateTimeFormatter = new Intl.DateTimeFormat(undefined, {
    timeZone: props.timeZone,
    hour: "numeric",
    minute: "2-digit",
    timeZoneName: "long",
  });

  const reset = () => {
    setLocation(props.defaultLocationId);
    setStart(props.defaultStart);
    setStartStr(
      props.defaultStart.format(DateTimeFormatter.ISO_OFFSET_DATE_TIME),
    );
    setDurationStr(formatDuration(props.defaultDuration));
    setError({
      location: false,
      start: false,
      duration: false,
    });
  };

  const parseStart = (newStart: string) => {
    try {
      const parsed = (
        ZonedDateTime.parse(
          newStart,
          DateTimeFormatter.ISO_OFFSET_DATE_TIME,
        )
        .withZoneSameInstant(ZoneId.of(props.timeZone))
      );
      setError({
        ...error,
        start: false,
      });
      setStartStr(
        parsed.format(DateTimeFormatter.ISO_OFFSET_DATE_TIME),
      );
      setStart(parsed);
      return parsed;
    } catch (e) {
      setError({
        ...error,
        start: true,
      });
    }
    return null;
  };

  const validateDuration = () => {
    if (!durationStr) {
      setError({ ...error, duration: true });
      return null;
    }

    const newDuration = parseDuration(durationStr);

    if (newDuration) {
      setError({ ...error, duration: false });
      setDurationStr(formatDuration(newDuration));
      return newDuration;
    } else {
      setError({ ...error, duration: true });
      return null;
    }
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const newDuration = validateDuration();
    const newStart = parseStart(startStr);
    if (!newDuration || !newStart) {
      return;
    }
    props.saveEntry({
      location,
      start: newStart,
      duration: newDuration,
    });
  };

  const onLocationChange = (newLocation: string|number) => {
    if (typeof newLocation === "string") {
      setLocation(newLocation);
    }
  };

  const onDateChange = (str: string, date: Date) => {
    const newStartStr = (
      ZonedDateTime.of(
        LocalDate.of(
          date.getFullYear(),
          date.getMonth() + 1,
          date.getDate(),
        ),
        start.toLocalTime(),
        ZoneId.of(props.timeZone),
      )
      .format(DateTimeFormatter.ISO_OFFSET_DATE_TIME)
    );

    setStartStr(newStartStr);
    parseStart(newStartStr);
  };

  const onTimeChange = (str: string, date: Date) => {
    const newLocalStart = (
      LocalDateTime.of(
        start.toLocalDate(),
        LocalTime.of(
          date.getHours(),
          date.getMinutes(),
        ),
      )
    );
    const newStartStr = (
      newLocalStart
      .atZone(ZoneId.of(props.timeZone))
      .format(DateTimeFormatter.ISO_OFFSET_DATE_TIME)
    );

    setStartStr(newStartStr);
    parseStart(newStartStr);
  };

  const onTimeZoneChange = (value: string|number) => {
    if (typeof value === "string") {
      const newStartStr = (
        start.toLocalDateTime()
        .atZone(ZoneId.of(value))
        .format(DateTimeFormatter.ISO_OFFSET_DATE_TIME)
      );
      setStartStr(newStartStr);
      parseStart(newStartStr);
    }
  };

  const onExpanderClick = () => {
    props.onExpanderClick();
    reset();
  };

  const onCancelClick = (e: React.MouseEvent) => {
    if (
      e.altKey
        || e.metaKey
        || e.shiftKey
        || e.ctrlKey
    ) {
      return;
    }
    reset();
    props.onClose();
  };

  // Calculate if the timezone is ambiguous.
  const timeZoneChoices: Array<{ label: string, value: string }> = [];
  {
    const localStart = start.toLocalDateTime();
    const zoneId = ZoneId.of(props.timeZone);
    const transition = zoneId.rules().transition(localStart);
    if (transition) {
      const offsets = transition.validOffsets();
      for (const offset of offsets) {
        const timeZonePart = (
          dateTimeFormatter.formatToParts(
            jodaConvert(
              ZonedDateTime.of(
                localStart,
                offset,
              ),
            ).toDate(),
          ).find((part) => part.type === "timeZoneName")
        );
        if (timeZonePart) {
          timeZoneChoices.push({
            label: timeZonePart.value,
            value: offset.id(),
          });
        }
      }
    } else {
      const timeZonePart = (
        dateTimeFormatter.formatToParts(
          jodaConvert(start).toDate(),
        ).find((part) => part.type === "timeZoneName")
      );
      if (timeZonePart) {
        timeZoneChoices.push({
          label: timeZonePart.value,
          value: start.offset().id(),
        });
      }
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <Card
        className={props.className}
        expanded={props.expanded}
        onExpanderClick={onExpanderClick}>
        {props.children}

        <CardText expandable>
          <SelectField
            id={`entry-${props.id}-location`}
            label="Location"
            value={location}
            onChange={onLocationChange}
            fullWidth
            stripActiveItem={false}
            menuItems={
              props.locations.map((loc) => (
                { label: loc.name, value: loc.id }
              ))
            } />

          <DatePicker
            id={`entry-${props.id}-start-date`}
            portal={true}
            label="Start Date"
            minDate={
              jodaConvert(props.eventStart.toLocalDate())
              .toDate()
            }
            maxDate={
              jodaConvert(props.eventEnd.toLocalDate())
              .toDate()
            }
            value={
              jodaConvert(start.toLocalDate()).toDate()
            }
            onChange={onDateChange}
          />

          <TimePicker
            id={`entry-${props.id}-start-time`}
            portal={true}
            label="Start Time"
            value={
              // Let's just pick a date that we're pretty sure didn't have a DST
              // transition. We're just going to use the hours and minutes
              // anyway.
              jodaConvert(
                LocalDateTime.of(
                  LocalDate.of(2000, 1, 1),
                  start.toLocalTime(),
                ),
              ).toDate()
            }
            onChange={onTimeChange}
          />

          {
            timeZoneChoices.length > 1
              ? (
                <SelectField
                  id={`entry-${props.id}-time-zone`}
                  value={start.offset().toString()}
                  label="Ambiguous Time Zone"
                  menuItems={timeZoneChoices}
                  stripActiveItem={false}
                  onChange={onTimeZoneChange} />
              ) : null
          }

          <TextField
            id={`entry-${props.id}-duration`}
            label="Duration"
            value={durationStr}
            onChange={(value) => {
              setDurationStr(`${value}`);
            }}
            placeholder="HH:MM"
            error={error.duration}
            onBlur={validateDuration}
            required />
        </CardText>

        {
          props.expanded
            ? (
              <CardActions>
                <Button flat
                  onClick={onCancelClick}>
                  Cancel
                </Button>
                <Button primary
                  disabled={props.isSavingForm}
                  flat
                  type="submit">
                  Save Entry
                </Button>
              </CardActions>
            ) : null
        }
      </Card>
    </form>
  );
};

interface Props {
  item: ScheduleItem;
  timeZone: string;
  eventSlug: string;
  eventStart: ZonedDateTime;
  eventEnd: ZonedDateTime;
  locations: ReadonlyArray<ScheduleLocation>;
  transactionId: number|null;
  createEntry(params: {
    itemId: string,
    location: string,
    start: ZonedDateTime,
    duration: Duration,
    transactionId: number,
  }): void;
  updateEntry(params: {
    id: string,
    location: string,
    start: ZonedDateTime,
    duration: Duration,
    transactionId: number,
  }): void;
  deleteEntry(params: {
    id: string,
    transactionId: number,
  }): void;
}

export const EntryList: React.FC<Props> = (props) => {
  const lastTxnId = React.useRef<number>();
  const lastFormId = React.useRef<string>();
  const [expanded, setExpanded] = React.useState(
    props.item.entries.reduce<{ [key: string]: boolean }>(
      (acc, entry) => ({
        ...acc,
        [entry.id]: false,
      }),
      { new: false },
    ),
  );

  // Collapse forms after they have successfully submitted.
  React.useEffect(() => {
    if (
      typeof lastTxnId.current === "number"
      && typeof lastFormId.current === "string"
      && props.transactionId === null
    ) {
      setExpanded({
        ...expanded,
        [lastFormId.current]: false,
      });
      lastTxnId.current = undefined;
      lastFormId.current = undefined;
    }
  });

  function expandHandler(entryId: string) {
    return (
      () => setExpanded({ ...expanded, [entryId]: !expanded[entryId] })
    );
  }

  const entryCards = (
    props.item.entries.map(
      (entry) => {
        const startTime = formatDateTime(
          entry.start,
          props.timeZone,
        );
        const end = entry.start.plusTemporalAmount(entry.duration);

        const format = new Intl.DateTimeFormat(undefined, {
          weekday:
            entry.start.truncatedTo(ChronoUnit.DAYS)
              .isEqual(end.truncatedTo(ChronoUnit.DAYS))
                ? undefined
                : "short"
          ,
          hour: "numeric",
          minute: "numeric",
          timeZone: props.timeZone,
        });
        const endTimeStr = format.format(jodaConvert(end).toDate());
        const durationStr = formatDuration(entry.duration);
        const expand = expandHandler(entry.id);

        return (
          <EntryCard
            key={entry.id}
            id={entry.id}
            defaultLocationId={entry.location.id}
            defaultStart={entry.start}
            defaultDuration={entry.duration}
            locations={props.locations}
            timeZone={props.timeZone}
            eventStart={props.eventStart}
            eventEnd={props.eventEnd}
            saveEntry={(params) => {
              lastFormId.current = entry.id;
              lastTxnId.current = Math.random();
              props.updateEntry({
                ...params,
                id: entry.id,
                transactionId: lastTxnId.current,
              });
            }}
            onClose={expand}
            expanded={expanded[entry.id]}
            className="md-cell md-cell--12"
            isSavingForm={
              lastFormId.current === entry.id
              && lastTxnId.current === props.transactionId
            }
            onExpanderClick={expand}>
            <CardText>
              <div>{entry.location.name}</div>
              <div>{
                  // \u2014 is the em dash.
                  `${startTime} \u2014 ${endTimeStr} (${durationStr})`
              }</div>
            </CardText>
            <CardActions expander>
              <Button
                flat
                component={Link}
                to={`/event/${props.eventSlug}/schedule/grid/items/${props.item.id}#entryId=${entry.id}`}>
                Show in Grid
              </Button>
              <Button
                flat
                onClick={(e) => {
                  props.deleteEntry({
                    id: entry.id,
                    transactionId: Math.random(),
                  });
                }}>
                Delete
              </Button>
            </CardActions>
          </EntryCard>
        );
      },
    )
  );

  return (
    <Grid stacked>
      <h4>Scheduled Entries</h4>
      {
        entryCards.length > 0 ? entryCards : <em>None</em>
      }
      {
        props.locations.length > 0
          ? (
            <EntryCard
              id="new"
              defaultLocationId={props.locations[0].id}
              defaultStart={props.eventStart}
              defaultDuration={props.item.requestedDuration || Duration.ofHours(1)}
              locations={props.locations}
              timeZone={props.timeZone}
              eventStart={props.eventStart}
              eventEnd={props.eventEnd}
              saveEntry={(params) => {
                lastTxnId.current = Math.random();
                lastFormId.current = "new";
                props.createEntry({
                  ...params,
                  itemId: props.item.id,
                  transactionId: lastTxnId.current,
                });
              }}
              onClose={expandHandler("new")}
              isSavingForm={
                props.transactionId === lastTxnId.current
                && lastFormId.current === "new"
              }
              expanded={expanded.new}
              className="md-cell md-cell--12"
              onExpanderClick={expandHandler("new")}>
              <CardActions onClick={expandHandler("new")} expander>
                <Button flat>
                  Enter New Entry
                </Button>
              </CardActions>
            </EntryCard>
          ) : (
            <>
              <p>
                You need to create a location before you can schedule this item.
              </p>
              <Button
                raised
                component={Link}
                to={`/event/${props.eventSlug}/schedule/locations/`}
                iconEl={<FontIcon>room</FontIcon>}
              >
                View locations
              </Button>
            </>
        )
      }
    </Grid>
  );
};

