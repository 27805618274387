import * as React from "react";

import {ScheduleModelAction} from "../model/reducer";

import {NewItemForm} from "../NewItemForm";
import {BEM} from "./constants";

interface ItemDrawerAddProps {
  eventSlug: string;
  dispatch(action: ScheduleModelAction): void;
}

export const ItemDrawerAdd: React.FC<ItemDrawerAddProps> = (props) => {
  return (
    <section
      className={`md-toolbar-relative dialogs__content md-list--drawer ${BEM}_drawer`}
    >
      <NewItemForm eventSlug={props.eventSlug}
        dispatch={props.dispatch}
      />
    </section>
  );
};

