import * as React from "react";
import {
  Button,
  CircularProgress,
  DialogContainer,
  FontIcon,
  ListItem,
} from "react-md";
import { connect, useSelector } from "react-redux";
import {
  Link,
} from "react-router-dom";
import {Dispatch} from "redux";

import {
  ColorInfo,
  ScheduleEntry,
} from "../model";
import {
  ScheduleModelAction,
  ScheduleModelState,
} from "../model/reducer";

import {ScheduleGridDispatch} from "./sagas";
import {ScheduleGrid} from "./ScheduleGrid";
import {
  RouteInfo,
} from "./util";

import { AppFrame, MediaArg, MediaType } from "../../AppFrame";
import { BEM } from "./constants";

interface MovingActionProps {
  dispatch(action: ScheduleModelAction): void;
}

const MovingToolBar: React.FC<MovingActionProps> = (props) => (
  <Button flat
    className="md-btn--toolbar"
    tooltipLabel="Cancel moving"
    iconChildren="close"
    onClick={() => props.dispatch({
      type: "STOP_MOVING_ENTRY",
    })}
  >
    Cancel
  </Button>
);

const MovingMenu: React.FC<MovingActionProps> = (props) => (
  <ListItem icon
    leftIcon={<FontIcon>cancel</FontIcon>}
    primaryText="Cancel moving"
    onClick={() => props.dispatch({
      type: "STOP_MOVING_ENTRY",
    })}
  />
);

interface EntryActionProps {
  eventSlug: string;
  itemId: string;
  entryId: string;
  dispatch(action: ScheduleModelAction): void;
  showLegend(): void;
}

const EntryMenu: React.FC<EntryActionProps> = (props) => (
  <React.Fragment>
    <ListItem
      onClick={() => props.dispatch({
        type: "SELECT_ITEM",
        itemId: props.itemId,
      })}
      leftIcon={<FontIcon>info</FontIcon>}
      primaryText="Item info"
    />
    <ListItem
      onClick={() => props.dispatch({
        type: "DELETE_ENTRY",
        entryId: props.entryId,
        transactionId: Math.random(),
      })}
      leftIcon={<FontIcon>delete</FontIcon>}
      primaryText="Delete entry"
    />
    <ListItem
      component={Link}
      to={`/event/${props.eventSlug}/schedule/grid`}
      leftIcon={<FontIcon>cancel</FontIcon>}
      primaryText="Cancel selection"
    />
  </React.Fragment>
);

const EntryToolBar: React.FC<EntryActionProps> = (props) => (
  <React.Fragment>
    <Button
      flat
      className="md-btn--toolbar"
      tooltipLabel="Item info"
      iconChildren="info"
      onClick={() => props.dispatch({
        type: "SELECT_ITEM",
        itemId: props.itemId,
      })}
    >
      Item info
    </Button>
    <Button
      flat
      className="md-btn--toolbar"
      tooltipLabel="Delete entry"
      iconChildren="delete"
      onClick={() => props.dispatch({
        type: "DELETE_ENTRY",
        entryId: props.entryId,
        transactionId: Math.random(),
      })}
    >
      Delete entry
    </Button>
    <Link to={`/event/${props.eventSlug}/schedule/grid`}>
      <Button
        flat
        iconChildren="cancel"
        className="md-btn--toolbar"
        tooltipLabel="Cancel selection"
      >
        Unselect
      </Button>
    </Link>
  </React.Fragment>
);

interface PublishButtonProps {
  dispatch: Dispatch<ScheduleModelAction>;
}

const PublishButton: React.FC<{}> = connect(
  () => ({}),
  (dispatch: Dispatch<ScheduleModelAction>) => ({ dispatch }),
)((props: PublishButtonProps) => (
  <Button
    flat
    className="md-btn--toolbar"
    tooltipLabel="Update public schedule"
    iconChildren="publish"
    onClick={() => props.dispatch({
      type: "PUBLISH_SCHEDULE",
    })}
  >
    Publish
  </Button>
));

interface ShowLegendButtonProps {
  showLegend(): void;
}

const ShowLegendButton: React.FC<ShowLegendButtonProps> = (props) => {
  const hasLegend = useSelector<ScheduleModelState, boolean>(
    (state) => {
      const config = state.model?.source?.event.schedule?.configuration;
      return config
        ? !!config.coloredItemField || !!config.emphasizedField
        : false;
    },
  );

  if (!hasLegend) {
    return null;
  }

  return (
    <Button
      flat
      className="md-btn--toolbar"
      tooltipLabel="Show legend"
      iconChildren="category"
      onClick={props.showLegend}
    >
      Legend
    </Button>
  );
};

const ShowLegendListItem: React.FC<ShowLegendButtonProps> = (props) => (
  <ListItem
    leftIcon={<FontIcon>help</FontIcon>}
    primaryText="Show legend"
    onClick={props.showLegend}
  />
);

interface ConflictListItemProps {
  count?: number;
  eventSlug?: string;
  dispatch: Dispatch<ScheduleModelAction>;
}

const ConflictListItem: React.FC<{}> =
  connect(
    (state: ScheduleModelState, ownProps: {}) => ({
      eventSlug:
        state.model ? state.model.eventSlug : undefined,
      count:
        state.model ? state.model.conflicts.length : undefined,
    }),
    (dispatch: Dispatch<ScheduleModelAction>) => ({ dispatch }),
  )(
    (props: ConflictListItemProps) => (
      !props.eventSlug
        ? null
        : (
          <ListItem
            leftIcon={
              <FontIcon>
                {props.count ? "error" : "check_circle_outline"}
              </FontIcon>
            }
            primaryText={
              !props.count ? "No conflicts found"
              : props.count === 1 ? "1 conflict found"
              : `${props.count} conflicts found`
            }
          />
        )
    ),
  );

interface ConflictButtonProps {
  count?: number;
  eventSlug?: string;
  dispatch: Dispatch<ScheduleModelAction>;
}

const ConflictButton: React.FC<{}> =
  connect(
    (state: ScheduleModelState, ownProps: {}) => ({
      eventSlug:
        state.model ? state.model.eventSlug : undefined,
      count:
        state.model ? state.model.conflicts.length : undefined,
    }),
    (dispatch: Dispatch<ScheduleModelAction>) => ({ dispatch }),
  )(
    (props: ConflictButtonProps) => (
      !props.eventSlug || props.count === undefined
        ? null
        : (
          <Button
            id="conflicts-grid"
            flat
            iconChildren={props.count ? "error" : "check_circle_outline"}
            component={Link}
            tooltipLabel="Show conflicts"
            aria-describedby="conflict-count"
            to={`/event/${props.eventSlug}/schedule/grid/conflicts`}
          >
            {
              props.count > 1 ? `${props.count} conflicts`
                : props.count === 1 ? "1 conflict"
                : "No conflicts"
            }
          </Button>
        )
    ),
  );

interface LegendDialogProps {
  visible: boolean;
  setVisible(newVisible: boolean): void;
}

interface LegendDialogStateProps {
  colorInfo: ColorInfo|null;
}

const LegendDialog: React.FC<LegendDialogProps> =
  connect(
    (state: ScheduleModelState, ownProps: {}) => ({
      colorInfo:
        state.model ? state.model.colorInfo : null,
    }),
  )(
    ({ setVisible, colorInfo, visible }: LegendDialogProps & LegendDialogStateProps) => {
      const hide = () => setVisible(false);
      return (
        <DialogContainer
          id="legend-dialog"
          visible={visible}
          title="Legend"
          onHide={hide}
          actions={[
            { children: "Close", onClick: hide },
          ]}
        >
          {
            colorInfo
              ? (
                <>
                  <h4>{colorInfo.field.label}</h4>
                  <ul className={`${BEM}_legend-list`}>
                    {
                      colorInfo.field.options.map(
                        (option) => (
                          <li>
                            <div
                              className={`${BEM}_legend-color`}
                              style={{ backgroundColor: `#${option.color}` }}
                            />
                            {option.label}
                          </li>
                        ),
                      )
                    }
                  </ul>
                </>
              ) : null
          }
        </DialogContainer>
      );
    },
  );

interface DefaultActionProps {
  eventSlug: string;
  dispatch: ScheduleGridDispatch;
  showLegend(): void;
}

const DefaultToolBar: React.FC<DefaultActionProps> =
  (props) => (
    <React.Fragment>
      <Button
        flat
        className="md-btn--toolbar"
        tooltipLabel="Schedule item"
        iconChildren="event"
        to={`/event/${props.eventSlug}/schedule/grid/items`}
        component={Link}
      >
        Schedule item
      </Button>
      <PublishButton />
      <ConflictButton />
      <ShowLegendButton showLegend={props.showLegend}/>
    </React.Fragment>
);

const DefaultMenu: React.FC<DefaultActionProps> =
  (props) => (
    <React.Fragment>
      <ListItem
        leftIcon={<FontIcon>event</FontIcon>}
        primaryText="Schedule item"
        component={Link}
        to={`/event/${props.eventSlug}/schedule/grid/items`}
      />
      <ListItem
        onClick={() => props.dispatch({
          type: "PUBLISH_SCHEDULE",
        })}
        leftIcon={<FontIcon>publish</FontIcon>}
        primaryText="Update public schedule"
      />
      <ConflictListItem />
      <ShowLegendListItem showLegend={props.showLegend} />
    </React.Fragment>
);

interface ScheduleGridAppFrameProps {
  rightDrawerVisible: boolean;
  leftDrawerVisible: MediaArg;
  state: ScheduleModelState;
  selectedEntry: ScheduleEntry|null;
  eventSlug: string;
  routeInfo: RouteInfo|null;
  gridRef: React.RefObject<ScheduleGrid>;
  dispatch: ScheduleGridDispatch;
  changeNavDrawerVisibility(media: MediaType, visible: boolean): void;
  logout(): void;
}

export const ScheduleGridAppFrame: React.FC<ScheduleGridAppFrameProps> =
  (props) => {
    const [legendVisible, setLegendVisible] = React.useState(false);
    const showLegend = () => setLegendVisible(true);
    return (
      <AppFrame
        drawerVisible={props.leftDrawerVisible}
        changeDrawerVisibility={props.changeNavDrawerVisibility}
        eventSlug={props.eventSlug}
        logout={props.logout}
        toolbarActions={
          props.state.movingEntry
            ? (
              <MovingToolBar dispatch={props.dispatch} />
            ) : (
              props.selectedEntry
                ? (
                  <EntryToolBar
                    itemId={props.selectedEntry.item.id}
                    entryId={props.selectedEntry.id}
                    eventSlug={props.eventSlug}
                    dispatch={props.dispatch}
                    showLegend={showLegend}
                  />
                ) : (
                  <DefaultToolBar
                    dispatch={props.dispatch}
                    eventSlug={props.eventSlug}
                    showLegend={showLegend}
                  />
                )
            )
        }
        toolbarTitle={
          props.state.movingEntry || props.selectedEntry
            ? ""
            : "Reg.Works"
        }
        toolbarTheme={
          props.state.movingEntry || props.selectedEntry
            ? "secondary"
            : "primary"
        }
        actionsMenu={
          props.state.movingEntry
            ? (
              <MovingMenu dispatch={props.dispatch} />
            ) : (
              props.selectedEntry
                ? (
                  <EntryMenu
                    itemId={props.selectedEntry.item.id}
                    entryId={props.selectedEntry.id}
                    eventSlug={props.eventSlug}
                    dispatch={props.dispatch}
                    showLegend={showLegend}
                  />
                ) : (
                  <DefaultMenu
                    dispatch={props.dispatch}
                    eventSlug={props.eventSlug}
                    showLegend={showLegend}
                  />
                )
            )
        }
      >
        {
          (frameProps) =>
            <>
              {
                props.state.model ? (
                  <ScheduleGrid
                    model={props.state.model}
                    movingEntry={props.state.movingEntry}
                    selectedEntry={props.selectedEntry}
                    entryPreviewPos={
                      props.state.entryPreviewPos
                    }
                    dispatch={props.dispatch}
                    messages={props.state.messages}
                    eventSlug={props.eventSlug}
                    drawerVisible={
                      props.rightDrawerVisible
                      && (
                        // If we're on mobile, hide the drawer while moving the
                        // entry.
                        frameProps.media === "mobile"
                          ? !props.state.movingEntry
                          : true
                      )
                    }
                    routeInfo={props.routeInfo}
                    ref={props.gridRef}
                    transactionState={props.state.transactionState}
                    media={frameProps.media}
                  />
                ) : (
                  <CircularProgress id="schedule-grid-progress" />
                )
              }
              <LegendDialog
                visible={legendVisible}
                setVisible={setLegendVisible}
              />
            </>
        }
      </AppFrame>
    );
};

