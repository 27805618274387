import {DocumentNode} from "graphql";
import {
  Duration,
  ZonedDateTime,
} from "js-joda";
import * as React from "react";
import {
  Mutation,
  MutationFunction,
} from "react-apollo";
import {
  Button,
  CircularProgress,
  Drawer,
  FontIcon,
  Toolbar,
} from "react-md";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import {Dispatch} from "redux";

import {
  ScheduleItem,
  ScheduleLocation,
  ScheduleParticipant,
} from "../model";
import {
  ScheduleModelAction,
  ScheduleModelState,
} from "../model/reducer";

import {namedComponent} from "../../../namedComponent";
import {EditItemForm} from "../EditItemForm";
import {ItemDetails} from "../ItemDetails";
import {ItemFormValues} from "../ItemForm";
import {NewItemForm} from "../NewItemForm";

import {EntryList} from "../EntryList";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const updateScheduleItemMutation: DocumentNode = require("../model/updateScheduleItem.graphql");
import {
  updateScheduleItem,
  updateScheduleItemVariables,
} from "../model/__generated__/updateScheduleItem";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const createPersonMutation: DocumentNode = require("../createPerson.graphql");
import {
  createPerson,
  createPersonVariables,
} from "../__generated__/createPerson";

import {
  scheduleGetEverything_event_schedule_itemForm_fields as CustomField,
} from "../__generated__/scheduleGetEverything";

const BEM = "rw-schedule-items";

interface SideSheetDetailProps {
  itemId: string;
}

interface SideSheetDetailInnerProps {
  state: {
    item: ScheduleItem;
    eventSlug: string;
    eventId: string;
    eventStart: ZonedDateTime,
    eventEnd: ZonedDateTime,
    timeZone: string;
    txnId: number|null;
    allParticipants: ReadonlyMap<string, ScheduleParticipant>;
    customFields: ReadonlyArray<CustomField>;
    locations: ReadonlyMap<string, ScheduleLocation>;
  }|null;
  dispatch(action: ScheduleModelAction): void;
}

export const SideSheetDetail: React.FC<SideSheetDetailProps> =
  connect(
    (state: ScheduleModelState, ownProps: SideSheetDetailProps) => {
      const item = state.model && state.model.items.get(ownProps.itemId);
      if (
        !state.model
          || !state.model.source.event.schedule
          || !item
      ) {
        return { state: null };
      }

      return ({
        state: {
          item,
          eventSlug: state.model.eventSlug,
          eventStart: state.model.startsAt,
          eventEnd: state.model.endsAt,
          eventId: state.model.source.event.id,
          timeZone: state.model.source.event.timeZone,
          allParticipants: state.model.participants,
          locations: state.model.locations,
          customFields: state.model.source.event.schedule.itemForm.fields,
          txnId: state.transactionState ? state.transactionState.id : null,
        },
      });
    },
    (dispatch: Dispatch<ScheduleModelAction>) => ({ dispatch }),
  )(
    React.memo(
      namedComponent(
        "SideSheetDetail",
        ({state, dispatch}: SideSheetDetailInnerProps) => {
          if (!state) {
            return <CircularProgress id="item-side-sheet-detail" />;
          }

          return (
            <div className={`${BEM}_details`}>
              <div className="md-grid">
                <Button
                  component={Link}
                  label="View in grid"
                  iconEl={<FontIcon>grid_on</FontIcon>}
                  raised
                  className="md-cell md-cell--12"
                  to={`/event/${state.eventSlug}/schedule/grid/items/${state.item.id}`}
                />
              </div>
              <EntryList
                item={state.item}
                timeZone={state.timeZone}
                eventSlug={state.eventSlug}
                locations={Array.from(state.locations.values())}
                eventStart={state.eventStart}
                eventEnd={state.eventEnd}
                transactionId={state.txnId}
                createEntry={(params) => {
                  const location = state.locations.get(params.location);
                  if (!location) {
                    return;
                  }
                  dispatch({
                    type: "START_NEW_ENTRY",
                    itemId: params.itemId,
                  });
                  dispatch({
                    type: "MOVE_ENTRY_PREVIEW",
                    minutesAfterStart:
                      Duration.between(
                        state.eventStart,
                        params.start,
                      ).toMinutes(),
                    durationMinutes: params.duration.toMinutes(),
                    locationRow: location.topRow,
                  });
                  dispatch({
                    type: "SAVE_ENTRY",
                    transactionId: params.transactionId,
                  });
                }}
                updateEntry={(params) => {
                  const location = state.locations.get(params.location);
                  if (!location) {
                    return;
                  }
                  dispatch({
                    type: "START_MOVING_ENTRY",
                    entryId: params.id,
                    mode: "FULL",
                  });
                  dispatch({
                    type: "MOVE_ENTRY_PREVIEW",
                    minutesAfterStart:
                      Duration.between(
                        state.eventStart,
                        params.start,
                      ).toMinutes(),
                    durationMinutes: params.duration.toMinutes(),
                    locationRow: location.topRow,
                  });
                  dispatch({
                    type: "SAVE_ENTRY",
                    transactionId: params.transactionId,
                  });
                }}
                deleteEntry={({id, transactionId}) => {
                  dispatch({
                    type: "DELETE_ENTRY",
                    entryId: id,
                    transactionId,
                  });
                }}
              />
              <ItemDetails
                eventSlug={state.eventSlug}
                item={state.item.source}
                allParticipants={
                  Array.from(state.allParticipants.values())
                }
                fields={state.customFields}
                addItemParticipant={
                  (itemId, identityId) => dispatch({
                    type: "ADD_ITEM_PARTICIPANT",
                    itemId,
                    identityId,
                  })
                }
                removeItemParticipant={
                  (itemParticipantId) => dispatch({
                    type: "REMOVE_ITEM_PARTICIPANT",
                    itemParticipantId,
                  })
                }
                showMessage={
                  (message) => dispatch({
                    type: "SHOW_MESSAGE",
                    message,
                  })
                }
              />
            </div>
          );
        },
      ),
    ),
  );


interface SideSheetEditProps {
  itemId: string;
  eventSlug: string;
  showMessage(message: string): void;
  goBackToItem(): void;
}

export const SideSheetEdit =
  connect(
    () => ({}),
    (dispatch) => ({
      showMessage(message: string) {
        dispatch({
          type: "SHOW_MESSAGE",
          message,
        });
      },
    }),
  )(
    namedComponent(
      "SideSheetEdit",
      (props: SideSheetEditProps) => {
        return (
          <Mutation mutation={createPersonMutation}>
            {
              (
                createPersonFn: MutationFunction<createPerson, createPersonVariables>,
              ) => (
                <Mutation mutation={updateScheduleItemMutation}>
                  {
                    (
                      saveItem: MutationFunction<
                        updateScheduleItem,
                        updateScheduleItemVariables
                      >,
                    ) => {
                      const submit =
                        (formValues: ItemFormValues) => {
                        if (
                          formValues.owner
                            && formValues.owner.type === "NEW"
                        ) {
                          const {type, ...personVars} = formValues.owner;
                          createPersonFn({ variables: personVars })
                            .then((personResult) => {
                              if (!personResult || !personResult.data) {
                                props.showMessage(
                                  "Creating the person failed. Item could not be"
                                  + " saved.",
                                );
                                return;
                              }
                              const {owner, ...itemParams} = formValues;
                              return saveItem({
                                variables: {
                                  ...itemParams,
                                  itemId: props.itemId,
                                  owner: personResult.data.createPerson.id,
                                },
                              });
                            })
                            .then(props.goBackToItem)
                            .catch(
                              (error: object) =>
                                props.showMessage(error.toString()),
                            );
                        } else {
                          const {owner, ...itemParams} = formValues;
                          saveItem({
                            variables: {
                              ...itemParams,
                              itemId: props.itemId,
                              owner: owner ? owner.id : null,
                            },
                          })
                          .then(props.goBackToItem)
                          .catch(
                            (error: object) =>
                              props.showMessage(error.toString()),
                          );
                        }
                      };
                      return (
                        <section>
                          <EditItemForm eventSlug={props.eventSlug}
                            submit={submit}
                            itemId={props.itemId}
                          />
                        </section>
                      );
                    }
                  }
                </Mutation>
              )
            }
          </Mutation>
        );
      },
    ),
  );

interface SideSheetNewProps {
  eventSlug: string;
  dispatch(action: ScheduleModelAction): void;
}

export const SideSheetNew =
  connect(
    () => ({}),
    (dispatch) => ({ dispatch }),
  )(
    namedComponent(
      "SideSheetNew",
      (props: SideSheetNewProps) => {
        return (
          <section>
            <NewItemForm eventSlug={props.eventSlug}
              dispatch={props.dispatch}
            />
          </section>
        );
      },
    ),
  );


interface SideSheetProps {
  eventSlug: string;
  items: ReadonlyMap<string, ScheduleItem>;
  itemId: string|null;
  view: "DETAIL"|"EDIT"|"NEW"|null;
  customFields: ReadonlyArray<CustomField>;
  goBack(): void;
  changeView(view: "DETAIL"|"EDIT"|"NEW"|null): void;
}

export const SideSheet: React.FC<SideSheetProps> = React.memo(
  namedComponent(
    "SideSheet",
    (props) => {
      const [isDrawerModal, setIsDrawerModal] = React.useState<boolean>(true);

      const onVisibilityChange = (visible: boolean) => {
        if (!visible) {
          props.changeView(null);
        }
      };
      const item = (
        props.itemId && props.view
          ? props.items.get(props.itemId)
          : null
      );

      const title = item ? item.displayName : "New Item";

      const actions = (() => {
        if (props.view === "EDIT") {
          return (
            <Button icon onClick={() => props.goBack()}>
              arrow_back
            </Button>
          );
        } else if (props.view === "DETAIL") {
          return [
            <Button
              icon
              key="edit"
              onClick={() => props.changeView("EDIT")}
            >
              edit
            </Button>,
            <Button icon onClick={() => props.changeView(null)} key="close">
              close
            </Button>,
          ];
        } else {
          return (
            <Button icon onClick={() => props.changeView(null)}>
              close
            </Button>
          );
        }
      })();
      const contents = (() => {
        if (!props.itemId) {
          if (props.view !== "NEW") {
            return null;
          } else {
            return (
              <SideSheetNew eventSlug={props.eventSlug} />
            );
          }
        }

        switch (props.view) {
          case "DETAIL":
            return (
              <SideSheetDetail
                itemId={props.itemId} />
            );
          case "EDIT":
            return (
              <SideSheetEdit
                itemId={props.itemId}
                eventSlug={props.eventSlug}
                goBackToItem={
                  () => props.changeView("DETAIL")
                }
              />
            );
          default:
            return null;
        }
      })();

      return (
        <Drawer
          visible={!!props.view}
          onVisibilityChange={onVisibilityChange}
          position="right"
          autoclose={false}
          onMediaTypeChange={(drawerType, mediaInfo) => {
            setIsDrawerModal(mediaInfo.mobile);
          }}
          mobileType={Drawer.DrawerTypes.TEMPORARY}
          tabletType={Drawer.DrawerTypes.PERSISTENT}
          desktopType={Drawer.DrawerTypes.PERSISTENT}
          lastChild={true}
          portal={isDrawerModal}
          inline={!isDrawerModal}
          className={props.view ? `${BEM}_side_container` : undefined}
          header={
            !!props.view
              ? (
                <Toolbar
                  className={`${BEM}_side_toolbar`}
                  titleClassName={`${BEM}_side_toolbar_title`}
                  actions={actions}
                  title={title}
                />
                ) : null
          }>
          <div className={`${BEM}_side_scroller`} key={props.view || undefined}>
            {contents}
          </div>
        </Drawer>
      );
    }),
);
SideSheet.displayName = "SideSheet";

