import {
  ApolloClient,
  ApolloQueryResult,
  ObservableQuery,
} from "apollo-client";
import { ExecutionResult, FetchResult, Observable } from "apollo-link";
import {DocumentNode} from "graphql";
import {History} from "history";
import * as React from "react";
import { Provider } from "react-redux";
import {
  Saga,
  SagaMiddleware,
  Task,
} from "redux-saga";

import {
  Schedule,
  ScheduleEntry,
} from "./model";
import {
  ScheduleModelAction,
  ScheduleModelState,
  ScheduleStore,
  createScheduleStore,
} from "./reducer";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const scheduleFetchQuery: DocumentNode = require("./scheduleFetch.graphql");
import {
  scheduleFetch,
  scheduleFetchVariables,
} from "./__generated__/scheduleFetch";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const schedulePollQuery: DocumentNode = require("./schedulePoll.graphql");
import {
  schedulePoll,
  schedulePollVariables,
  schedulePoll_scheduleUpdates_updatedItems as ScheduleItem,
} from "./__generated__/schedulePoll";

export interface ScheduleModelChildrenProps<SagaAction = never> {
  state: ScheduleModelState;
  selectedEntry: ScheduleEntry|null;
  dispatch(action: ScheduleModelAction|SagaAction): void;
}

export interface ScheduleModelProps<SagaAction = never>
{
  eventSlug: string;
  whileLoading: React.ReactElement;
  client: ApolloClient<{}>;
  history: History;
  extraSagas?: Saga[];
  selectedEntryId: string|null;
  children(props: ScheduleModelChildrenProps<SagaAction>): React.ReactElement;
}

interface PollingQuery
{
  query: Observable<FetchResult<ExecutionResult<schedulePoll>>>;
  subscription: ZenObservable.Subscription;
  variables: schedulePollVariables;
}

export class ScheduleModel<SagaAction = never>
extends React.Component<ScheduleModelProps<SagaAction>, {}>
{
  private store: ScheduleStore;
  private dispatch: (action: ScheduleModelAction|SagaAction) => void;

  private fetchQuery
    : ObservableQuery<scheduleFetch, scheduleFetchVariables>|null = null;
  private fetchSubscription: ZenObservable.Subscription|null = null;

  private pollingData: PollingQuery|null = null;

  private sagaTasks: Map<Saga, Task>;
  private sagaMiddleware: SagaMiddleware;

  constructor(props: Readonly<ScheduleModelProps<SagaAction>>) {
    super(props);

    this.updateFromPolling = this.updateFromPolling.bind(this);
    this.setModelFromCache = this.setModelFromCache.bind(this);
    this.retryPolling = this.retryPolling.bind(this);

    const storeResult =
      createScheduleStore(props.client, props.extraSagas || []);
    this.store = storeResult.store;
    this.store.subscribe(this.forceUpdate.bind(this));
    this.dispatch = this.store.dispatch.bind(this.store);
    this.sagaTasks = storeResult.sagas;
    this.sagaMiddleware = storeResult.sagaMiddleware;
  }

  componentWillReceiveProps(nextProps: ScheduleModelProps<SagaAction>) {
    const nextSagas = new Set(nextProps.extraSagas);

    for (const nextSaga of nextSagas) {
      if (!this.sagaTasks.has(nextSaga)) {
        const task = this.sagaMiddleware.run(nextSaga);
        this.sagaTasks.set(nextSaga, task);
      }
    }
    for (const [prevSaga, prevTask] of this.sagaTasks.entries()) {
      if (!nextSagas.has(prevSaga)) {
        prevTask.cancel();
        this.sagaTasks.delete(prevSaga);
      }
    }
  }

  componentDidMount() {
    this.fetchQuery =
      this.props.client.watchQuery<scheduleFetch, scheduleFetchVariables>({
        query: scheduleFetchQuery,
        variables: {eventSlug: this.props.eventSlug},
        fetchPolicy: "cache-and-network",
      });
    this.fetchSubscription =
      this.fetchQuery.subscribe(this.setModelFromCache);
  }

  componentWillUnmount() {
    if (this.fetchSubscription) {
      this.fetchSubscription.unsubscribe();
    }
    if (this.pollingData) {
      this.pollingData.subscription.unsubscribe();
    }
  }

  render() {
    const state = this.store.getState();
    if (!state.model) {
      return this.props.whileLoading;
    }

    const model: Schedule = state.model;
    const data = model.source;
    const version = data.event!.schedule!.version!;
    const eventId = data.event!.id!;

    if (!this.pollingData) {
      const variables = {eventId, version};
      const query = this.props.client
        .subscribe<ExecutionResult<schedulePoll>, schedulePollVariables>({
          query: schedulePollQuery,
          variables,
          fetchPolicy: "network-only",
        });
      const subscription =
        query.subscribe(this.updateFromPolling);
      this.pollingData = {
        variables,
        query,
        subscription,
      };
    }

    return (
      <Provider store={this.store}>
        {
          this.props.children({
            state,
            dispatch: this.dispatch,
            selectedEntry: (
              this.props.selectedEntryId
                && model.entries.get(this.props.selectedEntryId)
                || null
            ),
          })
        }
      </Provider>
    );
  }

  retryPolling() {
    if (!this.pollingData) {
      return;
    }
    const eventId = this.pollingData.variables.eventId;
    const version = this.pollingData.variables.version;
    setTimeout(
      () => {
        const variables = {
          eventId,
          version,
        };
        const query = this.props
          .client
          .subscribe<ExecutionResult<schedulePoll>, schedulePollVariables>({
            query: schedulePollQuery,
            variables,
            fetchPolicy: "network-only",
          });
        const subscription = query.subscribe(this.updateFromPolling);
        this.pollingData = {
          query,
          subscription,
          variables,
        };
      },
      Math.floor(Math.random() * 1000),
    );
  }

  updateFromPolling(pollResult: FetchResult<ExecutionResult<schedulePoll>>) {
    const pollData = pollResult.data ? pollResult.data.data : null;
    if (this.fetchQuery && pollData) {
      this.fetchQuery.updateQuery((prev) => {
        if (!(
            prev
            && prev.event
            && prev.event.schedule
            && prev.event.schedule.items
        )) {
          return prev;
        }

        // Update all existing old items
        const newItems = (() => {
          // Only do the work if there's been an update in the items
          if (pollData.scheduleUpdates.updatedItems.length > 0) {
            // Collect the updated items by ID
            const updatedItemPairs = (
              pollData.scheduleUpdates
                .updatedItems
                .map((item): [string, ScheduleItem] => [item.id, item])
            );
            const updatedItems = new Map(updatedItemPairs);
            // Make an array of existing items, replacing updated ones
            const result = prev.event.schedule.items.map((oldItem) => {
              const newItem = updatedItems.get(oldItem.id);
              if (newItem) {
                updatedItems.delete(oldItem.id);
                return newItem;
              }
              return oldItem;
            });
            // Add items that weren't replaced to the end.
            result.push(...updatedItems.values());
            result.sort(
              (item1, item2) =>
              item1.id < item2.id ? -1 : item1.id === item2.id ? 0 : 1,
            );
            return result;
          } else {
            return prev.event.schedule.items;
          }
        })();

        return {
          event: {
            ...prev.event,
            schedule: {
              ...pollData.scheduleUpdates.schedule,
              items: newItems,
            },
          },
        };
      });
    }
  }

  setModelFromCache(result: ApolloQueryResult<scheduleFetch>): void {
    if (result.data) {
      this.dispatch({
        type: "UPDATE_MODEL",
        data: result.data,
        eventSlug: this.props.eventSlug,
      });
    }
  }
}

