import {DocumentNode} from "graphql";
import {Duration} from "js-joda";
import * as React from "react";
import { Query } from "react-apollo";
import {CircularProgress} from "react-md";

import {extractCustomFieldValue} from "./format";

import {
  FieldInstanceInput,
  FieldValueInput,
} from "./../../__generated__/globalTypes";

import {
  ItemForm,
  ItemFormValues,
  filterFields,
} from "./ItemForm";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const fullScheduleItemQuery: DocumentNode = require("./fullScheduleItem.graphql");
import {
  fullScheduleItem,
  fullScheduleItemVariables,
} from "./__generated__/fullScheduleItem";

interface EditItemFormProps {
  eventSlug: string;
  itemId: string;
  submit(formValues: ItemFormValues): void;
}

export const EditItemForm: React.FC<EditItemFormProps> = (props) => {
  const [formValues, setFormValues] =
    React.useState<ItemFormValues|null>(null);
  const onCompleted = React.useCallback(
    (data: fullScheduleItem) => {
      if (
        !data.event
          || !data.event.schedule
          || !data.event.schedule.item
      ) {
        return;
      }

      const item = data.event.schedule.item;
      const customFields: FieldInstanceInput[] = [];

      for (const field of data.event.schedule.itemForm.fields) {
        let value: FieldValueInput|null = null;
        switch (field.__typename) {
          case "TextField":
            case "SelectOneField":
              const stringValue = extractCustomFieldValue(
                item.customFields,
                field.name,
                "stringValue",
              );
              if (stringValue !== undefined) {
                value = { stringValue };
              }
              break;
          case "BooleanField":
            const booleanValue = extractCustomFieldValue(
              item.customFields,
              field.name,
              "booleanValue",
            );
            if (booleanValue !== undefined) {
              value = { booleanValue };
            } else if (field.booleanDefault !== null) {
              value = { booleanValue: field.booleanDefault };
            } else if (field.required && field.adminWritable) {
              // Default to unchecked if it's required and writable.
              value = { booleanValue: false };
            }
            break;
        }

        customFields.push({
          name: field.name,
          value,
        });
      }

      setFormValues({
        event: data.event.id,
        version: data.event.schedule.version,
        displayName: item.displayName,
        status: item.status,
        owner:
          item.ownerParticipant
            ? { type: "EXISTING", id: item.ownerParticipant.identityId }
            : null
        ,
        orgName: item.orgName,
        description: item.description,
        requestedCount: item.requestedCount,
        requestedDuration:
          typeof item.requestedDuration === "string"
            ? Duration.parse(item.requestedDuration)
            : null
        ,
        rating: item.rating,
        customFields,
      });
    },
    [],
  );


  return (<Query<fullScheduleItem, fullScheduleItemVariables>
    query={fullScheduleItemQuery}
    variables={{
      eventSlug: props.eventSlug,
      id: props.itemId,
    }}
    onCompleted={onCompleted}
    fetchPolicy="no-cache">
    {(result) => {
      if (result.error) {
        return <>Error: {result.error}</>;
      }
      if (
        result.loading
          || !formValues
          || !result.data
          || !result.data.event
          || !result.data.event.schedule
      ) {
        return <CircularProgress id="schedule-grid-progress" />;
      }

      const customFields = result.data.event.schedule.itemForm.fields;
      const onSubmit =
        (formValuesPatch: Partial<ItemFormValues>) => {
          const newFormValues = {
            ...formValues,
            ...formValuesPatch,
          };
          if (!newFormValues.customFields) {
            props.submit(newFormValues);
            return;
          }

          props.submit({
            ...newFormValues,
            customFields:
              filterFields(customFields, newFormValues.customFields)
            ,
          });
        };

      return (
        <ItemForm
          submitLabel="Save Item"
          setFormValues={setFormValues}
          formValues={formValues}
          participants={
            result.data.event.schedule.participants
              .map((part) => ({
                id: part.identityId,
                displayName: part.displayName,
              }))
          }
          onSubmit={onSubmit}
          customFields={result.data.event.schedule.itemForm.fields}
        />
      );
    }}
  </Query>
  );
};

