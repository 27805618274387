import * as React from "react";
import {
  Card,
  CardText,
  FontIcon,
  GridList,
  List,
  ListItem,
} from "react-md";

import {formatDateTime} from "../format";
import {
  Schedule,
  ScheduleEntry,
} from "../model";
import {
  ScheduleModelAction,
} from "../model/reducer";

import {
  BEM,
} from "./constants";
import {
  getResourceName,
} from "./util";

interface ShortEntryListItemProps {
  id: string;
  label: string;
  selected: boolean;
  dispatch(action: ScheduleModelAction): void;
}

const ShortEntryListItem: React.FC<ShortEntryListItemProps> = (
  {id, label, selected, dispatch},
) => (
  <ListItem
    primaryText={label}
    rightIcon={<FontIcon>launch</FontIcon>}
    style={{
      background: selected ? "rgba(0, 0, 0, 0.15)" : undefined,
    }}
    onClick={() => dispatch({
      type: "SELECT_ENTRY",
      entryId: id,
      scrollToEntry: true,
    })}
  />
);

interface ItemDrawerConflictListProps {
  model: Schedule;
  selectedEntry: ScheduleEntry|null;
  dispatch(action: ScheduleModelAction): void;
}

export const ItemDrawerConflictList: React.FC<ItemDrawerConflictListProps> =
  (props) => {
    const conflictElements = (
      props.model.conflicts.length === 0
        ? (<div className="md-grid">No conflicts detected</div>)
        : (
          <GridList
            desktopSize={12}
            tabletSize={4}
            phoneSize={4}
          >
            {
              props.model.conflicts.map(
                (conflict) => {
                  const name = getResourceName(conflict.resource);

                  const entries =
                    conflict.entryIds.map(
                      (id) => props.model.entries.get(id),
                    )
                      .filter((maybeEntry): maybeEntry is ScheduleEntry => !!maybeEntry);

                  const conflictStart = formatDateTime(
                    conflict.start,
                    props.model.source.event.timeZone,
                  );
                  const conflictEnd = formatDateTime(
                    conflict.start.plusTemporalAmount(conflict.duration),
                    props.model.source.event.timeZone,
                  );

                  if (entries.length === 0) {
                    return null;
                  }

                  return (
                    <Card
                      key={
                        `${conflict.resource.type}:${conflict.resource.id}`
                        + `:${conflict.entryIds[0]}`
                      }
                    >
                      <CardText>
                        <p>
                          {name}
                          {
                            conflict.resource.type === "LOCATION"
                              ? ` (limit ${conflict.resource.limit})`
                              : null
                          }
                          <br />
                          From {conflictStart} to {conflictEnd}
                        </p>
                          <List>
                            {
                              conflict.resource.type !== "LOCATION"
                                ? (
                                  conflict.entries.map(
                                    (entry) => (
                                      <ShortEntryListItem
                                        key={entry.id}
                                        id={entry.id}
                                        label={entry.item.displayName}
                                        selected={
                                          props.selectedEntry
                                            ? props.selectedEntry.id === entry.id
                                            : false
                                        }
                                        dispatch={props.dispatch}
                                      />
                                    ),
                                  )
                                ) : (
                                  <ShortEntryListItem
                                    id={conflict.entries[0].id}
                                    label={
                                      "Starts with: "
                                      + conflict.entries[0].item.displayName
                                    }
                                    selected={
                                      props.selectedEntry !== null
                                      && conflict.entries.find((entry) =>
                                        entry.id === props.selectedEntry!.id,
                                      ) !== undefined
                                    }
                                    dispatch={props.dispatch}
                                  />
                                )
                            }
                          </List>
                      </CardText>
                    </Card>
                  );
                },
              )
            }
          </GridList>
        )
    );
    return (
      <section className={
          "md-toolbar-relative dialogs__content"
          + " md-list--drawer"
          + ` ${BEM}_drawer`
      }>
      {conflictElements}
    </section>
    );
  };
