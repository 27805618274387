export const BEM = "rw-schedule-grid";

export const ROW_HEIGHT = 40;

export const PIXELS_PER_MINUTE = 3; // must be synced with $pixelWidth in the SCSS

export const LIST_ITEM_PATH = "/event/:eventSlug/schedule/grid/items";
export const NEW_ITEM_PATH = "/event/:eventSlug/schedule/grid/items/new";
export const ITEM_DETAIL_PATH = "/event/:eventSlug/schedule/grid/items/:itemId";
export const ITEM_EDIT_PATH = "/event/:eventSlug/schedule/grid/items/:itemId/edit";
export const CONFLICT_PATH = "/event/:eventSlug/schedule/grid/conflicts";

