import * as React from "react";

import {InlineBadge} from "../../layout/InlineBadge";
import {formatStatus} from "../format";
import {BEM} from "./constants";

interface EntryTileProps {
  displayName: string;
  rating: string;
  status: string;
  emphasis: boolean;
  color: string;
  state?: "DELETING" | "SELECTED" | "LIST_ITEM" | "RESIZING" | "CONFLICT" | "MOVING";
}

export const EntryTile: React.FC<EntryTileProps> = (props) => {
  const stateClass = (
    props.state === "MOVING" ? ` ${BEM}_entry_paper_moving`
      : props.state === "DELETING" ? ` ${BEM}_entry_paper_deleting`
      : props.state === "SELECTED" ? ` ${BEM}_entry_paper_selected`
      : props.state === "LIST_ITEM" ? ` ${BEM}_entry_paper_list_item`
      : props.state === "RESIZING" ? ` ${BEM}_entry_paper_resize_mode`
      : props.state === "CONFLICT" ? ` ${BEM}_entry_paper_conflict`
      : ""
  );
  return (
    <div className={`${BEM}_entry_paper` + stateClass}
      style={{borderBottomColor: props.color}}>
      <div className={`${BEM}_entry_title`}>
        {
          props.emphasis
            ? <em>{props.displayName}</em>
            : props.displayName
        }
      </div>
      {
        props.rating !== "G"
          ? <InlineBadge className={`${BEM}_entry_badge`}>
            {props.rating}
          </InlineBadge>
          : null
      }
      <InlineBadge
        className={`${BEM}_entry_badge`}
        title={formatStatus(props.status) || ""}
      >
        {props.status.substring(0, 3)}
      </InlineBadge>
    </div>
  );
};

