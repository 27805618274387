import { useMutation } from "@apollo/react-hooks";
import { DocumentNode } from "graphql";
import * as React from "react";
import { Button, FontIcon } from "react-md";

import { CompoundViolationError } from "../../../net/CompoundViolationError";
import { CustomFormId } from "../../../__generated__/globalTypes";
import { FieldDialog } from "./FieldDialog";
import { FieldFormData } from "./FieldFormData";
import { handleMutationError } from "./util";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const createBooleanFieldMutation: DocumentNode = require("./createBooleanField.graphql");
import {
  createBooleanField,
  createBooleanFieldVariables,
} from "./__generated__/createBooleanField";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const createSelectOneFieldMutation: DocumentNode = require("./createSelectOneField.graphql");
import {
  createSelectOneField,
  createSelectOneFieldVariables,
} from "./__generated__/createSelectOneField";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const createTextFieldMutation: DocumentNode = require("./createTextField.graphql");
import {
  createTextField,
  createTextFieldVariables,
} from "./__generated__/createTextField";

const BEM = "rw-schedule-config";

const useCreateField = (
  fieldMetadata: {
    eventId: string,
    customFormId: CustomFormId,
    version: number,
  },
) => {
  const [createBooleanFieldMutate] =
    useMutation<createBooleanField, createBooleanFieldVariables>(
      createBooleanFieldMutation,
    );
  const [createSelectOneFieldMutate] =
    useMutation<createSelectOneField, createSelectOneFieldVariables>(
      createSelectOneFieldMutation,
    );
  const [createTextFieldMutate] =
    useMutation<createTextField, createTextFieldVariables>(
      createTextFieldMutation,
    );
  return async (data: FieldFormData) => {
    try {
      if (data.type === "SelectOneField") {
        return handleMutationError(
          await createSelectOneFieldMutate({
            variables: {
              ...data,
              ...fieldMetadata,
              options: data.options.slice(),
            },
          }),
          "createScheduleSelectOneField",
        )
          .selectOneField;
      } else if (data.type === "BooleanField") {
        return handleMutationError(
          await createBooleanFieldMutate({
            variables: {
              ...data,
              ...fieldMetadata,
            },
          }),
          "createScheduleBooleanField",
        )
          .booleanField;
      } else {
        return handleMutationError(
          await createTextFieldMutate({
            variables: {
              ...data,
              ...fieldMetadata,
            },
          }),
          "createScheduleTextField",
        )
          .textField;
      }
    } catch (error) {
      const regWorksError = CompoundViolationError.fromError(error);
      if (regWorksError) {
        throw regWorksError;
      } else {
        throw error;
      }
    }
  };
};

interface NewFieldProps {
  eventId: string;
  maxOrder: number;
  customFormId: CustomFormId;
  version: number;
  setError(error: string|null): void;
}

export const NewField: React.FC<NewFieldProps> = (props) => {
  const [visible, setVisible] = React.useState(false);
  const createField = useCreateField({
    eventId: props.eventId,
    customFormId: props.customFormId,
    version: props.version,
  });

  const showDialog = () => setVisible(true);
  const hideDialog = () => setVisible(false);

  return (
    <>
      <div className="rw-right-container button-group">
        <Button
          iconChildren={<FontIcon>add</FontIcon>}
          onClick={showDialog}
          primaryText="Create field"
          className={`${BEM}_button rw-right`}
          raised
        >
          Create field
        </Button>
      </div>
      {
        visible ? (
          <FieldDialog
            id="new-dialog"
            initialValue={{
              type: "BooleanField" as const,
              // Names are generated and we want them to be unique. Since
              // version number increments with every added field, this works.
              // Also, it's idempotent.
              name: `--${props.version}`,
              required: false,
              adminWritable: true,
              default: null,
              help: "",
              label: "",
              order: props.maxOrder + 1000,
              public: false,
              applicationWritable: false,
            }}
            newField={true}
            mutate={createField}
            afterMutate={hideDialog}
            onHide={hideDialog}
            setError={props.setError}
            version={props.version}
          />
        ) : null
      }
    </>
  );
};
