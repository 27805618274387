import * as React from "react";

import {namedComponent} from "../../../namedComponent";
import {ScheduleLocation} from "../model";

import {
  BEM,
  ROW_HEIGHT,
} from "./constants";

interface GutterLeftProps {
  version: number;
  locations: ScheduleLocation[];
}

export const GutterLeft: React.FC<GutterLeftProps> = React.memo(
  namedComponent("GutterLeft", (props) => {
    const labels = Array.from(props.locations, (location) => {
      return (
        <div key={location.id}
          className={`${BEM}_header ${BEM}_header--row`}
          style={{
            height: `${location.rowHeight * ROW_HEIGHT}px`,
            top: `${location.topRow * ROW_HEIGHT}px`,
          }}>
          {location.name}
        </div>
      );
    });

    return (
      <React.Fragment>
        {...labels}
      </React.Fragment>
    );
  }),
  (prevProps, newProps) => prevProps.version === newProps.version,
);

