import * as React from "react";

/**
 * Adds a name to a component inline.
 */
export function namedComponent<
  T extends React.FC<any> | React.ComponentClass<any, any>
>(
  name: string,
  component: T,
): T {
  component.displayName = name;
  return component;
}

