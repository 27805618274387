import {DocumentNode} from "graphql";
import * as React from "react";
import { Query } from "react-apollo";
import {CircularProgress} from "react-md";

import {ScheduleModelAction} from "./model/reducer";

import {
  FieldInstanceInput,
  FieldValueInput,
} from "../../__generated__/globalTypes";

import {
  ItemForm,
  ItemFormValues,
  filterFields,
} from "./ItemForm";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const getItemFormQuery: DocumentNode = require("./getItemForm.graphql");
import {
  getItemForm, getItemFormVariables,
} from "./__generated__/getItemForm";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const createPersonMutation: DocumentNode = require("./createPerson.graphql");
import {
  createPerson,
  createPersonVariables,
} from "./__generated__/createPerson";

interface NewItemFormProps {
  eventSlug: string;
  dispatch(action: ScheduleModelAction): void;
}

export const NewItemForm: React.FC<NewItemFormProps> = (props) => {
  const [formValues, setFormValues] =
    React.useState<ItemFormValues|null>(null);

  return (<Query<getItemForm, getItemFormVariables>
    query={getItemFormQuery}
    variables={{ eventSlug: props.eventSlug }}
    fetchPolicy="network-only"
    onCompleted={
      (data: getItemForm) => {
        if (
          !data.event
          || !data.event.schedule
        ) {
          return;
        }

        const customFields: FieldInstanceInput[] = [];

        for (const field of data.event.schedule.itemForm.fields) {
          let value: FieldValueInput|null = null;
          switch (field.__typename) {
            case "TextField":
              if (field.textDefault) {
                value = { stringValue: field.textDefault };
              }
              break;
            case "SelectOneField":
              if (field.selectOneDefault) {
                value = { stringValue: field.selectOneDefault };
              }
              break;
            case "BooleanField":
              if (field.booleanDefault !== null) {
                value = { booleanValue: field.booleanDefault };
              } else if (field.required && field.adminWritable) {
                // Default to unchecked if it's required and writable.
                value = { booleanValue: false };
              }
              break;
          }

          customFields.push({
            name: field.name,
            value,
          });
        }

        setFormValues({
          event: data.event.id,
          version: data.event.schedule.version,
          displayName: "",
          orgName: null,
          status: "DRAFT",
          description: null,
          requestedCount: null,
          requestedDuration: null,
          rating: "G",
          owner: null,
          customFields,
        });
      }
    }>
    {(result) => {
      if (result.error) {
        return <>Error: {result.error}</>;
      }
      if (
        result.loading
          || !formValues
          || !result.data
          || !result.data.event
          || !result.data.event.schedule
      ) {
        return <CircularProgress id="schedule-grid-progress" />;
      }

      const customFields = result.data.event.schedule.itemForm.fields;
      const onSubmit = ((formValuesPatch: Partial<ItemFormValues>) => {
        const {owner, ...params} = {
          ...formValues,
          ...formValuesPatch,
        };

        if (owner && owner.type === "NEW") {
          const {type, ...personParams} = owner;
          result.client.mutate<createPerson, createPersonVariables>({
            mutation: createPersonMutation,
            variables: personParams,
          })
            .then((personResult) => {
              if (!personResult || !personResult.data) {
                props.dispatch({
                  type: "SHOW_MESSAGE",
                  message:
                    "Creating the person failed. Item could not be saved.",
                });
                return;
              }

              props.dispatch({
                type: "CREATE_SCHEDULE_ITEM",
                owner: personResult.data.createPerson.id,
                ...params,
                customFields:
                  filterFields(customFields, formValues.customFields),
              });
            });
        } else {
          props.dispatch({
            type: "CREATE_SCHEDULE_ITEM",
            owner: owner ? owner.id : null,
            ...params,
            customFields:
              filterFields(customFields, formValues.customFields),
          });
        }
      });

      return (
        <ItemForm
          submitLabel="Add Item"
          setFormValues={setFormValues}
          formValues={formValues}
          participants={
            result.data.event.schedule.participants
              .map((part) => ({
                id: part.identityId,
                displayName: part.displayName,
              }))
          }
          onSubmit={onSubmit}
          customFields={result.data.event.schedule.itemForm.fields}
        />
      );
    }}
  </Query>
  );
};

