import * as React from "react";

import { ScheduleItem } from "../model";
import { ScheduleModelAction } from "../model/reducer";

import { BEM, ROW_HEIGHT } from "./constants";
import { EntryTile } from "./EntryTile";
import { useDragHandlers } from "./useDragHandlers";

interface GridEntryTileProps {
  x: number;
  y: number;
  width: number;
  item: ScheduleItem;
  entryId: string;
  state?: "DELETING" | "MOVING" | "SELECTED" | "CONFLICT";
  eventSlug: string;
  dispatch(action: ScheduleModelAction): void;
}

export const GridEntryTile: React.FC<GridEntryTileProps> = (
  (props) => {
    const onDrag = () => props.dispatch({
      type: "START_MOVING_ENTRY",
      entryId: props.entryId,
      mode: "FULL",
    });
    const dragHandlers = useDragHandlers({onDrag, handleTouch: false});

    const color = props.item.color;
    const href = `#entryId=${props.entryId}`;
    const onClick: React.MouseEventHandler<HTMLElement> = (e) => {
      if (
        e.ctrlKey
        || e.shiftKey
        || e.altKey
        || e.metaKey
      ) {
        return;
      }
      props.dispatch({
        type: "SELECT_ENTRY",
        entryId: props.entryId,
        scrollToEntry: false,
      });
      e.currentTarget.focus();
      e.preventDefault();
      e.stopPropagation();
    };
    const onDoubleClick = (e: React.MouseEvent<HTMLElement>) => {
      props.dispatch({
        type: "SELECT_ENTRY",
        entryId: props.entryId,
        scrollToEntry: false,
      });
      props.dispatch({
        type: "SELECT_ITEM",
        itemId: props.item.id,
      });
      e.currentTarget.focus();
      e.preventDefault();
      e.stopPropagation();
    };
    const onKeyUp = (e: React.KeyboardEvent) => {
      if (
        e.key === "Delete"
        && !e.ctrlKey
        && !e.shiftKey
        && !e.altKey
        && !e.metaKey
        && props.state === "SELECTED"
      ) {
        props.dispatch({
          type: "DELETE_ENTRY",
          entryId: props.entryId,
          transactionId: Math.random(),
        });
      }
    };
    const onMouseDownLeftHandle = () =>
      props.dispatch({
        type: "START_MOVING_ENTRY",
        entryId: props.entryId,
        mode: "START",
      });

    const onMouseDownRightHandle = () =>
      props.dispatch({
        type: "START_MOVING_ENTRY",
        entryId: props.entryId,
        mode: "END",
      });
    return (
      <div
        style={{
          position: "absolute",
          height: ROW_HEIGHT,
          transform: `translate(${props.x}px, ${props.y}px)`,
          width: `${props.width}px`,
        }}
      >
        <div
          className={`${BEM}_entry`}
          title={props.item.displayName}
          style={{
            width: `${props.width}px`,
          }}
        >
          <a
            style={{
              width: `${props.width}px`,
              height: ROW_HEIGHT,
              position: "absolute",
            }}
            href={href}
            draggable={false}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
            onKeyUp={onKeyUp}
            {...dragHandlers}
          >
            <EntryTile
              displayName={props.item.displayName}
              state={props.state}
              color={color}
              status={props.item.status}
              rating={props.item.source.rating}
              emphasis={props.item.emphasis}
            />
          </a>
          <div className={`${BEM}_entry_handle ${BEM}_entry_handle_left`}
            onMouseDown={onMouseDownLeftHandle}
          />
          <div className={`${BEM}_entry_handle ${BEM}_entry_handle_right`}
            onMouseDown={onMouseDownRightHandle}
          />
        </div>
      </div>
    );
  }
);

