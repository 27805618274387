import { ExecutionResult } from "graphql";

export class FieldMutationError extends Error {}

export const handleMutationError = <TData extends object, FieldName extends keyof TData>(
  result: ExecutionResult<TData>,
  mutationName: FieldName,
): TData[FieldName] => {
  if (!result.data) {
    throw new FieldMutationError("Error saving: There was no response.");
  } else {
    return result.data[mutationName];
  }
};

export const slugify = (str: string) => {
  return str
    // remove diacritics
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    // spaces to dashes
    .replace(/\s+/g, "-")
    // ampersand to the word "and"
    .replace(/&/g, "-and-")
    // remove non-alphanumerics
    .replace(/[^\w\-]+/g, "")
    // collapse multiple dashes
    .replace(/\-\-+/g, "-")
    // trim starting dashes
    .replace(/^-+/, "")
    // eliminate punycode
    .replace(/^xn/, "x-n");
};
