/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * A machine readable tag denoting a purpose for a Contact
 */
export enum ContactPurpose {
  SECURITY = "SECURITY",
}

/**
 * An identifier for a custom form
 */
export enum CustomFormId {
  ScheduleItem = "ScheduleItem",
  ScheduleParticipant = "ScheduleParticipant",
}

/**
 * The right to perform one or more actions.
 */
export enum Permission {
  ContactCreate = "ContactCreate",
  ContactDelete = "ContactDelete",
  ContactUpdate = "ContactUpdate",
  EventCreate = "EventCreate",
  EventLocationCreate = "EventLocationCreate",
  EventLocationDelete = "EventLocationDelete",
  EventLocationUpdate = "EventLocationUpdate",
  EventUpdate = "EventUpdate",
  IdentityProviderReadExternalIds = "IdentityProviderReadExternalIds",
  OrganizationCreate = "OrganizationCreate",
  OrganizationInviteCancel = "OrganizationInviteCancel",
  OrganizationInviteCreate = "OrganizationInviteCreate",
  OrganizationSetSecurityEmail = "OrganizationSetSecurityEmail",
  OrganizationUpdate = "OrganizationUpdate",
  OrganizationViewContacts = "OrganizationViewContacts",
  PersonCreateOwned = "PersonCreateOwned",
  PersonCreateUnowned = "PersonCreateUnowned",
  PersonRead = "PersonRead",
  PersonSetSecurityEmail = "PersonSetSecurityEmail",
  PersonUpdate = "PersonUpdate",
  PersonUpdateOwner = "PersonUpdateOwner",
  ScheduleCreate = "ScheduleCreate",
  ScheduleCustomFieldCreate = "ScheduleCustomFieldCreate",
  ScheduleCustomFieldDelete = "ScheduleCustomFieldDelete",
  ScheduleCustomFieldUpdate = "ScheduleCustomFieldUpdate",
  ScheduleCustomFieldValueRead = "ScheduleCustomFieldValueRead",
  ScheduleCustomFieldValueWrite = "ScheduleCustomFieldValueWrite",
  ScheduleDraftView = "ScheduleDraftView",
  ScheduleEntryCreate = "ScheduleEntryCreate",
  ScheduleEntryDelete = "ScheduleEntryDelete",
  ScheduleEntryUpdate = "ScheduleEntryUpdate",
  ScheduleItemCreate = "ScheduleItemCreate",
  ScheduleItemParticipantCreate = "ScheduleItemParticipantCreate",
  ScheduleItemParticipantDelete = "ScheduleItemParticipantDelete",
  ScheduleItemParticipantInvite = "ScheduleItemParticipantInvite",
  ScheduleItemParticipantUpdate = "ScheduleItemParticipantUpdate",
  ScheduleItemRead = "ScheduleItemRead",
  ScheduleItemSubmit = "ScheduleItemSubmit",
  ScheduleItemUpdate = "ScheduleItemUpdate",
  ScheduleItemUpdateOverrideSubmissionRequired = "ScheduleItemUpdateOverrideSubmissionRequired",
  ScheduleItemUpdateOwner = "ScheduleItemUpdateOwner",
  ScheduleItemUpdateStatus = "ScheduleItemUpdateStatus",
  ScheduleParticipantRead = "ScheduleParticipantRead",
  ScheduleParticipantWrite = "ScheduleParticipantWrite",
  SchedulePublish = "SchedulePublish",
  ScheduleUpdate = "ScheduleUpdate",
  TeamAddRole = "TeamAddRole",
  TeamCreate = "TeamCreate",
  TeamRemove = "TeamRemove",
  TeamUpdate = "TeamUpdate",
  UserCreate = "UserCreate",
  UserRead = "UserRead",
  UserUpdate = "UserUpdate",
}

/**
 * Specifies a custom field by the form it is in and the name of the
 * field.
 */
export interface FieldIdInput {
  formId: CustomFormId;
  name: string;
}

/**
 * A container object, for specifying all that is needed to set a field
 * instance. While the schema admits several values for `value`, the
 * actual allowed values are `null`, `{ stringValue: string }`, and
 * `{ booleanValue: boolean }`.
 */
export interface FieldInstanceInput {
  name: string;
  value?: FieldValueInput | null;
}

/**
 * A polymorphic value wrapper for inputting values that can assume multiple types.
 */
export interface FieldValueInput {
  stringValue?: string | null;
  booleanValue?: boolean | null;
}

/**
 * A single option for a multiple choice selection field.
 */
export interface SelectOptionInput {
  name: string;
  label: string;
  color?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
