import {DocumentNode} from "graphql";
import {Duration} from "js-joda";
import * as React from "react";
import {
  Mutation,
  MutationFunction,
} from "react-apollo";
import {
  FontIcon,
  List,
  ListItem,
} from "react-md";
import {Link} from "react-router-dom";

import {
  ScheduleParticipant,
} from "./model";
import {
  scheduleGetEverything_event_schedule_itemForm_fields as CustomField,
  scheduleGetEverything_event_schedule_items as ScheduleItem,
} from "./__generated__/scheduleGetEverything";

import {
  formatDuration,
  nl2br,
  statusList,
} from "./format";
import {ItemParticipantList} from "./ItemParticipantList";
import {ItemTransitions} from "./ItemTransitions";
import {SetNewParticipantParams} from "./ParticipantField";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const createPersonMutation: DocumentNode = require("./createPerson.graphql");
import {
  createPerson,
  createPersonVariables,
} from "./__generated__/createPerson";

interface ItemDetailsViewProps {
  eventSlug: string;
  fields: ReadonlyArray<CustomField>;
  item: ScheduleItem;
  allParticipants: ReadonlyArray<ScheduleParticipant>;
  createParticipantForItem(
    params: SetNewParticipantParams & { itemId: string },
  ): void;
  addItemParticipant(itemId: string, identityId: string): void;
  removeItemParticipant(itemParticipantId: string): void;
}

const ItemDetailsView: React.FC<ItemDetailsViewProps> = (
  (props) => {
    const status =
      statusList.find(
        (statusEntry) => statusEntry.value === props.item.status,
    );
    const formattedDuration = (
      typeof props.item.requestedDuration === "string"
        ? formatDuration(Duration.parse(props.item.requestedDuration))
        : null
    );

    return (
      <dl>
        <dt>Title</dt>
        <dd>
          {props.item.displayName}
        </dd>
        <dt>Status</dt>
        <dd>
          {status ? status.label : props.item.status}
          <ItemTransitions
            itemId={props.item.id}
            currentStatus={props.item.status}
            version={props.item.version}
          />
        </dd>
        <dt>Description</dt>
        <dd>
          {nl2br(props.item.description, "None")}
        </dd>
        <dt>Presented by</dt>
        <dd>
          { props.item.orgName ? props.item.orgName : <em>None</em> }
        </dd>
        <dt>
          Owner
        </dt>
        <dd>
          {
            props.item.ownerParticipant
              ? (
                <List>
                  <ListItem
                    component={Link}
                    flat
                    to={
                      `/event/${props.eventSlug}/schedule/participants/${props.item.ownerParticipant.identityId}`
                    }
                    primaryText={props.item.ownerParticipant.displayName}
                    rightIcon={<FontIcon>launch</FontIcon>}
                  />
                </List>
              ) : <em>None</em>
          }
        </dd>
        <dt>
          Rating
        </dt>
        <dd>
          {props.item.rating}
        </dd>
        <dt>
          Requested Count
        </dt>
        <dd>
          {
            props.item.requestedCount || props.item.requestedCount === 0
              ? props.item.requestedCount
              : <em>None</em>
          }
        </dd>
        <dt>
          Requested Duration
        </dt>
        <dd>
          {
            formattedDuration ? formattedDuration : <em>None</em>
          }
        </dd>
        {
          props.fields.map((field) => {
            const fieldValue = props.item.customFields.find(
              (f) => f.name === field.name,
            );

            const value =
              nl2br(fieldValue && fieldValue.displayValue, "None");

            return (
              <React.Fragment key={field.name}>
                <dt>{field.label}</dt>
                <dd>{value}</dd>
              </React.Fragment>
            );
          })
        }
        <dt>
          Participants
        </dt>
        <dd>
          <ItemParticipantList
            eventSlug={props.eventSlug}
            item={props.item}
            allParticipants={props.allParticipants}
            addItemParticipant={props.addItemParticipant}
            removeItemParticipant={props.removeItemParticipant}
            createParticipantForItem={
              (params) => {
                props.createParticipantForItem({
                  ...params,
                  itemId: props.item.id,
                });
              }
            }
          />
        </dd>
      </dl>
    );
  }
);

interface ItemDetailsProps {
  eventSlug: string;
  fields: ReadonlyArray<CustomField>;
  item: ScheduleItem;
  allParticipants: ReadonlyArray<ScheduleParticipant>;
  addItemParticipant(itemId: string, identityId: string): void;
  removeItemParticipant(itemParticipantId: string): void;
  showMessage(message: string): void;
}

export const ItemDetails: React.FC<ItemDetailsProps> = (props) => {
  return (
    <Mutation mutation={createPersonMutation}>
      {
        (createPersonFn: MutationFunction<createPerson, createPersonVariables>) => {
          const createParticipantForItem =
            (params: SetNewParticipantParams & { itemId: string }) => {
              createPersonFn({ variables: params })
              .then((personResult) => {
                if (!personResult || !personResult.data) {
                  props.showMessage(
                    "Creating the person failed. Item could not be saved.",
                  );
                  return;
                }
                const id: string = personResult.data.createPerson.id;

                props.addItemParticipant(
                  params.itemId,
                  id,
                );
              });
            };

          return (
            <ItemDetailsView
              eventSlug={props.eventSlug}
              createParticipantForItem={createParticipantForItem}
              {...props}
            />
          );
        }
      }
    </Mutation>
  );
};

