import {DocumentNode} from "graphql";
import * as React from "react";
import { Query } from "react-apollo";

import {CircularProgress} from "react-md";

import { Auth, STATE_NOT_FOUND_ERROR } from "../net/auth";

import {Center} from "./layout/Center";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const eventAuth: DocumentNode = require("./eventAuth.graphql");
import {
  eventAuth,
  eventAuthVariables,
} from "./__generated__/eventAuth";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const inviteAuth: DocumentNode = require("./inviteAuth.graphql");
import {
  inviteAuth,
  inviteAuthVariables,
} from "./__generated__/inviteAuth";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const account: DocumentNode = require("./account.graphql");
import { selfForRoot } from "./__generated__/selfForRoot";

import {Permission} from "../__generated__/globalTypes";

type IdentityProviderSource =
  { type: "event", eventSlug: string }
    | { type: "invite", inviteId: string }
    | null;

interface AuthRedirectProps
{
  idpSource: IdentityProviderSource;
  auth: Auth;
  render: React.FC<{}>;
}

export const AuthRedirect: React.FC<AuthRedirectProps> = (props) => {
  const redirectToAuth = (idpId?: string, inviteId?: string) => {
    props.auth.createAuthorizationRedirect(
      document.location.href,
      idpId,
      inviteId,
    )
      .then((redirectUrl) => document.location.assign(redirectUrl));
  };

  // If we don't have an IdP source and we're not logged in, log in with the
  // default IdP.
  React.useEffect(() => {
    if (window.location.pathname === "/auth") {
      const matches = /code=([^&#=]*)/.exec(window.location.search);
      if (matches) {
        props.auth.fetchToken(matches[1])
          .then((redirectTarget) => {
            window.location.href = redirectTarget;
          })
          .catch((e) => {
            alert(e);
            if (e === STATE_NOT_FOUND_ERROR) {
              alert(
                "Error logging in. Somehow, your browser lost what you were"
                + " trying to do before you logged in. Please retry the link"
                + " that you used to get to Reg.Works Scheduling.",
              );
            } else {
              alert("Error logging in. Please try again.");
            }
          });
      }
    } else if (!props.idpSource && !props.auth.getToken()) {
      redirectToAuth();
    }
  });

  if (!props.idpSource) {
    if (!props.auth.getToken()) {
      return <CircularProgress id="schedule-login-progress" />;
    } else {
      return (
        <Query<selfForRoot, {}>
          query={account}
          onError={
            (error) => {
              if (
                error.networkError
                && (error.networkError as any).response
                && (error.networkError as any).response.status === 401
              ) {
                props.auth.logout();
              }
            }
          }
          fetchPolicy="network-only"
        >
          {
            (result) => {
              if (result.error) {
                return <>Error: {result.error.toString()}</>;
              }
              if (result.loading) {
                return <CircularProgress id="schedule-login-progress" />;
              }

              return props.render({});
            }
          }
        </Query>
      );
    }
  }

  if (props.idpSource.type === "event") {
    return (
      <Query<eventAuth, eventAuthVariables>
        query={eventAuth}
        variables={{ eventSlug: props.idpSource.eventSlug }}
        onCompleted={
          (result) => {
            if (!result.session) {
              // If you aren't logged in
              if (
                result.event
                && result.event.preferredIdentityProvider
                && result.event.preferredIdentityProvider.id
              ) {
                // If there's an IdP to use
                redirectToAuth(result.event.preferredIdentityProvider.id);
              } else {
                // If there's no IdP to use
                redirectToAuth();
              }
            } else if (
              result.event
              && result.event.preferredIdentityProvider
              && !result.session.authentications.find(
                (auth) =>
                auth.credential.identityProvider.id
                === result.event.preferredIdentityProvider!.id,
              )
              && result.event.permissions
              .indexOf(Permission.ScheduleDraftView) === -1
            ) {
              // If we're logged, can't see the schedule, and using the wrong
              // IdP,
              redirectToAuth(result.event.preferredIdentityProvider.id);
            }
          }
        }
        onError={
          (error) => {
            if (
              error.networkError
              && (error.networkError as any).response
              && (error.networkError as any).response.status === 401
            ) {
              props.auth.logout();
            }
          }
        }
        fetchPolicy="network-only"
      >
        {
          (result) => {
            if (result.error) {
              return <>Error: {result.error.toString()}</>;
            }
            if (
              result.loading
              || !result.data
              || !result.data.event
              || result.data.event.permissions
                .indexOf(Permission.ScheduleDraftView) === -1
            ) {
              return <CircularProgress id="schedule-grid-progress" />;
            }

            return props.render({});
          }
        }
      </Query>
    );
  } else {
    return (
      <Query<inviteAuth, inviteAuthVariables>
        query={inviteAuth}
        variables={{ inviteId: props.idpSource.inviteId }}
        onCompleted={
          (result) => {
            if (!result.session) {
              // If you aren't logged in
              if (result.invite) {
                // If there's an IdP to use
                const identityProvider =
                  result.invite.__typename === "ScheduleItemParticipantInvite"
                  ? result.invite.scheduleItemInviteIdentityProvider
                  : result.invite.organizationInviteIdentityProvider;

                redirectToAuth(
                  identityProvider || undefined,
                  result.invite.id,
                );
              }
            }
          }
        }
        onError={
          (error) => {
            if (
              error.networkError
              && (error.networkError as any).response
              && (error.networkError as any).response.status === 401
            ) {
              props.auth.logout();
            }
          }
        }
        fetchPolicy="network-only"
      >
        {
          (result) => {
            if (result.error) {
              return <>Error: {result.error.toString()}</>;
            }
            if (result.loading || !result.data) {
              return <CircularProgress id="schedule-grid-progress" />;
            }

            if (!result.data.invite) {
              return (
                <Center>
                  <div>
                    <h1>Invite not found</h1>
                    <p>It may have been used or expired.</p>
                  </div>
                </Center>
              );
            }

            return props.render({});
          }
        }
      </Query>
    );
  }
};

