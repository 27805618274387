import * as React from "react";
import { DragObjectWithType, useDrag, useDrop } from "react-dnd";
import {
  AccessibleFakeButton,
  FontIcon,
} from "react-md";

import { FieldDialog } from "./FieldDialog";
import { FieldFormData } from "./FieldFormData";
import {convertFieldToFormData, useUpdateField} from "./useUpdateField";
import {
  customFields_event_schedule_itemForm_fields as CustomField,
} from "./__generated__/customFields";

const BEM = "rw-schedule-config";

interface FieldTypeChipProps {
  fieldType: FieldFormData["type"];
  hovered: boolean;
}

const FieldTypeChip: React.FC<FieldTypeChipProps> = (
  {
    fieldType,
    hovered,
  }) => (
  <div
    className={
      "md-chip"
      + (
        hovered ? " md-chip--hover" : ""
      )
    }
    title={
      fieldType === "TextField"
        ? "Text field"
        : fieldType === "BooleanField"
        ? "Boolean field"
        : "Select one field"
    }
  >
    <span
      className={
        "md-chip-text"
        + (
          hovered ? " md-chip-text--hover" : ""
        )
      }
    >
      {
        fieldType === "TextField"
          ? "Text"
          : fieldType === "BooleanField"
          ? "Checkbox"
          : "Dropdown"
      }
    </span>
  </div>
);

interface FieldDragObject extends DragObjectWithType {
  type: string;
  id: string;
}

interface FieldProps {
  eventSlug: string;
  field: CustomField;
  version: number;
  setError(error: string|null): void;
  moveField(srcRef: string, destRef: string): void;
}

export const Field: React.FC<FieldProps> = (props) => {
  const [visible, setVisible] = React.useState(false);
  const [hovered, setHovered] = React.useState(false);
  const updateField = useUpdateField();

  const fieldDragItemType = props.field.formId + "_FIELD";
  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: fieldDragItemType,
      id: props.field.ref,
    },
    collect(monitor) {
      return { isDragging: monitor.isDragging() };
    },
  });

  const [{ canDrop }, drop] = useDrop({
    accept: fieldDragItemType,
    hover(otherOption: FieldDragObject) {
      if (props.field.ref !== otherOption.id) {
        props.moveField(otherOption.id, props.field.ref);
      }
    },
    collect(monitor) {
      return { canDrop: monitor.canDrop() };
    },
  });

  const initialValue = convertFieldToFormData(props.field);
  const showDialog = () => setVisible(true);
  const hideDialog = () => setVisible(false);

  return (
    <>
      <li
        ref={
          (node) => {
            if (node) {
              preview(drop(node));
            }
            return null;
          }
        }
        onMouseEnter={() => {
          // Prevent hover effect during drag, which otherwise happens in
          // Chrome.
          if (!canDrop) {
            setHovered(true);
          }
        }}
        onMouseLeave={() => {
          setHovered(false);
        }}
        style={{ opacity: isDragging ? 0 : 1 }}
        className={
          `md-list-tile--two-lines ${BEM}_field-item`
          + (
            hovered && !isDragging ? " md-list-tile--active" : ""
          )
        }
      >
        <div
          ref={drag}
          className={`${BEM}_field-item-icon`}
        >
          <FontIcon>
            drag_indicator
          </FontIcon>
        </div>
        <AccessibleFakeButton
          className={`${BEM}_field-item-button`}
          onClick={showDialog}
          onTabFocus={() => setHovered(true)}
          onBlur={() => setHovered(false)}
        >
          <div className="md-tile-content md-tile-content--right-padding">
            <div className="md-tile-text--primary md-text">
              {props.field.label}
            </div>
            <div className="md-tile-text--secondary md-text md-text--secondary">
              {props.field.help || <>&nbsp;</>}
            </div>
          </div>
          <div className="md-tile-addon">
            <FieldTypeChip
              fieldType={props.field.__typename}
              hovered={hovered}
            />
          </div>
        </AccessibleFakeButton>
      </li>
      {
        visible ? (
          <FieldDialog
            id={`${props.field.name}`}
            initialValue={initialValue}
            mutate={
              updateField({
                ref: props.field.ref,
                version: props.field.version,
              })
            }
            newField={false}
            afterMutate={hideDialog}
            onHide={hideDialog}
            setError={props.setError}
            version={props.field.version}
          />
        ) : null
      }
    </>
  );
};
