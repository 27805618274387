import { DocumentNode } from "graphql";
import * as React from "react";
import { Query } from "react-apollo";
import { CircularProgress } from "react-md";

import { CustomFormId } from "../../../__generated__/globalTypes";
import { Center } from "../../layout/Center";
import { FieldList } from "./FieldList";
import { GlobalSettings } from "./GlobalSettings";
import { NewField } from "./NewField";

import "./index.scss";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const customFieldsQuery: DocumentNode = require("./customFields.graphql");
import {
  customFields,
  customFieldsVariables,
} from "./__generated__/customFields";

interface FieldConfigurationProps {
  eventSlug: string;
  setError(error: string|null): void;
}

export const FieldConfiguration: React.FC<FieldConfigurationProps> = (props) => (
  <Query<customFields, customFieldsVariables>
    query={customFieldsQuery}
    variables={{ eventSlug: props.eventSlug }}
  >
    {
      (result) => {
        if (
          !result.data
            || !result.data.event
            || !result.data.event.schedule
        ) {
          if (result.error) {
            return (
              <Center>
                There was an error: {result.error.toString()}
              </Center>
            );
          } else {
            return <Center>
              <CircularProgress id="field-list-loading" />
            </Center>;
          }
        }

        const configuration = result.data.event.schedule.configuration;
        const maxItemOrder = result.data.event.schedule.itemForm.fields
          .reduce(
            (acc, field) => acc < field.order ? field.order : acc,
            0,
          );
        const maxParticipantOrder = result.data.event.schedule.participantForm.fields
          .reduce(
            (acc, field) => acc < field.order ? field.order : acc,
            0,
          );

        return (
          <>
            <GlobalSettings
              initialValue={{
                emphasizedFieldId: configuration.emphasizedField
                  ? {
                    name: configuration.emphasizedField.name,
                    formId: configuration.emphasizedField.formId,
                  } : null,
                coloredFieldName: configuration.coloredItemField?.name || null,
              }}
              eventId={result.data.event.id}
              version={result.data.event.schedule.version}
              itemFields={result.data.event.schedule.itemForm.fields}
              participantFields={
                result.data.event.schedule.participantForm.fields
              }
              setError={props.setError}
            />
            <h4>Item fields</h4>
            {
              result.data.event.schedule.itemForm.fields.length > 0
                ? (
                  <FieldList
                    fields={result.data.event.schedule.itemForm.fields}
                    setError={props.setError}
                    version={result.data!.event.schedule!.version}
                    eventSlug={props.eventSlug}
                  />
              ) : <em>No fields</em>
            }
            <NewField
              eventId={result.data.event.id}
              maxOrder={maxItemOrder}
              customFormId={CustomFormId.ScheduleItem}
              version={result.data.event.schedule.version}
              setError={props.setError}
            />
            <h4>Participant fields</h4>
            {
              result.data.event.schedule.participantForm.fields.length > 0
                ? (
                  <FieldList
                    fields={result.data.event.schedule.participantForm.fields}
                    setError={props.setError}
                    version={result.data!.event.schedule!.version}
                    eventSlug={props.eventSlug}
                  />
                ) : <em>No fields</em>
            }
            <NewField
              eventId={result.data.event.id}
              maxOrder={maxParticipantOrder}
              customFormId={CustomFormId.ScheduleParticipant}
              version={result.data.event.schedule.version}
              setError={props.setError}
            />
          </>
        );
      }
    }
  </Query>
);
