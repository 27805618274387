import {Resource} from "../model";

import {
  CONFLICT_PATH,
  ITEM_DETAIL_PATH,
  ITEM_EDIT_PATH,
  LIST_ITEM_PATH,
  NEW_ITEM_PATH,
} from "./constants";

export type RouteInfo = (
  {
    path: typeof LIST_ITEM_PATH,
  } | {
    path: typeof ITEM_DETAIL_PATH,
    itemId: string,
  } | {
    path: typeof NEW_ITEM_PATH,
  } | {
    path: typeof ITEM_EDIT_PATH,
    itemId: string,
  } | {
    path: typeof CONFLICT_PATH,
  }
);

export function getResourceName(resource: Resource) {
  switch (resource.type) {
    case "LOCATION":
      return `Location: ${resource.location.name}`;
    case "PARTICIPANT":
      return `Participant: ${resource.participant.displayName}`;
    default:
      throw new Error("Invalid resource type");
  }
}

