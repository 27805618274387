import {
  Duration,
  ZonedDateTime,
  convert as jodaConvert,
} from "js-joda";
import * as React from "react";

import {
  FieldInstanceInput,
  FieldValueInput,
} from "../../__generated__/globalTypes";

export const statusList = [
  { label: "New", value: "NEW" },
  { label: "Pending", value: "PENDING" },
  { label: "Waitlisted", value: "WAITLISTED" },
  { label: "Approved", value: "APPROVED" },
  { label: "Draft", value: "DRAFT" },
  { label: "Review", value: "REVIEW" },
  { label: "Declined", value: "DECLINED" },
  { label: "Public", value: "PUBLIC" },
];

export function formatStatus(status: string): string|null {
  const statusItem = statusList.find((s) => s.value === status);
  if (!statusItem) {
    return null;
  }
  return statusItem.label;
}

const dateTimeFormatCache: Map<string, Intl.DateTimeFormat> = new Map();
export function formatDateTime(dateTime: ZonedDateTime, timeZone: string): string {
  let format = dateTimeFormatCache.get(timeZone);
  if (!format) {
    format = new Intl.DateTimeFormat(undefined, {
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
      timeZone,
    });
    dateTimeFormatCache.set(timeZone, format);
  }
  return format.format(jodaConvert(dateTime).toDate());
}


export function formatDuration(duration: Duration): string {
  return (
    `${duration.toHours()}:`
    + String(duration.toMinutes() % 60).padStart(2, "0")
  );
}

export function parseDuration(str: string): Duration|null {
  const minuteMatch = str.match(/^\d+$/);
  if (minuteMatch) {
    const minutes = Number(minuteMatch[0]);
    if (isNaN(minutes)) {
      return null;
    }
    return Duration.ofMinutes(minutes);
  }

  const hmMatch = str.match(/^(\d+):(\d\d)$/);
  if (hmMatch) {
    const hours = Number(hmMatch[1]);
    const minutes = Number(hmMatch[2]);
    if (isNaN(hours) || isNaN(minutes)) {
      return null;
    }
    return Duration.ofHours(hours).plusMinutes(minutes);
  }

  try {
    return Duration.parse(str);
  } catch (e) {
    return null;
  }
}

export function extractCustomFieldValue<T extends keyof FieldValueInput>(
  customFieldInstances: FieldInstanceInput[],
  name: string,
  fieldType: T,
): NonNullable<FieldValueInput[T]>|undefined {
  let fieldVal: NonNullable<FieldValueInput[T]>|undefined;
  for (const fieldInst of customFieldInstances) {
    if (
      fieldInst.name === name
      && fieldInst.value
      && fieldInst.value[fieldType] != null
    ) {
      fieldVal = fieldInst.value[fieldType]!;
      break;
    }
  }
  return fieldVal;
}

export function nl2br(str: string|undefined|null, placeholder: string|null) {
  if (!str) {
    return <em>{placeholder}</em>;
  }

  return str.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
}

export function parseQuery(hash: string): { [key: string]: string } {
  return (
    hash
      ? (
        hash.slice(1)
        .split("&")
        .reduce(
          (params: { [key: string]: string }, param) => {
            const [key, value] = param.split("=");
            params[key] = (
              value ? decodeURIComponent(value.replace(/\+/g, " ")) : ""
            );
            return params;
          },
          {},
        )
      ) : {}
  );
}

