import * as React from "react";
import {
  Button,
  Drawer,
  Toolbar,
} from "react-md";
import {Link} from "react-router-dom";

import {
  Schedule,
  ScheduleEntry,
} from "../model";
import {
  ScheduleModelAction,
} from "../model/reducer";

import {
  BEM,
  CONFLICT_PATH,
  ITEM_DETAIL_PATH,
  ITEM_EDIT_PATH,
  LIST_ITEM_PATH,
  NEW_ITEM_PATH,
} from "./constants";
import {ItemDrawerAdd} from "./ItemDrawerAdd";
import {ItemDrawerConflictList} from "./ItemDrawerConflictList";
import {ItemDrawerDetail} from "./ItemDrawerDetail";
import {ItemDrawerEdit} from "./ItemDrawerEdit";
import {ItemDrawerList} from "./ItemDrawerList";
import {
  RouteInfo,
} from "./util";

interface ItemDrawerProps {
  eventSlug: string;
  model: Schedule;
  visible: boolean;
  routeInfo: RouteInfo|null;
  selectedEntry: ScheduleEntry|null;
  isModal: boolean;
  hide(): void;
  dispatch(action: ScheduleModelAction): void;
}

export const ItemDrawer: React.FC<ItemDrawerProps> = React.memo((props) => {
  const onVisibilityChange = (visible: boolean) => {
    if (!visible) {
      props.hide();
    }
  };

  const item = (
    props.routeInfo
    && (
      props.routeInfo.path === ITEM_DETAIL_PATH
      || props.routeInfo.path === ITEM_EDIT_PATH
    ) ? props.model.items.get(props.routeInfo.itemId)
      : null
  );

  const title = (() => {
    if (!props.routeInfo) {
      return "";
    }
    switch (props.routeInfo.path) {
      case LIST_ITEM_PATH:
        return "Schedule Item";
      case NEW_ITEM_PATH:
        return "New Item";
      case CONFLICT_PATH:
        return "Conflicts";
      case ITEM_DETAIL_PATH:
      case ITEM_EDIT_PATH:
        return item ? item.displayName : "";
      default:
        return "";
    }
  })();

  const actions = (() => {
    if (!props.routeInfo || !props.visible) {
      return [];
    }
    const close = (
      <Button icon onClick={() => onVisibilityChange(false)}>
        close
      </Button>
    );
    switch (props.routeInfo.path) {
      case ITEM_DETAIL_PATH:
        const editUrl = (
          ITEM_EDIT_PATH
            .replace(":eventSlug", props.eventSlug)
            .replace(":itemId", props.routeInfo.itemId)
        );
        return [
          <Button
            icon
            component={Link}
            to={editUrl}
          >
            edit
          </Button>,
          close,
        ];
      case ITEM_EDIT_PATH:
        const detailUrl = (
          ITEM_DETAIL_PATH
            .replace(":eventSlug", props.eventSlug)
            .replace(":itemId", props.routeInfo.itemId)
        );
        return [
          <Button
            icon
            component={Link}
            to={detailUrl}
          >
            arrow_back
          </Button>,
        ];
      default:
        return [close];
    }
  })();
  const contents = (() => {
    if (!props.routeInfo) {
      return null;
    }
    switch (props.routeInfo.path) {
      case LIST_ITEM_PATH:
        return (
          <ItemDrawerList
            items={props.model.items}
            dispatch={props.dispatch} />
          );
      case NEW_ITEM_PATH:
        return (
          <ItemDrawerAdd dispatch={props.dispatch}
            eventSlug={props.eventSlug}
          />
        );
      case ITEM_DETAIL_PATH:
        return (
          <ItemDrawerDetail
            itemId={props.routeInfo.itemId}
            eventSlug={props.eventSlug} />
        );
      case ITEM_EDIT_PATH:
        return (
          <ItemDrawerEdit
            dispatch={props.dispatch}
            itemId={props.routeInfo.itemId}
            eventSlug={props.eventSlug}
           />
        );
      case CONFLICT_PATH:
        return (
          <ItemDrawerConflictList
            dispatch={props.dispatch}
            selectedEntry={props.selectedEntry}
            model={props.model}
          />
        );
      default:
        return null;
    }
  })();

  return (
    <Drawer
      visible={props.visible}
      onVisibilityChange={onVisibilityChange}
      position="right"
      autoclose={false}
      mobileType={Drawer.DrawerTypes.TEMPORARY}
      tabletType={Drawer.DrawerTypes.PERSISTENT}
      desktopType={Drawer.DrawerTypes.PERSISTENT}
      lastChild={true}
      portal={props.isModal}
      inline={!props.isModal}
      className={`${BEM}_drawer_paper`}
      header={
        <Toolbar
          className={`${BEM}_drawer_toolbar`}
          titleClassName={`${BEM}_drawer_toolbar_header`}
          actions={actions}
          title={title}
        />
      }
    >
      {contents}
    </Drawer>
  );
});
ItemDrawer.displayName = "ItemDrawer";

