import * as React from "react";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import { Snackbar } from "react-md";

import {AppFrame, MediaArg, MediaType} from "../../AppFrame";
import { FieldConfiguration } from "./FieldConfiguration";

export interface ScreenProps
{
  eventSlug: string;

  drawerVisible: MediaArg;
  changeDrawerVisibility(media: MediaType, visible: boolean): void;
  logout(): void;
}

export const ScheduleConfigurationScreen: React.FC<ScreenProps> = (props) => {
  const [toastError, setToastError] = React.useState<string|null>(null);
  return (
    <AppFrame
      eventSlug={props.eventSlug}
      drawerVisible={props.drawerVisible}
      changeDrawerVisibility={props.changeDrawerVisibility}
      logout={props.logout}
    >
      {
        () =>
          <DndProvider backend={Backend}>
            <div className="rw-schedule-config_container">
              <div className="md-grid md-grid--40-16">
                <div className="md-cell md-cell--12 md-text-container">
                  <h3>Custom Fields</h3>
                  <FieldConfiguration
                    eventSlug={props.eventSlug}
                    setError={setToastError}
                  />
                </div>
              </div>
              <Snackbar
                toasts={toastError ? [{
                  text: toastError,
                  action: "Dismiss",
                }] : []}
                onDismiss={() => setToastError(null)}
                autohide={false}
                portal={true}
              />
            </div>
          </DndProvider>
      }
    </AppFrame>
  );
};

