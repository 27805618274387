/**
 * Redefinition of ScheduleItem so that it can accept items from anywhere.
 */
interface ScheduleItem {
  readonly status: string;
  readonly entries: ReadonlyArray<object>;
}

interface FilterDisjunctionToken {
  type: "DISJUNCTION";
}
interface LiteralToken {
  type: "LITERAL";
  value: string;
}
interface KeywordToken {
  type: "KEYWORD";
  key: string;
  value: string;
}
interface LeftParenToken {
  type: "LEFT_PAREN";
}
interface RightParenToken {
  type: "RIGHT_PAREN";
}
type FilterToken = (
  LiteralToken
    | KeywordToken
    | FilterDisjunctionToken
    | LeftParenToken
    | RightParenToken
);
const operatorPrecedence = {
  "IMPLICIT_CONJUNCTION": 1 as const,
  "DISJUNCTION": 2 as const,
};

interface FilterDisjunctionNode {
  type: "DISJUNCTION";
}
interface FilterConjunctionNode {
  type: "IMPLICIT_CONJUNCTION";
}
type FilterTerm = LiteralToken | KeywordToken;
type FilterOpToken = FilterDisjunctionNode | FilterConjunctionNode | LeftParenToken;
type FilterOpNode = FilterDisjunctionNode | FilterConjunctionNode;
type FilterNode = FilterTerm | FilterDisjunctionNode | FilterConjunctionNode;

function* tokenizeFilter(filterStr: string): IterableIterator<FilterToken> {
  let index = 0;
  let acc: string[] = [];

  function readUntil(chars: string[]) {
    while (
      index < filterStr.length
        && chars.every((char) => char !== filterStr[index])
    ) {
      acc.push(filterStr[index]);
      ++index;
    }
  }

  while (index < filterStr.length) {
    if (filterStr[index] === " ") {
      // Skip a space
      ++index;
    } else if (filterStr[index] === "\"") {
      // Read a quoted string.
      ++index;
      readUntil(["\""]);
      // Skip the final quote.
      ++index;

      yield {
        type: "LITERAL",
        value: acc.join(""),
      };
      acc = [];
    } else if (filterStr[index] === "(") {
      yield { type: "LEFT_PAREN" };
      ++index;
    } else if (filterStr[index] === ")") {
      yield { type: "RIGHT_PAREN" };
      ++index;
    } else if (filterStr.startsWith("OR ", index)) {
      yield { type: "DISJUNCTION" };
      index += 3;
    } else {
      // Read an unquoted string, which maybe be a search key.
      readUntil([":", " ", ")"]);

      if (filterStr[index] === " " || index >= filterStr.length) {
        // If the unquoted term ended with a space or the end of the string,
        // then it's a literal.
        yield {
          type: "LITERAL",
          value: acc.join(""),
        };
        acc = [];
      } else if (filterStr[index] === ":") {
        // If it ended with a colon, then this is a search keyword.
        const key = acc.join("");
        acc = [];
        ++index;
        // Now we parse the value, which may be quoted.
        if (filterStr[index] === "\"") {
          readUntil(["\""]);
        } else {
          readUntil([" ", ")"]);
        }
        yield {
          type: "KEYWORD",
          key,
          value: acc.join(""),
        };
        acc = [];
      }
    }
  }
}

function parseFilter(filterStr: string): FilterNode[] {
  if (!filterStr) {
    return [];
  }

  // Convert to reverse polish notation.
  const tokens = Array.from(tokenizeFilter(filterStr));
  const output: FilterNode[] = [];
  const operators: FilterOpToken[] = [];

  function handleOperator(node: FilterOpNode) {
    let topOp = operators[operators.length - 1];
    while (
      operators.length > 0
        && topOp.type !== "LEFT_PAREN"
        && operatorPrecedence[topOp.type] >= operatorPrecedence[node.type]
    ) {
      output.push(topOp);
      operators.pop();
      topOp = operators[operators.length - 1];
    }
    operators.push(node);
  }

  for (let i = 0; i < tokens.length; ++i) {
    const token = tokens[i];
    const next: FilterToken|undefined = tokens[i + 1];
    if (token.type === "LITERAL" || token.type === "KEYWORD") {
      output.push(token);
    } else if (token.type === "DISJUNCTION") {
      handleOperator({ type: "DISJUNCTION" });
    } else if (token.type === "LEFT_PAREN") {
      operators.push(token);
    } else if (token.type === "RIGHT_PAREN") {
      let topOp = operators[operators.length - 1];
      while (topOp && topOp.type !== "LEFT_PAREN") {
        operators.pop();
        output.push(topOp);
        topOp = operators[operators.length - 1];
      }
      if (topOp && topOp.type === "LEFT_PAREN") {
        operators.pop();
      } else {
        // Unmatched parens
        return output;
      }
    }
    if (
      (
        token.type === "LITERAL"
          || token.type === "KEYWORD"
          || token.type === "RIGHT_PAREN"
      )
      && next
      && (next.type === "LITERAL" || next.type === "KEYWORD")
    ) {
      handleOperator({ type: "IMPLICIT_CONJUNCTION" });
    }
  }
  while (operators.length > 0) {
    const topOp = operators.pop()!;
    if (topOp.type === "LEFT_PAREN") {
      // Unmatched parens.
      break;
    }
    output.push(topOp);
  }

  return output;
}

export function compileFilter<T extends ScheduleItem>(
  extractTerms: (item: T) => string[],
  filterStr: string,
): (item: T) => boolean {
  const output = parseFilter(filterStr);

  if (output.length === 0) {
    return () => true;
  }

  // Build the filter.
  const filter: Array<((item: T) => boolean)> = [];
  for (const token of output) {
    if (token.type === "KEYWORD") {
      const key = token.key;
      const value = token.value;
      switch (key) {
        case "status":
          filter.push(
            (item: T) => item.status === value.toUpperCase(),
          );
          break;
        case "count":
          const countMatch = value.match(/^(<|>|>=|<=|=|!=|!|)([0-9]+)$/);
          if (countMatch) {
            const countOp = countMatch[1];
            const count = Number(countMatch[2]);
            if (countOp === "<") {
              filter.push((item: ScheduleItem) => item.entries.length < count);
            } else if (countOp === ">") {
              filter.push((item: ScheduleItem) => item.entries.length > count);
            } else if (countOp === "<=") {
              filter.push(
                (item: T) => item.entries.length <= count,
              );
            } else if (countOp === ">=") {
              filter.push(
                (item: T) => item.entries.length >= count,
              );
            } else if (countOp === "!=" || countOp === "!") {
              filter.push(
                (item: T) => item.entries.length !== count,
              );
            } else {
              filter.push(
                (item: T) => item.entries.length === count,
              );
            }
          } else {
            filter.push(() => false);
          }
          break;
        default:
          filter.push(() => false);
      }
    } else if (token.type === "DISJUNCTION") {
      const right = filter.pop();
      const left = filter.pop();
      if (!left || !right) {
        break;
      }
      filter.push((item: T) => left(item) || right(item));
    } else if (token.type === "IMPLICIT_CONJUNCTION") {
      const right = filter.pop();
      const left = filter.pop();
      if (!left || !right) {
        break;
      }
      filter.push((item: T) => left(item) && right(item));
    } else {
      const regexp = (
        new RegExp(
          token.value.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"),
          "i",
        )
      );
      filter.push(
        (item: T) => (
          !extractTerms(item).every((term) => term.search(regexp) === -1)
        ),
      );
    }
  }

  if (filter.length !== 1) {
    return () => false;
  }

  return filter[0];
}
