import { Form, Formik, FormikErrors, FormikHelpers } from "formik";
import * as React from "react";
import {
  Button,
  Checkbox,
  DialogContainer,
  SelectionControlGroup,
  TextField,
  Toolbar,
} from "react-md";

import { CompoundViolationError } from "../../../net/CompoundViolationError";
import { BooleanFieldOptions } from "./BooleanFieldOptions";
import {
  BooleanFieldFormData,
  FieldFormData,
  SelectOneFieldFormData,
  TextFieldFormData,
} from "./FieldFormData";
import { SelectOneOptions } from "./SelectOneOptions";
import { TextFieldOptions } from "./TextFieldOptions";
import { FieldMutationError, slugify } from "./util";
import {
  customFields_event_schedule_itemForm_fields as CustomField,
} from "./__generated__/customFields";

const BEM = "rw-schedule-config";

interface TypeSelectorProps {
  values: FieldFormData;
  setValues(data: FieldFormData): void;
}

const typeSelectorOptions = [
  {
    label: "Checkbox field",
    value: "BooleanField",
  },
  {
    label: "Dropdown field",
    value: "SelectOneField",
  },
  {
    label: "Text field",
    value: "TextField",
  },
];
const TypeSelector: React.FC<TypeSelectorProps> = (props) => {
  const onChange = (newType: string) => {
    // Make changing type idempotent, because when you change the field type,
    // it clears all of the properties of the field that are specific to that
    // field type.
    if (newType === props.values.type) {
      return;
    }
    const commonFields = {
      name: props.values.name,
      label: props.values.label,
      order: props.values.order,
      help: props.values.help,
      required: props.values.required,
      adminWritable: props.values.adminWritable,
      public: props.values.public,
      applicationWritable: props.values.applicationWritable,
    };
    if (newType === "BooleanField") {
      props.setValues({
        ...commonFields,
        type: "BooleanField",
        default: null,
      });
    } else if (newType === "TextField") {
      props.setValues({
        ...commonFields,
        type: "TextField",
        default: null,
        maximumLength: null,
        multiline: false,
      });
    } else if (newType === "SelectOneField") {
      props.setValues({
        ...commonFields,
        type: "SelectOneField",
        default: null,
        options: [],
      });
    }
  };

  return (
    <SelectionControlGroup
      id="type"
      name="type"
      type="radio"
      label="Field type"
      value={props.values.type}
      controls={typeSelectorOptions}
      onChange={onChange}
    />
  );
};

export interface AfterMutateArgs {
  actions: FormikHelpers<FieldFormData>;
  newField: CustomField;
}

interface FieldDialogProps {
  id: string;
  initialValue: FieldFormData;
  newField: boolean;
  version: number;

  mutate(data: FieldFormData): Promise<CustomField | null>;
  afterMutate(data: AfterMutateArgs): void;
  onHide(): void;
  setError(error: string|null): void;
}

export const FieldDialog =
  (props: FieldDialogProps) => {
    const onHide = () => {
      props.setError(null);
      props.onHide();
    };
    const submit = (
      data: FieldFormData,
      actions: FormikHelpers<FieldFormData>,
    ) => {
      props.setError(null);
      props.mutate(data)
        .then((newField) => {
          if (newField) {
            props.afterMutate({
              actions,
              newField,
            });
          }
        })
        .catch((error) => {
          if (error instanceof CompoundViolationError) {
            props.setError(error.toString());
          } else if (error instanceof FieldMutationError) {
            props.setError(error.message);
          } else {
            props.setError(
              error.errors
              && error.errors[0]
              && error.errors[0].message
                ? error.errors[0].message
                : error.toString()
              ,
            );
          }
          actions.setSubmitting(false);
        });
    };
    const validate =
      (values: FieldFormData) => {
        let error = false;

        // Check common errors. We have common errors so we can cast it later,
        // when checking type specific fields
        const commonErrors: FormikErrors<FieldFormData> = {};
        if (!values.label) {
          commonErrors.label = "A field label is required.";
          error = true;
        }
        if (values.order === null) {
          commonErrors.order = "Order number must be set.";
          error = true;
        } else if (values.order % 1 !== 0) {
          commonErrors.order = "Order number must be a whole number.";
          error = true;
        } else if (values.order === -0) {
          commonErrors.order = "Order number must be a number.";
          error = true;
        }

        if (values.type === "SelectOneField") {
          const errors =
            commonErrors as FormikErrors<SelectOneFieldFormData>;
          // Check that all colors are either set or unset.
          let colorRequired = false;
          for (const option of values.options) {
            if (option.color !== null) {
              colorRequired = true;
              break;
            }
          }
          const optionErrors =
            new Array<{ color: string }|undefined>(values.options.length);
          // Mark unset colors as wrong
          for (const [index, option] of values.options.entries()) {
            if (option.color === null) {
              if (colorRequired) {
                optionErrors[index] = {
                  color: "This color is not set. Colors must be all set or" +
                    " all unset.",
                };
                error = true;
              }
            } else if (!option.color.match(/^[0-9a-fA-F]{6}$/)) {
              optionErrors[index] = {
                color: "Color must be six characters, consisting of digits" +
                  " 0-9 and letters A-F.",
              };
              error = true;
            }
          }
          errors.options = optionErrors;

          if (error) {
            return errors;
          }
        } else if (values.type === "TextField") {
          const errors = commonErrors as FormikErrors<TextFieldFormData>;
          if (
            values.maximumLength !== null
            && values.maximumLength % 1 !== 0
          ) {
            errors.maximumLength = "Order number must be a whole number.";
          }

          if (error) {
            return errors;
          }
        } else {
          const errors = commonErrors as FormikErrors<BooleanFieldFormData>;
          if (error) {
            return errors;
          }
        }
        return;
      };
    return (
      <Formik<FieldFormData>
        initialValues={props.initialValue}
        initialStatus={{
          formError: null as string | null,
        }}
        onSubmit={submit}
        validate={validate}

      >
        {
          (formProps) => {
            const saveDisabled =
              !formProps.dirty
              || formProps.isSubmitting
              || !formProps.isValid;
            const saveButtonLabel =
              formProps.isSubmitting
                ? "Saving..."
                : "Save";
            const saveButtonTooltip =
              !formProps.isValid
                ? "Cannot save with errors"
                : !formProps.dirty
                ? "Cannot save without changes"
                : undefined;
            return (
              <DialogContainer
                id={`field-dialog-${props.id}`}
                visible={true}
                component={Form}
                onHide={onHide}
                fullPage={true}
              >
                <Toolbar
                  colored
                  title={formProps.values.label}
                  className={`${BEM}_toolbar`}
                  nav={
                    <Button
                      icon
                      onClick={onHide}
                    >
                      arrow_back
                    </Button>
                  }
                  actions={[
                    <Button
                      key="edit-back"
                      flat
                      onClick={onHide}
                    >
                      Cancel
                    </Button>,
                    <Button
                      key="edit-save"
                      raised
                      secondary
                      type="submit"
                      disabled={saveDisabled}
                      title={saveButtonTooltip}
                    >
                      {saveButtonLabel}
                    </Button>,
                  ]}
                />
                <section>
                  <div className="md-grid md-grid--40-16">
                    <div className="md-cell md-cell--12 md-text-container">
                      <TextField
                        id="label"
                        name="label"
                        label="Label"
                        required
                        onChange={(newValue, e) => {
                          // If this is a new field, then set the name from the
                          // label.
                          if (props.newField) {
                            // Slugify the value.
                            const slug =
                              slugify(newValue.toString()) || "field";
                            // New fields are supposed to default to a version
                            // number suffix. If there isn't one, just make up a
                            // random one.
                            const suffix = "--" + props.version;
                            formProps.setFieldValue("name", slug + suffix);
                          }
                          formProps.handleChange(e);
                        }}
                        value={formProps.values.label}
                        error={!!formProps.errors.label || undefined}
                        errorText={formProps.errors.label}
                      />
                      <TextField
                        id="help"
                        name="help"
                        label="Field help text"
                        rows={2}
                        onChange={(_, e) => formProps.handleChange(e)}
                        value={formProps.values.help}
                      />
                      <TextField
                        id="order"
                        name="order"
                        label="Order number"
                        helpText="Fields are ordered by this number."
                        required
                        pattern="-?[0-9]*"
                        onChange={(value) => {
                          if (value === "") {
                            formProps.setFieldValue("order", null);
                          } else {
                            const parsedValue =
                              typeof value === "number"
                                ? value
                                : value.match(/^-?[0-9]+$/)
                                ? +value
                                : value === "-"
                                ? -0
                                : null;
                            if (
                              typeof parsedValue === "number"
                              // This prevents out of range numbers that get
                              // displayed in exponential form. Also prevents
                              // values that are not allowed by the API.
                              && -2147483648 < parsedValue
                              && parsedValue < 2147483647
                            ) {
                              formProps.setFieldValue("order", parsedValue);
                            }
                          }
                        }}
                        value={
                          formProps.values.order !== null
                            ? formProps.values.order === -0
                            ? "-"
                            : formProps.values.order
                            : ""
                        }
                        error={!!formProps.errors.order}
                        errorText={formProps.errors.order}
                      />
                      <Checkbox
                        id="required"
                        name="required"
                        label="Require this field"
                        checked={formProps.values.required}
                        onChange={(_, e) => formProps.handleChange(e)}
                      />
                      <Checkbox
                        id="adminWritable"
                        name="adminWritable"
                        label="Allow administrators to write to this field"
                        checked={formProps.values.adminWritable}
                        onChange={(_, e) => formProps.handleChange(e)}
                      />
                      <p className="md-caption">
                        Usually, you want this on, but if a field is intended
                        just
                        as a way for a presenter to give information to
                        administrators, you may want to turn this off.
                      </p>
                      <Checkbox
                        id="public"
                        name="public"
                        label="Show this field on the public schedule"
                        checked={formProps.values.public}
                        onChange={(_, e) => formProps.handleChange(e)}
                      />
                      {
                        props.newField
                          ? (
                            <TypeSelector
                              values={formProps.values}
                              setValues={formProps.setValues}
                            />
                          ) : null
                      }
                      {
                        formProps.values.type === "SelectOneField"
                          ? (
                            <SelectOneOptions version={props.version}/>
                          ) : formProps.values.type === "TextField"
                          ? <TextFieldOptions/>
                          : <BooleanFieldOptions/>
                      }
                      <div
                        className="button-group rw-right-container"
                        style={{
                          marginTop: 16,
                        }}
                      >
                        <Button
                          raised
                          secondary
                          type="submit"
                          disabled={saveDisabled}
                          title={saveButtonTooltip}
                        >
                          {saveButtonLabel}
                        </Button>
                      </div>
                    </div>
                  </div>
                </section>
              </DialogContainer>
            );
          }
        }
      </Formik>
    );
  };
