export const SPACING = 1000;

enum Direction {
  UP,
  UP_LEFT,
  LEFT,
}

export function computeOrderDiff<T>(
  getId: (entity: T) => string|number,
  getOrder: (entity: T) => number,
  x: ReadonlyArray<T>,
  y: ReadonlyArray<T>,
) {
  // Find the longest common subsequence. These will be the entities whose
  // order we do not change. This implementation is based on the CLRS Algorithms
  // book.
  const m = x.length;
  const n = y.length;
  // Create empty arrays.
  const scores = new Array<number[]>();
  for (let row = 0; row < m + 1; row++) {
    scores.push([]);
    for (let col = 0; col < n + 1; col++) {
      scores[row].push(0);
    }
  }
  const backtrackDirections = new Array<Array<Direction | undefined>>();
  for (let row = 0; row < m; row++) {
    backtrackDirections.push([]);
    for (let col = 0; col < n; col++) {
      backtrackDirections[row].push(undefined);
    }
  }
  for (let row = 0; row < m; row++) {
    for (let col = 0; col < n; col++) {
      if (getId(x[row]) === getId(y[col])) {
        scores[row + 1][col + 1] = scores[row][col] + 1;
        backtrackDirections[row][col] = Direction.UP_LEFT;
      } else if (scores[row][col + 1] >= scores[row + 1][col]) {
        scores[row + 1][col + 1] = scores[row][col + 1];
        backtrackDirections[row][col] = Direction.UP;
      } else {
        scores[row + 1][col + 1] = scores[row + 1][col];
        backtrackDirections[row][col] = Direction.LEFT;
      }
    }
  }

  // Compute the needed inserts to create y. We do not count deletions, because
  // the mutation on entities implicitly removes the entity from its current
  // entity.
  const ops = [];
  // This is a list of indices of items from x in reverse order that are not
  // going to be changed. These are used to determine fixed order numbers to
  // place entities between. This is also the longest common subsequence.
  const unchangedIndices = [];
  let i = m - 1;
  let j = n - 1;
  while (i >= 0 && j >= 0) {
    if (backtrackDirections[i][j] === Direction.UP_LEFT) {
      unchangedIndices.push(i);
      i -= 1;
      j -= 1;
    } else if (backtrackDirections[i][j] === Direction.UP) {
      i -= 1;
    } else {
      ops.push({
        insertBefore: unchangedIndices.length - 1,
        entity: y[j],
      });
      j -= 1;
    }
  }
  while (i >= 0) {
    i--;
  }
  while (j >= 0) {
    ops.push({
      insertBefore: unchangedIndices.length - 1,
      entity: y[j],
    });
    j--;
  }
  ops.reverse();

  // Calculate the ranges of orders that moved entities have to fit into.
  const orderRanges = new Map<string, T[]>();
  for (const op of ops) {
    let rangeName;
    if (op.insertBefore === unchangedIndices.length - 1) {
      const end = getOrder(x[unchangedIndices[unchangedIndices.length - 1]]);
      rangeName = `,${end}`;
    } else if (0 < op.insertBefore) {
      const begin = getOrder(x[unchangedIndices[op.insertBefore + 1]]);
      const end = getOrder(x[unchangedIndices[op.insertBefore]]);
      rangeName = `${begin},${end}`;
    } else {
      const begin = getOrder(x[unchangedIndices[0]]);
      rangeName = `${begin},`;
    }
    orderRanges.set(
      rangeName,
      orderRanges.has(rangeName)
        ? [...orderRanges.get(rangeName), op.entity]
        : [op.entity],
    );
  }

  // Assign the new order numbers.
  const orderChanges = [];
  for (const [range, entities] of orderRanges.entries()) {
    const [startStr, endStr] = range.split(",");
    const start = parseInt(startStr, 10);
    const end = parseInt(endStr, 10);
    if (isNaN(start)) {
      // Just put the earliest ones 1000 apart, before the first one.
      for (const [index, entity] of entities.entries()) {
        orderChanges.push({
          entity,
          newOrder: end + (index - entities.length) * SPACING,
        });
      }
    } else if (isNaN(end)) {
      // Just put the last ones 1000 apart, after the last one.
      for (const [index, entity] of entities.entries()) {
        orderChanges.push({
          entity,
          newOrder: start + (index + 1) * SPACING,
        });
      }
    } else if (end - start > entities.length) {
      // If there is enough space for all of them, space them evenly in the
      // interval.
      const intervalSpacing = (end - start - 1) / (entities.length + 1);
      // We want don't want to start at the very beginning of the interval.
      let currentOrder = start + 1 + intervalSpacing;
      for (const entity of entities) {
        orderChanges.push({
          entity,
          newOrder: Math.floor(currentOrder),
        });
        currentOrder += intervalSpacing;
      }
    } else {
      // If there is not enough space in the interval to fit in all of the
      // entities, renumber them all and bail out.
      return y
        .map((entity, index) => ({
          entity,
          newOrder: index * SPACING,
        }));
    }
  }
  return orderChanges;
}
