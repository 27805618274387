import {DocumentNode} from "graphql";
import * as React from "react";
import { Query } from "react-apollo";
import { CircularProgress } from "react-md";

import { Center } from "../../layout/Center";
import { DraggableLocationList } from "./DraggableLocationList";
import { LocationLinkList } from "./LocationLinkList";
import { Dispatch, State } from "./LocationModel";
import {
  getLocation_event_schedule_location as Location,
} from "./__generated__/getLocation";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const getLocationsQuery: DocumentNode = require("./getLocations.graphql");
import {
  getLocations,
  getLocationsVariables,
} from "./__generated__/getLocations";

interface LocationListQueryProps
{
  eventSlug: string;
  children(locations: ReadonlyArray<Location>): React.ReactElement;
}

const LocationListQuery: React.FC<LocationListQueryProps> = (props) => (
  <Query<getLocations, getLocationsVariables>
    query={getLocationsQuery}
    variables={{eventSlug: props.eventSlug}}
  >
    {
      (queryResult) => {
        if (
          queryResult.loading
          || !queryResult.data
          || !queryResult.data.event
          || !queryResult.data.event.schedule
          || !queryResult.data.event.schedule.locations
        ) {
          return (
            <CircularProgress id="event-location-progress" />
          );
        }
        const locationsResult = queryResult.data.event.schedule.locations;

        return props.children(locationsResult);
      }
    }
  </Query>
);

interface LocationListProps
{
  state: State;
  dispatch: Dispatch;
}

export const LocationList: React.FC<LocationListProps> = ({ state, dispatch }) => (
  <LocationListQuery
    eventSlug={state.eventSlug}
  >
    {
      (locations) => {
        if (locations.length === 0) {
          return <Center>No locations found.</Center>;
        } else if (state.draggable) {
          return (
            <DraggableLocationList
              locations={locations}
              eventSlug={state.eventSlug}
              listState={state.reorderedLocations}
              setListState={
                (locationOrder) => (
                  dispatch({
                    type: "setLocationOrder",
                    locationOrder,
                  })
                )
              }
            />
          );
        } else {
          return (
            <LocationLinkList
              locations={locations}
              eventSlug={state.eventSlug}
            />
          );
        }
      }
    }
  </LocationListQuery>
);
