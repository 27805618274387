import * as React from "react";
import {
  FontIcon,
  ListItem,
  Menu,
  MenuButton,
} from "react-md";

import "./QuickFilter.scss";

export interface QuickFilterProps
{
  setFilter(newFilter: string): void;
}

export const QuickFilter: React.FC<QuickFilterProps> = (props) => (
  <div title="Quick Filters">
    <MenuButton
      id="quick-filter"
      primary
      raised
      position={Menu.Positions.BELOW}
      menuItems={[
        <ListItem
          key="unreviewed"
          primaryText="Unreviewed items"
          onClick={
            () => props.setFilter(
              "status:NEW OR status:PENDING OR status:DRAFT OR status:REVIEW",
            )
          }
        />,
        <ListItem
          key="public"
          primaryText="Public unscheduled items"
          onClick={
            () => props.setFilter(
              "status:PUBLIC count:0",
            )
          }
        />,
      ]}
      iconBefore={false}
      iconEl={<FontIcon inherit>arrow_drop_down</FontIcon>}
      simplifiedMenu={true}
    >
      <FontIcon inherit>filter_list</FontIcon>
    </MenuButton>
  </div>
);

