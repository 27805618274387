import * as React from "react";

import "./AbsoluteViewport.scss";

const BEM = "rwa-absolute-viewport";

// Padding for the guttters so that there's a little extra gutter to allow for
// the scrollbar making the content viewport smaller than the gutter viewport.
const SCROLLBAR_GUTTER_PADDING = 40;

interface ScrollTarget {
  top: number;
  left: number;
  right: number;
  bottom: number;
  exclusionBufferPercentage: number;
}

export interface AbsoluteViewportProps {
  sizeBottom: number;
  sizeRight: number;

  gutterTop?: React.ReactNode;
  gutterTopSize?: number;

  gutterBottom?: React.ReactNode;
  gutterBottomSize?: number;

  gutterLeft?: React.ReactNode;
  gutterLeftSize?: number;

  gutterRight?: React.ReactNode;
  gutterRightSize?: number;

  cornerTopLeft?: React.ReactNode;
  cornerTopRight?: React.ReactNode;
  cornerBottomLeft?: React.ReactNode;
  cornerBottomRight?: React.ReactNode;

  overlay?: React.ReactNode;
}

export class AbsoluteViewport
extends React.Component<AbsoluteViewportProps, {}>
{
  private readonly content: React.RefObject<HTMLDivElement>;
  private readonly gutterLeft: React.RefObject<HTMLDivElement>;
  private readonly gutterRight: React.RefObject<HTMLDivElement>;
  private readonly gutterTop: React.RefObject<HTMLDivElement>;
  private readonly gutterBottom: React.RefObject<HTMLDivElement>;

  private skipContent = false;
  private skipLeft = false;
  private skipRight = false;
  private skipTop = false;
  private skipBottom = false;

  constructor(props: Readonly<AbsoluteViewportProps>) {
    super(props);

    this.content = React.createRef();
    this.gutterLeft = React.createRef();
    this.gutterRight = React.createRef();
    this.gutterTop = React.createRef();
    this.gutterBottom = React.createRef();

    this.handleScrollContent = this.handleScrollContent.bind(this);
    this.handleScrollLeft = this.handleScrollLeft.bind(this);
    this.handleScrollRight = this.handleScrollRight.bind(this);
    this.handleScrollTop = this.handleScrollTop.bind(this);
    this.handleScrollBottom = this.handleScrollBottom.bind(this);
  }

  render() {
    const props = this.props;
    const width = (props.sizeRight) + "px";
    const height = (props.sizeBottom) + "px";
    const gutterWidth = (props.sizeRight + SCROLLBAR_GUTTER_PADDING) + "px";
    const gutterHeight = (props.sizeBottom + SCROLLBAR_GUTTER_PADDING) + "px";

    const gutterTopSize = `${props.gutterTopSize || 32}px`;
    const gutterBottomSize = `${props.gutterBottomSize || 32}px`;

    const gridTemplateRows =
      `${props.gutterTopSize || 0}px 1fr ${props.gutterBottomSize || 0}px`;
    const gridTemplateColumns =
      `${props.gutterLeftSize || 0}px 1fr ${props.gutterRightSize || 0}px`;
    const gridStyle = {
      msGridRows: gridTemplateRows,
      msGridColumns: gridTemplateColumns,
      gridTemplateRows,
      gridTemplateColumns,
    };

    return (
      <div className={BEM} style={gridStyle}>
        {props.gutterTop && props.gutterLeft ? (
          <div className={`${BEM}_corner ${BEM}_corner--top ${BEM}_corner--left`}>
            {props.cornerTopLeft}
          </div>
        ) : null}

        {props.gutterTop ? (
          <div className={`${BEM}_gutter ${BEM}_gutter--top`}
               ref={this.gutterTop} onScroll={this.handleScrollTop}>
            <div className={`${BEM}_scroller`}
              style={{width: gutterWidth, height: gutterTopSize}}>
              {props.gutterTop}
            </div>
          </div>
        ) : null}

        {props.gutterTop && props.gutterRight ? (
          <div className={`${BEM}_corner ${BEM}_corner--top ${BEM}_corner--right`}>
            {props.cornerTopRight}
          </div>
        ) : null}

        {props.gutterLeft ? (
          <div className={`${BEM}_gutter ${BEM}_gutter--left`}
               ref={this.gutterLeft} onScroll={this.handleScrollLeft}>
            <div className={`${BEM}_scroller`} style={{height: gutterHeight}}>
              {props.gutterLeft}
            </div>
          </div>
        ) : null}

        {
          props.overlay ? (
            <div className={`${BEM}_overlay`}>
              {props.overlay}
            </div>
          ) : null
        }

        <div className={`${BEM}_content`}
             ref={this.content} onScroll={this.handleScrollContent}>
          <div className={`${BEM}_scroller`} style={{width, height}}>
            {props.children}
          </div>
        </div>

        {props.gutterRight ? (
          <div className={`${BEM}_gutter ${BEM}_gutter--right`}
               ref={this.gutterRight} onScroll={this.handleScrollRight}>
            <div className={`${BEM}_scroller`} style={{height: gutterHeight}}>
              {props.gutterRight}
            </div>
          </div>
        ) : null}

        {props.gutterBottom && props.gutterLeft ? (
          <div className={`${BEM}_corner ${BEM}_corner--bottom ${BEM}_corner--left`}>
            {props.cornerBottomLeft}
          </div>
        ) : null}

        {props.gutterBottom ? (
          <div className={`${BEM}_gutter ${BEM}_gutter--bottom`}
               ref={this.gutterBottom} onScroll={this.handleScrollBottom}>
            <div className={`${BEM}_scroller`}
              style={{width: gutterWidth, height: gutterBottomSize}}>
              {props.gutterBottom}
            </div>
          </div>
        ) : null}

        {props.gutterBottom && props.gutterRight ? (
          <div className={`${BEM}_corner ${BEM}_corner--bottom ${BEM}_corner--right`}>
            {props.cornerBottomRight}
          </div>
        ) : null}
      </div>
    );
  }

  scrollIntoView(params: ScrollTarget) {
    if (!this.content.current) {
      return;
    }

    // If the target rectangle is inside the viewport minus the buffer zone,
    // then we don't need to scroll.
    const viewportTarget = {
      top: params.top - this.content.current.scrollTop,
      left: params.left - this.content.current.scrollLeft,
      bottom: params.bottom - this.content.current.scrollTop,
      right: params.right - this.content.current.scrollLeft,
    };
    const bufFactor = params.exclusionBufferPercentage / 100;
    const bufferedViewport = {
      top: this.content.current.offsetHeight * bufFactor,
      left: this.content.current.offsetWidth * bufFactor,
      bottom: this.content.current.offsetHeight * (1 - bufFactor),
      right: this.content.current.offsetWidth * (1 - bufFactor),
    };
    if (
      viewportTarget.top > bufferedViewport.top
        && viewportTarget.left > bufferedViewport.left
        && viewportTarget.bottom < bufferedViewport.bottom
        && viewportTarget.right < bufferedViewport.right
    ) {
      return;
    }

    // Scroll the center of the target to the center of the viewport.
    const targetCenter = {
      x: (params.left + params.right) / 2,
      y: (params.top + params.bottom) / 2,
    };
    const topToCenter = targetCenter.y - this.content.current.offsetHeight / 2;
    const leftToCenter = targetCenter.x - this.content.current.offsetWidth / 2;
    // Clamp top and left to make sure that they aren't out of bounds.
    const top = Math.min(
      Math.max(topToCenter, 0),
      this.props.sizeBottom - this.content.current.offsetHeight,
    );
    const left = Math.min(
      Math.max(leftToCenter, 0),
      this.props.sizeRight - this.content.current.offsetWidth,
    );

    this.skipContent = true;
    this.content.current.scrollTop = top;
    this.content.current.scrollLeft = left;

    if (this.gutterLeft.current) {
      this.skipLeft = true;
      this.gutterLeft.current.scrollTop = top;
    }

    if (this.gutterRight.current) {
      this.skipRight = true;
      this.gutterRight.current.scrollTop = top;
    }

    if (this.gutterTop.current) {
      this.skipTop = true;
      this.gutterTop.current.scrollLeft = left;
    }

    if (this.gutterBottom.current) {
      this.skipBottom = true;
      this.gutterBottom.current.scrollLeft = left;
    }
  }

  handleScrollContent(event: React.UIEvent<HTMLDivElement>) {
    if (this.skipContent) {
      this.skipContent = false;
      return;
    }

    const top = event.currentTarget.scrollTop;
    const left = event.currentTarget.scrollLeft;

    if (this.gutterLeft.current) {
      this.skipLeft = true;
      this.gutterLeft.current.scrollTop = top;
    }

    if (this.gutterRight.current) {
      this.skipRight = true;
      this.gutterRight.current.scrollTop = top;
    }

    if (this.gutterTop.current) {
      this.skipTop = true;
      this.gutterTop.current.scrollLeft = left;
    }

    if (this.gutterBottom.current) {
      this.skipBottom = true;
      this.gutterBottom.current.scrollLeft = left;
    }
  }

  handleScrollLeft(event: React.UIEvent<HTMLDivElement>) {
    if (this.skipLeft) {
      this.skipLeft = false;
      return;
    }

    const top = event.currentTarget.scrollTop;

    if (this.gutterRight.current) {
      this.skipRight = true;
      this.gutterRight.current.scrollTop = top;
    }

    if (this.content.current) {
      this.skipContent = true;
      this.content.current.scrollTop = top;
    }
  }

  handleScrollRight(event: React.UIEvent<HTMLDivElement>) {
    if (this.skipRight) {
      this.skipRight = false;
      return;
    }

    const top = event.currentTarget.scrollTop;

    if (this.gutterLeft.current) {
      this.skipLeft = true;
      this.gutterLeft.current.scrollTop = top;
    }

    if (this.content.current) {
      this.skipContent = true;
      this.content.current.scrollTop = top;
    }
  }

  handleScrollTop(event: React.UIEvent<HTMLDivElement>) {
    if (this.skipTop) {
      this.skipTop = false;
      return;
    }

    const left = event.currentTarget.scrollLeft;

    if (this.gutterBottom.current) {
      this.skipBottom = true;
      this.gutterBottom.current.scrollLeft = left;
    }

    if (this.content.current) {
      this.skipContent = true;
      this.content.current.scrollLeft = left;
    }
  }

  handleScrollBottom(event: React.UIEvent<HTMLDivElement>) {
    if (this.skipBottom) {
      this.skipBottom = false;
      return;
    }

    const left = event.currentTarget.scrollLeft;

    if (this.gutterTop.current) {
      this.skipTop = true;
      this.gutterTop.current.scrollLeft = left;
    }

    if (this.content.current) {
      this.skipContent = true;
      this.content.current.scrollLeft = left;
    }
  }

  scrollBy(dx: number, dy: number) {
    if (!this.content.current) {
      return;
    }

    const unclampedTop =
      this.content.current.scrollTop + dy;
    const unclampedLeft =
      this.content.current.scrollLeft + dx;
    // Clamp to stay in the scroll region.
    const top = Math.min(
      Math.max(unclampedTop, 0),
      this.props.sizeBottom - this.content.current.offsetHeight,
    );
    const left = Math.min(
      Math.max(unclampedLeft, 0),
      this.props.sizeRight - this.content.current.offsetWidth,
    );

    this.skipContent = true;
    this.content.current.scrollTop = top;
    this.content.current.scrollLeft = left;

    if (this.gutterLeft.current) {
      this.skipLeft = true;
      this.gutterLeft.current.scrollTop = top;
    }

    if (this.gutterRight.current) {
      this.skipRight = true;
      this.gutterRight.current.scrollTop = top;
    }

    if (this.gutterTop.current) {
      this.skipTop = true;
      this.gutterTop.current.scrollLeft = left;
    }

    if (this.gutterBottom.current) {
      this.skipBottom = true;
      this.gutterBottom.current.scrollLeft = left;
    }
  }

  getBoundingClientRect() {
    if (!this.content.current) {
      return null;
    }
    return this.content.current.getBoundingClientRect();
  }

  getScroll() {
    if (!this.content.current) {
      return null;
    }
    return {
      top: this.content.current.scrollTop,
      left: this.content.current.scrollLeft,
      right: this.content.current.scrollLeft
        + this.content.current.clientWidth,
      bottom: this.content.current.scrollTop
        + this.content.current.clientHeight,
      width: this.content.current.clientWidth,
      height: this.content.current.clientHeight,
    };
  }
}
