import { useLocalStorage } from "@rehooks/local-storage";
import * as React from "react";
import { Dialog, FontIcon } from "react-md";

interface FakeButtonProps {
  icon?: string;
}

const TButton: React.FC<FakeButtonProps> = (props) => (
  props.children
    ? (
      <span className="tour-button--text">
        { props.icon ? <><FontIcon>{props.icon}</FontIcon>&nbsp;</> : null }
        { props.children }
      </span>
    ) : props.icon
    ? (
      <FontIcon className="tour-button--icon">
        {props.icon}
      </FontIcon>
    ) : null
);

const tourStepMessages = [
  <>
    <p>Would you like a tour?</p>
    <ol>
      <li>
        Click <TButton icon="home">My Events</TButton> in the left
        navigation menu.
      </li>
      <li>
        Click <TButton>Start tour</TButton> below to continue.
      </li>
    </ol>
  </>,
  <>
    <p>
      This is a list of all the schedules you have access to. You can see
      information on your event, as well as statistics on any programming in
      your schedule, if there is any. It also has buttons that go to the various
      screens you can use to work on your schedule. In order to schedule
      anything, you will need a place to schedule it.
    </p>
    <ol>
      <li>
        Click <TButton icon="more_vert"/> in the lower right of the event card
        to show the menu.
      </li>
      <li>
        Click <TButton>Locations</TButton> in the menu.
      </li>
      <li>Click <TButton>Continue</TButton> below.</li>
    </ol>
  </>,
  <>
    <p>
      This is the Locations list. If you have any locations they will be listed
      here.
    </p>
    <p>
      If you have no locations:
      <ol>
        <li>
          Click <TButton icon="add">Add Location</TButton> in the top toolbar.
        </li>
        <li>
          Fill out the location information in the dialog.
        </li>
        <li>Click <TButton>Save</TButton>.</li>
        <li>
          Repeat until you have all the locations you need. We will need at
          least 2 locations for this tour.
        </li>
        <li>Click <TButton>Continue</TButton> below.</li>
      </ol>
    </p>
  </>,
  <>
    <p>
      If you need to change your locations:
      <ol>
        <li>Click the location's name in the list.</li>
        <li>Edit the information in the dialog.</li>
        <li>Click <TButton>Save</TButton>.</li>
        <li>Click <TButton>Continue</TButton> below.</li>
      </ol>
    </p>
  </>,
  <>
    <p>
      If you aren't happy with the order of your locations:
      <ol>
        <li>
          Click <TButton icon="open_with">Reorder</TButton> button in the top
          toolbar.
        </li>
        <li>
          Drag and drop the names of your locations until they are in the order
          you like.
        </li>
        <li>
          Click <TButton icon="save">Save order</TButton> in the top toolbar.
        </li>
        <li>Click <TButton>Continue</TButton> below.</li>
      </ol>
    </p>
  </>,
  <>
    <p>
      Now that we have somewhere to schedule our programming, we need to make
      something to schedule.
    </p>
    <ol>
      <li>
        Click <TButton icon="event">Item List</TButton> in the left navigation
        menu.
      </li>
      <li>Click <TButton>Continue</TButton> below.</li>
    </ol>
  </>,
  <>
    <p>
      This is the item list. It shows a list of all the programming that you can
      schedule. Each schedule item can be scheduled one or more times. Let's
      make an item to schedule.
    </p>
    <ol>
      <li>
        Click <TButton icon="add">Create item</TButton> in the top toolbar.
      </li>
      <li>Click <TButton>Continue</TButton> below.</li>
    </ol>
  </>,
  <>
    <p>
      This is the new item form. It lets you enter information on an item you
      can then schedule.
    </p>
    <ol>
      <li>
        Fill in <TButton>Title (public) *</TButton> field at the top of the
        form, on right side of the screen. Use any title you like.
      </li>
      <li>
        Fill in any other required fields indicated with a <strong>*</strong>,
        if configured on your event.
      </li>
      <li>
        Click the <TButton>Add item</TButton> button below the form. You may
        need to scroll down.
      </li>
      <li>Click <TButton>Continue</TButton> below.</li>
    </ol>
  </>,
  <>
    <p>
      Now that you have an item, let's attach some participants to your item.
      <ol>
        <li>
          In the item details section on the right side of the screen,
          near the bottom, under <u><strong>Participants</strong></u>, click on
          the <strong>Add participant</strong> field.
        </li>
        <li>
          Click <TButton icon="add">New participant</TButton>.
        </li>
        <li>
          Fill in the form with a name, birth date, and email for an imaginary
          person.
        </li>
        <li>
          Click <TButton>Create</TButton> at the button of the dialog.
        </li>
        <li>Click <TButton>Continue</TButton> below.</li>
      </ol>
    </p>
  </>,
  <>
    <p>
      Now that your item has a participant, you can approve the item for
      publishing. All items have a <strong>Status</strong> that affects its
      visibility on the public schedule. Certain changes in status send emails
      to an item's owner: for example, notifying a submitter that their
      programming has been approved.
    </p>
    <ol><li>Click <TButton>Continue</TButton> below.</li></ol>
  </>,
  <>
    <p>
      There are also several other statuses: New, Pending, Waitlisted, Approved,
      Draft, Review, and Declined.
    </p>
    <p>
      Typically, submitted items will go New, Pending, Approved, Public.
      Programming created by schedule administrators will go Draft, Review,
      Public. Of course, you can skip the middle steps if you don't need to
      coordinate a formal approval process.
    </p>
    <ol><li>Click <TButton>Continue</TButton> below.</li></ol>
  </>,
  <>
    <p>
      To make your item show up on the public schedule as soon as it is
      scheduled:
      <ol>
        <li>
          In the item details section, on the right side of the screen,
          underneath <u><strong>Status</strong></u>,
          click <TButton>Queue to publish</TButton>.
        </li>
        <li>Click <TButton>Continue</TButton> below.</li>
      </ol>
    </p>
  </>,
  <>
    <p>
      Now that you have an item ready to schedule, let's schedule it. To do
      that, let's open the grid.
    </p>
    <ol>
      <li>
        Click <TButton icon="grid_on">View in Grid</TButton> near the top of the
        item details section, on the right side of the screen.
      </li>
      <li>Click <TButton>Continue</TButton> below.</li>
    </ol>
  </>,
  <>
    <p>
      This is the schedule grid, where you can schedule your items. To schedule
      your new item:
    </p>
    <ol>
      <li>
        In the item details section on the left side of the screen, near the
        top, click the <TButton>Place new entry</TButton> button.
      </li>
      <li>
        Click the time and place where you want to schedule the item in the
        grid.
      </li>
      <li>Click <TButton>Continue</TButton> below.</li>
    </ol>
  </>,
  <>
    <p>
      On your newly scheduled entry, you can see its title, the first 3 letters
      of its status, its rating if it is not for general audiences, and a
      colored strip on the bottom indicating a customizable track or category.
      Moving it around is easy:
    </p>
    <ol>
      <li>Click the entry to select it.</li>
      <li>
        Drag and drop the entry to move it. You will need to use the
        red <TButton icon="open_with" /> button if you are using a touchscreen.
      </li>
      <li>
        Drag the ends of the entry to resize it. This does not work on
        touchscreens.
      </li>
      <li>Click <TButton>Continue</TButton> below.</li>
    </ol>
  </>,
  <>
    <p>
      When you selected the entry, it turned gray to indicate that it is
      selected. Additionally, actions you can perform on the item appear in the
      top toolbar.
    </p>
    <ol>
      <li>
        Click <TButton icon="cancel">Unselect</TButton> in the top toolbar to
        unselect the entry.
      </li>
      <li>
        Click on the entry again to select it.
      </li>
      <li>
        Click <TButton icon="info">Item info</TButton> in the top toolbar to
        bring the details for that entry's item details back. You can also
        double-click the entry to do this.
      </li>
      <li>Click <TButton>Continue</TButton> below.</li>
    </ol>
  </>,
  <>
    <p>
      In order to delete the entry, you can use the toolbar or your keyboard:
    </p>
    <ol>
      <li>Click the entry to select it.</li>
      <li>Press the delete key on your keyboard.</li>
      <li>Click <TButton>Continue</TButton> below.</li>
    </ol>
  </>,
  <>
    <p>
      There's another way to schedule items if you don't already have them on
      screen. The schedule item list lets you quickly schedule several items.
      By default, it shows every item you have, but you can search them.
    </p>
    <ol>
      <li>
        Click <TButton icon="event">Schedule item</TButton> in the top toolbar.
      </li>
      <li>
        Underneath <strong>Schedule Item</strong> on the right side, click
        the <TButton icon="search">search field</TButton> and type the name of
        your item.
      </li>
      <li>
        Drag and drop the entry from the list into the grid to a time and place
        you want to put it.
      </li>
      <li>Click <TButton>Continue</TButton> below.</li>
    </ol>
  </>,
  <>
    <p>
      Each item can have participants attached and you probably don't want to
      schedule a participant in two places at once. If there is a chance of a
      conflict while scheduling, then a pink conflict zone appears, warning you
      of a possible conflict.
    </p>
    <ol>
      <li>
        Drag the entry from the list into the grid again.
      </li>
      <li>
        Without letting go of the new entry, hover over the pink conflict zone
        in the grid to see who is conflicting.
      </li>
      <li>
        Drop the entry inside the conflict zone.
      </li>
      <li>Click <TButton>Continue</TButton> below.</li>
    </ol>
  </>,
  <>
    <p>
      You have just created a scheduling conflict. You can see the conflicting
      entry highlighted in red. Let's fix the conflict we created by moving the
      other entry.
    </p>
    <ol>
      <li>Click the entry you just placed to select it.</li>
      <li>Click the other entry that is highlighted in red.</li>
      <li>Drag and drop that entry outside the conflict zone.</li>
      <li>Click <TButton>Continue</TButton> below.</li>
    </ol>
  </>,
  <>
    <p>
      Finally, we can publish our schedule. This is a separate step from setting
      the status on an item to public, just in case you accidentally schedule
      an entry at the wrong time. You wouldn't want your attendees to see an
      incorrect schedule!
    </p>
    <ol>
      <li>
        Click <TButton icon="cancel">Unselect</TButton> in the top toolbar.
      </li>
      <li>
        Click the <TButton icon="publish">Publish</TButton> button
        in the top toolbar.
      </li>
      <li>
        Click the <TButton icon="public">Public Schedule</TButton> link in the
        left navigation menu to open your published schedule in a new tab.
      </li>
      <li>Click <TButton>Continue</TButton> below.</li>
    </ol>
  </>,
  <>
    <p>
      That concludes the tour of the Reg.Works schedule grid. We hope that you find
      Reg.Works helpful for your event.
    </p>
    <ol><li>Click <TButton>Finish</TButton> below.</li></ol>
  </>,
];

interface TourState
{
  step: number|null;
}

export const useTourState: () => [number|null, (newStep: number|null) => void] = () => {
  const [stepObject, setStepObject] =
    useLocalStorage<TourState>(
      "RegWorksTourStep",
      { step: 0 },
    );
  const step = stepObject ? stepObject.step : null;
  const setStep = (newStep: number|null) => setStepObject({step: newStep});
  return [step, setStep];
};

export const TourPopup: React.FC<{}> = () => {
  const [step, setStep] = useTourState();
  if (step !== null) {
    return (
      <Dialog
        id="tour-dialog"
        containFocus={false}
        aria-labelledby="tour-dialog-title"
        className="md-background--card"
        contentProps={{
          // Needed to reset scroll on the content.
          key: `tour-page-${step}`,
        }}
        actions={
          [
            ...(
              tourStepMessages[step + 1] !== undefined
                ? [{
                  key: "skip",
                  onClick: () => setStep(null),
                  children: "Skip tour",
                }] : []
            ),
            ...(
              tourStepMessages[step - 1] !== undefined
                ? [{
                  key: "prev",
                  onClick: () => setStep(step - 1),
                  children: "Previous",
                }] : []
            ),
            ...(
              tourStepMessages[step + 1] !== undefined
                ? [{
                  key: "next",
                  onClick: () => setStep(step + 1),
                  primary: true,
                  children: step === 0 ? "Start tour" : "Continue",
                }] : [{
                    key: "next",
                    onClick: () => setStep(null),
                    primary: true,
                    children: "Finish",
                }]
            ),
          ]
        }
      >
        <h2 id="tour-dialog-title">Tour</h2>
        <div className="md-body-1">
          {tourStepMessages[step]}
        </div>
      </Dialog>
    );
  } else {
    return null;
  }
};
