import { Form, Formik, FormikHelpers } from "formik";
import { DocumentNode } from "graphql";
import * as React from "react";
import { Mutation, MutationFunction, Query } from "react-apollo";
import { Button, DialogContainer, TextField } from "react-md";
import { Route, RouteChildrenProps, useHistory } from "react-router";
import { Link } from "react-router-dom";

import { SPACING } from "../util";
import { locationSort } from "./util";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const updateLocationMutation: DocumentNode = require("./updateLocation.graphql");
import {
  updateLocation,
  updateLocationVariables,
} from "./__generated__/updateLocation";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const deleteLocationMutation: DocumentNode = require("./deleteLocation.graphql");
import {
  deleteLocation,
  deleteLocationVariables,
} from "./__generated__/deleteLocation";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const createLocationMutation: DocumentNode = require("./createLocation.graphql");
import {
  createLocation,
  createLocationVariables,
} from "./__generated__/createLocation";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const getLocationQuery: DocumentNode = require("./getLocation.graphql");
import {
  getLocation,
  getLocationVariables,
  getLocation_event_schedule_location as Location,
} from "./__generated__/getLocation";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const getLocationsQuery: DocumentNode = require("./getLocations.graphql");
import {
  getLocations,
  getLocationsVariables,
} from "./__generated__/getLocations";

const LOCATION_DETAIL_PATH = "/event/:eventSlug/schedule/locations/:id";

interface LocationFormData
{
  name: string;
  order: number;
  description: string|null;
  maxConcurrentEntries: number|null;
}

interface LocationDialogProps
{
  eventSlug: string;
  title: string;
  initialValue: LocationFormData;
  additionalActions?: React.ReactElement[];
  mutate(data: LocationFormData): Promise<Location|null>;
  afterMutate(data: {
    actions: FormikHelpers<LocationFormData>,
    newLocation: Location,
  }): void;
  onHide(): void;
}

const LocationDialog: React.FC<LocationDialogProps> = (props) => (
  <Formik
    initialValues={props.initialValue}
    initialStatus={{
      formError: null as string|null,
    }}
    onSubmit={(data, actions) => {
      props.mutate({
        name: data.name,
        description: data.description || null,
        maxConcurrentEntries: data.maxConcurrentEntries || null,
        order: data.order,
      })
        .then((newLocation) => {
          if (newLocation) {
            props.afterMutate({
              actions,
              newLocation,
            });
          }
        })
        .catch((error) => {
          actions.setStatus({
            formError:
              error.errors
              && error.errors[0]
              && error.errors[0].message
                ? error.errors[0].message
                : error.toString()
            ,
          });
        });
    }}
  >
    {
      (formProps) => (
        <DialogContainer
          id="location-dialog"
          visible={true}
          title={props.title}
          component={Form}
          portal
          onHide={props.onHide}
          actions={[
            ...(props.additionalActions ? props.additionalActions : []),
            <Button
              key="edit-back"
              flat
              component={Link}
              to={`/event/${props.eventSlug}/schedule/locations/`}
            >
              Back
            </Button>,
            <Button
              key="edit-save"
              raised={formProps.dirty}
              flat={!formProps.dirty}
              primary
              type="submit"
              disabled={!formProps.dirty}
            >
              {
                formProps.dirty
                  ? "Save"
                  : "Saved"
              }
            </Button>,
          ]}
        >
          {
            formProps.status.formError
              ? (
                <div className="md-text--error">
                  {formProps.status.formError}
                </div>
              ) : null
          }
          <TextField
            id="name"
            name="name"
            label="Location name"
            required
            onChange={(_, e) => formProps.handleChange(e)}
            value={formProps.values.name}
          />
          <TextField
            id="order"
            name="order"
            label="Order number"
            type="number"
            helpText="Locations are ordered by this number."
            required
            onChange={(_, e) => formProps.handleChange(e)}
            value={formProps.values.order}
          />
          <TextField
            id="description"
            name="description"
            label="Description"
            onChange={(_, e) => formProps.handleChange(e)}
            value={formProps.values.description || ""}
          />
          <TextField
            id="maxConcurrentEntries"
            name="maxConcurrentEntries"
            type="number"
            label="Maximum concurrent entries"
            helpText={
              "The maximum number of entries that can be in this"
              + " location at the same time. If empty, there is no limit."
            }
            onChange={(_, e) => formProps.handleChange(e)}
            min={1}
            value={
              formProps.values.maxConcurrentEntries === null
                ? ""
                : formProps.values.maxConcurrentEntries
            }
          />
        </DialogContainer>
      )
    }
  </Formik>
);

interface LocationNewDialogProps
{
  eventSlug: string;
}

const LocationNewDialog: React.FC<LocationNewDialogProps> = (props) => {
  const history = useHistory();
  return (
    <Query<getLocations, getLocationsVariables>
      query={getLocationsQuery}
      variables={{ eventSlug: props.eventSlug }}
      fetchPolicy="cache-only"
    >
      {
        (locationsResult) => {
          if (
            !locationsResult.data
            || !locationsResult.data.event
            || !locationsResult.data.event.schedule
          ) {
            return null;
          }
          const data = locationsResult.data;
          const schedule = locationsResult.data.event.schedule;
          const version = schedule.version;
          const defaultOrder =
            schedule.locations.length >= 1
              ? (
                schedule.locations[schedule.locations.length - 1].order
                  + SPACING
              ) : 0;

          return (
            <Mutation<createLocation, createLocationVariables>
              mutation={createLocationMutation}
              update={
                (cache, result) => {
                  if (
                    !result.data
                      || !data.event.schedule
                  ) {
                    return;
                  }

                  data.event.schedule.version =
                    result.data.createEventLocation.eventLocation.version;
                  data.event.schedule.locations.push(
                    result.data.createEventLocation.eventLocation,
                  );
                  data.event.schedule.locations.sort(locationSort);
                  cache
                    .writeQuery<getLocations, getLocationsVariables>({
                      query: getLocationsQuery,
                      variables: {
                        eventSlug: props.eventSlug,
                      },
                      data,
                    });
                }
              }
            >
              {
                (mutate: MutationFunction<createLocation, createLocationVariables>) => (
                  <LocationDialog
                    eventSlug={props.eventSlug}
                    mutate={
                      (formData) => (
                        mutate({
                          variables: {
                            ...formData,
                            eventId: data.event.id,
                            version,
                          },
                        })
                          .then((result) => {
                            if (!result || !result.data) {
                              return null;
                            }
                            return result.data.createEventLocation.eventLocation;
                          })
                      )
                    }
                    afterMutate={
                      () => {
                        history.push(`/event/${props.eventSlug}/schedule/locations`);
                      }
                    }
                    onHide={
                      () => history.push(`/event/${props.eventSlug}/schedule/locations`)
                    }
                    title="New Location"
                    initialValue={{
                      name: "",
                      order: defaultOrder,
                      description: null,
                      maxConcurrentEntries: 1,
                    }}
                  />
                )
              }
            </Mutation>
          );
        }
      }
    </Query>
  );
};

interface DeleteLocationDialogProps
{
  eventSlug: string;
  location: Location;
  version: number;
  visible: boolean;
  onHide(): void;
  onDelete(): void;
}

const DeleteLocationDialog: React.FC<DeleteLocationDialogProps> = (props) => {
  return (
    <Mutation<deleteLocation, deleteLocationVariables>
      mutation={deleteLocationMutation}
      variables={{
        id: props.location.id,
        version: props.version,
      }}
      update={
        (cache, result) => {
          const locationsQueryResult = cache
            .readQuery<getLocations, getLocationsVariables>({
              query: getLocationsQuery,
              variables: { eventSlug: props.eventSlug },
            });

          if (
            !locationsQueryResult
            || !locationsQueryResult.event.schedule
            || !result.data
          ) {
            return;
          }

          locationsQueryResult.event.schedule.version =
            result.data.deleteEventLocation.schedule.version;
          locationsQueryResult.event.schedule.locations =
            locationsQueryResult.event.schedule.locations
              .filter((loc) => loc.id !== props.location.id);
          cache
            .writeQuery<getLocations, getLocationsVariables>({
              query: getLocationsQuery,
              variables: {
                eventSlug: props.eventSlug,
              },
              data: locationsQueryResult,
            });
        }
      }
    >
      {
        (mutate: MutationFunction<deleteLocation, deleteLocationVariables>) => {
          return (
            <DialogContainer
              id="confirm-location-delete"
              visible={props.visible}
              title="Delete location"
              onHide={() => props.onHide()}
              actions={[
                <Button
                  key="cancel-delete"
                  flat
                  onClick={() => props.onHide()}
                >
                  Cancel
                </Button>,
                <Button
                  key="delete"
                  flat
                  secondary
                  onClick={() =>
                    mutate()
                      .then(props.onDelete)
                      .catch((error) => (
                        alert(
                          error.graphQLErrors
                          && error.graphQLErrors[0]
                          && error.graphQLErrors[0].extensions
                          && error.graphQLErrors[0].extensions.errors
                          && error.graphQLErrors[0].extensions.errors[0]
                          && error.graphQLErrors[0].extensions.errors[0].humanError === "being used"
                            ? (
                              `Location "${props.location.name}" is in use`
                              + " and cannot be deleted. Please remove any"
                              + " entries using the location before deleting"
                              + " it."
                            ) : error.toString(),
                        )
                      ))
                  }
                >
                  Delete
                </Button>,
              ]}
            >
              <p>
                Are you sure you want to delete the location
                "{props.location.name}"?
              </p>
            </DialogContainer>
          );
        }
      }
    </Mutation>
  );
};

interface LocationEditDialogProps
{
  eventSlug: string;
  version: number;
  location: Location;
}

const LocationEditDialog: React.FC<LocationEditDialogProps> = (props) => {
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const history = useHistory();
  return (
    <Mutation<updateLocation, updateLocationVariables>
      mutation={updateLocationMutation}
      update={
        (cache, result) => {
          const locationsQueryResult = cache
            .readQuery<getLocations, getLocationsVariables>({
              query: getLocationsQuery,
              variables: { eventSlug: props.eventSlug },
            });

          if (
            !locationsQueryResult
            || !locationsQueryResult.event.schedule
            || !result.data
          ) {
            return;
          }

          locationsQueryResult.event.schedule.version =
            result.data.updateEventLocation.version;
          locationsQueryResult.event.schedule.locations.sort(locationSort);
          cache
            .writeQuery<getLocations, getLocationsVariables>({
              query: getLocationsQuery,
              variables: {
                eventSlug: props.eventSlug,
              },
              data: locationsQueryResult,
            });
        }
      }
    >
      {
        (mutate: MutationFunction<updateLocation, updateLocationVariables>) => (
          !confirmDelete
            ? (
              <LocationDialog
                eventSlug={props.eventSlug}
                mutate={
                  (formData) => (
                    mutate({
                      variables: {
                        ...formData,
                        id: props.location.id,
                        version: props.location.version,
                      },
                    })
                      .then((result) => {
                        if (!result || !result.data) {
                          return null;
                        }
                        return result.data.updateEventLocation;
                      })
                  )
                }
                afterMutate={
                  ({ actions}) => {
                    actions.resetForm();
                  }
                }
                onHide={
                  () => history.push(`/event/${props.eventSlug}/schedule/locations`)
                }
                title={props.location.name}
                initialValue={{
                  name: props.location.name,
                  order: props.location.order,
                  description: props.location.description || "",
                  maxConcurrentEntries: props.location.maxConcurrentEntries,
                }}
                additionalActions={[
                  <Button
                    key="location-show-delete"
                    flat
                    secondary
                    onClick={() => setConfirmDelete(true)}
                  >
                    Delete
                  </Button>,
                ]}
              />
            ) : (
              <DeleteLocationDialog
                key="delete"
                eventSlug={props.eventSlug}
                location={props.location}
                version={props.version}
                onDelete={
                  () =>
                    history.push(`/event/${props.eventSlug}/schedule/locations`)
                }
                visible={confirmDelete}
                onHide={() => setConfirmDelete(false)}
              />
            )
        )
      }
    </Mutation>
  );
};

interface RouteParams
{
  eventSlug: string;
  id: string;
}

export const LocationDialogRoute: React.FC<{}> = () => (
  <Route path={LOCATION_DETAIL_PATH}>
    {
      (detailRouteProps: RouteChildrenProps<RouteParams>) => {
        if (detailRouteProps.match) {
          const { match } = detailRouteProps;
          if (match.params.id === "new") {
            return (
              <LocationNewDialog
                eventSlug={match.params.eventSlug}
              />
            );
          } else {
            return (
              <Query<getLocation, getLocationVariables>
                key="detail"
                query={getLocationQuery}
                variables={{
                  eventSlug: match.params.eventSlug,
                  id: match.params.id,
                }}
              >
                {
                  (queryResult) => {
                    if (
                      queryResult.loading
                      || !queryResult.data
                      || !queryResult.data.event
                      || !queryResult.data.event.schedule
                      || !queryResult.data.event.schedule.location
                    ) {
                      return null;
                    }

                    return (
                      <LocationEditDialog
                        eventSlug={match.params.eventSlug}
                        version={queryResult.data.event.schedule.version}
                        location={queryResult.data.event.schedule.location}
                      />
                    );
                  }
                }
              </Query>
            );
          }
        } else {
          return null;
        }
      }
    }
  </Route>
);
