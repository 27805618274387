import { useLocalStorage } from "@rehooks/local-storage";
import {DocumentNode} from "graphql";
import * as React from "react";
import { useQuery } from "react-apollo";
import {
  Button,
  Divider,
  Drawer,
  FontIcon,
  ListItem,
  MenuButton,
  NavigationDrawer,
  Toolbar,
} from "react-md";
import {DrawerType} from "react-md/lib/Drawers/Drawer";
import Layover from "react-md/lib/Helpers/Layover";
import {Link, Route} from "react-router-dom";

import { TourPopup, useTourState } from "./TourPopup";

// this is necessary until we can fix the GraphQL TS declaration generator
// tslint:disable-next-line:no-require-imports
const eventSeriesQuery: DocumentNode = require("./eventSeries.graphql");
import {
  eventSeries,
  eventSeriesVariables,
} from "./__generated__/eventSeries";

interface NavLinkProps {
  to: string;
  label: string;
  exact?: boolean;
  icon: string;
  showTitle: boolean;
}

const NavLink: React.FC<NavLinkProps> = (props) => (
  <Route path={props.to} exact={props.exact}>
    {({ match }) => (
      <ListItem
        component={Link}
        active={!!match}
        to={props.to}
        primaryText={props.label}
        leftIcon={<FontIcon>{props.icon}</FontIcon>}
        title={props.showTitle ? props.label : undefined}
      />
    )}
  </Route>
);

interface PublicScheduleLinkProps {
  eventSlug: string;
  isMini: boolean;
}

const PublicScheduleLink: React.FC<PublicScheduleLinkProps> = (props) => {
  const eventSeriesQueryResult = useQuery<eventSeries, eventSeriesVariables>(
    eventSeriesQuery,
    {
      variables: {
        slug: props.eventSlug,
      },
    },
  );
  // Only show the link if we can fetch an event series for this event and this
  // event has a published schedule.
  const publicEventSeriesSlug =
    (
      eventSeriesQueryResult.data
      && eventSeriesQueryResult.data.event.eventSeries
      && (
        eventSeriesQueryResult.data.event.eventSeries.currentEvent?.id
        === eventSeriesQueryResult.data.event.id
      ) && eventSeriesQueryResult.data.event.publicSchedule
    ) ? eventSeriesQueryResult.data.event.eventSeries.slug
      : null;

  if (publicEventSeriesSlug) {
    return (
      <a
        key="public"
        href={`http://${publicEventSeriesSlug}.reg.works/`}
        target="_blank"
      >
        <ListItem
          primaryText="Public Schedule"
          leftIcon={<FontIcon>public</FontIcon>}
          title={props.isMini ? "Public Schedule" : undefined}
        />
      </a>
    );
  } else {
    return (
      <ListItem
        primaryText="Public Schedule"
        leftIcon={<FontIcon>public</FontIcon>}
        title="Schedule is not yet published"
        disabled={true}
      />
    );
  }
};

export interface AppFrameProps {
  eventSlug: string|null;
  toolbarActions?: React.ReactElement<any> | Array<React.ReactElement<any>>;
  toolbarTheme?: "primary" | "secondary";
  toolbarTitle?: string;
  actionsMenu?: React.ReactElement<any> | ReadonlyArray<React.ReactElement<any>>;

  drawerVisible: MediaArg;
  changeDrawerVisibility(
    media: MediaType,
    visible: boolean,
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void;
  logout(): void;
  children(props: { media: MediaType }): React.ReactNode;
}

export interface MediaArg
{
  mobile: boolean;
  tablet: boolean;
  desktop: boolean;
}

export type MediaType = keyof MediaArg;

export const AppFrame = (props: AppFrameProps) => {
  // All the necessary state to animate the overlay. The react-md animations
  // make assumptions about the stacking context that don't play well with our
  // app (i.e. it isn't fixed), so we have to implement them ourselves.
  const [media, setMedia] = React.useState<MediaType>(
    () => {
      const initialMedia = Drawer.getCurrentMedia();
      if (initialMedia.desktop) {
        return "desktop";
      } else if (initialMedia.tablet) {
        return "tablet";
      } else {
        return "mobile";
      }
    },
  );
  const [animating, setAnimating] = React.useState<boolean>(false);
  const [overlayActive, setOverlayActive] = React.useState<boolean>(false);
  const [showToolbarTextState, setShowToolbarText] =
    useLocalStorage<{ visible: boolean }>(
      "showToolbarText",
      { visible: true },
    );
  const [, setStep] = useTourState();
  const showToolbarText =
    showToolbarTextState ? showToolbarTextState.visible : true;
  const animationTimeout = React.useRef<number|undefined>(undefined);
  const onMediaTypeChange = React.useCallback(
    (type: DrawerType, newMedia: MediaArg) => {
      if (newMedia.desktop) {
        setMedia("desktop");
      } else if (newMedia.tablet) {
        setMedia("tablet");
      } else {
        setMedia("mobile");
      }
    },
    [setMedia],
  );
  const changeDrawerVisibility = React.useCallback(
    (visible: boolean, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (visible) {
        setAnimating(true);
        setOverlayActive(false);
        animationTimeout.current = setTimeout(
          () => setOverlayActive(true),
          17, // This is the React CSSTransitionTransitionGroupChild TICK.
        );
      } else {
        setOverlayActive(false);
        animationTimeout.current = setTimeout(
          () => setAnimating(false),
          Drawer.defaultProps!.transitionDuration,
        );
      }
      props.changeDrawerVisibility(media, visible, event);
    },
    [props.changeDrawerVisibility, media],
  );
  const toggleShowToolbarText = React.useCallback(
    () => {
      setShowToolbarText({
        visible: !showToolbarText,
      });
    },
    [setShowToolbarText, showToolbarText],
  );
  const onClickOverlay = React.useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) =>
    changeDrawerVisibility(false, e),
    [changeDrawerVisibility],
  );
  const restartTour = React.useCallback(
    () => setStep(0),
    [setStep],
  );
  React.useEffect(
    () => () => {
      if (animationTimeout.current) {
        clearTimeout(animationTimeout.current);
      }
    },
    [],
  );

  const actionsMenu = (
    props.actionsMenu
    ? (
      <MenuButton
        icon
        id="frame-actions"
        className="md-btn--toolbar"
        anchor={{
          x: Layover.HorizontalAnchors.INNER_RIGHT,
          y: Layover.VerticalAnchors.OVERLAP,
        }}
        position={Layover.Positions.TOP_RIGHT}
        menuItems={props.actionsMenu}
        simplifiedMenu={false}
      >
        more_vert
      </MenuButton>
    ) : null
  );

  const toolbarButton = (
    <Button
      flat
      tooltipLabel={
        showToolbarText
          ? "Hide toolbar text"
          : "Show toolbar text"
      }
      iconChildren={
        showToolbarText
          ? "chevron_right"
          : "chevron_left"
      }
      onClick={toggleShowToolbarText}
    >
      Hide text
    </Button>
  );

  const actions = (
    props.toolbarActions
      ? (
        media === "desktop"
          ? (
            <React.Fragment>
              {props.toolbarActions}
              {toolbarButton}
              {actionsMenu}
            </React.Fragment>
          ) : (
            <div className="app-mobile-toolbar-outer">
              <div className="app-mobile-toolbar-inner">
                <div className="app-mobile-toolbar-actions">
                  {props.toolbarActions}
                </div>
                {media === "tablet" ? toolbarButton : null}
                {actionsMenu}
              </div>
            </div>
          )
      ) : undefined
  );

  const navItems = (
    isMini: boolean,
  ) =>
    [
      (
        <NavLink
          key="my-events"
          to={props.eventSlug ? `/event/${props.eventSlug}/` : "/"}
          label="My Events"
          icon="home"
          exact
          showTitle={isMini}
        />
      ),
      ...(
        props.eventSlug ? [
          <Divider key="top-divider" />,
          (
            <NavLink
              key="items"
              to={`/event/${props.eventSlug}/schedule/items/`}
              label="Item List"
              icon="event"
              showTitle={isMini}
            />
          ),
          (
            <NavLink
              key="grid"
              to={`/event/${props.eventSlug}/schedule/grid/`}
              label="Schedule Grid"
              icon="grid_on"
              showTitle={isMini}
            />
          ),
          (
            <NavLink
              key="participants"
              to={`/event/${props.eventSlug}/schedule/participants/`}
              label="Participant List"
              icon="people"
              showTitle={isMini}
            />
          ),
          (
            <NavLink
              key="locations"
              to={`/event/${props.eventSlug}/schedule/locations/`}
              label="Locations"
              icon="room"
              showTitle={isMini}
            />
          ),
          (
            <NavLink
              key="config"
              to={`/event/${props.eventSlug}/schedule/config/`}
              label="Schedule Configuration"
              icon="settings"
              showTitle={isMini}
            />
          ),
          <PublicScheduleLink
            key="public-schedule"
            eventSlug={props.eventSlug}
            isMini={isMini}
          />,
        ] : []
      ),
      <div key="bottom-divider" id="main-nav-divider" />,
      ...(
        media === "desktop"
          ? [
            <ListItem
              key="toggle-nav"
              primaryText={isMini ? "Expand navigation" : "Collapse navigation"}
              leftIcon={<FontIcon>{
                isMini
                  ? "arrow_forward"
                  : "arrow_back"
              }</FontIcon>}
              title={isMini ? "Expand navigation" : undefined}
              onClick={
                (e) => changeDrawerVisibility(isMini, e)
              }
            />,
          ] : []
      ),
      (
        <NavLink
          key="account"
          to="/account"
          label="My Account"
          icon="account_box"
          showTitle={isMini}
        />
      ),
      <ListItem
        key="tour"
        primaryText="Restart tour"
        leftIcon={<FontIcon>help</FontIcon>}
        title={isMini ? "Restart tour" : undefined}
        onClick={restartTour}
      />,
      (
        <ListItem
          key="log-out"
          onClick={props.logout}
          primaryText="Log out"
          leftIcon={<FontIcon>logout</FontIcon>}
          title={isMini ? "Log out" : undefined}
        />
      ),
    ];

  return (
    <NavigationDrawer
      id="main-nav-root"
      contentId="main-nav-content"
      toolbarTitle={
        media !== "mobile"
          ? (
            props.toolbarTitle === undefined
              ? "Reg.Works"
              : props.toolbarTitle
          ) : ""
      }
      mobileDrawerType={NavigationDrawer.DrawerTypes.TEMPORARY}
      tabletDrawerType={NavigationDrawer.DrawerTypes.PERSISTENT}
      desktopDrawerType={
        props.drawerVisible.desktop
          ? NavigationDrawer.DrawerTypes.FULL_HEIGHT
          : NavigationDrawer.DrawerTypes.PERSISTENT_MINI
      }
      drawerHeader={
        <Toolbar
          className="md-divider-border md-divider-border--bottom"
          actions={
            <Button
              icon
              onClick={(e) => changeDrawerVisibility(false, e)}
              tooltipLabel="Collapse navigation"
            >
              arrow_back
            </Button>
          }
        />
      }
      toolbarActions={actions}
      toolbarZDepth={1}
      toolbarClassName={
        (
          showToolbarText && media !== "mobile"
            ? ""
            : "main-btn-text-hide"
        ) + (
          props.toolbarTheme === "secondary"
            ? " toolbar-secondary-color-button"
            : ""
        )
      }
      toolbarId="main-nav-toolbar"
      toolbarThemeType="themed"
      visible={props.drawerVisible[media]}
      onVisibilityChange={changeDrawerVisibility}
      onMediaTypeChange={onMediaTypeChange}
      navItemsId="main-nav-list"
      overlay={false}
      navItems={navItems(false)}
      miniDrawerChildren={
        <ul className="md-list md-toolbar-relative" id="main-nav-list-mini">
          {navItems(true)}
        </ul>
      }
      drawerClassName="dark-theme"
      miniDrawerClassName="dark-theme"
      extractMini={false}
      children={
        <React.Fragment>
          {
            (props.drawerVisible[media] || animating) && media === "mobile"
              ? (
                <div
                  className={
                    "md-overlay md-overlay--drawer md-pointer--hover"
                    + (
                      overlayActive ? " md-overlay--active" : ""
                    )
                  }
                  onClick={onClickOverlay}
                />
              ) : null
          }
          {props.children({ media })}
          <TourPopup />
        </React.Fragment>
      }
    />
  );
};
